import {
    PURGE_SCHOOL,

    GET_SCHOOL,
    GET_SCHOOL_SUCCESS,
    GET_SCHOOL_FAIL,

    GET_SCHOOL_BY_INVITE_CODE_SUCCESS,
    GET_SCHOOL_BY_INVITE_CODE_FAIL,

    GET_FILTERED_SCHOOLS,
    GET_FILTERED_SCHOOLS_SUCCESS,
    GET_FILTERED_SCHOOLS_FAIL,

    GET_ALL_SCHOOLS,
    GET_ALL_SCHOOLS_SUCCESS,
    GET_ALL_SCHOOLS_FAIL,
  
  } from './actionTypes';
  import { createPromiseAction } from '@adobe/redux-saga-promise';

  export const purgeSchool = () => ({
    type: PURGE_SCHOOL,
  });
  
  ///// GET_SCHOOL
  export const getSchoolPromise = createPromiseAction("GET_SCHOOL_PROMISE");

  export const getSchool = schoolId => ({
    type: GET_SCHOOL,
    schoolId: schoolId,
  })
  
  export const getSchoolSuccess = schoolData => ({
    type: GET_SCHOOL_SUCCESS,
    school: schoolData,
  })
  
  export const getSchoolFail = error => ({
    type: GET_SCHOOL_FAIL,
    payload: error,
  });

  export const getSchoolByInviteCodeSuccess = schoolData => ({
    type: GET_SCHOOL_BY_INVITE_CODE_SUCCESS,
    school: schoolData,
  })
  
  export const getSchoolByInviteCodeFail = error => ({
    type: GET_SCHOOL_BY_INVITE_CODE_FAIL,
    payload: error,
  });
  
  ///// GET_FILTERED_SCHOOLS
  
  export const getFilteredSchools = (schoolKeyword) => ({
    type: GET_FILTERED_SCHOOLS,
    schoolKeyword: schoolKeyword,
  })
  
  export const getFilteredSchoolsSuccess = schoolsData => {
    let byIdFilter = {};
    schoolsData.forEach((schoolData) => {
      byIdFilter[schoolData.schoolId] = schoolData});
  
    const allFilteredIds = schoolsData
      .map((schoolData) => schoolData.schoolId);
    return {
      type: GET_FILTERED_SCHOOLS_SUCCESS,
      byIdFilter: byIdFilter,
      allFilteredIds: allFilteredIds,
    }
  }
    
  export const getFilteredSchoolsFail = error => ({
    type: GET_FILTERED_SCHOOLS_FAIL,
    payload: error,
  })

  ///// GET_ALL_SCHOOLS
  
  export const getAllSchools = () => ({
    type: GET_ALL_SCHOOLS,
  })
  
  export const getAllSchoolsSuccess = schoolsData => {
    let byId = {};
    schoolsData.forEach((schoolData) => {
      byId[schoolData.schoolId] = schoolData});
  
    const allIds = schoolsData
      .map((schoolData) => schoolData.schoolId);
    return {
      type: GET_ALL_SCHOOLS_SUCCESS,
      byId: byId,
      allIds: allIds,
    }
  }
    
  export const getAllSchoolsFail = error => ({
    type: GET_ALL_SCHOOLS_FAIL,
    payload: error,
  })
  