import { call, put, takeLeading } from 'redux-saga/effects';
import {
  createReportPromise,
  createReportSuccess,
  createReportFail,
} from './actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* createReportPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createReport, action.payload);
      yield put(createReportSuccess(response.data.reports));
      return yield response.data;
    } catch (error) {
      yield put(createReportFail(error));
    }
  });
}

function* reportSaga() {
  yield takeLeading(createReportPromise, createReportPromiseHandler);
}

export default reportSaga;