import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./SharedScreenFull.module.scss";
import ProfileImage from "@components/ProfileImage";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Tldrawer } from "@components/CanvasDrawer/Tldrawer";
import { CommentsLayer } from "@components/ClassRoom/CommentsLayer/CommentsLayer";

const SharedScreenFull = (props) => {
  const { screenImg, show, setShow, name, timeString } = props;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className={styles["modal"]}
    >
      <Modal.Body className={styles["modal-body"]}>
        <div className={styles["wrapper"]}>
          {screenImg ? (
            <img
              src={`data:image/jpeg;base64,${screenImg}`}
              alt='screenshot'
              className={styles["shared-screen"]}
            />
          ) : (
            <div>선생님이 공유해 주시는 화면이 보이는 곳입니다</div>
          )}
          <CommentsLayer isVisible={show} />
          {/* <Tldrawer isVisible={show} /> */}
          <div className={styles["show-fullscreen-btn"]}>
            <div className={styles["profile-wrapper"]}>
              <ProfileImage size={28} />
              <div className={styles["name"]}>{name}</div>
              <TimerOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  color: "#ffffff",
                  margin: "2px 10px 0 27px",
                }}
              />
              <div>{timeString}</div>
            </div>
            <button onClick={() => setShow(false)}>
              <FullscreenIcon
                style={{
                  color: "#ffffff",
                  width: 16,
                  height: 16,
                }}
              />
              <div className={styles["fullscreen-btn-text"]}>
                전체 화면 종료
              </div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SharedScreenFull;
