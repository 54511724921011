import { call, put, takeLeading } from 'redux-saga/effects';
import {
  getParticipationPromise,
  getParticipationSuccess,
  getParticipationFail,
  getParticipationsByClientPromise,
  getParticipationsByClientSuccess,
  getParticipationsByClientFail,
  getParticipationsByClassroomPromise,
  getParticipationsByClassroomSuccess,
  getParticipationsByClassroomFail,
  createParticipationPromise,
  createParticipationSuccess,
  createParticipationFail,
  updateParticipationPromise,
  updateParticipationSuccess,
  updateParticipationFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getParticipationPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.getParticipation, params);
      yield put(getParticipationSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getParticipationFail(error));
    }
  });
}

function* getParticipationsByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.getParticipationsByClassroom,
        action.payload.classroomId
      );
      yield put(getParticipationsByClientSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getParticipationsByClientFail(error));
    }
  });
}

function* getParticipationsByClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.getParticipationsByClient,
        action.payload.clientId
      );
      yield put(getParticipationsByClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getParticipationsByClassroomFail(error));
    }
  });
}

function* createParticipationPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.createParticipation, params);
      yield put(createParticipationSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createParticipationFail(error));
    }
  });
}

function* updateParticipationPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.updateParticipation, params);
      yield put(updateParticipationSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateParticipationFail(error));
    }
  });
}

function* participationSaga() {
  yield takeLeading(getParticipationPromise, getParticipationPromiseHandler);
  yield takeLeading(
    getParticipationsByClientPromise,
    getParticipationsByClientPromiseHandler
  );
  yield takeLeading(
    getParticipationsByClassroomPromise,
    getParticipationsByClassroomPromiseHandler
  );
  yield takeLeading(
    createParticipationPromise,
    createParticipationPromiseHandler
  );
  yield takeLeading(
    updateParticipationPromise,
    updateParticipationPromiseHandler
  );
}

export default participationSaga;
