import { useQuery } from '@tanstack/react-query';
import { getQuizsetSessionActive } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { setSocketData } from '@store/actions';

export const useQuizsetSession = (groupId = '') => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { data: activeQuizsetSession, isFetching, refetch } = useQuery({
    queryKey: ['quizsetSessionActive', groupId],
    queryFn: () => getQuizsetSessionActive(groupId),
    enabled: !!user?.clientId && !!groupId,
    select: ({ data }) => data,
    refetchOnMount: true,
    onSuccess: ({ quizsetSession }) => {
      switch (quizsetSession.state) {
        case 'READY':
          if (!/quizpang\/.*\/ready/g.test(window.location.pathname)) {
            dispatch(
              setSocketData({
                method: 'POST',
                uri: '/classroom/sendImage',
                groupId,
                clientId: user.clientId,
                type: 'QUIZSET_SESSION_ACTIVE_CHECK',
                data: '',
              })
            );
          }
          break;
        case 'STARTED':
          if (!/quizpang\/.*\/session|result/g.test(window.location.pathname)) {
            dispatch(
              setSocketData({
                method: 'POST',
                uri: '/classroom/sendImage',
                groupId,
                clientId: user.clientId,
                type: 'QUIZSET_SESSION_ACTIVE_CHECK',
                data: '',
              })
            );
          }
          break;
        // case 'FINISHED':
        default:
          break;
      }
    },
  });

  const storageQuizTime = useMemo(() => {
    const storage = localStorage.getItem('quizSessionSpinner');
    if (storage && activeQuizsetSession) {
      const { quizsetSessionQuizJoint, quizsetSession } = activeQuizsetSession;
      const { quizsetSessionId, quizOrder } = JSON.parse(storage);

      if (
        quizOrder === quizsetSessionQuizJoint.quizOrder &&
        quizsetSessionId === quizsetSession.quizsetSessionId
      ) {
        return JSON.parse(storage);
      }
    }
    return null;
  }, [activeQuizsetSession]);

  const saveQuizTime = useCallback(
    (second) => {
      if (!activeQuizsetSession) {
        return;
      }
      localStorage.setItem(
        'quizSessionSpinner',
        JSON.stringify({
          quizsetSessionId:
            activeQuizsetSession?.quizsetSession.quizsetSessionId,
          quizOrder: activeQuizsetSession?.quizsetSessionQuizJoint.quizOrder,
          second,
        })
      );
    },
    [activeQuizsetSession]
  );
  const clearQuizTime = useCallback(() => {
    localStorage.removeItem('quizSessionSpinner');
  }, []);

  return {
    quizsetSession: activeQuizsetSession?.quizsetSession,
    quizOrder: activeQuizsetSession?.quizsetSessionQuizJoint.quizOrder,
    isLoading: isFetching,
    refetch,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  };
};
