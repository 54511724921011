import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  PURGE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAIL,
} from './actionTypes';

export const purgeReportList = () => ({
  type: PURGE_REPORT,
});

//CREATE_REPORT
export const createReportPromise = createPromiseAction('CREATE_REPORT_PROMISE');

export const createReportSuccess = (report) => ({
  type: CREATE_REPORT_SUCCESS,
  report: report,
});

export const createReportFail = (error) => ({
  type: CREATE_REPORT_FAIL,
  payload: error,
});
