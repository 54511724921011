import React from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components";

// const getRanking = (arr) =>
//   arr.map((x, y, z) => z.filter((w) => w > x).length + 1);

const QuizRecord = ({ data }) => {
  const user = useSelector((state) => state.user);
  const myIndex = data.leaderboard.findIndex(
    (x) => x.clientId === user.clientId
  );
  const my = data.leaderboard[myIndex];
  // const scores = data.leaderboard.map((x) => x.score);

  return (
    <Container
      style={{ marginTop: 0, borderBottomWidth: 1, borderColor: "#EEEEEE" }}
    >
      <Column1>{format(new Date(data.startAt), "yyyy-MM-dd")}</Column1>
      <Column2>{data.quizsetTitle}</Column2>
      <Column3>
        {my.rank ? (
          <>
            <RankingTitle1>
              {my.rank}위 ({data.leaderboard.length}명)
            </RankingTitle1>
            <RankingTitle2>{my.correctAnswerCount}문제 정답</RankingTitle2>
            <RankingTitle3>{my.score}점</RankingTitle3>
          </>
        ) : (
          <RankingTitle1>미참여</RankingTitle1>
        )}
      </Column3>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  padding: 18px 16px;
  justify-content: space-between;
`;
const Column1 = styled.div`
  width: 20%;
  color: #00000099;
`;
const Column2 = styled.div`
  width: 30%;
  color: #000000de;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const Column3 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RankingTitle1 = styled.div`
  color: #000000de;
  font-size: 14px;
`;
const RankingTitle2 = styled.div`
  color: #00000099;
  font-size: 12px;
  margin: 0 16px;
`;
const RankingTitle3 = styled.div`
  color: #056b75;
  font-size: 14px;
  font-weight: 500;
`;

export default QuizRecord;
