import { getQuizChoiceByQuizId } from '@api';
import { Quiz } from '@mui/icons-material';
import { Column } from '@pages/Survey/common';
import { createSurveyresponse } from '@store/actions';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const TrueFalseChoices = ({
  title,
  quizId,
  quizType,
  submit,
  handleSubmit,
  link,
  upload,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const { data } = useQuery({
    queryKey: ['surveyChoice', quizId],
    queryFn: () => getQuizChoiceByQuizId(quizId),
  });

  useEffect(() => {
    if (submit) {
      handleSubmit(value, quizId);
    }
  }, [submit, handleSubmit, value, quizId]);

  const choice = data?.data.choices;

  useEffect(() => {
    dispatch(createSurveyresponse(quizId, value, quizType, null, null));
  }, [upload]);

  return (
    <Column>
      <ReactQuill
        value={title}
        readOnly
        theme="bubble"
        style={{
          width: '100%',
        }}
      />
      {link && link !== 'NotSet' ? (
        <img
          crossOrigin="anonymous"
          src={link}
          alt="quiz-image"
          style={{
            width: '50%',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        />
      ) : null}

      <div
        style={{
          gap: '14px',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '24px',
        }}
      >
        {choice?.map((data) => (
          <StyledButton
            key={data.choiceId}
            style={{}}
            selected={value === data.choiceId}
            surveyResponseData={data.choiceId}
            onClick={() => setValue(data.choiceId)}
          >
            {data.description}
          </StyledButton>
        ))}
      </div>
    </Column>
  );
};

const StyledButton = styled.button`
  width: 166px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 8px !important;
  border: ${(props) =>
    props.selected
      ? '2px solid var(--primary-darken-3)'
      : '1px solid var(--grey-lighten-2)'};
  background: ${(props) =>
    props.selected ? 'var(--primary-lighten-5)' : 'white'};
`;
