import React, { memo, useEffect, useState } from 'react';
import DefaultProfile from 'assets/images/icons/default-profile.png';
import styled from 'styled-components'

const Wrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.size * 0.04}px;
`;

const Image = styled.img`
border-radius: ${Number.MAX_SAFE_INTEGER}px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

`;

const ProfileImage = ({ size, url, subscribeImageChange = 0 }) => {
  const [isError, setIsError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const src = url

    useEffect(() => {
      setLoaded(false);
      setIsError(false);
    
      const img = new window.Image();
      img.onload = () => {
        setLoaded(true);
      };
      img.onerror = () => {
        setIsError(true);
      };
      img.src = src;
    }, [url, subscribeImageChange]);

  return (
    <Wrapper size={size}>
      {(!url || isError) && (
        <Image src={DefaultProfile} />
      )}
      {loaded && !isError && url && (
        <Image src={src}/>
      )}
    </Wrapper>
  );
};

export default memo(ProfileImage);
