import {
  PURGE_ACCOUNT,

  REGISTER_TEACHER_SUCCESS,
  REGISTER_TEACHER_FAIL,

} from './actionTypes';
import * as api from '@api';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeAccount = () => ({
  type: PURGE_ACCOUNT,
});

export const registerTeacherPromise = createPromiseAction('REGISTER_TEACHER');

export const registerTeacherSuccess = accountData => {
  return {
    type: REGISTER_TEACHER_SUCCESS,
    accountData: accountData,
  };
}

export const registerTeacherFail = error => {
  return {
    type: REGISTER_TEACHER_FAIL,
    error: error,
  }
}