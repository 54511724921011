import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EraserIcon from '@icons/material/EraserIcon';
import {
  Replay,
  Redo,
  Undo,
  Create,
  FormatColorFill,
} from '@mui/icons-material';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { CompactPicker } from 'react-color';
import { imageToFile, uploadQuizStylusWrite } from '@api';
import { useSelector } from 'react-redux';
import QuizStylusLogger from '../quiz-stylus-logger';

const QuizStylusWriteComponent = ({
  quizId,
  quizsetSessionId,
  quizTimelimit,
  stylusImage,
  setStylusImage,
  containerRef,
  stylusData,
  setStylusData,
  deviceType,
  quizEnded,
  setResponseLink,
  stylusImageUpdateTrigger,
  quizMode,
}) => {
  console.log('styluseImageUpdateTrigger', stylusImageUpdateTrigger);

  const canvasRef = useRef(null);
  const { clientId } = useSelector((state) => state.user);
  // const [stylusData, setStylusData] = useState([]);
  const [strokeWidth, setStrokeWidth] = useState(4);
  const [strokeColor, setStrokeColor] = useState('#000');
  const [isVisibleColor, setIsVisibleColor] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [timeLeft, setTimeLeft] = useState(9999);

  // const sendStylusCapture = async () => {
  //   if (!canvasRef?.current || stylusImage) {
  //     return;
  //   }

  //   const paths = await canvasRef.current.exportPaths();
  //   if (!paths.length) {
  //     return;
  //   }

  //   const data = await canvasRef.current.exportImage('png');
  //   const file = imageToFile(data, clientId);
  //   const res = await uploadQuizStylusWrite(file, quizsetSessionId, quizId);
  //   setStylusImage(res);
  // };

  // useEffect(() => {
  //   if (quizEnded) {
  //     sendStylusCapture();
  //   }
  // }, [quizEnded, quizId, quizsetSessionId, stylusImage, setStylusImage]);

  const onChangeEraserMode = useCallback((isEraser) => {
    canvasRef?.current.eraseMode(isEraser);
    setIsEraserMode(isEraser);
  }, []);

  const onClear = useCallback(() => {
    canvasRef?.current.clearCanvas();
  }, []);

  const onPathChange = useCallback(async () => {
    if (!canvasRef?.current) {
      return;
    }
    const paths = await canvasRef.current.exportPaths();
    if (!paths.length) {
      return;
    }
    const data = await canvasRef.current.exportImage('png');

    setStylusImage(data);
  }, [setStylusImage]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    setTimeLeft(quizTimelimit);

    return () => clearInterval(interval);
  }, [quizId, quizTimelimit, canvasRef]);

  useEffect(() => {
    onPathChange();
  }, [stylusImageUpdateTrigger]);

  useEffect(() => {
    if (timeLeft === 2) {
      onPathChange();
    }
    if (timeLeft === 1) {
      const numRows = stylusData.length;
      // round to 4 decimal places
      let mb = JSON.stringify(stylusData).length / 1024 / 1024;
      mb = Math.round(mb * 10000) / 10000;
      let kb = JSON.stringify(stylusData).length / 1024;
      kb = Math.round(kb * 10000) / 10000;
      // console.log("+-------------------------+")
      // console.log(`stylusLoggerData: ${numRows} rows, ${kb}KB, ${mb}MB`)
      // console.log("+-------------------------+")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <StyledContainer>
      <StyledToolkitWrap>
        <StyledModeButton onClick={() => canvasRef?.current.undo()}>
          <Undo />
        </StyledModeButton>
        <StyledModeButton onClick={() => canvasRef?.current.redo()}>
          <Redo />
        </StyledModeButton>
        <StyledModeButton
          $isActive={!isEraserMode}
          onClick={() => onChangeEraserMode(false)}
        >
          <Create />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isEraserMode}
          onClick={() => onChangeEraserMode(true)}
        >
          <EraserIcon />
        </StyledModeButton>
        <StyledModeButton onClick={onClear}>
          <Replay />
        </StyledModeButton>
        <StyledModeButton
          $backgroundColor={strokeColor}
          onClick={() => setIsVisibleColor(!isVisibleColor)}
        >
          <FormatColorFill />
        </StyledModeButton>
        {isVisibleColor && (
          <StyledGithubPicker
            triangle="top-right"
            color={strokeColor}
            onChangeComplete={(color) => {
              setStrokeColor(color.hex);
              setIsVisibleColor(false);
            }}
          />
        )}
      </StyledToolkitWrap>
      <QuizStylusLogger
        name="SketchCanvas"
        stylusData={stylusData}
        setStylusData={setStylusData}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
        strokeColor={strokeColor}
        containerRef={containerRef}
        isEraser={isEraserMode}
        deviceType={deviceType}
      >
        <ReactSketchCanvas
          ref={canvasRef}
          id="react-sketch-canvas-student"
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 2,
          }}
          eraserWidth={80}
          strokeWidth={strokeWidth}
          strokeColor={strokeColor}
          // onStroke={onPathChange}
        />
      </QuizStylusLogger>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  border: 1px solid #676767;
  border-radius: 8px;
  max-width: 960px;
  margin: 20px auto 0;
`;

const StyledToolkitWrap = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 4px;
  color: white;
  z-index: 5;
  border-radius: 8px;
`;
const StyledModeButton = styled.button`
  padding: 8px;
  font-size: 12px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || '#969696'};
  border-radius: 50%;
  margin: 0 4px;
  border: 1px solid #969696;

  ${({ $isActive }) =>
    $isActive &&
    `
      background-color: white;
      color: black;
  `}
`;
const StyledGithubPicker = styled(CompactPicker)`
  position: absolute !important;
  right: 10px;
  bottom: -90px;
  width: 300px !important;
  background-color: white !important;
`;

export const QuizStylusWrite = memo(QuizStylusWriteComponent);
