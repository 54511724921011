// // helper functions to display gaze information and dot in browser.
// import { gazeArchive } from "./quiz-eyetracking-logger";

// // show gaze information on screen.
// function showGazeInfoOnDom(gazeInfo) {
//   let gazeInfoDiv = document.getElementById("gazeInfo");
//   gazeInfoDiv.innerText = `Gaze Information Below
//                           \nx: ${gazeInfo.x}
//                           \ny: ${gazeInfo.y}
//                           \ntimestamp: ${gazeInfo.timestamp}
//                           \ntrackingState: ${gazeInfo.trackingState}
//                           \neyeMovementState: ${gazeInfo.eyemovementState}
//                           \nleftOpenness: ${gazeInfo.leftOpenness}
//                           \nrightOpenness: ${gazeInfo.rightOpness}
//                           `;
// }

// // hide gaze information on screen.
// function hideGazeInfoOnDom() {
//   let gazeInfoDiv = document.getElementById("gazeInfo");
//   gazeInfoDiv.innerText = "";
// }

// // show gaze dot on screen.
// function showGazeDotOnDom(gazeInfo) {
//   // console.log("Entering showGazeDotOnDom");
//   let canvas = document.getElementById("output");
//   //언제 이 배열이 reset돼서 archive에 넘어가는지에 대한 고민은 필요함
//   // let gazeArchive = [];
//   canvas.width = window.self.innerWidth;
//   canvas.height = window.self.innerHeight;
//   let ctx = canvas.getContext("2d");
//   //점색깔 투명하게하기
//   // ctx.globalAlpha = "0";
//   ctx.fillStyle = "#425080";
//   // console.log("x: ", gazeInfo.x, "y: ", gazeInfo.y);
//   //gaze 좌표 위치 저장
//   // console.log(gazeInfo)
//   gazeArchive.push({ 
//     x: gazeInfo.x, 
//     y: gazeInfo.y, 
//     timeStamp: gazeInfo.timestamp,
//     trackingState: gazeInfo.trackingState,
//     eyemovementState: gazeInfo.eyemovementState,
//     leftOpenness: gazeInfo.leftOpenness,
//     rightOpenness: gazeInfo.rightOpenness
//   });
//   ctx.clearRect(0, 0, canvas.width, canvas.height);

//   // 배열 비우는 코드
//   if (gazeArchive.length == 50) {
//     console.log(gazeArchive);
//     // gazeArchive.splice(0);
//   }
//   // console.log(gazeArchive.length, gazeArchive[gazeArchive.length - 1]);

//   // 현재 gaze위치에 점을 그림
//   ctx.beginPath();
//   ctx.arc(gazeInfo.x, gazeInfo.y, 10, 0, Math.PI * 2, true);
//   ctx.fill();
// }

// function hideGazeDotOnDom() {
//   let canvas = document.getElementById("output");
//   let ctx = canvas.getContext("2d");
//   ctx.clearRect(0, 0, canvas.width, canvas.height);
// }

// function showGaze(gazeInfo) {
//   // console.log("Entering showGaze");
//   // showGazeInfoOnDom(gazeInfo);
//   showGazeDotOnDom(gazeInfo);
// }

// function hideGaze() {
//   // hideGazeInfoOnDom();
//   hideGazeDotOnDom();
// }

// export { showGaze, hideGaze };