import axios from "axios";
// import { REACT_APP_NIA_BACKEND_ADDR } from "@.env";

const Backend = axios.create({
//   baseURL: REACT_APP_NIA_BACKEND_ADDR,
});

let header = {
  headers: {
    "Access-Control-Max-Age": 600,
  },
};

export const niaGetBackend = async (endpoint, annonymous = false) => {
  if (annonymous) {
    return await Backend.get(endpoint, { headers: { Authorization: "" } });
  }
  return await Backend.get(endpoint, header);
};

export const niaPostBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  if (annonymous) {
    return await Backend.post(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await Backend.post(endpoint, payload, header);
};

export const niaPatchBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  if (annonymous) {
    return await Backend.patch(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await Backend.patch(endpoint, payload, header);
};

export const niaDeleteBackend = async (
    endpoint,
    payload = {},
    annonymous = false
  ) => {
    if (annonymous) {
      return await Backend.patch(endpoint, payload, {
        headers: { Authorization: "" },
      });
    }
    return await Backend.delete(endpoint, header);
  };

export const niaPutAWS = async (
  presignedUrl,
  payload = {},
  contentType = 'text/plain',
) => {
  return await fetch(presignedUrl, {method: 'PUT', body: payload, headers: {'Content-Type': contentType}});
}

export const niaGetAWS = async (contentUrl) => {
  return await fetch(contentUrl);
}