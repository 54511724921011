import {
  PURGE_CLIENT,
  PURGE_CLIENT_TEMP,
  GET_CLIENT_TEMP,
  GET_CLIENT_TEMP_SUCCESS,
  GET_CLIENT_TEMP_FAIL,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  FIND_CLIENT,
  FIND_CLIENT_SUCCESS,
  FIND_CLIENT_FAIL,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  SET_PRIMARY_GROUP,
  // SET_PRIMARY_GROUP_SUCCESS,
  SET_PRIMARY_GROUP_FAIL,
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeClient = () => ({
  type: PURGE_CLIENT,
});

export const purgeClientTemp = () => ({
  type: PURGE_CLIENT_TEMP,
});


///// GET_CLIENT

export const getClient = (clientId) => ({
  type: GET_CLIENT,
  clientId: clientId,
});

export const getClientSuccess = (clientData) => ({
  type: GET_CLIENT_SUCCESS,
  client: api.unpackClient(clientData),
});

export const getClientFail = (error) => ({
  type: GET_CLIENT_FAIL,
  payload: error,
});

///// GET_CLIENT TEMP Multiple

export const getClientTemp = (clientIds) => ({
  type: GET_CLIENT_TEMP,
  clientIds: clientIds,
});

export const getClientTempSuccess = (clientData) => {
  let byIdTemp = {};
  clientData.forEach((clientData) => {
    byIdTemp[clientData.clientId] = api.unpackClient(clientData);
  });

  const allIdsTemp = clientData.map((clientData) => clientData.clientId);

  return {
    type: GET_CLIENT_TEMP_SUCCESS,
    byIdTemp: byIdTemp,
    allIdsTemp: allIdsTemp,
  };
};

export const getClientTempFail = (error) => ({
  type: GET_CLIENT_TEMP_FAIL,
  payload: error,
});

///// FIND_CLIENT

export const findClient = (clientEmail) => ({
  type: FIND_CLIENT,
  clientEmail: clientEmail,
});

export const findClientSuccess = (clientData) => {
  let byIdTemp = {};
  clientData.forEach((clientData) => {
    byIdTemp[clientData.clientId] = api.unpackClient(clientData);
  });

  const allIdsTemp = clientData.map((clientData) => clientData.clientId);
  return {
    type: FIND_CLIENT_SUCCESS,
    byIdTemp: byIdTemp,
    allIdsTemp: allIdsTemp,
  };
};

export const findClientFail = (error) => ({
  type: FIND_CLIENT_FAIL,
  payload: error,
});

///// GET_CLIENTS

export const getClients = (clientIds) => {
  return {
    type: GET_CLIENTS,
    clientIds: clientIds,
  };
};

export const getClientsByMembershipSuccess = (clientMembershipsData) => {
  let byId = {};
  clientMembershipsData.forEach((clientMembershipData) => {
    byId[clientMembershipData.client.clientId] = api.unpackClient(
      clientMembershipData.client
    );
  });

  const allIds = clientMembershipsData.map(
    (clientMembershipsData) => clientMembershipsData.client.clientId
  );

  return {
    type: GET_CLIENTS_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getClientsSuccess = (clientsData) => {
  let byId = {};
  clientsData.forEach((clientData) => {
    byId[clientData.clientId] = api.unpackClient(clientData);
  });

  const allIds = clientsData.map((clientData) => clientData.clientId);
  return {
    type: GET_CLIENTS_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getClientsFail = (error) => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
});

// SET_PRIMARY_GROUP,

export const setPrimaryGroup = (clientId, groupId) => {
  return {
    type: SET_PRIMARY_GROUP,
    clientId: clientId,
    groupId: groupId,
  };
};

// export const setPrimaryGroupSuccess = clientData => {
//   const client = api.unpackClient(clientData)
//   return {
//     type: SET_PRIMARY_GROUP_SUCCESS,
//     client: client,
//   };
// }
export const setPrimaryGroupFail = (error) => {
  return {
    type: SET_PRIMARY_GROUP_FAIL,
    payload: error,
  };
};
