import {
  selectAllMyGroupsCachever,
  selectManagingGroupCacheVer,
} from '@app/store/selectors';
import {
  getMembershipPromise,
  getGroupProgramconfigsPromise,
  getTimetablesByGroupPromise,
  getMembershipsByGroupPromise,
  getGroupFilelinkPromise,
  getActiveClassroomByGroupPromise,
} from '@store/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getActiveManagingByGroupPromise,
  getManagingsByGroupPromise,
} from '@app/store/actions';
import { useInterval } from './utils';

const DevManager = () => {
  //chromeExtensionId
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

  let devArray = [
    [
      'managingId1',
      [
        { url: 'youtube.com', dayMax: 3600, usage: 0 },
        { url: 'daum.net', dayMax: 3600, usage: 0 },
        { url: 'samsung.com', dayMax: 0, usage: 0 },
      ],
    ],
    // ['testkey_uuid_2', { url: 'youtube.com', dayMax: 0, usage: 0 }],
    // ['testkey_uuid_3', { url: 'youtube.com', dayMax: 0, usage: 0 }],
    // ['testkey_uuid_4', { url: 'youtube.com', dayMax: 0, usage: 0 }],
  ];

  //   console.log('devArray', devArray);

  useInterval(() => {
    devArray[0][1].usage += 1;
    console.log('devArrayCount', devArray[0][0]);
    chrome.runtime.sendMessage(
      chromeExtensionId,
      { type: 'MANAGING_PROGRAMS', data: devArray },
      function(response) {
        console.log('[DevManager]: ', response);
      }
    );
  }, 1000);
  return <div />;
};

export default DevManager;
