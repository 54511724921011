import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getQuizsetSessionHistoryPromise } from '@app/store/actions';
import SidebarClassRoom from '@components/Layout/SidebarClassRoom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import BaseCard from '@components/Card/baseCard';
import QuizRecord from './quiz-record';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getBackend, getQuizsetSessionHistories } from '@api';
import {
  exitQuizpangDevModePromise,
  setQuizpangDevModePromise,
} from '@store/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BottomContentLayer,
  HeaderSection,
  Heading6,
  MainContentLayer,
  Row,
  Column,
  Body2,
  Subtitle1,
  VerticalSpace8,
  Heading7,
  HorizontalSpace4,
  HeadderButtonLayer,
  MiddleMenuBar,
  HorizontalSpace8,
  Subtitle4,
  Row2,
  Subtitle5,
  SubTitle2,
} from '@app/pages/QuizPang/common';
import { QuizsetSurveyCard } from './quizset-card';
import QueryString from 'qs';
import { useQuizsetSurvey } from './hooks/useSurvey';

const QuizPang = () => {
  const { ref, inView } = useInView();
  const { groupId } = useParams();
  const { tabKey } = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const user = useSelector((state) => state.user);

  //   const historyList = useSelector((state) => state.quizsetSession.histories);
  const [historyList, setHistoryList] = useState([]);
  const participated = historyList.filter((x) => {
    return x.leaderboard.some((y) => y.clientId === user.clientId);
  });
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    setIndex(index + 1);
  };

  const sessionHistory = useCallback(async () => {
    try {
      const res = await getQuizsetSessionHistories({
        groupId: groupId,
        index: index,
      });
      setHistoryList((prev) => prev.concat(res.data.quizsetSessionHistories));
      setIsLoadingMore(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  }, [groupId, index]);

  useEffect(() => {
    sessionHistory();
  }, [groupId, index]);

  // 클릭 시 퀴즈팡 개발자 모드로 전환
  const history = useHistory();

  const handleQuizpangDevMode = () => {
    dispatch(setQuizpangDevModePromise());
  };
  // 클릭 시 퀴즈팡 개발자 모드 해제
  const handleExitQuizpangDevMode = () => {
    dispatch(exitQuizpangDevModePromise());
  };

  const handleQuizpangDevPage = () => {
    history.replace('/dev/quizDevMode');
  };

  const [selected, setSelected] = useState(tabKey || 'survey');
  const [activeButton2, setActiveButton2] = useState(0);
  const [quizsetSurveys, setQuizsetSurveys] = useState([]);

  const quizsetSurvey = useQuizsetSurvey(groupId, user.clientId);

  if (quizsetSurvey.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-2xl font-bold">
          선생님이 공개하신 설문지를 가져오고 있어요...
        </div>
      </div>
    );
  }

  if (quizsetSurvey.isError)
    return (
      <div className="flex justify-center items-center h-screen ml-280">
        <div className="text-2xl font-bold">
          설문지가 없는 것 같아요. 선생님께 문의해주세요.
        </div>
        <div className="text-2xl font-bold">
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            뒤로가기
          </button>
        </div>
      </div>
    );

  const quizsetSurveyData = quizsetSurvey.quizsetSurvey.data;

  console.log('quizsetSurveyData', quizsetSurveyData);

  return (
    <>
      <SidebarClassRoom inClass />
      <Stack ml="280px" p="14px">
        <BaseCard style={{ minHeight: '100vh' }}>
          <Stack px="40px" py="20px" minHeight="100%" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">퀴즈팡</Typography>
            </Stack>
            <Selector>
              <Select
                onClick={() => {
                  setSelected('survey');
                }}
                style={
                  selected === 'survey'
                    ? { color: '#13305A', borderBottom: '#13305A 3px solid' }
                    : { borderBottom: 'transparent 3px solid' }
                }
              >
                설문지
              </Select>
              <Select
                onClick={() => {
                  setSelected('quiz');
                }}
                style={
                  selected === 'quiz'
                    ? { color: '#13305A', borderBottom: '#13305A 3px solid' }
                    : { borderBottom: 'transparent 3px solid' }
                }
              >
                퀴즈 배틀 사용기록
              </Select>
            </Selector>
            {selected === 'quiz' ? (
              <>
                {' '}
                <Divider />
                <SubTitle>나의 퀴즈 기록</SubTitle>
                <Header>
                  <Column1>
                    <HeaderText>참여날짜</HeaderText>
                  </Column1>
                  <Column2>
                    <HeaderText>퀴즈 제목</HeaderText>
                  </Column2>
                  <Column3>
                    <HeaderText>순위</HeaderText>
                  </Column3>
                </Header>
                {isLoading ? (
                  <div>퀴즈팡 기록을 가져오고 있어요.</div>
                ) : participated.length > 0 ? (
                  participated.map((x, i) => <QuizRecord key={i} data={x} />)
                ) : (
                  <div>아직 퀴즈 기록이 없어요.</div>
                )}
                {isLoadingMore ? (
                  <div>퀴즈팡 기록을 가져오고 있어요.</div>
                ) : (
                  historyList.length > 4 && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        onClick={handleLoadMore}
                        style={{
                          backgroundColor: '#0894A0',
                          padding: 8,
                          marginTop: 8,
                          borderRadius: 4,
                          color: '#fff',
                        }}
                      >
                        더보기
                      </button>
                    </div>
                  )
                )}
              </>
            ) : (
              <BottomContentLayer>
                <VerticalSpace8 />
                <Row>
                  <Subtitle1>설문지</Subtitle1>
                  <HorizontalSpace8 />
                  <SortButton
                    style={{
                      color:
                        activeButton2 === 0 || activeButton2 === 1
                          ? 'var(--primary-darken-2)'
                          : '#00000099',
                    }}
                    onClick={() =>
                      setActiveButton2(activeButton2 === 0 ? 1 : 0)
                    }
                  >
                    문제 제작일{activeButton2 === 0 ? ' ▼' : ' ▲'}
                  </SortButton>
                  <HorizontalSpace4 />
                  <HorizontalSpace4 />
                  <SortButton
                    style={{
                      color:
                        activeButton2 === 2 || activeButton2 === 3
                          ? 'var(--primary-darken-2)'
                          : '#00000099',
                    }}
                    onClick={() =>
                      setActiveButton2(activeButton2 === 2 ? 3 : 2)
                    }
                  >
                    문제 질문{activeButton2 === 2 ? ' ▼' : ' ▲'}
                  </SortButton>
                  <HorizontalSpace4 />
                </Row>
                <VerticalSpace8 />
                <StyledGrid>
                  {activeButton2 === 0
                    ? quizsetSurveyData
                        .slice()
                        .sort((a, b) =>
                          b.quizset.createdAt.localeCompare(a.quizset.createdAt)
                        )
                        .map((quizset) => (
                          <QuizsetSurveyCard
                            key={quizset.quizset.quizsetId}
                            allData={quizsetSurveyData}
                            data={quizset}
                            onClickBottomButton={() => {
                              history.push(
                                `/survey/${groupId}/${quizset.quizset.quizsetId}/${quizset.quizsetSession.quizsetSessionId}`
                              );
                            }}
                          />
                        ))
                    : activeButton2 === 1
                    ? quizsetSurveyData
                        .slice()
                        .sort((a, b) =>
                          a.quizset.createdAt.localeCompare(b.quizset.createdAt)
                        )
                        .map((quizset) => (
                          <QuizsetSurveyCard
                            key={quizset.quizset.quizsetId}
                            allData={quizsetSurveyData}
                            data={quizset}
                            onClickBottomButton={() => {
                              history.push(
                                `/survey/${groupId}/${quizset.quizset.quizsetId}/${quizset.quizsetSession.quizsetSessionId}`
                              );
                            }}
                          />
                        ))
                    : activeButton2 === 2
                    ? quizsetSurveyData
                        .slice()
                        .sort((a, b) =>
                          b.quizset.title.localeCompare(a.quizset.title)
                        )
                        .map((quizset) => (
                          <QuizsetSurveyCard
                            key={quizset.quizset.quizsetId}
                            allData={quizsetSurveyData}
                            data={quizset}
                            onClickBottomButton={() => {
                              history.push(
                                `/survey/${groupId}/${quizset.quizset.quizsetId}/${quizset.quizsetSession.quizsetSessionId}`
                              );
                            }}
                          />
                        ))
                    : quizsetSurveys
                        .slice()
                        .sort((a, b) =>
                          a.quizset.title.localeCompare(b.quizset.title)
                        )
                        .map((quizset) => (
                          <QuizsetSurveyCard
                            key={quizset.quizset.quizsetId}
                            allData={quizsetSurveys}
                            data={quizset}
                            onClickBottomButton={() => {
                              history.push(
                                `/survey/${groupId}/${quizset.quizset.quizsetId}/${quizset.quizsetSession.quizsetSessionId}`
                              );
                            }}
                          />
                        ))}
                </StyledGrid>
              </BottomContentLayer>
            )}
          </Stack>
        </BaseCard>
      </Stack>
    </>
  );
};

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 16px;
`;

const Header = styled.div`
  display: flex;

  background-color: #424242;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 16px;
  flex-direction: row;
`;

const Column1 = styled.div`
  width: 20%;
`;

const Column2 = styled.div`
  width: 30%;
`;

const Column3 = styled.div`
  width: 50%;
`;

const HeaderText = styled.div`
  color: #fafafa;
`;

const Caption = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

const RefetchButton = styled.button`
  width: 20%;
  height: 54px;
  background-color: #0ba1ae;
  padding: auto;
  border-radius: 8px;
  color: #fff;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledGrid = styled.div`
  display: grid;
  /*grid-template-columns: repeat(1, minmax(4, 1fr));*/
  grid-gap: 10px;
  /*grid-auto-flow: row;*/
  @media (min-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }
  /*@media (min-width: 2000px) {
    grid-template-columns: repeat(6, 1fr);
  }*/
`;
const SortButton = styled.button`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-size: 12px;
`;
const Selector = styled.div`
  display: flex;
  gap: 15px;
`;
const Select = styled.div`
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;
export default QuizPang;

// {isLoading ? (
//     <div
//       style={{
//         flex: 1,
//         justifyContent: 'center',
//         marginVertical: 48,
//       }}
//     >
//       {/* <ActivityIndicator size={32} color={colors.grey.base} /> */}
//       <Caption style={{ marginTop: 24, textAlign: 'center' }}>
//         기록 로딩 중입니다...
//       </Caption>
//     </div>
//   ) : participated.length > 0 ? (
//     participated.map((x, i) => <QuizRecord key={i} data={x} />)
//   ) : (
//     <text style={{ margin: 8 }}>아직 퀴즈 기록이 없어요.</text>
//   )}
