import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMembershipPromise,
  createParticipationPromise,
  getActiveClassroomByGroupPromise,
  getGroupByInviteCodePromise,
  getManagingsByGroupPromise,
  setSocketData,
} from '@store/actions';
import { useHistory, useParams } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './managing.module.scss';
import {
  selectAllProgramconfigs,
  selectAllPrograms,
  selectGroupById,
  selectManagingByGroupId,
  selectManagingGroup,
} from '@store/selectors';
import PageWrapper from '@components/Layout/PageWrapper';
import MdInput from '@components/Input/MdInput';
import FileLink from '@components/Layout/FileLink';
import { ReactComponent as CalendarSvg } from '@images/icons/calendar.svg';
import icons from '@images/icons/appIcons';
import notice from '../../assets/images/icons/img_notice.png';
import ImgOffClass from '@images/classroom/playful cat-pana.png';
import moment from 'moment';

const Managing = () => {
  /**
   * @type {{groupId: string | undefined}}
   */
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [inviteCode, setInviteCode] = useState('');
  const user = useSelector((state) => state.user);
  const programs = useSelector((state) => state.programs);
  const eventEmitter = useSelector((state) => state.control.eventEmitter);

  const [activeAfterSchoolClassroom, setActiveAfterSchoolClassroom] = useState(
    /** @type {focuspang.Classroom?} */ (null)
  );
  const [filelinks, setFilelinks] = useState(
    /** @type {focuspang.Filelink[]?} */ (null)
  );
  const [programconfigs, setProgramconfigs] = useState(
    /** @type {focuspang.Programconfig[]?} */ (null)
  );
  const [activities, setActivities] = useState(/** @type {Activity[]} */ ([]));
  const [groups, setGroups] = useState(
    /** @type {focuspang.Group[]?} */ (null)
  );
  const [currentGroup, setCurrentGroup] = useState(
    /** @type {focuspang.Group?} */ (null)
  );

  // useEffect(async () => {
  //   const groups = (await dispatch(getMembershipPromise(user.clientId))).map(
  //     (membership) => membership.group
  //   );
  //   console.log('[Home] groups', groups);
  //   setGroups(groups);

  //   const activeClassroom = await dispatch(
  //     getActiveClassroomByGroupPromise(params.groupId)
  //   );

  //   if ((params.groupId ?? null) !== null) {
  //     if ((activeClassroom ?? null) === null) {
  //       history.replace(`/classroom/off/${params.groupId}`);
  //     } else {
  //       console.log('[AfterSchool] activeClassroom', activeClassroom);
  //       setActiveAfterSchoolClassroom(activeClassroom);

  //       global.eventEmitter.on('filelinkchanged', async () => {
  //         const filelinks = await dispatch(
  //           getGroupFilelinkPromise(params.groupId)
  //         );
  //         console.log('[AfterSchool] filelinks', filelinks);
  //         setFilelinks(filelinks);
  //       });
  //       global.eventEmitter.emit('filelinkchanged');

  //       global.eventEmitter.on('programconfigchanged', async () => {
  //         const programconfigs = await dispatch(
  //           getGroupProgramconfigsPromise(params.groupId)
  //         );
  //         console.log('[AfterSchool] programconfigs', programconfigs);
  //         setProgramconfigs(programconfigs);
  //       });
  //       global.eventEmitter.emit('programconfigchanged');
  //     }
  //   }
  // }, []);

  const extensionResponseData = useSelector(
    (state) => state.programconfigs.extensionResponseData
  );

  useEffect(() => {
    setCurrentGroup(
      groups?.find((group) => group.groupId === params.groupId) ?? null
    );
  }, [groups]);

  // NOTE: 그룹은 있으나 수업이 없으면 리다이렉트
  // useEffect(() => {
  //   if (params.groupId != null) {
  //     dispatch(getActiveClassroomByGroupPromise(params.groupId)).then((activeClassroom) => {
  //       if (activeClassroomSession == null) {
  //         history.replace(`/classroom/off/${params.groupId}`);
  //       } else {
  //         setActiveAfterSchoolClassroom(activeClassroomSession);
  //         dispatch(getGroupFilelinkPromise(params.groupId));
  //       }
  //     });
  //   }
  // }, [params.groupId]);

  const renderInformation = (title, information) => {
    return (
      <div className={styles['my-info-line']}>
        <span className={styles['span-info-title']}>{title}</span>
        <div className={styles['render-info']}>
          <span className={styles['span-render-info']}>{information}</span>
        </div>
      </div>
    );
  };

  /**
   *
   * @param {number} seconds
   */
  const convertTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds - hours * 3600) / 60);
    const sec = seconds - hours * 3600 - min * 60;

    if (hours === 0 && min === 0 && sec === 0) {
      return null;
    }

    return `${hours > 0 ? `${hours}시간` : ''} ${
      hours > 0 || min > 0 ? `${min}분` : '0분'
    }`;
  };

  // !!NOTE: 네이티브에서 가져오게 되는 앱 사용시간을 activities에 저장하는 interval에 돌릴 함수
  const activityUpdater = () => {
    programconfigs.forEach((programconfig) => {
      /** @type {Activity} */
      const activity = {
        id: programconfig.programId,
        activity: Math.trunc(Math.random() * 100),
      }; //window.cApi.getActivity(programconfig.programId);
      if (activity) {
        setActivities([
          ...activities,
          { id: programconfig.programId, activity: activity.activity },
        ]);
      }
    });
  };

  //useEffect(() => {
  //  // NOTE: getGroupPromise가 없어서 invite code로 사용하도록 함
  //  dispatch(getGroupByInviteCodePromise(params.groupId?.substring(0, 8))).then((group) => {
  //    setAfterSchoolGroup(group);
  //  });
  //}, []);

  const joinToGroup = () => {
    const code = inviteCode.replace(/ /gi, '');
    dispatch(getGroupByInviteCodePromise(code))
      .then((group) => {
        dispatch(
          createMembershipPromise({
            groupId: group.groupId,
            clientId: user.clientId,
            role: 'STUDENT',
          })
        ).then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: group.groupId,
              clientId: user.clientId,
              type: 'JOIN_GROUP',
              data: '',
            })
          );

          dispatch(getActiveClassroomByGroupPromise(group.groupId)).then(
            (activeClassroom) => {
              console.log('active classroom', activeClassroom);
              if (activeClassroom !== null) {
                dispatch(
                  createParticipationPromise({
                    classroomId: activeClassroom?.classroomId,
                    clientId: user.clientId,
                    attendedAt: new Date().toISOString(),
                  })
                ).then((e) => {
                  dispatch(
                    setSocketData({
                      method: 'POST',
                      uri: '/classroom/sendImage',
                      groupId: group.groupId,
                      clientId: user.clientId,
                      type: 'ATTEND_CLASS',
                      data: '',
                    })
                  );

                  // NOTE: 웹소켓으로 보낸 후 라는 보장이 없어서 0.1초 후에 실행.
                  setTimeout(() => {
                    history.go(0);
                  }, 100);
                });
              } else {
                history.go(0);
              }
            }
          );
        });
      })
      .catch(() => {
        alert('존재하지 않는 초대 코드입니다.');
      });
  };

  useEffect(() => {
    if (user.state === 'NOT_SIGNED_IN') {
      history.replace('/auth');
    }
  }, [user.state]);

  // useEffect(() => {
  //   const schoolId = group ? group.school : sub_group[0]?.school;
  //   dispatch(getSchoolPromise(schoolId)).then((data) => {
  //     setSchool(data.name);
  //   });
  // }, []);

  const managingGroup = useSelector((state) => selectManagingGroup(state));

  const managingSchedule = useSelector((state) =>
    selectManagingByGroupId(state, params?.groupId)
  );

  const managingScheduleInManaging = managingSchedule?.filter(
    (schedule) => schedule.state === 'IN_MANAGING'
  );

  const groupByGroupId = useSelector((state) =>
    selectGroupById(state, params?.groupId)
  );

  const allPrograms = useSelector((state) => selectAllPrograms(state));

  const allProgramConfigs = useSelector((state) =>
    selectAllProgramconfigs(state)
  );

  const getProgramUrl = (programId) => {
    const program = allPrograms.find(
      (program) => program.programId === programId
    );
    return program?.web;
  };

  const getRemainTime = (dayMax, usage) => {
    if (dayMax <= 0) {
      return 0;
    }
    if (dayMax > 0) {
      return dayMax - usage;
    }
  };

  return (
    <>
      <Sidebar afterSchoolGroup={currentGroup ?? undefined} />

      {managingGroup.length === 0 && (
        // 관리 그룹 없을 경우
        <PageWrapper title="관리 그룹 초대받기">
          <div className={styles['inviteText']}>
            선생님께 전달 받은 초대 코드를 입력해주세요.
          </div>
          <MdInput
            label="초대 코드"
            value={inviteCode}
            onChange={(
              /** @type {React.ChangeEvent<HTMLInputElement>} */ e
            ) => {
              setInviteCode(e.target.value);
            }}
            style={{ marginTop: 16, width: 328 }}
          />
          <div className={styles['submit-container']}>
            <button
              className={styles['submit-button']}
              onClick={(e) => {
                e.preventDefault();
                joinToGroup();
              }}
            >
              제출
            </button>
          </div>
        </PageWrapper>
      )}

      {managingScheduleInManaging?.length === 0 ||
      managingScheduleInManaging === undefined ? (
        <PageWrapper>
          <div className={styles['wrapper']}>
            <div className={styles['main-container']}>
              <img
                className={styles['img-off-class']}
                src={ImgOffClass}
                alt="활성화된 수업 없음"
              />
              <span className={styles['span-bold-notice']}>
                아직 관리 중이 아니에요.
              </span>
            </div>

            <div className={styles['file-link']}>
              <div className={styles['link-title']}>받은 링크 목록</div>
              {filelinks && <FileLink filelinks={filelinks} />}
            </div>
          </div>
        </PageWrapper>
      ) : (
        <div></div>
      )}

      {managingScheduleInManaging && managingScheduleInManaging.length !== 0 && (
        <>
          <PageWrapper>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={styles['container']}>
                <div className={styles['span-section-title']}>
                  {groupByGroupId?.subjectName} -{' '}
                  {managingScheduleInManaging[0]?.title}
                </div>
                <div className={styles['line']} />
                <div className={styles['subtitle']}>
                  수업 시간 외 사용이 관리되는 앱/웹사이트 목록입니다.
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 16,
                  }}
                >
                  <div className={styles['period-container']}>
                    <CalendarSvg fill="#ffffff" style={{ marginRight: 8 }} />
                    <div className={styles['period-text']}>
                      자기관리 기간 :{' '}
                    </div>
                    <div className={styles['period-text2']}>
                      {`${moment(
                        managingScheduleInManaging[0]?.startDate
                      ).format('YYYY.MM.DD A hh:mm')} ~ ${moment(
                        managingScheduleInManaging[0]?.endDate
                      ).format('YYYY.MM.DD A hh:mm')}`}
                    </div>
                  </div>
                </div>

                {allProgramConfigs?.filter(
                  (programConfig) =>
                    programConfig?.groupId ===
                    managingScheduleInManaging[0].managingId
                )?.length > 0 ? (
                  <>
                    <div className={styles['userInfo-container']}>
                      <div className={styles['bar-title']}>
                        관리 앱/웹사이트
                      </div>
                      <div className={styles['bar-title']}>
                        총 사용 가능 시간
                      </div>
                      <div className={styles['bar-title']}>남은 사용 시간</div>
                    </div>

                    {allProgramConfigs
                      ?.filter(
                        (programConfig) =>
                          programConfig?.groupId ===
                          managingScheduleInManaging[0].managingId
                      )
                      ?.map((programconfig, index) => (
                        <div className={styles['card-container']}>
                          <div className={styles['card-textBox']}>
                            <div
                              style={{
                                width: 28,
                                height: 28,
                                borderRadius: 4,
                                marginRight: 8,
                              }}
                            >
                              {(() => {
                                const icon =
                                  icons[
                                    programs.byId[programconfig?.programId]
                                      ?.alias
                                  ];
                                return icon ? (
                                  <img src={icon} alt="icon" />
                                ) : (
                                  <img src={notice} alt="icon" />
                                );
                              })()}
                            </div>
                            <div>
                              {programs.byId[programconfig.programId]
                                ?.korName ?? '알수 없음'}
                            </div>
                          </div>
                          <div className={styles['card-textBox2']}>
                            {convertTimeString(
                              programconfig.dayMax -
                                (activities.find(
                                  (activity) =>
                                    activity.id === programconfig.programId
                                )?.activity ?? 0)
                            ) ?? (
                              <span style={{ color: '#B71C1C' }}>
                                사용 못함
                              </span>
                            )}
                          </div>
                          <div className={styles['card-textBox3']}>
                            {convertTimeString(
                              getRemainTime(
                                programconfig.dayMax,
                                extensionResponseData.find(
                                  (data) =>
                                    data.url ===
                                    getProgramUrl(programconfig.programId)
                                )?.usage ?? 0
                              )
                            ) ?? (
                              <span style={{ color: '#B71C1C' }}>
                                사용 못함
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <div
                    className={styles['subtitle2']}
                    style={{
                      paddingTop: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    관리중인 앱/웹사이트가 없습니다.
                  </div>
                )}
              </div>

              <div className={styles['file-link']}>
                <div className={styles['link-title']}>받은 링크 목록</div>
                {filelinks && <FileLink filelinks={filelinks} />}
              </div>
            </div>
          </PageWrapper>
        </>
      )}
    </>
  );
};

export default Managing;

{
  /* <PageWrapper title='관리 그룹 초대받기'>
        <div className={styles["inviteText"]}>선생님께 전달 받은 초대 코드를 입력해주세요.</div>
        <MdInput
          label="초대 코드"
          style={{ marginTop: 16, width: 328 }}
        />
        <div className={styles["submit-container"]}>
          <button className={styles["submit-button"]}>제출</button>
        </div>
      </PageWrapper> */
}
