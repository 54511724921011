import { authProtectedRoutes } from "@routes";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { store } from "@store";
// import { rrfProps } from "@store";
import { Switch, useHistory } from "react-router-dom";
import AppRoute from "@routes/route";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MasterManager from "./managers";
import ReactGA from 'react-ga';
import './common.scss'
import '@tldraw/tldraw/tldraw.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#0BA1AE",
    },
  },
});

const App = (props) => {
  const persistor = persistStore(store, {});
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize("G-27BKQ9XN89");
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <Switch>
            {authProtectedRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}
          </Switch>
          <MasterManager />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
