import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCommentsLayerPromise,
  getCommentsLayerSuccess,
  getCommentsLayerFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

/**
 * CommentsLayer 활성 상태
 * @param { groupId } action
 */
function* getCommentsLayer(action) {
  // yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getCommentsLayer, action.payload);
      yield put(getCommentsLayerSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getCommentsLayerFail(error));
    } finally {
      // yield put(updateGroupActivityLoading(false));
    }
  });
}

function* commentsLayerSaga() {
  yield takeLatest(getCommentsLayerPromise, getCommentsLayer);
}

export default commentsLayerSaga;
