
export async function getSvgAsImage(svg, options) {
  const { type, quality, scale } = options;
  const width = +svg.getAttribute("width");
  const height = +svg.getAttribute("height");
  let scaledWidth = width * scale;
  let scaledHeight = height * scale;
  const dataUrl = await getSvgAsDataUrl(svg);
  scaledWidth = Math.floor(scaledWidth);
  scaledHeight = Math.floor(scaledHeight);
  const canvas = await new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = async () => {
      await new Promise((resolve2) => setTimeout(resolve2, 250));
      const canvas2 = document.createElement("canvas");
      const ctx = canvas2.getContext("2d");
      canvas2.width = scaledWidth;
      canvas2.height = scaledHeight;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
      URL.revokeObjectURL(dataUrl);
      resolve(canvas2);
    };
    image.onerror = () => {
      resolve(null);
    };
    image.src = dataUrl;
  });
  const blob = await new Promise((resolve) =>
    canvas.toBlob(
      (blob2) => {
        resolve(blob2);
      },
      "image/" + type,
      quality
    )
  );
  const reader = new FileReader();
  reader.readAsDataURL(blob); 
  const base64 = await new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
  });
  return base64;
}

/** @public */
export async function getSvgAsDataUrl(svg) {
  const clone = svg.cloneNode(true);
  clone.setAttribute("encoding", 'UTF-8"');

  const fileReader = new FileReader();
  const imgs = Array.from(clone.querySelectorAll("image"));

  for (const img of imgs) {
    const src = img.getAttribute("xlink:href");
    if (src) {
      if (!src.startsWith("data:")) {
        const blob = await (await fetch(src)).blob();
        const base64 = await new Promise()((resolve, reject) => {
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.onerror = () => reject(fileReader.error);
          fileReader.readAsDataURL(blob);
        });
        img.setAttribute("xlink:href", base64);
      }
    }
  }

  return getSvgAsDataUrlSync(clone);
}

/** @public */
export function getSvgAsDataUrlSync(node) {
  const svgStr = new XMLSerializer().serializeToString(node);
  // NOTE: `unescape` works everywhere although deprecated
  const base64SVG = window.btoa(unescape(encodeURIComponent(svgStr)));
  return `data:image/svg+xml;base64,${base64SVG}`;
}
