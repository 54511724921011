// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stickers_container__6gV5O {\n  display: flex;\n  flex-wrap: nowrap; }\n  .Stickers_container__6gV5O .Stickers_span-shorted__6x\\+q6 {\n    height: 60px;\n    line-height: 60px;\n    font-size: 14px;\n    color: var(--text-black-light); }\n", "",{"version":3,"sources":["webpack://./src/components/ClassRoom/Stickers/Stickers.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB,EAAA;EAFnB;IAKI,YAAY;IACZ,iBAAiB;IAEjB,eAAe;IACf,8BAA8B,EAAA","sourcesContent":[".container{\r\n  display: flex;\r\n  flex-wrap: nowrap;\r\n\r\n  .span-shorted{\r\n    height: 60px;\r\n    line-height: 60px;\r\n\r\n    font-size: 14px;\r\n    color: var(--text-black-light);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Stickers_container__6gV5O",
	"span-shorted": "Stickers_span-shorted__6x+q6"
};
export default ___CSS_LOADER_EXPORT___;
