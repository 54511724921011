import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    getQuizChoiceByChoiceIdPromise,
    getQuizChoiceByChoiceIdSuccess,
    getQuizChoiceByChoiceIdFail,
    getQuizChoiceByQuizIdPromise,
    getQuizChoiceByQuizIdSuccess,
    getQuizChoiceByQuizIdFail,
    createQuizChoicePromise,
    createQuizChoiceSuccess,
    createQuizChoiceFail,
    updateQuizChoicePromise,
    updateQuizChoiceSuccess,
    updateQuizChoiceFail,
    deleteQuizChoicePromise,
    deleteQuizChoiceSuccess,
    deleteQuizChoiceFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getQuizChoiceByChoiceIdPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizChoiceByChoiceId, action.payload);
      yield put(getQuizChoiceByChoiceIdSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getQuizChoiceByChoiceIdFail(error));
    }
  })
};

function* getQuizChoiceByQuizIdPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizChoiceByQuizId, action.payload);
      yield put(getQuizChoiceByQuizIdSuccess(response.data.choices));
      return yield response.data;
    } catch (error) {
      yield put(getQuizChoiceByQuizIdFail(error));
    }
  });
}

function* createQuizChoicePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizChoice, action.payload);
      yield put(createQuizChoiceSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizChoiceFail(error));
    }
  });
}

function* updateQuizChoicePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateQuizChoice, action.payload);
      yield put(updateQuizChoiceSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateQuizChoiceFail(error));
      throw error;
    }
  });
}

function* deleteQuizChoicePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteQuizChoice, action.payload);
      yield put(deleteQuizChoiceSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteQuizChoiceFail(error));
    }
  });
}

function* quizChoiceSaga() {
  yield takeLeading(getQuizChoiceByChoiceIdPromise, getQuizChoiceByChoiceIdPromiseHandler);
  yield takeEvery(getQuizChoiceByQuizIdPromise, getQuizChoiceByQuizIdPromiseHandler);
  yield takeLeading(createQuizChoicePromise, createQuizChoicePromiseHandler);
  yield takeLeading(updateQuizChoicePromise, updateQuizChoicePromiseHandler);
  yield takeLeading(deleteQuizChoicePromise, deleteQuizChoicePromiseHandler);
}

export default quizChoiceSaga;
