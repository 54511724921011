import { profileImgPath, refreshToken } from '@api';
import { purgeAll, setProfileImageURL } from '@store/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const AuthManager = ({ children }) => {
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user
  );
  const updated = useSelector((state) => state.user.updated);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [userValidated, setUserValidated] = useState(false);

  useEffect(() => {
    if (user.signedIn) {
      if (!updated) {
        dispatch(purgeAll());
      }
      if (!userValidated) {
        setUserValidated(true);
      }
    }
    if (!user.signedIn) {
      setUserValidated(false);
      console.log('[AuthManager]', 'user.signedIn false');
      history.replace('/auth');
      // return setRootToSignInIfNotAlready();
    }

    if (user?.state == null) {
      console.log('[AuthManager]', 'user.state null');
      return;
    }

    if (!updated) {
      dispatch(purgeAll());
    }

    dispatch(
      setProfileImageURL(
        `${profileImgPath(user.clientId)}?${new Date().getTime()}`
      )
    );
  }, [user]);

  const [prevUserState, setPrevUserState] = useState(null);
  useEffect(() => {
    if (user?.state == null) {
      return;
    }

    if (prevUserState === user.state) {
      return;
    }

    console.log('[AuthManager]', prevUserState, '->', user.state);
    setPrevUserState(user.state);
    refreshToken();

    switch (user.state) {
      case 'NOT_SIGNED_IN':
        history.replace('/auth');
        //   setRootToSignInIfNotAlready();
        return;

      case 'REGISTERED_APPORVED':
        setUserValidated(true);
        return;

      default:
        console.log('[AuthManager]', 'invalid user state:', user.state);

        setUserValidated(true);
        return;
    }
  }, [user.state]);

  useEffect(() => {
    if (!userValidated) {
      return;
    }

    console.log(
      '[AuthManager]',
      ' navigating to ClassStack',
      'current:',
      location.pathname
    );
    if (location.pathname.includes('auth')) {
      history.replace('/home');
    }
  }, [userValidated, user.state, location.pathname, history]);

  return <div>{userValidated && children}</div>;
};

export default AuthManager;
