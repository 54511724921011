export const selectSubquestionAnswer = (state, subquestionId) => {
  const answer = state.answer.allIds
    .map(answerId => state.answer.byId[answerId])
    .filter(answer => answer.subquestionId === subquestionId);

  if (answer.length == 0) {
    return [];
  }
    
  return answer;
}

export const selectAllAnswers = (state) => {
    if (state.answer.allIds.length == 0) {
        return [];
    }
    return state.answer.allIds.map(answerId => state.answer.byId[answerId]);
}