import { Input, TextField } from '@mui/material';
import QuizKeyboardLogger from '@pages/QuizPang/quiz-keyboard-logger';
import { debounce } from '@pages/Survey/utils';
import { createSurveyresponse } from '@store/actions';
import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch } from 'react-redux';

export const LongChoices = ({
  title,
  quizId,
  submit,
  quizType,
  handleSubmit,
  keyboardData,
  setKeyboardData,
  deviceType,
  link,
  upload,
  quizCurrentIndex,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(null);

  // const debouncedDispatch = debounce((quizId, newValue) => {
  //   console.log('debouncedDispatch', quizId, newValue);
  //   setInputValue(newValue);
  // }, 800);

  useEffect(() => {
    if (submit) {
      handleSubmit(inputValue, quizId);
    }
  }, [submit, handleSubmit, inputValue, quizId]);

  // useEffect(() => {
  //   // 키보드 로거의 입력이 감지되면 debouncedDispatch를 실행합니다.
  //   // debouncedDispatch는 800ms의 딜레이가 있습니다.
  //   // 따라서 800ms동안 입력이 없으면 debouncedDispatch가 실행됩니다.
  //   // debouncedDispatch는 survey response를 생성합니다.
  //   // survey response는 survey reducer에 저장됩니다.
  //   debouncedDispatch(quizId, inputValue);
  // }, [keyboardData]);

  const handleTextChange = (text) => {
    const newValue = text.target.value;
    setInputValue(newValue);
    // debouncedDispatch(quizId, newValue);
  };

  // const uniqueSrc = `${link}?time=${new Date().getTime()}`;

  useEffect(() => {
    dispatch(createSurveyresponse(quizId, null, quizType, inputValue, null));
  }, [upload]);

  return (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
        // 스크롤 가능하게
        overflow: 'auto',
        // 높이 지정
        height: '100%',
        // 스크롤바 숨기기
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <ReactQuill
        value={title}
        readOnly
        theme="bubble"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      {link && link !== 'NotSet' ? (
        //https://focuspang-test.s3.ap-northeast-2.amazonaws.com/1697529149307.png
        <img
          crossOrigin="anonymous"
          src={link}
          alt="quiz-image"
          style={{
            width: '480px',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
            marginTop: '120px',
          }}
        />
      ) : null}

      <Input
        variant="outlined"
        defaultValue={inputValue}
        // value={inputValue}
        // onChange={handleTextChange}
        sx={{ width: '70%' }}
        style={{
          width: 700,
          height: 200,
          backgroundColor: '#61616114',
          borderRadius: 8,
          padding: 16,
          textAlignVertical: 'top',
          fontSize: 16,

          marginTop: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '20px',
        }}
        placeholderTextColor="#00000099"
        multiline={true}
        readOnly={
          deviceType !== 'iOS' && deviceType !== 'Android' ? false : true
        }
      />
      {deviceType !== 'iOS' && deviceType !== 'Android' ? null : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <QuizKeyboardLogger
            input={inputValue}
            setInput={setInputValue}
            keyboardData={keyboardData}
            setKeyboardData={setKeyboardData}
            deviceType={deviceType}
            quizCurrentIndex={quizCurrentIndex}
          />
        </div>
      )}
    </Stack>
  );
};
