import { POST_DATA_SUCCESS, POST_DATA_FAILURE, CLIP_AND_SEND_GPS_DATA, CLIP_AND_SEND_TOUCH_DATA, CLIP_AND_SEND_KEYBOARD_DATA, CLIP_AND_SEND_STYLUS_DATA, CLIP_AND_SEND_ACCELGYRO_DATA, CLIP_AND_SEND_EYETRACKING_DATA} from './actionTypes';

export const postDataSuccess = (domainType, response) => ({
  type: POST_DATA_SUCCESS,
  payload: { domainType, response },
});

export const postDataFailure = (domainType, response) => ({
  type: POST_DATA_FAILURE,
  payload: { domainType, response },
});

export const clipAndSendGpsData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_GPS_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});

export const clipAndSendTouchData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_TOUCH_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});

export const clipAndSendKeyboardData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_KEYBOARD_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});

export const clipAndSendStylusData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_STYLUS_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});

export const clipAndSendAccelgyroData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_ACCELGYRO_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});

export const clipAndSendEyetrackingData = (clientId, quizId, domainType, quizStart, quizEnd) => ({
  type: CLIP_AND_SEND_EYETRACKING_DATA,
  payload: {clientId, quizId, domainType, quizStart, quizEnd},
});