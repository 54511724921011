import styled from 'styled-components';
import { Button, Switch } from '@mui/material';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BaseText = styled.span`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Heading4 = styled(BaseText)`
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  color: #000000;
`;

export const Heading5 = styled(BaseText)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

export const Heading6 = styled(BaseText)`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`;

export const Heading7 = styled(BaseText)`
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`;

export const Caption = styled(BaseText)`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
`;

export const CaptionMedium = styled(Caption)`
  font-weight: 500;
`;

export const Subtitle1 = styled(BaseText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Subtitle2 = styled(BaseText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Subtitle3 = styled(BaseText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #353433;
`;
export const Subtitle4 = styled(BaseText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

export const Subtitle5 = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

export const Body1 = styled(BaseText)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Body2 = styled(BaseText)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

export const SideBarRest = styled(Row)`
  flex: 1;
  min-height: 100vh;
  margin-left: 280px;
  background-color: var(--primary-light);
  padding: 14px 14px 0 14px;
`;

export const StickySection = styled(Column)`
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: var(--primary-blue-700);
  padding: 19px 12px;
  text-align: start;
  font-weight: bold;
  color: white;
`;

export const MainContentLayer = styled(Column)`
  flex: 1;
  height: 100%;
  background-color: #fff;
  border: 1px solid #eee;
`;
export const StudentLayer = styled(Column)`
  flex: 1 1 400px;
  min-width: 400px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #eee;
`;

export const HeaderSection = styled(Column)`
  padding: 16px 24px 0 24px;
`;

export const HeaderSectionHeading = styled(Heading6)`
  margin: 8px 24px 8px 0;
`;

// row headerSection
export const HeaderSectionRow16 = styled(Row)`
  padding: 16px 24px 16px 24px;
  align-items: center;
`;
// row header section padding top 0
export const HeaderSectionRow0 = styled(Row)`
  padding: 16px 24px 16px 0px;
  align-items: center;
`;

export const MiddleMenuBar = styled(Row)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 12px 24px;
  justify-content: space-between;
`;

export const TabButton = styled(Button).attrs({
  variant: 'outlined',
})`
  width: 120px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.6);

  :hover {
    color: #0ba1ae;
  }
`;

export const TabButtonSmall = styled(TabButton)`
  width: 96px;
`;

export const TabButtonFocused = styled(TabButton)`
  background-color: #0ba1ae;
  color: #fff;
  border: none;

  :hover {
    background-color: #0ba1ae;
    color: #fff;
    border: none;
  }
`;

export const TabButtonInteractive = ({ isSelected, children, onClick }) => {
  if (isSelected)
    return (
      <TabButtonFocused
        onClick={onClick}
        style={{ backgroundColor: 'var(--primry-daken-2)', color: '#fff' }}
      >
        {children}
      </TabButtonFocused>
    );
  return <TabButton onClick={onClick}>{children}</TabButton>;
};

export const TabButtonFocusedSmall = styled(TabButtonFocused)`
  width: 96px;
`;

export const SubTitle2 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.37);
`;

export const HeadderButtonLayer = styled(Row)`
  padding: 16px 24px;
`;

export const BottomContentLayer = styled(Column)`
  padding: 16px 24px;
`;

export const VerticalSpace4 = styled.div`
  height: 4px;
`;

export const VerticalSpace8 = styled.div`
  height: 8px;
`;

export const VerticalSpace12 = styled.div`
  height: 12px;
`;

export const VerticalSpace16 = styled.div`
  height: 16px;
`;

export const VerticalSpace24 = styled.div`
  height: 24px;
`;

export const HorizontalSpace4 = styled.div`
  width: 4px;
`;

export const HorizontalSpace8 = styled.div`
  width: 8px;
`;

export const HorizontalSpace12 = styled.div`
  width: 12px;
`;

export const HorizontalSpace16 = styled.div`
  width: 16px;
`;

export const HorizontalSpace4M = styled.div`
  margin-left: 4px;
`;

export const InputGray = styled.input`
  padding: 16px 12px;
  background: rgba(97, 97, 97, 0.08);
  border-radius: 4px;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputWhite = styled.input`
  padding: 9px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  flex: 1;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputGrayScroll = styled.textarea`
  padding: 16px 12px;
  background: rgba(97, 97, 97, 0.08);
  border-radius: 4px;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  resize: vertical; // 세로 방향으로만 resize 가능하도록 설정
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputWhiteScroll = styled.textarea`
  padding: 9px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #fff;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  resize: vertical; // 세로 방향으로만 resize 가능하도록 설정
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const ArrowBackIconContainer = styled.div`
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;

  svg {
    color: var(--primary);

    &:hover {
      color: var(--secondary);
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
`;

export const SwitchMuiCustom = ({ text, ...props }) => {
  return (
    <>
      <SwitchText>{text}</SwitchText>
      <Switch
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={props.onChange}
        sx={{
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'var(--primary-darken-2)',
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'var(--point-opacity-4)',
          },
        }}
      />
    </>
  );
};

const SwitchText = styled(Caption)`
  color: var(--text-black-dark);
  font-weight: 500;
`;

export const ButtonCustom = ({ children, ...props }) => {
  return (
    <ButtonStyle style={props.style} onClick={props.onClick}>
      {children}
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  border-radius: 4px;
  font-size: 14px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;
