import {
  PURGE_SUBQUESTION,

  GET_SUBQUESTIONS_SUCCESS,

  GET_SUBQUESTION_CONTENT,
  GET_SUBQUESTION_CONTENT_SUCCESS,
  GET_SUBQUESTION_CONTENT_FAIL,

  GET_SUBQUESTION_SOLUTION,
  GET_SUBQUESTION_SOLUTION_SUCCESS,
  GET_SUBQUESTION_SOLUTION_FAIL,

} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeSubquestion = () => ({
  type: PURGE_SUBQUESTION,
})

///// GET_SUBQUESTIONS
export const getSubquestionsSuccess = (subquestions) => {
  let newSubquestionById = {};
  subquestions.forEach(subquestion => {
    newSubquestionById[subquestion.subquestionId] = subquestion;
  });
  const newSubquestionAllIds = subquestions.map(subquestion => subquestion.subquestionId);
  return {
    type: GET_SUBQUESTIONS_SUCCESS,
    newById: newSubquestionById,
    newAllIds: newSubquestionAllIds,
  };
}


///// GET_SUBQUESTION_CONTENT
export const getSubquestionContent = (subquestion) => ({
  type: GET_SUBQUESTION_CONTENT,
  subquestion: subquestion,
});

export const getSubquestionContentSuccess = (subquestionId, content) => ({
  type: GET_SUBQUESTION_CONTENT_SUCCESS,
  subquestionId: subquestionId,
  content: content
})

export const getSubquestionContentFail = (error) => ({
  type: GET_SUBQUESTION_CONTENT_FAIL,
  payload: error,
});

///// GET_SUBQUESTION_SOLUTION
export const getSubquestionSolution = (subquestion) => ({
  type: GET_SUBQUESTION_SOLUTION,
  subquestion: subquestion,
});
  
export const getSubquestionSolutionSuccess = (subquestionId, solution) => ({
  type: GET_SUBQUESTION_SOLUTION_SUCCESS,
  subquestionId: subquestionId,
  solution: solution
})

export const getSubquestionSolutionFail = (error) => ({
  type: GET_SUBQUESTION_SOLUTION_FAIL,
  payload: error,
});