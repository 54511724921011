import {
  createMembershipPromise,
  createParticipationPromise,
  getGroupByInviteCodePromise,
  setSocketData,
  resetSocket,
  getActiveClassroomByGroupPromise,
} from '@store/actions';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './InviteCodeJoinUpForm.module.scss';
// import {
//   decipherInviteCodePromise,
//   studentJoinUpPromise,
// } from '@store/actions';
// 로그인후 수업추가할때 사용
const InviteCodeJoinUpForm = ({ handleChange, values, setShowInvite }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user
  );
  const history = useHistory();
  console.log('[InviteCodeJoinUpForm]', user);

  const joinToGroup = (e) => {
    const code = values.code.replace(/ /gi, '');
    e.preventDefault();
    setLoading(true);
    dispatch(getGroupByInviteCodePromise(code))
      .then((group) => {
        dispatch(
          createMembershipPromise({
            groupId: group.groupId,
            clientId: user.clientId,
            role: 'STUDENT',
          })
        ).then(() => {
          console.log('membership created', e);
          dispatch(getActiveClassroomByGroupPromise(group.groupId))
            .then((
              /** @type {import('@store/classrooms/reducer').ClassroomSession} */ activeClassroomSession
            ) => {
              console.log('active classroom', activeClassroomSession);
              if (activeClassroomSession !== null) {
                dispatch(
                  createParticipationPromise({
                    classroomId: activeClassroomSession?.classroomId,
                    clientId: user.clientId,
                    attendedAt: new Date().toISOString(),
                  })
                ).then((e) => {
                  dispatch(
                    setSocketData({
                      method: 'POST',
                      uri: '/classroom/sendImage',
                      groupId: group?.groupId,
                      clientId: user?.clientId,
                      type: 'JOIN_GROUP',
                      data: '',
                    })
                  );
                  // NOTE: 웹소켓으로 보낸 후 라는 보장이 없어서 0.1초 후에 실행.
                  // setTimeout(() => {
                  //   history.go(0);
                  // }, 1000);
                });
              } else {
                history.go(0);
              }
            })
            .then(() => {
              dispatch(
                setSocketData({
                  method: 'POST',
                  uri: '/classroom/sendImage',
                  groupId: group?.groupId,
                  clientId: user?.clientId,
                  type: 'JOIN_GROUP',
                  data: '',
                })
              );
            });
        });
      })
      .catch(() => {
        setLoading(false);
        alert('존재하지 않는 초대 코드입니다.');
      });
  };

  return (
    <div>
      {/* <form onSubmit={joinToGroup}> */}
      <div className={styles['container']}>
        <input
          type="text"
          name="code"
          placeholder="초대코드"
          className={styles['input-normal']}
          value={values.code}
          onChange={handleChange}
        />
        <button
          onClick={() => setShowInvite(false)}
          className={styles['input-submit']}
        >
          취소
        </button>
        {loading ? (
          <div className={styles['input-submit']}>추가중</div>
        ) : (
          <input
            type="submit"
            name="submit"
            value="확인"
            className={styles['input-submit']}
            onClick={joinToGroup}
          />
        )}
      </div>
      {/* </form> */}
    </div>
  );
};

export default InviteCodeJoinUpForm;
