import {
  PURGE_SESSION,

  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAIL,

  CREATE_SESSION,
  CREATE_SESSION_PROMISE,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAIL,
  CREATE_SESSION_WARN,

  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,

  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL,

  GET_SUPERVISOR_PROMISE,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISOR_FAIL,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeSession = () => ({
  type: PURGE_SESSION,
})

///// GET_SESSION
export const getSession = (sessionId, studentId) => ({
  type: GET_SESSION,
  sessionId: sessionId,
  studentId: studentId,
});

export const getSessionSuccess = (sessions) => {
  let newSessionById = {};
  sessions.forEach(session => {
    newSessionById[session.sessionId] = session;
  });
  const newSessionAllIds = sessions.map(session => session.sessionId);

  return { 
    type: GET_SESSION_SUCCESS,
    sessionById: newSessionById,
    sessionAllIds: newSessionAllIds,
  };
}

export const getSessionFail = (error) => ({
  type: GET_SESSION_FAIL,
  payload: error,
});

///// CREATE_SESSION
export const createSessionPromise = createPromiseAction(CREATE_SESSION_PROMISE);

export const createSession = session => ({
  type: CREATE_SESSION,
  session: session,
})

export const createSessionSuccess = sessionData => {
  let {questions, subquestions, choices, outlines, ...session} = sessionData;
  return {
    type: CREATE_SESSION_SUCCESS,
    session: session,
  }  
}

export const createSessionFail = error => ({
  type: CREATE_SESSION_FAIL,
  payload: error,
})

export const createSessionWarn = warn => ({
  type: CREATE_SESSION_WARN,
  payload: warn,
})

///// UPDATE_SESSION
export const updateSession = session => ({
  type: UPDATE_SESSION,
  session: session,
})

export const updateSessionSuccess = session => ({
  type: UPDATE_SESSION_SUCCESS,
  session: session,
})

export const updateSessionFail = error => ({
  type: UPDATE_SESSION_FAIL,
  payload: error,
})

///// UPDATE_SESSION
export const deleteSession = sessionId => ({
  type: DELETE_SESSION,
  sessionId: sessionId,
})

export const deleteSessionSuccess = sessionId => ({
  type: DELETE_SESSION_SUCCESS,
  sessionId: sessionId,
})

export const deleteSessionFail = error => ({
  type: DELETE_SESSION_FAIL,
  payload: error,
})

///// GET_SUPERVISOR
export const getSupervisorPromise = createPromiseAction(GET_SUPERVISOR_PROMISE);

export const getSupervisorSuccess = client => ({
  type: GET_SUPERVISOR_SUCCESS,
  client: client,
})

export const getSupervisorFail = error => ({
  type: GET_SUPERVISOR_FAIL,
  payload: error,
})