import { call, put, takeLeading, takeEvery } from 'redux-saga/effects'
import {
  GET_OUTLINE,
} from './actionTypes';
import {
  getOutlineSuccess,
  getOutlineFail,

  getQuestions,
} from '@store/actions'
import * as api from '@api';

function* getOutlineHandler(action) {
  try {
    const response = yield call(api.getSessionOutline, action.sessionId);
    const questions = [... new Set(response.data.outlines.map(outline => outline.questionId))];
    yield put(getQuestions({questionIds:questions}));

    yield put(getOutlineSuccess(response.data.outlines));
  } catch(error) {
    yield put(getOutlineFail(error));
  }
}

function* outlineSaga() {
  yield takeEvery(GET_OUTLINE, getOutlineHandler);
}

export default outlineSaga;
