import { getBackend, postBackend } from '@api/Backend';

export const getQuizsetServeys = async (groupId, clientId) => {
  return await getBackend(
    `quizsetSession/survey?groupId=${groupId}&clientId=${clientId}`
  );
};

export const getQuizset = async (quizsetId) => {
  return await getBackend(`quizset?quizsetId=${quizsetId}`);
};

export const postQuizResponse = async (quizResponse) => {
  return await postBackend(`quizResponse`, quizResponse);
};

export const postQuisResponseList = async (quizResponseList) => {
  return await postBackend(`quizresponse?list=true`, quizResponseList);
};
