import * as api from '@api';
import { call, put, takeLeading } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import {
  createQuizsetSessionByQuizFail,
  createQuizsetSessionByQuizPromise,
  createQuizsetSessionByQuizSuccess,
  createQuizsetSessionFail,
  createQuizsetSessionPromise,
  createQuizsetSessionSuccess,
  getQuizsetSessionHistoryFail,
  getQuizsetSessionHistoryPromise,
  getQuizsetSessionHistorySuccess,
  getQuizsetSessionPromise,
  getQuizsetSessionSuccess,
  getQuizsetSessionFail,
  getQuizsetSessionResultFail,
  getQuizsetSessionResultPromise,
  getQuizsetSessionResultSuccess,
} from '@app/store/quizsetSession/actions';
import {
  clearQuizpangSessionFail,
  clearQuizpangSessionPromise,
  clearQuizpangSessionSuccess,
  exitQuizpangDevModeFail,
  exitQuizpangDevModePromise,
  exitQuizpangDevModeSuccess,
  setQuizpangDevModeFail,
  setQuizpangDevModePromise,
  setQuizpangDevModeSuccess,
  setQuizsetSessionFail,
  setQuizsetSessionPromise,
  setQuizsetSessionSuccess,
} from './actions';
import { func } from 'prop-types';

function* getQuizsetSessionHistoryPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.getQuizsetSessionHistories,
        action.payload
      );
      yield put(
        getQuizsetSessionHistorySuccess(response.data.quizsetSessionHistories)
      );
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetSessionHistoryFail(error));
    }
  });
}

function* getQuizsetSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizsetSession, action.payload);
      yield put(getQuizsetSessionSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetSessionFail(error));
    }
  });
}

function* createQuizsetSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizsetSession, action.payload);
      yield put(createQuizsetSessionSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizsetSessionFail(error));
    }
  });
}

function* createQuizsetSessionByQuizPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.createQuizsetSessionByQuiz,
        action.payload
      );
      yield put(createQuizsetSessionByQuizSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizsetSessionByQuizFail(error));
    }
  });
}

function* getQuizsetSessionResultPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const { quizsetSessionId, quizIndex } = action.payload;
      const response = yield call(
        api.getQuizsetSessionResult,
        quizsetSessionId,
        quizIndex
      );
      yield put(
        getQuizsetSessionResultSuccess({
          quizsetSessionId,
          result: response.data,
        })
      );
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetSessionResultFail(error));
    }
  });
}

function* setQuizpangDevModePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      yield put(setQuizpangDevModeSuccess());
      return yield true;
    } catch (error) {
      yield put(setQuizpangDevModeFail(error));
    }
  });
}

function* exitQuizpangDevModePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      yield put(exitQuizpangDevModeSuccess());
      return yield true;
    } catch (error) {
      yield put(exitQuizpangDevModeFail(error));
    }
  });
}

function* setQuizsetSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      yield put(setQuizsetSessionSuccess(action.payload));
      return yield true;
    } catch (error) {
      yield put(setQuizsetSessionFail(error));
    }
  });
}

function* clearQuizpangSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      yield put(clearQuizpangSessionSuccess());
      return yield true;
    } catch (error) {
      yield put(clearQuizpangSessionFail(error));
    }
  });
}

function* quizsetSessionSaga() {
  yield takeLeading(
    getQuizsetSessionHistoryPromise,
    getQuizsetSessionHistoryPromiseHandler
  );
  yield takeLeading(getQuizsetSessionPromise, getQuizsetSessionPromiseHandler);
  yield takeLeading(
    createQuizsetSessionPromise,
    createQuizsetSessionPromiseHandler
  );
  yield takeLeading(
    createQuizsetSessionByQuizPromise,
    createQuizsetSessionByQuizPromiseHandler
  );
  yield takeLeading(
    getQuizsetSessionResultPromise,
    getQuizsetSessionResultPromiseHandler
  );
  yield takeLeading(
    setQuizpangDevModePromise,
    setQuizpangDevModePromiseHandler
  );
  yield takeLeading(
    exitQuizpangDevModePromise,
    exitQuizpangDevModePromiseHandler
  );
  yield takeLeading(setQuizsetSessionPromise, setQuizsetSessionPromiseHandler);
  yield takeLeading(
    clearQuizpangSessionPromise,
    clearQuizpangSessionPromiseHandler
  );
}

export default quizsetSessionSaga;