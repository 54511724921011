import { all, call, put, takeLeading, takeEvery } from 'redux-saga/effects';

import {
  getTimetablePromise,
  getTimetableSuccess,
  getTimetableFail,
  getTimetablesByGroupPromise,
  getTimetablesByGroupSuccess,
  getTimetablesByGroupFail,
  getTimetablesByGroupListPromise,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getTimetablePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getTimetable, action.payload);

      yield put(getTimetableSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getTimetableFail(error));
    }
  });
}

function* getTimetablesByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getTimetablesByGroup, action.payload);

      yield put(getTimetablesByGroupSuccess(response.data.timetables));
      return yield response.data.timetables;
    } catch (error) {
      yield put(getTimetablesByGroupFail(error));
    }
  });
}
function* updateGroupTimeTable(groupId) {
  try {
    const response = yield call(api.getTimetablesByGroup, groupId);
    yield put(getTimetablesByGroupSuccess(response.data.timetables));
  } catch (e) {
    // ignore
  }
}

function* getTimetablesByGroupListPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    const groupIds = action.payload;
    yield all(groupIds.map((id) => call(updateGroupTimeTable, id)));
  });
}

function* timetableSaga() {
  yield takeLeading(getTimetablePromise, getTimetablePromiseHandler);
  yield takeEvery(
    getTimetablesByGroupPromise,
    getTimetablesByGroupPromiseHandler
  );
  yield takeLeading(
    getTimetablesByGroupListPromise,
    getTimetablesByGroupListPromiseHandler
  );
}

export default timetableSaga;
