// 모둠 활동
export const GET_GROUP_ACTIVITY_SUCCESS = "GET_GROUP_ACTIVITY_SUCCESS";
export const GET_GROUP_ACTIVITY_FAIL = "GET_GROUP_ACTIVITY_FAIL";

// 모둠
export const GET_SUBGROUP_ACTIVITY_SUCCESS = "GET_SUBGROUP_ACTIVITY_SUCCESS";
export const GET_SUBGROUP_ACTIVITY_FAIL = "GET_SUBGROUP_ACTIVITY_FAIL";

// 로딩
export const GROUP_ACTIVITY_LOADING = "GROUP_ACTIVITY_LOADING";
