import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./inviteCodeForm.module.scss";
import { getGroupByInviteCodePromise, getGroupsPromise, getSchoolPromise } from "@store/actions";
import { School } from "@mui/icons-material";
import { getGroupByInviteCode } from '@app/api';

/**
 * @typedef {Object} InviteCodeFormProps
 * @prop {Function} nextStep - 다음단계로 이동하는 함수
 * @prop {Function} handleChange - 입력한 값을 저장하는 함수
 * @prop {import('@components/Modal/InviteCode').StudentInfo} values - 입력한 값을 저장하는 객체
 */

/**
 * 로그인할 때 사용
 * @param {InviteCodeFormProps} props
 */
const InviteCodeForm = ({ nextStep, handleChange, values }) => {
  const dispatch = useDispatch();

  const next = (e) => {
    const code = values.code.replace(/ /gi, "");
    console.log("[InviteCodeForm 입력한 초대코드]", code);
    e.preventDefault();
    dispatch(getGroupByInviteCodePromise(code))
      .then((response) => {
        console.log("[InviteCodeForm]", response);
        if (!response) throw new Error();
        dispatch(getSchoolPromise(response.schoolId)).then((School) => {
          nextStep();
        });
      })
      .catch((error) => {
        console.log("[InviteCodeForm Error]", error);
        alert("초대코드를 다시 입력해주세요.");
      });
  };

  return (
    <div>
      <form onSubmit={next}>
        {/* <div className='flex ml-4'> */}
        <div className={styles["container"]}>
          <input
            type='text'
            name='code'
            placeholder='초대코드'
            className={styles["input-normal"]}
            value={values.code}
            onChange={handleChange}
          />
          <input
            type='submit'
            name='submit'
            value='다음'
            className={styles["input-submit"]}
          />
        </div>
      </form>
    </div>
  );
};

export default InviteCodeForm;
