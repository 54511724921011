import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  GET_GROUP,
  GET_MY_GROUPS,
  GET_GROUPS,
  GET_GROUP_STUDENTS,
  GET_GROUP_TEMP,
  FIND_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  KICK_STUDENTS,
  SIGN_UP_STUDENTS,
} from './actionTypes';
import {
  // promises
  createGroupPromise,
  getGroupStudentsPromise,
  getGroupSuccess,
  getGroupFail,
  getGroupByInviteSuccess,
  getGroupByInviteFail,
  getGroupByInviteCodePromise,
  getGroupTempSuccess,
  getGroupTempFail,
  getMyGroupsSuccess,
  getMyGroupsFail,
  getGroupsSuccess,
  getGroupsFail,
  findGroupSuccess,
  findGroupFail,
  getGroupStudentsSuccess,
  getGroupStudentsFail,
  createGroupSuccess,
  createGroupFail,
  updateGroupSuccess,
  updateGroupFail,
  deleteGroupSuccess,
  deleteGroupFail,
  kickStudentsSuccess,
  kickStudentsFail,

  signUpStudentsSuccess,
  signUpStudentsFail,
  // promise
  getMyGroupsPromise,
  getGroupsPromise,
  deleteGroupPromise,

  kickStudentsPromise,
  signUpStudentsPromise,

} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getGroupHandler(action) {
  // console.log('[getGroupsHandler!!!!!!]',action.groupId)
  try {
    const response = yield call(api.getGroup, action.groupId);
    yield put(getGroupSuccess(response.data));
    // console.log('[getGroupSuccess!!!!!!]',response.data)
  } catch (error) {
    yield put(getGroupFail(error));
  }
}

function* getGroupStudentsHandler(action) {
  try {
    const response = yield call(api.getGroupStudents, action.classroomId);
    const ClassStdData = response.data;

    yield call(api.storeStorageData, '@students', ClassStdData);
    yield put(getGroupStudentsSuccess(response.data));
  } catch (error) {
    yield put(getGroupStudentsFail(error));
  }
}

function* getGroupStudentsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.getGroupStudents,
        action.payload.classroomId
      );
      const ClassStdData = response.data;

      yield call(api.storeStorageData, '@students', ClassStdData);
      yield put(getGroupStudentsSuccess(ClassStdData));
      return yield response.data;
    } catch (error) {
      yield put(getGroupStudentsFail(error));
    }
  });
}

function* getGroupTempHandler(action) {
  try {
    const response = yield call(api.getGroup, action.groupId);
    yield put(getGroupTempSuccess(response.data));
  } catch (error) {
    yield put(getGroupTempFail(error));
  }
}

function* getMyGroupsHandler(action) {
  try {
    const response = yield call(api.getMyGroups);
    yield put(getMyGroupsSuccess(response.data));
  } catch (error) {
    yield put(getMyGroupsFail(error));
  }
}

function* getMyGroupsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getMyGroups);
      yield put(getMyGroupsSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getMyGroupsFail(error));
    }
  });
}

function* getGroupsHandler(action) {
  try {
    const response = yield call(api.getGroups, action.groupId);
    yield put(getGroupsSuccess(response.data));
  } catch (error) {
    yield put(getGroupsFail(error));
  }
}

function* getGroupsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroups, action.payload.groupIds);
      yield put(getGroupsSuccess(response.data));
    } catch (error) {
      yield put(getGroupsFail(error));
    }
  });
}

function* findGroupHandler(action) {
  try {
    const response = yield call(api.findGroup, action.groupName);
    yield put(findGroupSuccess(response.data));
  } catch (error) {
    yield put(findGroupFail(error));
  }
}

// function* getGroupClientsHandler(action) {
//   try {
//     const response = yield call(api.getGroupClients, action.groupId);
//     yield put(getClientsSuccess(response.data));
//     yield put(getGroupClientsSuccess());
//   } catch(error) {
//     yield put(getGroupClientsFail(error));
//   }
// }

function* createGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const groupObject = action.payload;
      const response = yield call(api.createGroup, groupObject);

      yield put(createGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createGroupFail(error));
    }
  });
}

function* createGroupHandler(action) {
  try {
    const response = yield call(api.createGroup, action.groupData);
    yield put(createGroupSuccess(response.data));
  } catch (error) {
    yield put(createGroupFail(error));
  }
}

function* updateGroupHandler(action) {
  try {
    const response = yield call(api.updateGroup, action.groupData);
    yield put(updateGroupSuccess(action.groupData));
  } catch (error) {
    yield put(updateGroupFail(error));
  }
}

function* deleteGroupHandler(action) {
  try {
    yield call(api.deleteGroup, action.group.id);
    yield put(deleteGroupSuccess(action.group));
  } catch (error) {
    yield put(deleteGroupFail(error));
  }
}

function* deleteGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const groupId = {
        id: action.payload,
      };
      const response = yield call(api.deleteGroup, groupId);
      yield put(deleteGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteGroupFail(error));
    }
  });
}

function* signUpStudentsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.signUpStudents, action.payload.groupId, action.payload.student);
      yield put(signUpStudentsSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(signUpStudentsFail(error));
    }
  });
}

function* kickStudentsHandler(action) {
  try {
    const response = yield call(
      api.kickGroupStudent,
      action.groupId,
      action.studentIds
    );
    yield put(kickStudentsSuccess(response.data));
  } catch (error) {
    yield put(kickStudentsFail(error));
  }
}

function* kickStudentsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.kickGroupStudent,
        action.payload.groupId,
        action.payload.studentIds
      );
      yield put(kickStudentsSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(kickStudentsFail(error));
    }
  });
}

function* getGroupByInviteCodePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const groupObject = action.payload;
      const response = yield call(api.getGroupByInviteCode, groupObject);

      yield put(getGroupByInviteSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getGroupByInviteFail(error));
    }
  });
}

function* groupSaga() {
  yield takeEvery(GET_GROUP, getGroupHandler);
  yield takeEvery(GET_GROUP_TEMP, getGroupTempHandler);
  yield takeLeading(GET_MY_GROUPS, getMyGroupsHandler);
  yield takeLeading(GET_GROUPS, getGroupsHandler);
  yield takeLeading(FIND_GROUP, findGroupHandler);
  yield takeLeading(createGroupPromise, createGroupPromiseHandler);
  yield takeLeading(CREATE_GROUP, createGroupHandler);
  yield takeLeading(UPDATE_GROUP, updateGroupHandler);
  yield takeLeading(DELETE_GROUP, deleteGroupHandler);
  yield takeLeading(KICK_STUDENTS, kickStudentsHandler);
  yield takeLeading(GET_GROUP_STUDENTS, getGroupStudentsHandler);
  yield takeLeading(deleteGroupPromise, deleteGroupPromiseHandler);
  yield takeLeading(kickStudentsPromise, kickStudentsPromiseHandler);
  yield takeLeading(getGroupStudentsPromise, getGroupStudentsPromiseHandler);
  yield takeLeading(signUpStudentsPromise, signUpStudentsPromiseHandler);
  yield takeLeading(getMyGroupsPromise, getMyGroupsPromiseHandler);
  yield takeLeading(getGroupsPromise, getGroupsPromiseHandler);
  yield takeLeading(getGroupByInviteCodePromise, getGroupByInviteCodePromiseHandler);
}

export default groupSaga;
