import { UPDATE_QUIZCHOICE_SUCCESS } from '@store/quizChoice/actionTypes';
import { makeFreshAllIds } from '../helpers';
import {
    PURGE_QUIZRESPONSE,
    GET_QUIZRESPONSE_SUCCESS,
    GET_QUIZRESPONSE_FAIL,
    CREATE_QUIZRESPONSE_SUCCESS,
    CREATE_QUIZRESPONSE_FAIL,
    DELETE_QUIZRESPONSE_SUCCESS,
    DELETE_QUIZRESPONSE_FAIL,
    UPDATE_QUIZRESPONSE_FAIL,
} from './actionTypes';

const initialState = {
    byId: {},
    allIds: [],
    quizresponse: {},
};

const quizresponse = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZRESPONSE:
      return initialState;

    case GET_QUIZRESPONSE_SUCCESS:
      return {
        ...state,
        quizresponse: action.data,
      };

    case CREATE_QUIZRESPONSE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quizresponse.quizresponseId]: action.quizresponse,
        },
        allIds: makeFreshAllIds(state.allIds, [action.quizresponse.quizresponseId]),
      };

    case UPDATE_QUIZCHOICE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quizChoice.quizChoiceId]: action.quizChoice,
        },
        allIds: makeFreshAllIds(state.allIds, [action.quizChoice.quizChoiceId]),
      };

    case DELETE_QUIZRESPONSE_SUCCESS:
      return state;
    case GET_QUIZRESPONSE_FAIL:
    case CREATE_QUIZRESPONSE_FAIL:
    case UPDATE_QUIZRESPONSE_FAIL:
    case DELETE_QUIZRESPONSE_FAIL:
      console.error('[quizresponse Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default quizresponse;
