import {
    PURGE_QUIZENROLLMENT,
    GET_QUIZENROLLMENT_SUCCESS,
    GET_QUIZENROLLMENT_FAIL,
    CREATE_QUIZENROLLMENT_SUCCESS,
    CREATE_QUIZENROLLMENT_FAIL,
    DELETE_QUIZENROLLMENT_SUCCESS,
    DELETE_QUIZENROLLMENT_FAIL,
  } from './actionTypes';
  import { createPromiseAction } from '@adobe/redux-saga-promise';
  
  export const purgeQuizenrollment = () => ({
    type: PURGE_QUIZENROLLMENT,
  });

  // get
  export const getQuizenrollmentPromise = createPromiseAction('GET_QUIZENROLLMENT_PROMISE');
  export const getQuizenrollmentSuccess = (data) => {
    return {
      type: GET_QUIZENROLLMENT_SUCCESS,
      data,
    }
  };
  export const getQuizenrollmentFail = (error) => ({
    type: GET_QUIZENROLLMENT_FAIL,
    payload: error,
  });
  
  // create
  export const createQuizenrollmentPromise = createPromiseAction('CREATE_QUIZENROLLMENT_PROMISE');
  export const createQuizenrollmentSuccess = (quizenrollmentData) => ({
    type: CREATE_QUIZENROLLMENT_SUCCESS,
    quizenrollment: quizenrollmentData,
  });
  export const createQuizenrollmentFail = (error) => ({
    type: CREATE_QUIZENROLLMENT_FAIL,
    payload: error,
  });
  
  // delete
  export const deleteQuizenrollmentPromise = createPromiseAction('DELETE_QUIZENROLLMENT_PROMISE');
  export const deleteQuizenrollmentSuccess = (quizenrollmentData) => ({
    type: DELETE_QUIZENROLLMENT_SUCCESS,
    quizenrollmentData,
  });
  export const deleteQuizenrollmentFail = (error) => ({
    type: DELETE_QUIZENROLLMENT_FAIL,
    payload: error,
  });
  