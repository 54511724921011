import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '@mui/material';
import QuizKeyboardLogger from '../quiz-keyboard-logger';

export const QuizLongInput = ({
  setLongResult,
  keyboardData,
  setKeyboardData,
  deviceType,
  input,
  setInput,
}) => {
  // const [input, setInput] = useState("");
  const [timeLeft, setTimeLeft] = useState(9999);
  // const [keyboardData, setKeyboardData] = useState([]);

  const handleInputChange = (text) => {
    const inputValue = text.target.value;
    setInput(inputValue);
    setLongResult(inputValue);
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Input
            // onChange={handleInputChange}
            onChange={handleInputChange}
            style={{
              width: 700,
              height: 200,
              backgroundColor: '#61616114',
              borderRadius: 8,
              padding: 16,
              textAlignVertical: 'top',
              fontSize: 16,
            }}
            placeholderTextColor="#00000099"
            placeholder="서술형 입력"
            multiline={true}
            readOnly={
              deviceType !== 'iOS' && deviceType !== 'Android' ? false : true
            }
            value={input}
          />
        </div>

        {deviceType !== 'iOS' && deviceType !== 'Androidd' ? null : (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              maxWidth: '100vw',
            }}
          >
            <QuizKeyboardLogger
              setInput={setInput}
              keyboardData={keyboardData}
              setKeyboardData={setKeyboardData}
              deviceType={deviceType}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 1px;
  box-sizing: border-box;
`;
