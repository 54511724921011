import React from "react";
import styled from "styled-components";

export const QuizWait = () => {
  return (
    <StyledContainer>
      <StyledReady>
        <div>다음 퀴즈부터 참여 가능합니다.</div>
        <div>잠시만 기다려주세요.</div>
      </StyledReady>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const StyledReady = styled.div`
  line-height: 1.8;
  font-size: 15px;
  text-align: center;
`;
