import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['commentsLayer', 'reportEvents'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// saga
const sagaMiddleware = createSagaMiddleware();

// flipper
// const flipperReduxMiddleware = require("redux-flipper").default;

const middlewares = [
  promiseMiddleware,
  sagaMiddleware,
  // flipperReduxMiddleware(),
];


// debugger
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
export default store;

/**
 * @typedef {Object} State
 * @property {import('@store/user/reducer').UserState} user
 * @property {import('@store/clients/reducer').ClientsState} clients
 * @property {import('@store/groups/reducer').GroupsState} groups
 * notification
 * @property {import('@store/programs/reducer').ProgramsState} programs
 * @property {import('@store/programconfigs/reducer').ProgramconfigsState} programconfigs
 * @property {import('@store/classrooms/reducer').ClassroomState} classrooms
 * @property {import('@store/school/reducer').SchoolsState} schools
 * @property {import('@store/control/reducer').ControlState} control
 * @property {import('@store/filelinks/reducer').FilelinksState} filelinks
 * @property {import('@store/profile/reducer').ProfileState} profile
 * @property {import('@store/timetable/reducer').TimetableState} timetables
 */
