import { Tldraw } from "@tldraw/tldraw";

import React, { memo, useEffect, useMemo, useCallback, useState } from "react";
import EraserIcon from "@icons/material/EraserIcon";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setMyCommentsShared, setScreenData } from "@store/actions";
import { selectActiveClassroom } from "@store/selectors";
import { Replay, Create, ScreenShare } from "@mui/icons-material";
import { getSvgAsImage } from "./utils/utils";

const TldrawerComponent = ({ isVisible }) => {
  const dispatch = useDispatch();
  const [editor, setEditor] = useState();
  const {
    teacherComments,
    studentComments,
    commentsTarget,
    // strokeColor,
    isMyCommentsSharing,
  } = useSelector((state) => state.commentsLayer);
  const { selectedStd } = useSelector((state) => state.control);
  const { clientId } = useSelector((state) => state.user);
  const { groupClientIds } = useSelector((state) => state.membership);
  const otherGroupClientId = useMemo(
    () => groupClientIds.filter((id) => id !== clientId),
    [clientId, groupClientIds]
  );
  const activeClassroom = useSelector(selectActiveClassroom);

  const [isEraserMode, setIsEraserMode] = useState(false);

  const isScreenSharingTarget = useMemo(
    () => activeClassroom?.screenShared && selectedStd === clientId,
    [activeClassroom?.screenShared, clientId, selectedStd]
  );

  const isCommentsTarget = useMemo(
    () => commentsTarget.findIndex((id) => id === clientId) !== -1,
    [clientId, commentsTarget]
  );

  const onSendComments = useCallback(
    (data, targetClients) => {
      if (!activeClassroom?.groupId) {
        return;
      }
      dispatch(
        setScreenData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "COMMENTS_STUDENT",
          clientId,
          groupId: activeClassroom.groupId,
          data,
          targetClients,
        })
      );
    },
    [activeClassroom?.groupId, clientId, dispatch]
  );
  const onPathChange = useCallback(async () => {
    if (!activeClassroom?.groupId || !editor) {
      return;
    }

    let targetClients = otherGroupClientId;
    const svg = await editor.getSvg([...editor.currentPageShapeIds], {
      scale: 1,
      background: false,
    });
    if (!svg) return;
    const base64 = await getSvgAsImage(svg, {
      type: "png",
      quality: 1,
      scale: 1,
    });

    if (!isScreenSharingTarget || !isMyCommentsSharing) {
      targetClients = [];
    }
    console.log("🚀 ~ file: Tldrawer.js:81 ~ onPathChange ~ base64:", base64);
    onSendComments(base64, targetClients);
  }, [
    activeClassroom?.groupId,
    otherGroupClientId,
    editor,
    isScreenSharingTarget,
    isMyCommentsSharing,
    onSendComments,
  ]);

  const onChangeEraserMode = useCallback(
    (isEraser) => {
      editor.setCurrentTool(isEraser ? "eraser" : "draw");
      setIsEraserMode(isEraser);
    },
    [editor]
  );

  const onClear = useCallback(async () => {
    editor.selectAll();
    editor.deleteShapes(editor.selectedShapeIds);
    onPathChange();
  }, [editor, onPathChange]);

  const onIsMyCommentsShared = useCallback(() => {
    dispatch(setMyCommentsShared(!isMyCommentsSharing));
  }, [dispatch, isMyCommentsSharing]);

  const onMount = (editor) => {
    editor.setCurrentTool("draw");
    editor.selectAll();
		editor.resetZoom();
    editor.deleteShapes(editor.selectedShapeIds);
    setEditor(editor);
  };

  useEffect(() => {
    if (!editor) return;

    const interval = setInterval(() => {
      // onPathChange();
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [editor, onPathChange]);

  useEffect(() => {
    if (selectedStd !== clientId) {
      onSendComments("", otherGroupClientId);
    } else {
      onPathChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStd, clientId]);

  if (!isVisible) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledToolkitWrap>
        <StyledModeButton
          $isActive={!isEraserMode}
          onClick={() => onChangeEraserMode(false)}
        >
          <Create />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isEraserMode}
          onClick={() => onChangeEraserMode(true)}
        >
          <EraserIcon />
        </StyledModeButton>
        <StyledModeButton onClick={onClear}>
          <Replay />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isMyCommentsSharing}
          onClick={onIsMyCommentsShared}
        >
          <ScreenShare />
        </StyledModeButton>
      </StyledToolkitWrap>
      {otherGroupClientId
        .filter((id) => !!studentComments[id])
        .map((id) => (
          <StyledBackgroundComments key={`comments-${id}`}>
            <img src={studentComments[id]} alt='student-comments' />
          </StyledBackgroundComments>
        ))}
      {isCommentsTarget && teacherComments && (
        <StyledBackgroundComments>
          <img src={teacherComments} alt='teacher-comments' />
        </StyledBackgroundComments>
      )}
      <Tldraw hideUi isLocked persistenceKey='tldraw' onMount={onMount} autoFocus />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: crosshair;
	
  .tl-canvas {
    background-color: transparent;
  }
  .tl-background {
    background-color: transparent;
  }
  .tlui-style-panel__section {
    display: none;
  }
  .tlui-toolbar__extras {
    display: none;
  }
  .tlui-toolbar__tools {
    & > * {
      display: none;
    }
    .tlui-toolbar__divider {
      display: none;
    }
    button[data-tool="draw"],
    button[data-tool="eraser"] {
      display: inline-block;
    }
  }
  .tlui-layout__top {
    display: none;
    .tlui-layout__top__left {
    }
  }
  .tlui-layout__bottom {
    display: none;

    /* .tlui-debug-panel, .tlui-help-menu, .tlui-navigation-zone {
			display: none;
		} */
  }
`;
const StyledToolkitWrap = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 4px;
  color: white;
  z-index: 205;
  border-radius: 8px;
`;
const StyledModeButton = styled.button`
  padding: 8px;
  font-size: 12px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#969696"};
  border-radius: 50%;
  margin: 0 4px;

  ${({ $isActive }) =>
    $isActive &&
    `
      background-color: white;
      color: black;
  `}
`;
const StyledBackgroundComments = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Tldrawer = memo(TldrawerComponent);
