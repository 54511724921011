import {
  PURGE_OUTLINE,

  GET_OUTLINE,
  GET_OUTLINE_SUCCESS,
  GET_OUTLINE_FAIL,

  UPDATE_OUTLINE_SOLVED,
  UPDATE_OUTLINES_SOLVED,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeOutline = () => ({
  type: PURGE_OUTLINE,
})

///// GET_SESSION_OUTLINE
export const getOutline = (sessionId) => ({
  type: GET_OUTLINE,
  sessionId: sessionId,
});

export const getOutlineSuccess = (outlines) => {
  let newById = {};
  outlines.forEach(outline => {
    newById[outline.outlineId] = outline;
  });
  const newAllIds = outlines.map(outline => outline.outlineId);
  return {
    type: GET_OUTLINE_SUCCESS,
    newById: newById,
    newAllIds: newAllIds,
  };
}

export const getOutlineFail = (error) => ({
  type: GET_OUTLINE_FAIL,
  payload: error,
});


///// UPDATE_OUTLINE_SOLVED
export const updateOutlineSolved = (subquestionId) => ({
  type: UPDATE_OUTLINE_SOLVED,
  subquestionId: subquestionId,
});

///// UPDATE_OUTLINES_SOLVED
export const updateOutlinesSolved = (subquestionIds) => ({
  type: UPDATE_OUTLINES_SOLVED,
  subquestionIds: subquestionIds,
});