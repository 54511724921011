import React, { useEffect } from 'react';
import CardSidebarLectureInfo from '@components/Card/CardSidebarLectureInfo';
import { Link, useHistory } from 'react-router-dom';
import styles from './SidebarClassList.module.scss';
import {
  selectActiveClassroom,
  selectAfterSchoolGroup,
  selectAllClients,
  selectHRGroup,
  selectMyGroupsAsStudent,
  selectSubjectGroups,
} from '@store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClient,
  getClients,
  getGroupFilelinkPromise,
  getMembershipPromise,
  getMyGroups,
} from '@store/actions';
import imgSubjectIcon from '@images/home/ic-class-subject-24.svg';
import imgHRIcon from '@images/home/ic-class-hr-24.svg';
import { selectTeacherMemberships } from '@store/membership/selector';

const SidebarClassList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const HRGroup = useSelector((state) => selectHRGroup(state));
  const subjectGroup = useSelector((state) => selectSubjectGroups(state));
  const clients = useSelector((state) => selectAllClients(state));
  const teacherMemberships = useSelector((state) =>
    selectTeacherMemberships(state)
  );

  const isActive = !!useSelector((state) => selectActiveClassroom(state));
  // console.log('[isActive]', isActive)

  // console.log('Rerender sideClasslist');
  useEffect(() => {
    dispatch(getMembershipPromise(user?.client.clientId));
  }, [history]);

  const getTeacherName = (groupId) => {
    if (!groupId) return null;

    const foundMembership = teacherMemberships?.find(
      (membership) => membership.groupId === groupId
    );
    if (!foundMembership) return null;

    const foundClient = clients.find(
      (client) => foundMembership?.clientId === client.clientId
    );
    if (foundClient !== null) return foundClient?.userName;

    return null;
  };

  return (
    <div>
      {HRGroup && (
        <div
          className={styles['side-class-list']}
          onClick={(e) => {
            history.push(`/classroom/off?${HRGroup.groupId}`);
            // dispatch(getGroupFilelinkPromise(HRGroup.groupId));
          }}
        >
          <img
            className={styles['img-class-icon']}
            src={imgHRIcon}
            alt="classroom"
          />
          <div className={styles['side-class-grade']}>
            {HRGroup.grade + '학년' + HRGroup.groupName + '반'}
          </div>
          <div className={styles['side-class-name']}>
            {HRGroup ? '담임선생님' : ''}
          </div>
        </div>
      )}

      {subjectGroup &&
        subjectGroup.map((group) => {
          return (
            <div
              className={styles['side-class-list']}
              onClick={(e) => {
                history.push(`/classroom/off?${group.groupId}`);
                // dispatch(getGroupFilelinkPromise(group.groupId));
              }}
            >
              <img
                className={styles['img-class-icon']}
                src={imgSubjectIcon}
                alt="classroom"
              />
              <div className={styles['side-class-grade']}>
                {group.subjectName}
              </div>
              <div className={styles['side-class-name']}>
                {getTeacherName(group.groupId)}선생님
              </div>
            </div>
          );
        })}

      {/* useEffect(() => {
    dispatch(getMyGroups());
  }, []);

  const allMyGroups = useSelector((state) =>
    selectMyGroupsAsStudent(state, state.user)
  );

  return (
    <div>
      { allMyGroups.map(group => (
        <CardSidebarLectureInfo
          key={group.id}
          classInfo={group}
        />
      ))} */}
    </div>
  );
};

export default SidebarClassList;
