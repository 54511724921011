import React from 'react';
import styled from '@emotion/styled';

const Layout = styled.div`
  padding: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
`;

const Period = styled.div`
  color: #00000099;
  font-size: 12px;
  font-weight: 400;
`;

const Time = styled.div`
  color: #00000099;
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
`;

const ClassTimeline = ({ period, timeString }) => {
  return (
    <Layout>
      <Period>{period}교시</Period>
      <Time>{timeString}</Time>
    </Layout>
  );
};

export default ClassTimeline;
