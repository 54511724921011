import styles from "./Stickers.module.scss";
import Sticker from "@components/ClassRoom/Stickers/Sticker/Sticker";
import { useEffect, useRef, useState } from "react";

const Stickers = ({ stickers, stickerPresets, pageRef }) => {
  const [shownCount, setShownCount] = useState(0);
  const containerRef = useRef();
  const filteredStickers = [];
  const edit = Object.assign([], stickerPresets);
  edit.map((item) => {
    const tempJson = {};
    const filtered = stickers.filter((stick) => item.id === stick.sticker);
    tempJson.id = item.id;
    tempJson.stickers = filtered;
    tempJson.count = filtered.length;
    tempJson.name = item.phrase;
    filteredStickers.push(tempJson);
  });

  // console.log("sticker: ", edit);

  const handleResize = () => {
    if (containerRef.current && pageRef.current) {
      setShownCount(
        Math.max(
          Math.floor(
            (pageRef.current?.getBoundingClientRect().width - 20) / 78
          ) - 1,
          0
        )
      );
    }
  };


  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [pageRef]);

  return (
    <div className={styles.container} ref={containerRef}>
      {filteredStickers.map((sticker)=>(
        <Sticker sticker={sticker}/>
      ))}
      {/* {filteredStickers.slice(0, shownCount).map((sticker) => (
        <Sticker sticker={sticker} />
      ))} */}
      {/* {!!(stickers.length - shownCount > 0) && (
        <span className={styles["span-shorted"]}>
          ...외 {stickers.length - shownCount}개
        </span>
      )} */}
    </div>
  );
};

export default Stickers;
