import {
  PURGE_USER,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_IN_STUDENT_SUCCESS,
  SIGN_IN_STUDENT_FAIL,
  SIGN_OUT,
  RESTORE_USER,
  SET_USER_PRIMARY_GROUP_SUCCESS,
  SET_PRIMARY_GROUP_SUCCESS,
  TEACHER_SIGN_UP_SUCCESS,
  TEACHER_SIGN_UP_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FILL_TEACHER_ACCOUNT_SUCCESS,
  FILL_TEACHER_ACCOUNT_FAIL,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAIL,
  GET_MY_CLIENT_INFO_SUCCESS,
  GET_MY_CLIENT_INFO_FAIL,
  REGISTER_STUDENT_SUCCESS,
  REGISTER_STUDENT_FAIL,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "./actionTypes";

/**
 * @typedef {Object} UserState
 * @property {boolean} signedIn
 * @property {string} state
 * @property {string} userName
 * @property {string} studentNumber
 * @property {string} schoolId
 * @property {string} role
 * @property {string} accountId
 * @property {string} clientId
 * @property {string} userId
 * @property {boolean} privacyAccepted
 * @property {boolean} termsAccepted
 * @property {boolean} thirdPartyAccepted
 * @property {boolean} controlParentAccepted
 * @property {boolean} controlStudentAccepted
 * @property {boolean} teacherThirdPartyAccepted
 * @property {string} createdAt
 * @property {string} updatedAt
 */


const initialState = {
  signedIn: false,
  state: null,
  updated: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_USER:
      return initialState;

    case SIGN_UP_SUCCESS:
    case TEACHER_SIGN_UP_SUCCESS:
    case SIGN_IN_STUDENT_SUCCESS:
    case REGISTER_STUDENT_SUCCESS:
    case GET_USER_SUCCESS:
      return { ...state, ...action.user };

    case RESTORE_USER:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_INFO_SUCCESS:
    case FILL_TEACHER_ACCOUNT_SUCCESS:
    case GET_MY_CLIENT_INFO_SUCCESS:
      return { ...state, ...action.user };
    case SIGN_OUT:
      return { ...initialState };

    case SET_USER_PRIMARY_GROUP_SUCCESS:
    case SET_PRIMARY_GROUP_SUCCESS:
      return {
        ...state,
        primaryGroup: action.primaryGroup,
      };
    // todo: handle errors
    case SIGN_UP_FAIL:
    case GET_USER_FAIL:
    case TEACHER_SIGN_UP_FAIL:
    case SIGN_IN_STUDENT_FAIL:
    case UPDATE_USER_FAIL:
    case FILL_TEACHER_ACCOUNT_FAIL:
    case UPDATE_USER_INFO_FAIL:
    case GET_MY_CLIENT_INFO_FAIL:
    case REGISTER_STUDENT_FAIL:
      console.error('[user reducer]',action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default user;
