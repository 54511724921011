import {
  CREATE_REPORT_EVENTS_FAIL,
  CREATE_REPORT_EVENTS_SUCCESS,
  POST_REPORT_EVENTS_FAIL,
  POST_REPORT_EVENTS_SUCCESS,
  PURGE_REPORT_EVENTS,
  SET_REPORT_EVENT_FAIL,
  SET_REPORT_EVENT_PROMISE,
  SET_REPORT_EVENT_SUCCESS,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const createReportEventsPromise = createPromiseAction(
  'CREATE_REPORT_EVENTS'
);

export const purgeReportEvents = () => ({
  type: PURGE_REPORT_EVENTS,
});

export const setReportEventPromise = (reportEvent) => ({
  type: SET_REPORT_EVENT_PROMISE,
  reports: reportEvent,
});

export const setReportEventSuccess = (reportEvent) => ({
  type: SET_REPORT_EVENT_SUCCESS,
  report: reportEvent,
});

export const setReportEventFail = (error) => ({
  type: SET_REPORT_EVENT_FAIL,
  error: error,
});

export const createReportEventsSuccess = (report) => ({
  type: CREATE_REPORT_EVENTS_SUCCESS,
  report: report,
});

export const createReportEventsFail = () => {
  return {
    type: CREATE_REPORT_EVENTS_FAIL,
  };
};

export const postReportEventsPromise = createPromiseAction(
  'POST_REPORT_EVENTS'
);

export const postReportEventsSuccess = (report) => ({
  type: POST_REPORT_EVENTS_SUCCESS,
  report: report,
});

export const postReportEventsFail = () => ({
  type: POST_REPORT_EVENTS_FAIL,
});
