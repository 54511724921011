export const PURGE_SESSION = "PURGE_SESSION";

export const GET_SESSION = 'GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAIL = 'GET_SESSION_FAIL';

export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_PROMISE = 'CREATE_SESSION_PROMISE';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';
export const CREATE_SESSION_WARN = 'CREATE_SESSION_WARN';

export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';

export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAIL = 'DELETE_SESSION_FAIL';

export const GET_SUPERVISOR_PROMISE = 'GET_SUPERVISOR_PROMISE';
export const GET_SUPERVISOR_SUCCESS = 'GET_SUPERVISOR_SUCCESS';
export const GET_SUPERVISOR_FAIL = 'GET_SUPERVISOR_FAIL';