import { selectAllPrograms, selectGroupProgramconfigs } from '@store/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SiteManager = () => {
  const history = useHistory();

  let isIOSChrome = window.navigator.userAgent.match('CriOS');
  let isChromium = window.chrome;
  let vendorName = window.navigator.vendor;
  let isOpera = typeof window.opr !== 'undefined';
  let isIEedge = window.navigator.userAgent.indexOf('Edg') > -1;

  // for chrome extension
  // for dkkim kigcjnidkkfogajpphkhipndbbcdbfem
  // for
  // for shpark gjomgcgphkeanlpdcdjklcbkgkhikbkp
  // for supercoder  pmgkifpjobmkginiaoeemgeffgoohapo devMode
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  const user = useSelector((state) => state.user);
  const activeManaging = useSelector((state) => state.activeManaging);
  const allPrograms = useSelector((state) => selectAllPrograms(state));
  const programconfigsManaging = useSelector((state) =>
    selectGroupProgramconfigs(state, activeManaging?.managingId)
  );

  const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'WINDOWS';
    } else if (/Android/.test(userAgent)) {
      os = 'ANDROID';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };

  // useEffect(() => {
  //   const presentOs = getOS();
  //   if (isIOSChrome) {
  //     alert('IOS와 크롬브라우저로 해당서비스를 이용할 수 없습니다.');
  //     history.replace('/');
  //   } else if (
  //     isChromium !== null &&
  //     typeof isChromium !== 'undefined' &&
  //     vendorName === 'Google Inc.' &&
  //     isOpera === false &&
  //     isIEedge === false
  //   ) {
  //     if (presentOs === 'Android') {
  //       alert('안드로이드와 크롬브라우저로 해당서비스를 이용할 수 없습니다.');
  //       history.replace('/');
  //     }
  //   }

  //   // ERROR : 'chrome' is not defined
  //   // try{
  //   // 	chrome.runtime.sendMessage(chromeExtensionId, {clientId: user?.clientId},
  //   //   function(response) {
  //   //     console.log('App', response);
  //   //     if (!response.success)
  //   //       console.log('error')
  //   //   });
  //   // } catch (error) {
  //   // 	console.error(error)
  //   // }
  // }, []);

  return <div />;
};

export default SiteManager;
