import { call, put, takeLeading, takeEvery } from "redux-saga/effects";
import {
  GET_CLIENT,
  FIND_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_TEMP,
  SET_PRIMARY_GROUP,
} from "./actionTypes";
import {
  createStudentAdminPromise,
  getClientSuccess,
  getClientFail,
  getClientTempSuccess,
  getClientTempFail,
  findClientSuccess,
  findClientFail,
  getClientsSuccess,
  getClientsFail,
  setPrimaryGroupSuccess,
  setPrimaryGroupFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";


function* getClientHandler(action) {
  try {
    const response = yield call(api.getClient, action.clientId);
    yield put(getClientSuccess(response.data));
  } catch (error) {
    yield put(getClientFail(error));
  }
}

function* getClientHandlerTemp(action) {
  try {
    const response = yield call(api.getClients, action.clientIds);
    yield put(getClientTempSuccess(response.data));
  } catch (error) {
    yield put(getClientTempFail(error));
  }
}

function* findClientHandler(action) {
  try {
    const response = yield call(api.findClient, action.clientEmail);
    yield put(findClientSuccess(response.data));
  } catch (error) {
    yield put(findClientFail(error));
  }
}

function* getClientsHandler(action) {
  try {
    const response = yield call(api.getClients, action.clientIds);
    yield put(getClientsSuccess(response.data));
  } catch (error) {
    yield put(getClientsFail(error));
  }
}

function* clientSaga() {
  // yield takeLeading(GET_CLIENT, getClientHandler);
  // yield takeEvery(GET_CLIENT, getClientHandler);
  yield takeEvery(GET_CLIENT_TEMP, getClientHandlerTemp);
  yield takeLeading(GET_CLIENTS, getClientsHandler);
  yield takeEvery(FIND_CLIENT, findClientHandler);
}

export default clientSaga;
