import React from "react";
import EnabledApp from "@components/ClassRoom/EnabledApps/EnabledApp/EnabledApp";
import styles from "./EnabledApps.module.scss"
import classNames from "classnames/bind";

/**
 * 
 * @param {{enabledApps: import('@store/programs/reducer').Program[], screenShare: any?}} param0 
 * @returns 
 */
const EnabledApps = ({enabledApps, screenShare}) => {
  const cx = classNames.bind(styles);

  return (
    enabledApps.map(app => (
      <EnabledApp
        key={app.id}
        program={app}
      />
    ))
  )
}

export default EnabledApps;
