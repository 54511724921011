/**
 *
 * @param {import('@store').State} state
 * @returns
 */
export const selectAllProgramconfigs = (state) => {
  if (state.programconfigs.allIds.length === 0) {
    return [];
  }

  return state.programconfigs.allIds.map(
    (programconfigId) => state.programconfigs.byId[programconfigId]
  );
};

/**
 *
 * @param {import('@store').State} state
 * @returns
 */
export const selectClientProgramconfigs = (state) => {
  return selectAllProgramconfigs(state).filter(
    (programconfig) => programconfig?.clientId === state.user.clientId
  );
};

/**
 *
 * @param {import('@store').State} state
 * @param {string} groupId
 * @returns
 */
export const selectGroupProgramconfigs = (state, groupId) => {
  return selectAllProgramconfigs(state).filter((programconfig) => {
    if (programconfig === undefined) return false;

    return programconfig?.groupId === groupId;
  });
};

export const selectManagingProgramconfigs = (state, managing) => {
  return selectAllProgramconfigs(state).filter((programconfig) => {
    if (programconfig === undefined) return false;

    return programconfig?.groupId === managing?.managingId;
  });
};
