import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  FIND_NOTIFICATION,
  GET_ALL_NOTIFICATION,
  GET_CREATED_NOTIFICATION,
  DELETE_NOTIFICATION,
  PROCESS_NOTIFICATION,
} from "./actionTypes";
import {
  readNotificationPromise,
  getNotificationFail,
  getNotificationSuccess,
  findNotificationFail,
  findNotificationSuccess,
  getAllNotificationFail,
  getAllNotificationSuccess,
  processNotificationSuccess,
  processNotificationFail,
  getCreatedNotificationFail,
  getCreatedNotificationSuccess,
  readNotificationSuccess,
  readNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail,
  getGroup,
  getGroupSuccess,
  updateGroupSuccess,
  setApprovalCompleted,
  setClassroomState,
  purgeControl,
  setFilelinkState,
} from "@store/actions";
import * as api from "@api";
import { getNotification } from "@api";

function* getNotificationHandler(action) {
  try {
    const response = yield call(api.getNotification, action.clientId);
    yield put(getNotificationSuccess(response.data));
  } catch (error) {
    yield put(getNotificationFail(error));
  }
}

function* getNotificationSuccessHandler(action) {
  try {
    // switch notification kind
    // console.log("noti action : ", action);
    const payload = action.notification;
    switch (action.notification.kind) {
      // payload: {'classroom': {}, 'group: {}}
      case "APPROVRAL_COMPLETE":
        // yield put(reloadUserInfoPromise());
        yield put(setApprovalCompleted());
        break;
      case "CLASSROOM_START": 
      // case "CLASS_START": 
        yield put(setClassroomState(true));
        break;

      case "CLASSROOM_END": 
      // case "CLASS_END": 
        yield put(setClassroomState(false));
        break;

      case "DELETE_GROUP":
      case "UPDATE_GROUP":
      case "KICK_STUDENT":
        // todo
        // yield put()
        break;
      case "CREATE_FILELINK":
      case "DELETE_FILELINK":
        yield put(setFilelinkState());
        break;

      //payload: watchdog config instance
      case "PROGRAM_CONFIG_UPDATE":
      case "PROGRAM_CON_IG_UPDATE":
        // yield put(getProgramConfigSuccess(payload.teacher));
        // yield put(getProgramConfigSuccess(payload.student));
        break;

      case "PROGRAM_CONFIG_UPDATE_PLEASE": // for student
      case "PROGRAM_CON_IG_UPDATE_PLEASE": // for student
        // todo
        break;

      // 학생이 반에 들어갈때 백엔드
      case "JOIN_STUDENT":
        // console.log("join student : ", payload);
        yield put(updateGroupSuccess(payload));
        yield call(api.processNotification, action.notification.id);
        break;
      default:

        break;
    }
  } catch (error) {
    console.error(error);
  }
}

function* findNotificationHandler(action) {
  try {
    const response = yield call(api.findNotification, action.notificationId);
    yield put(findNotificationSuccess(response.data));
  } catch (error) {
    yield put(findNotificationFail(error));
  }
}

function* getAllNotificationHandler(action) {
  try {
    const response = yield call(api.getAllNotification);

    if (!response.data.length) {
      return;
    }
    // TODO: handle better
    yield call(getNotificationSuccessHandler, {
      notification: response.data[response.data.length - 1],
    });

    yield put(getAllNotificationSuccess(response.data));
  } catch (error) {
    yield put(getAllNotificationFail(error));
  }
}

function* readNotificationPromiseHandler(action) {
  try {
    const response = yield call(api.readNotification, action.payload.toClientId, action.payload);
    yield put(readNotificationSuccess(response.data));
  } catch (error) {
    yield put(readNotificationFail(error));
  }
}

function* deleteNotificationHandler(action) {
  try {
    const response = yield call(api.deleteNotification, action.notificationId);
    yield put(deleteNotificationSuccess(action.notificationId));
  } catch (error) {
    yield put(deleteNotificationFail(error));
  }
}

//0609 add
function* processNotificationHandler(action) {
  try {
    const response = yield call(api.processNotification, action.notificationId);
    yield put(processNotificationSuccess(response.data));
  } catch (error) {
    yield put(processNotificationFail(error));
  }
}

function* getCreatedNotificationHandler(action) {
  try {
    const response = yield call(getNotification);
    console.log("noti entered", response.data);
    if (response.data.length !== 0)
      yield call(getNotificationSuccessHandler, {
        notification: response.data[response.data.length - 1],
      });
    // console.log("response: ", response.data);
    yield put(getCreatedNotificationSuccess(response.data));
  } catch (error) {
    yield put(getCreatedNotificationFail(error));
  }
}

function* notificationSaga() {
  yield takeLeading(GET_NOTIFICATION, getNotificationHandler);
  // yield takeEvery(GET_NOTIFICATION_SUCCESS, getNotificationSuccessHandler);
  yield takeLeading(FIND_NOTIFICATION, findNotificationHandler);
  yield takeLeading(GET_ALL_NOTIFICATION, getAllNotificationHandler);
  yield takeEvery(readNotificationPromise, readNotificationPromiseHandler);
  yield takeLeading(DELETE_NOTIFICATION, deleteNotificationHandler);
  // 0609 add
  yield takeLeading(GET_CREATED_NOTIFICATION, getCreatedNotificationHandler);
  yield takeLeading(PROCESS_NOTIFICATION, processNotificationHandler);
}

export default notificationSaga;
