/**
 * @param {import('@store').State} state 
 */
export const selectAllClients = state => {
  if(state.clients.allIds.length === 0) {
    return [];
  }
  return state.clients.allIds?.map(clientId => state.clients.byId[clientId]);
}

/**
 * @param {import('@store').State} state 
 */
export const selectClientsTemp = state => {
  if(state.clients.allIdsTemp.length === 0) {
    return [];
  }
  return state.clients.allIdsTemp?.map(clientId => state.clients.byIdTemp[clientId]);
}