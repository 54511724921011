import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '@mui/material';
import QuizKeyboardLogger from '../quiz-keyboard-logger';

export const QuizInput = ({
  setShortResult,
  quizTimelimit,
  quizId,
  keyboardData,
  setKeyboardData,
  deviceType,
  input,
  setInput,
}) => {
  // const [input, setInput] = useState("");
  const [timeLeft, setTimeLeft] = useState(9999);
  // const [keyboardData, setKeyboardData] = useState([]);

  const handleInputChange = (text) => {
    const inputValue = text.target.value;
    setInput(inputValue);
    setShortResult(inputValue);
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <div style={{justifyContent:'center', display: 'flex'}}> */}
        <Input
          placeholder="정답 입력"
          readOnly={
            deviceType !== 'iOS' && deviceType !== 'Android' ? false : true
          }
          value={input}
          onChange={handleInputChange}
          style={{
            width: 480,
            backgroundColor: '#61616114',
            borderRadius: 4,
            padding: 8,
          }}
        />
        {deviceType !== 'iOS' && deviceType !== 'Android' ? null : (
          <QuizKeyboardLogger
            setInput={setInput}
            keyboardData={keyboardData}
            setKeyboardData={setKeyboardData}
            deviceType={deviceType}
          />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 1px;
  box-sizing: border-box;
`;

export default QuizInput;
