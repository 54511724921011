/**
 * @param {import('@store').State} state 
 */
export const selectClassroom = (state) => {
  return state.classrooms[state.classrooms.length - 1];
};

/**
 * @param {import('@store').State} state 
 */
export const selectActiveClassroom = (state) => {
  return state.classrooms.classSession;
};

/**
 * @param {import('@store').State} state 
 */
export const selectIsClassSession = (state) => {
  return state.classrooms.isClassSession;
};

export const selectClassroomGroupId = (state) => {
  return state.classrooms.groupId;
};
