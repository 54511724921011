import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  registerStudentPromise,
  signInStudentPromise,
  getActiveClassroomByGroupPromise,
  createParticipationPromise,
  setSocketData,
} from '@store/actions';
import { useHistory } from 'react-router-dom';
import styles from './restInfoForm.module.scss';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const RestInfoForm = ({
  prevStep,
  handleChange,
  values,
  setModalShowFalse,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const decipherGroup = useSelector((state) => state.groups.decipherGroup);
  const decipherSchool = useSelector((state) => state.schools.decipherSchool);
  const [loading, setLoading] = useState(false);
  // const nameRegex = /^[가-힣]+$/;
  const numberRegex = /^\d{5,5}$/;

  useEffect(() => {
    console.log('deciphered : ', decipherGroup, decipherSchool);
    if (
      Object.keys(decipherGroup).length === 0 ||
      Object.keys(decipherSchool).length === 0
    ) {
      alert('올바르지 않은 초대 코드예요. 다시 확인해주세요');
      prevStep();
      return;
    }
  }, []);

  const signIn = async (e) => {
    if (numberRegex.test(values.studentNumber) == false) {
      setLoading(false);
      alert('5자리 올바른 학번을 입력해주세요.');
    }
    // else if (nameRegex.test(values.name) == false) {
    //   alert('이름은 한글로만 입력해주세요.');
    // }
    else {
      setLoading(true);
      dispatch(
        registerStudentPromise({
          //임시로 signin 대신 register 사용하기
          schoolId: decipherSchool.schoolId,
          groupId: decipherGroup.groupId,
          userName: values.name.trim(),
          studentNumber: values.studentNumber,
          userId: '',
          password: '0000',
        })
      ).then((response) => {
        console.log('[register student]', response);
        if (!response) {
          dispatch(
            signInStudentPromise({
              groupId: decipherGroup.groupId,
              studentNumber: values.studentNumber,
              userName: values.name.trim(),
            })
          ).catch((err) => {
            setLoading(false);
            alert('로그인 실패');
            console.log('[signIn Error]', err);
          });

          return;
        }

        console.log('JOIN_GROUP', response);
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: decipherGroup.groupId,
            clientId: user.clientId,
            type: 'JOIN_GROUP',
            data: '',
          })
        );

        dispatch(getActiveClassroomByGroupPromise(decipherGroup.groupId)).then((
          /** @type {import('@store/classrooms/reducer').ClassroomSession} */ activeClassroomSession
        ) => {
          console.log('active classroom', activeClassroomSession);
          if (activeClassroomSession !== null) {
            dispatch(
              createParticipationPromise({
                classroomId: activeClassroomSession?.classroomId,
                clientId: user.clientId,
                attendedAt: new Date().toISOString(),
              })
            ).then((e) => {
              dispatch(
                setSocketData({
                  method: 'POST',
                  uri: '/classroom/sendImage',
                  groupId: decipherGroup.groupId,
                  clientId: user.clientId,
                  type: 'ATTEND_CLASS',
                  data: '',
                })
              );
            });
          }
        });
      });
    }
    /////////////////////////원래사용하던거
    //  dispatch(
    //   signInStudentPromise({
    //     groupId: decipherGroup.groupId,
    //     studentNumber: values.studentNumber,
    //     userName: values.name,
    //   })
    // ).then((clientData) => {
    //     if (clientData === undefined) {
    //       throw new Error("sign up failed");
    //     }
    //     console.log("[signUpHandler Success]", clientData);
    //     // history.push("/class-home/");
    //   })
    //   .catch((err) => {
    //     // alert("가입된 정보가 없습니다.");
    //     // console.log("call sign in and joinup");
    //     alert("catch");
    //     console.log("[signIn Error]", err);
    //   });
    ////////////////////////
    e.preventDefault();
  };

  return (
    <div className={styles['container']}>
      <form onSubmit={signIn}>
        <div className={styles['base-ground']}>
          <div style={{ marginLeft: 10, marginBottom: 20 }}>
            {decipherSchool.name +
              ' ' +
              decipherGroup.grade +
              '학년 ' +
              decipherGroup.groupName +
              '반'}
          </div>
          <input
            type="text"
            name="studentNumber"
            placeholder="학번 5자리"
            required
            className={styles['input-normal']}
            value={values.studentNumber}
            onChange={handleChange}
            maxLength="5"
          />
          <input
            type="text"
            name="name"
            placeholder="이름"
            required
            className={styles['input-normal']}
            value={values.name}
            onChange={handleChange}
            maxLength="25"
          />
          {/* <div style={{ marginLeft: 10, marginBottom: 20 }}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">성별</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="남" control={<Radio />} label="남" />
              <FormControlLabel value="여" control={<Radio />} label="여" />
              <FormControlLabel value="무성별" control={<Radio />} label="무성별" />
            </RadioGroup>
          </FormControl>
          </div>
          <div style={{ marginLeft: 10, marginBottom: 20 }}>
          <input
            type='text'
            name='phoneNumber'
            placeholder='휴대폰번호'
            required
            className={styles["input-normal"]}
            value={values.phoneNumber}
            onChange={handleChange}
            maxLength='25'
          />
          </div> */}

          {loading ? (
            <div className={styles['input-submit']}>로그인중</div>
          ) : (
            <input
              type="submit"
              name="submit"
              value="완료"
              onClick={setModalShowFalse}
              className={styles['input-submit']}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default RestInfoForm;
