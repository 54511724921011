export const PURGE_ALL = "PURGE_ALL";

export const PURGE_USER = "PURGE_USER";

export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";

export const SIGN_IN_STUDENT_SUCCESS = "SIGN_IN_STUDENT_SUCCESS";
export const SIGN_IN_STUDENT_FAIL = "SIGN_IN_STUDENT_FAIL";

export const SIGN_OUT = "SIGN_OUT";
export const RESTORE_USER = "RESTORE_USER";

export const CHANGE_PW = "CHANGE_PW";
export const CHANGE_PW_ERROR = "CHANGE_PW_ERROR";

export const SET_USER_PRIMARY_GROUP_SUCCESS = "SET_USER_PRIMARY_GROUP_SUCCESS";
export const SET_PRIMARY_GROUP_SUCCESS = "SET_PRIMARY_GROUP_SUCCESS";

export const TEACHER_SIGN_UP = "TEACHER_SIGN_UP";
export const TEACHER_SIGN_UP_SUCCESS = "TEACHER_SIGN_UP_SUCCESS";
export const TEACHER_SIGN_UP_FAIL = "TEACHER_SIGN_UP_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const FILL_TEACHER_ACCOUNT_SUCCESS = "FILL_TEACHER_ACCOUNT_SUCCESS";
export const FILL_TEACHER_ACCOUNT_FAIL = "FILL_TEACHER_ACCOUNT_FAIL";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAIL = "UPDATE_USER_INFO_FAIL";

export const GET_MY_CLIENT_INFO = "GET_MY_CLIENT_INFO";
export const GET_MY_CLIENT_INFO_SUCCESS = "GET_MY_CLIENT_INFO_SUCCESS";
export const GET_MY_CLIENT_INFO_FAIL = "GET_MY_CLIENT_INFO_FAIL";

export const REGISTER_STUDENT_SUCCESS = "REGISTER_STUDENT_SUCCESS";
export const REGISTER_STUDENT_FAIL = "REGISTER_STUDENT_FAIL";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";