/**
 * @param {import('@store').State} state 
 */
export const selectAllSchools = (state) => {
    if (state.schools.allIds.length === 0) {
        return [];
    }

    return state.schools.allIds.map((schoolId) => state.schools.byId[schoolId]);
};

/**
 * @param {import('@store').State} state 
 */
export const selectFilteredSchools = (state) => {
    if (state.schools.allFilteredIds.length === 0) {
        return [];
    }
    return state.schools.allFilteredIds?.map(
        (schoolId) => state.schools.byIdFilter[schoolId]
    );
};

/**
 * @param {import('@store').State} state 
 */
export const selectUserSchool = (state, user) => {
    const allSchools = selectAllSchools(state);
    if (allSchools.length == 0) {
        return null;
    }

    return allSchools.filter(
        (school) => school.schoolId === user.schoolId
    )[0];
};
