export const selectChoiceBySubquestion = (state, subquestionId) => {
    const choiceList = state.choice.allIds.filter(choiceId => 
        state.choice.byId[choiceId].subquestionId == subquestionId)
    .map(choiceId => state.choice.byId[choiceId]);

    return choiceList.sort((a, b) => {
        if (a.index > b.index) {
            return 1;
        } else if (a.index < b.index) {
            return -1;
        }
    })
}

export const selectChoiceByQuestion = (state, questionId) => {
    const choiceList = state.choice.allIds.filter(choiceId => 
        state.choice.byId[choiceId].questionId == questionId)
    .map(choiceId => state.choice.byId[choiceId]);
    return choiceList;
}

export const selectAllChoices = (state) => {
    const choiceList = state.choice.allIds
        .map(choiceId => state.choice.byId[choiceId]);
    return choiceList;
}