import {
  GET_GROUP_ACTIVITY_SUCCESS,
  GET_GROUP_ACTIVITY_FAIL,
  GET_SUBGROUP_ACTIVITY_SUCCESS,
  GET_SUBGROUP_ACTIVITY_FAIL,
  GROUP_ACTIVITY_LOADING,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const updateGroupActivityLoading = (isLoading) => ({
  type: GROUP_ACTIVITY_LOADING,
  payload: isLoading,
});
export const getGroupActivityPromise = createPromiseAction(
  "GET_GROUP_ACTIVITY_PROMISE"
);
export const getGroupActivitySuccess = ({ subgroupActivitys }) => ({
  type: GET_GROUP_ACTIVITY_SUCCESS,
  payload: {
    activityList: subgroupActivitys,
  },
});
export const getGroupActivityFail = (error) => ({
  type: GET_GROUP_ACTIVITY_FAIL,
  payload: error,
});

export const getSubgroupActivityPromise = createPromiseAction(
  "GET_SUBGROUP_ACTIVITY_PROMISE"
);
export const getSubgroupActivitySuccess = ({ subgroups }) => ({
  type: GET_SUBGROUP_ACTIVITY_SUCCESS,
  payload: {
    subgroupList: subgroups,
  },
});
export const getSubgroupActivityFail = (error) => ({
  type: GET_SUBGROUP_ACTIVITY_FAIL,
  payload: error,
});