import { Box, Button, Container, Divider } from '@mui/material';
import { getUserAgent } from '@pages/QuizPang/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { SurveySection } from '@components/Survey/SurveySection';
import { TimeChoices } from '@components/Survey/TimeChoices';
import { StylusChoices } from '@components/Survey/StylusChoices';
import { ShortChoices } from '@components/Survey/ShortChoices';
import { RadioChoices } from '@components/Survey/RadioForm';
import { MultipleChoices } from '@components/Survey/MultipleChoices';
import { LongChoices } from '@components/Survey/LongChoices';

export const SurveySessionScroll = ({
  groupId,
  quizsetId,
  quizsetSessionId,
  quizsetData,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sessionDeviceType = getUserAgent();
  const history = useHistory();

  //logger state
  const [isStartRecording, setIsStartRecording] = useState(true);

  // logger date state
  const [gpsData, setGpsData] = useState(null);
  const [accelgyroData, setAceelgyroData] = useState([]);
  const [sessionTouchData, setSessionTouchData] = useState([]);
  const [sessionKeyboardData, setSessionKeyboardData] = useState([]);
  const [sessionStylusData, setSessionStylusData] = useState([]);
  const [voiceData, setVoiceData] = useState([]);
  const [eyetrackingData, setEyeTrackingData] = useState([]);

  // logger quiz start, end state
  const [quizStart, setQuizStart] = useState(null);
  const [quizEnd, setQuizEnd] = useState(null);

  // logger data upload state
  const [archiveDataUpload, setArchiveDataUpload] = useState(false);

  // 스크롤 뷰 구현을 위해 quizsetData를 배열로 변환
  const quizsetArray = JSON.parse(quizsetData.quizzes);
  return (
    <Container
      maxWidth="lg"
      style={{
        width: '100%',
      }}
    >
      {quizsetArray.map((quiz, idx) => {
        switch (quiz.quizType) {
          case 'RADIO':
            return (
              <Box mb={2} mt={2} key={idx}>
                <RadioChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'SHORT':
            return (
              <Box mb={2} mt={2} key={idx}>
                <ShortChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'LONG':
            return (
              <Box mb={2} mt={2} key={idx}>
                <LongChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'STYLUS':
            return (
              <Box mb={2} mt={2} key={idx}>
                <StylusChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'TIME':
            return (
              <Box mb={2} mt={2} key={idx}>
                <TimeChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'MULTIPLE':
            return (
              <Box mb={2} mt={2} key={idx}>
                <MultipleChoices
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          case 'SECTION':
            return (
              <Box mb={2} mt={2} key={idx}>
                <SurveySection
                  title={quiz.title}
                  quizId={quiz.quizId}
                  link={quiz.link}
                  quizType={quiz.quizType}
                />
                <Divider />
              </Box>
            );
          default:
            return <></>;
        }
      })}
    </Container>
  );
};
