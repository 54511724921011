import {
  CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
  CREATE_QUIZSET_SESSION_FAIL,
  CREATE_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_FAIL,
  GET_QUIZSET_SESSION_HISTORY_FAIL,
  GET_QUIZSET_SESSION_HISTORY_SUCCESS,
  GET_QUIZSET_SESSION_RESULT_FAIL,
  GET_QUIZSET_SESSION_RESULT_SUCCESS,
  PURGE_QUIZSET_SESSION,
  SET_QUIZPANG_DEV_MODE_SUCCESS,
  SET_QUIZPANG_DEV_MODE_FAIL,
  EXIT_QUIZPANG_DEV_MODE_FAIL,
  EXIT_QUIZPANG_DEV_MODE_SUCCESS,
  SET_QUIZSET_SESSION_SUCCESS,
  SET_QUIZSET_SESSION_FAIL,
  CLEAR_QUIZPANG_SESSION_SUCCESS,
  CLEAR_QUIZPANG_SESSION_FAIL,
  GRANT_PERMISSION,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeQuizsetSession = () => ({
  type: PURGE_QUIZSET_SESSION,
});

export const getQuizsetSessionHistoryPromise = createPromiseAction(
  'GET_QUIZSET_SESSION_HISTORY_PROMISE'
);
export const getQuizsetSessionHistorySuccess = (data) => {
  return {
    type: GET_QUIZSET_SESSION_HISTORY_SUCCESS,
    data,
  };
};
export const getQuizsetSessionHistoryFail = (error) => ({
  type: GET_QUIZSET_SESSION_HISTORY_FAIL,
  payload: error,
});

export const getQuizsetSessionPromise = createPromiseAction(
  'GET_QUIZSET_SESSION_PROMISE'
);
export const getQuizsetSessionSuccess = (data) => {
  return {
    type: GET_QUIZSET_SESSION_SUCCESS,
    data,
  };
};
export const getQuizsetSessionFail = (error) => ({
  type: GET_QUIZSET_SESSION_FAIL,
  payload: error,
});

export const createQuizsetSessionPromise = createPromiseAction(
  'CREATE_QUIZSET_SESSION_PROMISE'
);
export const createQuizsetSessionSuccess = (data) => {
  return {
    type: CREATE_QUIZSET_SESSION_SUCCESS,
    data,
  };
};
export const createQuizsetSessionFail = (error) => ({
  type: CREATE_QUIZSET_SESSION_FAIL,
  payload: error,
});

export const createQuizsetSessionByQuizPromise = createPromiseAction(
  'CREATE_QUIZSET_SESSION_BY_QUIZ_PROMISE'
);
export const createQuizsetSessionByQuizSuccess = (data) => {
  return {
    type: CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
    data,
  };
};
export const createQuizsetSessionByQuizFail = (error) => ({
  type: CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  payload: error,
});

export const getQuizsetSessionResultPromise = createPromiseAction(
  'GET_QUIZSET_SESSION_RESULT_PROMISE'
);
export const getQuizsetSessionResultSuccess = (data) => {
  return {
    type: GET_QUIZSET_SESSION_RESULT_SUCCESS,
    data,
  };
};
export const getQuizsetSessionResultFail = (error) => ({
  type: GET_QUIZSET_SESSION_RESULT_FAIL,
  payload: error,
});

// 퀴즈팡 데브모드
export const setQuizsetSessionPromise = createPromiseAction(
  'SET_QUIZSET_SESSION_PROMISE'
);

// 전달받은 퀴즈세트를 퀴즈팡 세션으로 설정
export const setQuizsetSessionSuccess = (data) => {
  return {
    type: SET_QUIZSET_SESSION_SUCCESS,
    data,
  };
};

export const setQuizsetSessionFail = (error) => ({
  type: SET_QUIZSET_SESSION_FAIL,
  payload: error,
});

export const clearQuizpangSessionPromise = createPromiseAction(
  'CLEAR_QUIZPANG_SESSION_PROMISE'
);

export const clearQuizpangSessionSuccess = () => {
  return {
    type: CLEAR_QUIZPANG_SESSION_SUCCESS,
  };
};

export const clearQuizpangSessionFail = (error) => ({
  type: CLEAR_QUIZPANG_SESSION_FAIL,
  payload: error,
});

export const setQuizpangDevModePromise = createPromiseAction(
  'SET_QUIZPANG_DEV_MODE_PROMISE'
);

export const setQuizpangDevModeSuccess = () => {
  return {
    type: SET_QUIZPANG_DEV_MODE_SUCCESS,
  };
};

export const setQuizpangDevModeFail = (error) => ({
  type: SET_QUIZPANG_DEV_MODE_FAIL,
  payload: error,
});

export const exitQuizpangDevModePromise = createPromiseAction(
  'EXIT_QUIZPANG_DEV_MODE_PROMISE'
);

export const exitQuizpangDevModeSuccess = () => {
  return {
    type: EXIT_QUIZPANG_DEV_MODE_SUCCESS,
  };
};

export const exitQuizpangDevModeFail = (error) => ({
  type: EXIT_QUIZPANG_DEV_MODE_FAIL,
  payload: error,
});

export const grantPermission = (permission, granted) => {
  return {
    type: GRANT_PERMISSION,
    payload: {
      permission,
      granted,
    },
  };
};

// export const grantPermission = (permission) => ({
//   type: GRANT_PERMISSION,
//   permission,
// });

// // export const savePermission = (permission) => ({
// //   type: SAVE_PERMISSION,
// //   permission,
// // });