import { postBackend } from '@api/Backend';
import axios from 'axios';

/**
 * Creates an archive with the given data.
 * @param {Object} archiveData
 * @param {string} archiveData.context
 * @param {string} archiveData.contextId
 * @param {string} archiveData.domain
 * @param {string} archiveData.startedAt
 * @param {string} archiveData.endedAt
 */
export const createArchive = async (archiveData) => {
  return await postBackend(
    'https://test.ktor.focuspang.com/dp/archive',
    archiveData
  );
};
