import { makeFreshAllIds } from '../helpers';
import {
  PURGE_MANAGING,
  GET_MANAGING_SUCCESS,
  GET_MANAGING_FAIL,
  GET_ACTIVE_MANAGING_SUCCESS,
  GET_ACTIVE_MANAGING_FAIL,
  CREATE_MANAGING_SUCCESS,
  CREATE_MANAGING_FAIL,
  UPDATE_MANAGING_LOADING,
  UPDATE_MANAGING_SUCCESS,
  UPDATE_MANAGING_FAIL,
  DELETE_MANAGING_SUCCESS,
  DELETE_MANAGING_FAIL,
  SET_ACTIVE_MANAGING_NOT_EXIST,
  GET_MANAGINGS_BY_GROUP_SUCCESS,
  GET_MANAGINGS_BY_GROUP_FAIL,
  GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
  fetchedAllGroups: false,
  selectedGroupId: null,
  isManagingSession: false,
  managingSession: null,
  isManagingLoading: false,
  managed: null,
};

const managings = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_MANAGING:
      return initialState;

    case UPDATE_MANAGING_LOADING:
      return {
        ...state,
        isManagingLoading: action.payload,
      };
    case GET_MANAGINGS_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };
    case CREATE_MANAGING_SUCCESS:
    case GET_MANAGING_SUCCESS:
    case UPDATE_MANAGING_SUCCESS:
      const activeManaging =
        action.managing?.state === 'IN_MANAGING'
          ? {
              isManagingSession: true,
              managingSession: action.managing,
            }
          : {};
      return {
        ...state,
        ...activeManaging,
        byId: {
          ...state.byId,
          [action.managing?.managingId]: action.managing,
        },
        allIds: makeFreshAllIds(state.allIds, [action.managing?.managingId]),
      };

    case GET_ACTIVE_MANAGING_SUCCESS:
      return {
        ...state,
        isManagingSession: true,
        managingSession: action.managing,
        managed: action.managed,
      };

    case GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.managing.managingId]: action.managing },
        allIds: makeFreshAllIds(state.allIds, [action.managing.managingId]),
        isManagingSession: true,
        managingSession: action.managing,
      };

    case GET_MANAGING_FAIL:
    case GET_MANAGINGS_BY_GROUP_FAIL:
    case CREATE_MANAGING_FAIL:
    case UPDATE_MANAGING_FAIL:
    case DELETE_MANAGING_FAIL:
    case GET_ACTIVE_MANAGING_FAIL:
      console.error('[Managing Reducer] ', action.payload);
      return state;

    // todo only exception for 404 Not found
    case SET_ACTIVE_MANAGING_NOT_EXIST:
      return {
        ...state,
        isManagingSession: false,
        managingSession: null,
      };

    case DELETE_MANAGING_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.managingId]: action.managing,
        },
        allIds: state.allIds.filter(
          (managingId) => managingId !== action.managing.managingId
        ),
        //purge
      };

    default:
      break;
  }
  return state;
};

export default managings;
