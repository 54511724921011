import {
  PURGE_ALL,
  PURGE_USER,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_IN_STUDENT_SUCCESS,
  SIGN_IN_STUDENT_FAIL,
  SIGN_OUT,
  CHANGE_PW,
  CHANGE_PW_ERROR,
  RESTORE_USER,
  TEACHER_SIGN_UP_SUCCESS,
  TEACHER_SIGN_UP_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  REGISTER_STUDENT_SUCCESS,
  REGISTER_STUDENT_FAIL,

  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeAll = () => ({
  type: PURGE_ALL,
});

//get User
export const getUserPromise = createPromiseAction(
  "GET_USER_PROMISE"
);

export const getUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    user: {
      ...user,
      signedIn: true,
    },
  };
};

export const getUserFail = (error) => ({
  type: GET_USER_FAIL,
  error: error,
});


//Register a student
export const registerStudentPromise = createPromiseAction(
  "REGISTER_STUDENT_PROMISE"
);

export const registerStudentSuccess = (user) => {
  return {
    type: REGISTER_STUDENT_SUCCESS,
    user: {
      ...user,
      signedIn: true,
      updated: true,
    },
  };
};

export const registerStudentFail = (error) => ({
  type: REGISTER_STUDENT_FAIL,
  error: error,
});

///// PURGE_USER

export const purgeUser = () => ({
  type: PURGE_USER,
});

///// SIGN_UP

export const signUpPromise = createPromiseAction("SIGN_UP_PROMISE");

export const signUpSuccess = (profileBackend) => {
  return {
    type: SIGN_UP_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const signUpFail = (error) => ({
  type: SIGN_UP_FAIL,
  error: error,
});

///// SIGN_IN_STUDENT
export const signInStudentPromise = createPromiseAction(
  "SIGN_IN_STUDENT_PROMISE"
);

export const signInStudentSuccess = (user) => {
  return {
    type: SIGN_IN_STUDENT_SUCCESS,
    user: {
      ...user,
      signedIn: true,
      updated: true,
    },
  };
};

export const signInStudentFail = (error) => ({
  type: SIGN_IN_STUDENT_FAIL,
  error: error,
});

///// SIGN_OUT

export const signOut = () => ({
  type: SIGN_OUT,
});

///// Change PW

export const changePasswordPromise = createPromiseAction("CHANGE_PW_PROMISE");

export const changePassword = (password) => ({
  type: CHANGE_PW,
  payload: password,
});

export const changePasswordError = (error) => ({
  type: CHANGE_PW_ERROR,
  error: error,
});

///// RESTORE_USER

export const restoreUser = (profileData) => ({
  type: RESTORE_USER,
  profile: { ...profileData, signedIn: true },
});

///// TEACHER_SIGN_UP

export const teacherSignUpPromise = createPromiseAction(
  "TEACHER_SIGN_UP_PROMISE"
);

export const teacherSignUpSuccess = (profileBackend) => {
  return {
    type: TEACHER_SIGN_UP_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const teacherSignUpFail = (error) => ({
  type: TEACHER_SIGN_UP_FAIL,
  error: error,
});

/// UPDATE_USER 성현검토

export const updateUserPromise = createPromiseAction("UPDATE_USER_PROMISE");

export const updateUserSuccess = (userData) => ({
  type: UPDATE_USER_SUCCESS,
  classroom: api.unpackUser(userData),
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});
