import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  getProgramconfigPromise,
  getProgramconfigSuccess,
  getProgramconfigFail,
  getGroupProgramconfigsPromise,
  getGroupProgramconfigsSuccess,
  getGroupProgramconfigsFail,
  // createProgramconfigPromise,
  // createProgramconfigSuccess,
  // createProgramconfigFail,
  // updateProgramconfigPromise,
  // updateProgramconfigSuccess,
  // updateProgramconfigFail,
  // deleteProgramconfigPromise,
  // deleteProgramconfigSuccess,
  // deleteProgramconfigFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getProgramconfigPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getProgramconfig, action.payload);
      yield put(getProgramconfigSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getProgramconfigFail(error));
    }
  });
}

function* getGroupProgramconfigsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroupProgramconfigs, action.payload);
      yield put(getGroupProgramconfigsSuccess(response.data.programconfigs));
      return yield response.data.programconfigs;
    } catch (error) {
      yield put(getGroupProgramconfigsFail(error));
    }
  });
}

// function* createProgramconfigPromiseHandler(action) {
//   yield call(implementPromiseAction, action, function*() {
//     try {
//       console.log('create', action.payload);
//       const response = yield call(api.createProgramconfig, action.payload);
//       yield put(createProgramconfigSuccess(response.data));
//       return yield response.data;
//     } catch (error) {
//       yield put(createProgramconfigFail(error));
//     }
//   });
// }

// function* updateProgramconfigPromiseHandler(action) {
//   yield call(implementPromiseAction, action, function*() {
//     try {
//       const response = yield call(api.updateProgramconfig, action.payload);
//       yield put(updateProgramconfigSuccess(response.data));
//       return yield response.data;
//     } catch (error) {
//       yield put(updateProgramconfigFail(error));
//     }
//   });
// }

// function* deleteProgramconfigPromiseHandler(action) {
//   yield call(implementPromiseAction, action, function*() {
//     try {
//       console.log('delete', action.payload);
//       const response = yield call(api.deleteProgramconfig, action.payload);
//       yield put(deleteProgramconfigSuccess(action.payload));
//       return yield response.data;
//     } catch (error) {
//       yield put(deleteProgramconfigFail(error));
//     }
//   });
// }

function* programconfigSaga() {
  yield takeLeading(getProgramconfigPromise, getProgramconfigPromiseHandler);
  yield takeEvery(
    getGroupProgramconfigsPromise,
    getGroupProgramconfigsPromiseHandler
  );
  // yield takeEvery(
  //   createProgramconfigPromise,
  //   createProgramconfigPromiseHandler
  // );
  // yield takeLeading(
  //   updateProgramconfigPromise,
  //   updateProgramconfigPromiseHandler
  // );
  // yield takeEvery(
  //   deleteProgramconfigPromise,
  //   deleteProgramconfigPromiseHandler
  // );
}

export default programconfigSaga;
