import styles from "./FileLink.module.scss";
import CardFileLink from "../Card/CardFileLink";

const FileLink = ({ filelinks }) => {
  return (
    <div
      name='fileLinkContainer'
      className={styles["file-link-container-column"]}
    >
      {/* <div className={styles["file-link-title-container"]}> */}
        {/* <span name='fileLinkTitle' className={styles["file-link-title"]}>
          링크
        </span> */}
        {/* <img
          className={styles["add-app-img"]}
          src={modifyIcon}
          alt="editApplist"
          onClick={() => setModalShow(true)}
        /> */}
      {/* </div> */}
      {filelinks?.reverse().map((filelink) => (
        <CardFileLink filelink={filelink} />
      ))}

      {/* <ModalFileLink
        show={modalShow}
        onHide={() => setModalShow(false)}
        group={group}
      /> */}
    </div>
  );
};

export default FileLink;