export const PURGE_QUIZSET_SESSION = 'PURGE_QUIZSET_SESSION';
export const GET_QUIZSET_SESSION_HISTORY_SUCCESS = 'GET_QUIZSET_SESSION_HISTORY_SUCCESS';
export const GET_QUIZSET_SESSION_HISTORY_FAIL = 'GET_QUIZSET_SESSION_HISTORY_FAIL';
export const GET_QUIZSET_SESSION_SUCCESS ='GET_QUIZSET_SESSION_SUCCESS';
export const GET_QUIZSET_SESSION_FAIL ='GET_QUIZSET_SESSION_FAIL';
export const CREATE_QUIZSET_SESSION_SUCCESS = 'CREATE_QUIZSET_SESSION_SUCCESS';
export const CREATE_QUIZSET_SESSION_FAIL = 'CREATE_QUIZSET_SESSION_FAIL';
export const CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS = 'CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS';
export const CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL = 'CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL';
export const GET_QUIZSET_SESSION_RESULT_SUCCESS = 'GET_QUIZSET_SESSION_RESULT_SUCCESS';
export const GET_QUIZSET_SESSION_RESULT_FAIL = 'GET_QUIZSET_SESSION_RESULT_FAIL';

//set quizset session
export const SET_QUIZSET_SESSION_SUCCESS = 'SET_QUIZSET_SESSION_SUCCESS';
export const SET_QUIZSET_SESSION_FAIL = 'SET_QUIZSET_SESSION_FAIL';

export const CLEAR_QUIZPANG_SESSION_SUCCESS = 'CLEAR_QUIZPANG_SESSION';
export const CLEAR_QUIZPANG_SESSION_FAIL = 'CLEAR_QUIZPANG_SESSION_FAIL';

// set dev quizpang dev mode
export const SET_QUIZPANG_DEV_MODE_SUCCESS = 'SET_QUIZPANG_DEV_MODE_SUCCESS';
export const SET_QUIZPANG_DEV_MODE_FAIL = 'SET_QUIZPANG_DEV_MODE_FAIL';

// exit dev quizpang dev mode
export const EXIT_QUIZPANG_DEV_MODE_SUCCESS = 'EXIT_QUIZPANG_DEV_MODE_SUCCESS';
export const EXIT_QUIZPANG_DEV_MODE_FAIL = 'EXIT_QUIZPANG_DEV_MODE_FAIL';

// save and request permissions
export const GRANT_PERMISSION = 'GRANT_PERMISSION';
// export const REQUEST_PERMISSION = 'REQUEST_PERMISSION';