import styled from "styled-components";
import React, { useEffect, useState } from "react";
import BaseCard from "./baseCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Wrapper = styled.div`
padding: 16px;
`;

const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const Title = styled.div`
font-weight: 500;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.15px;
`;

const Body = styled.div`
margin-top: 16px;
display: ${props => props.open ? "block" : "none"};
background-color: #ffffff;
`;

const ToggleButton = styled.div`
color: #0BA1AE;
display: flex;
align-items: center;
cursor: pointer;

font-weight: 500;
font-size: 12px;
letter-spacing: 1.25px;
`;

const CollapseCard = ({ title, children, initialOpen }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (initialOpen) {
            setOpen(true);
        }
    }, [initialOpen]);

    return (
        <BaseCard radius={8}>
            <Wrapper>
                <Header style={{ marginBottom: open ? 16 : 0 }}>
                    <Title>{title}</Title>
                    <ToggleButton onClick={() => setOpen(prev => !prev)}>
                        { open ? "접기" : "펼치기" }
                        <KeyboardArrowDownIcon
                            fontSize="small"
                            style={{
                                transform: `rotate(${open ? 180 : 0}deg)`,
                                color: "#0BA1AE",
                            }}
                        />
                    </ToggleButton>
                </Header>
                <Body open={open}>
                    {children}
                </Body>
            </Wrapper>
        </BaseCard>
    );
};

export default CollapseCard;