import {
  PURGE_CHOICE,

  GET_CHOCIES_SUCCESS,

} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeChoice = () => ({
  type: PURGE_CHOICE,
})

///// GET_CHOICES
export const getChoicesSuccess = (choices) => {
  let newChoiceById = {};
  choices.forEach(choice => {
    newChoiceById[choice.choiceId] = choice;
  });
  const newChoiceAllIds = choices.map(choice => choice.choiceId);

  return {
    type: GET_CHOCIES_SUCCESS,
    newById: newChoiceById,
    newAllIds: newChoiceAllIds,
  };
}
