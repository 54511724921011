export const selectAllTimetables = (state) => {
  if (state.timetable.allIds.length === 0) {
    return [];
  }
  
  return state.timetable.allIds
    ?.filter(
      (timetableId) =>
        (!state.timetable.byId[timetableId].hasOwnProperty("deleted")) &&
        (state.timetable.byId[timetableId].state !== 'DELETED')
    )
    .map((timetableId) => state.timetable.byId[timetableId]);
};

export const selectGroupTimetables = (state, groupId) => {
  return selectAllTimetables(state).filter(
    (timetable) =>
      timetable.groupId === groupId && timetable.state !== 'DELETED'
  );
};
