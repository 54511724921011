import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  purgeAll,
  // updateUserInfoPromise
} from '@store/actions';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './index.module.scss';
import Stickers from '@components/ClassRoom/Stickers/Stickers';
import {
  selectAllStickerPreset,
  selectAllStickers,
  selectUserSchool,
  // selectUserSchool,
} from '@store/selectors';
import { selectHRGroup, selectSubjectGroups } from '@store/selectors';
import { getSchoolPromise } from '@store/actions';
import { profileImgPath, setToken } from '@api';
import PageWrapper from '@components/Layout/PageWrapper';
import MdInput from '@components/Input/MdInput';
import Password from '@components/Input/password';
import { Button } from '@mui/material';
import ProfileImage from '@components/ProfileImage';
import SignoutImg from '../../assets/images/icons/signout.png';
import ModalUpdateProfileImage from '@components/Modal/ModalUpdateProfileImage';

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user
  );
  const { decipherSchool } = useSelector(
    (/** @type {import('@store').State} */ state) => state.schools
  );
  // const school = useSelector((state) => selectUserSchool(state, user));
  const [school, setSchool] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [pw, setPw] = useState('');
  const [confirmPw, setConfirmedPw] = useState('');

  const group = useSelector((/** @type {import('@store').State} */ state) =>
    selectHRGroup(state)
  );
  const sub_group = useSelector((/** @type {import('@store').State} */ state) =>
    selectSubjectGroups(state)
  );
  const profileImageUrl = useSelector((
    /** @type {import('@store').State} */ state
  ) => {
    console.log(state.profile.profileImageUrl);
    return state.profile.profileImageUrl;
  });

  if (user.token !== undefined && user.token !== null) {
    setToken(user.token).then(() => {
      try {
        console.log('set token to program...');
        window.cAPI.setToken(user.token);
      } catch (error) {}
    });
  }

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }

    return profileImgPath(user.clientId);
  }, [user]);

  const navigateToEditMode = () => {
    history.replace('/setfillinfo/');
  };

  const renderInformation = (title, information) => {
    return (
      <div className={styles['my-info-line']}>
        <span className={styles['span-info-title']}>{title}</span>
        <div className={styles['render-info']}>
          <span className={styles['span-render-info']}>{information}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (user.state === 'NOT_SIGNED_IN') {
      history.replace('/auth');
    }
  }, [user.state]);

  // useEffect(() => {
  //   const schoolId = group ? group.school : sub_group[0]?.school;
  //   dispatch(getSchoolPromise(schoolId)).then((data) => {
  //     setSchool(data.name);
  //   });
  // }, []);

  const onPurgeClick = () => {
    dispatch(purgeAll());
  };

  // TODO: 비밀번호 변경 로직
  // const sendChangedPassword = () => {
  //   dispatch(
  //     updateUserInfoPromise({
  //       password: pw,
  //       name: user.username,
  //       state: "REGISTERED_FILLED",
  //     })
  //   )
  //     .then((clientData) => {
  //       if (clientData === undefined) {
  //         throw new Error("userInfo Fill Failed!");
  //       }
  //       alert(`비밀번호가 성공적으로 변경되었습니다!`);
  //     })
  //     .catch((error) => {
  //       console.error("fillinfo ERROR: ", error);
  //       alert("비밀번호 변경에 실패하였습니다. 다시 시도해주세요.");
  //     });
  // };

  // useEffect(() => {
  //   if (user.token !== undefined || user.token !== null) {
  //     setToken(user.token).then(() => {
  //       try {
  //         console.log("set token to program...");
  //         window.cAPI.setToken(user.token);
  //       } catch (error) {}
  //     });
  //   }
  // }, []);

  return (
    <>
      <ModalUpdateProfileImage
        visible={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        currentProfileImage={myProfileImage}
      />
      <Sidebar />
      <PageWrapper title="설정">
        <span className={styles['span-section-title']}>내 정보</span>
        <div className={styles['userInfo-container']}>
          <div className={styles['profile-wrapper']}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 24,
              }}
            >
              <ProfileImage size={44} url={profileImageUrl} />

              <button
                className={styles['profile-image-button']}
                onClick={() => setModalShow(true)}
              >
                프로필 사진 변경
              </button>
            </div>
            <button className={styles['signout-button']} onClick={onPurgeClick}>
              <img src={SignoutImg} width={15} height={17} />
              <div>로그아웃</div>
            </button>
          </div>
          <MdInput
            label="이름"
            value={user?.userName}
            style={{ marginTop: 24 }}
            disabled
          />
          <MdInput
            label="학교"
            value={decipherSchool?.name}
            style={{ marginTop: 24 }}
            disabled
          />
          <div className={styles['flex-view']}>
            <MdInput
              label="학년"
              value={`${group ? group.grade : sub_group[0]?.grade}`}
              disabled
            />
            <MdInput
              label="반"
              value={`${group ? group.groupName : sub_group[0]?.groupName}`}
              disabled
            />
            <MdInput label="학번" value={user?.studentNumber} disabled />
          </div>
          {/* {console.log(decipherSchool)}
          {renderInformation("학교", decipherSchool?.name)} */}
          {/* {renderInformation("학년", group ? group.grade : sub_group[0]?.grade)}
          {renderInformation(
            "반",
            group ? group.class_name : sub_group[0]?.class_name
          )} */}
          {/* {renderInformation("학번", user.userInfo?.studentNumber)}
          {renderInformation("이름", user.userInfo?.userName)} */}
        </div>
        {/* <div className={styles["passwordChange-container"]}>
          <span className={styles["settings-subtitle"]}>비밀번호 변경</span>
          <Password
            label="새 비밀번호 입력"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            style={{ marginTop: 16 }}
          />
          <Password
            label="비밀번호 입력 확인"
            value={confirmPw}
            onChange={(e) => setConfirmedPw(e.target.value)}
            style={{ marginTop: 4 }}
          />
          <div className={styles["password-button-wrapper"]}>
            {/* TODO: 비밀번호 로직 */}
        {/* {pw === confirmPw && pw !== "" ? (
              <Button
                variant="contained"
                onClick={sendChangedPassword}
              >
                비밀번호 변경
              </Button>
            ) : pw === "" ? (
              <Button variant="contained" disabled>
                비밀번호 변경
              </Button>
            ) : (
              <Button variant="contained">
                비밀번호가 일치하지 않습니다.
              </Button>
            )} */}

        <br />
        <div style={{ color: '#eeeeee' }}>ver 3.0.1</div>
      </PageWrapper>
    </>
  );
};

export default Settings;
