import { makeFreshAllIds } from '../helpers';
import {
  PURGE_PROGRAM,

  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAIL,

  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL,

} from './actionTypes';

/**
 * @typedef {Object} Program
 * @property {string} programId - id of program
 * @property {string} korName - korean name of programconfig
 * @property {string} engName - english name of programconfig
 * @property {string} alias - alias of programconfig
 * @property {string} web - web url of programconfig
 * @property {string} android - android package name of programconfig
 * @property {string} ios - ios package name of programconfig
 * @property {string} windows - windows package name of programconfig
 * @property {string} icon - icon url of programconfig
 * @property {string} createdAt - created at of programconfig
 * @property {string} updatedAt - updated at of programconfig
 */

/**
 * @typedef {Object} ProgramsState
 * @property {{[key: string]: Program}} byId - programconfigs by id
 * @property {string[]} allIds - array of programconfig ids
 */

const initialState = {
  byId: {},
  allIds: [],
};

const programs = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_PROGRAM:
      return initialState

    case GET_PROGRAM_SUCCESS:
      return {...state,
        byId: {...state.byId,
          [action.program.programId]: action.program},
        allIds: makeFreshAllIds(state.allIds, [action.program.programId])};

    case GET_PROGRAMS_SUCCESS:
      return {...state,
        byId: {...state.byId, ...action.byId},
        allIds: makeFreshAllIds(state.allIds, action.allIds)};

    // todo: handle errors
    case GET_PROGRAM_FAIL:
    case GET_PROGRAMS_FAIL:
      console.error('[Program Reducer]', action.payload);
      return state
    default:
      break;
  }
  return state;
};

export default programs;
