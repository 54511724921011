export const PURGE_QUIZCHOICE = 'PURGE_QUIZCHOICE';
export const GET_QUIZCHOICE_BY_CHOICEID_SUCCESS = 'GET_QUIZCHOICE_BY_CHOICEID_SUCCESS';
export const GET_QUIZCHOICE_BY_CHOICEID_FAIL = 'GET_QUIZCHOICE_BY_CHOICEID_FAIL';
export const GET_QUIZCHOICE_BY_QUIZID_SUCCESS = 'GET_QUIZCHOICE_BY_QUIZID_SUCCESS';
export const GET_QUIZCHOICE_BY_QUIZID_FAIL = 'GET_QUIZCHOICE_BY_QUIZID_FAIL';
export const CREATE_QUIZCHOICE_SUCCESS = 'CREATE_QUIZCHOICE_SUCCESS';
export const CREATE_QUIZCHOICE_FAIL = 'CREATE_QUIZCHOICE_FAIL';
export const UPDATE_QUIZCHOICE_SUCCESS = 'UPDATE_QUIZCHOICE_SUCCESS';
export const UPDATE_QUIZCHOICE_FAIL = 'UPDATE_QUIZCHOICE_FAIL'; 
export const DELETE_QUIZCHOICE_SUCCESS = 'DELETE_QUIZCHOICE_SUCCESS';
export const DELETE_QUIZCHOICE_FAIL = 'DELETE_QUIZCHOICE_FAIL';