import styled from "@emotion/styled";

const BaseCard = styled.div`
background: #FFFFFF;
${props => props.shadow ?
    `box-shadow: ${props.shadow}` :
    'border: 1px solid #EEEEEE'
};
border-radius: ${props => props.radius ?? 16}px;
`;

export default BaseCard;