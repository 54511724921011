import {
  PURGE_NOTIFICATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_CREATED_NOTIFICATION,
  GET_CREATED_NOTIFICATION_SUCCESS,
  GET_CREATED_NOTIFICATION_FAIL,
  FIND_NOTIFICATION,
  FIND_NOTIFICATION_SUCCESS,
  FIND_NOTIFICATION_FAIL,
  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  PROCESS_NOTIFICATION,
  PROCESS_NOTIFICATION_SUCCESS,
  PROCESS_NOTIFICATION_FAIL,
} from "./actionTypes";

import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

///// PURGE_NOTIFICATION
export const purgeNotification = () => ({
  type: PURGE_NOTIFICATION,
});

///// GET NOTIFICATION
export const getNotification = (clientId) => {
  return {
    type: GET_NOTIFICATION,
    clientId: clientId,
  };
};

export const getNotificationSuccess = (notificationData) => {
  const { notifications } = notificationData;
  return {
    type: GET_NOTIFICATION_SUCCESS,
    notifications,
  };
};

export const getNotificationFail = (error) => {
  return {
    type: GET_NOTIFICATION_FAIL,
    payload: error,
  };
};

// 0609 add
///// PROCESS NOTIFICATION
export const processNotification = (notificationId) => {
  return {
    type: PROCESS_NOTIFICATION,
    notificationId: notificationId,
  };
};

export const processNotificationSuccess = (notificationData) => {
  const notification = notificationData;
  return {
    type: PROCESS_NOTIFICATION_SUCCESS,
    notification: notification,
  };
};

export const processNotificationFail = (error) => {
  return {
    type: PROCESS_NOTIFICATION_FAIL,
    payload: error,
  };
};

///// FIND NOTIFICATION
export const findNotification = (notificationId) => {
  return {
    type: FIND_NOTIFICATION,
    notificationId: notificationId,
  };
};

export const findNotificationSuccess = () => {
  return {
    type: FIND_NOTIFICATION_SUCCESS,
  };
};

export const findNotificationFail = (error) => {
  return {
    type: FIND_NOTIFICATION_FAIL,
    payload: error,
  };
};

// 0609
//// GET CREATED NOTIFICATION
export const getCreatedNotification = () => {
  return {
    type: GET_CREATED_NOTIFICATION,
  };
};

export const getCreatedNotificationSuccess = (createdNotificationData) => {
  let byId = {};
  createdNotificationData.forEach((notificationData) => {
    byId[notificationData.id] = notificationData;
  });

  const allIds = createdNotificationData.map(
    (notificationData) => notificationData.id
  );
  return {
    type: GET_CREATED_NOTIFICATION_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getCreatedNotificationFail = (error) => {
  return {
    type: GET_CREATED_NOTIFICATION_FAIL,
    payload: error,
  };
};

///// GET ALL NOTIFICATION
export const getAllNotification = () => {
  return {
    type: GET_ALL_NOTIFICATION,
  };
};

export const getAllNotificationSuccess = (allNotificationData) => {
  let byId = {};

  allNotificationData.forEach((notificationData) => {
    byId[notificationData.id] = notificationData;
  });

  const allIds = allNotificationData.map(
    (notificationData) => notificationData.id
  );
  return {
    type: GET_ALL_NOTIFICATION_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getAllNotificationFail = (error) => {
  return {
    type: GET_ALL_NOTIFICATION_FAIL,
    payload: error,
  };
};

///// READ NOTIFICATION
export const readNotificationPromise = createPromiseAction('READ_NOTIFICATION');

// export const readNotification = (notification) => {
//   return {
//     type: READ_NOTIFICATION,
//     notification,
//   };
// };

export const readNotificationSuccess = (notification) => {
  return {
    type: READ_NOTIFICATION_SUCCESS,
    notification,
  };
};

export const readNotificationFail = (error) => {
  return {
    type: READ_NOTIFICATION_FAIL,
    payload: error,
  };
};

///// DELETE NOTIFICATION
export const deleteNotification = (notificationId) => {
  return {
    type: DELETE_NOTIFICATION,
    notificationId: notificationId,
  };
};

export const deleteNotificationSuccess = (notificationId) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    notificationId: notificationId,
  };
};

export const deleteNotificationFail = (error) => {
  return {
    type: DELETE_NOTIFICATION_FAIL,
    payload: error,
  };
};
