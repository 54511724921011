// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SidebarClassList_side-class-list__d1Npl {\n  font-family: \"Noto Sans KR\", \"Roboto\", sans-serif;\n  display: flex;\n  height: 44px;\n  width: 240px;\n  padding-left: 28px;\n  align-items: center; }\n\n.SidebarClassList_side-class-list__d1Npl:hover {\n  background-color: #f4f2ef; }\n\n.SidebarClassList_active__wP4cn {\n  background-color: #f4f2ef; }\n\n.SidebarClassList_img-class-icon__YqxID {\n  width: 24px;\n  height: 24px; }\n\n.SidebarClassList_side-class-grade__iqvbH {\n  float: left;\n  margin-right: 3px;\n  color: #66666e; }\n\n.SidebarClassList_side-class-name__xtUNM {\n  font-size: small;\n  color: #9b9b9b; }\n", "",{"version":3,"sources":["webpack://./src/components/Layout/SidebarClassList.module.scss"],"names":[],"mappings":"AAMA;EACE,iDAAiD;EACjD,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB,EAAA;;AAGrB;EACE,yBAAyB,EAAA;;AAG3B;EACE,yBAAyB,EAAA;;AAG3B;EACE,WAAW;EACX,YAAY,EAAA;;AAGd;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc,EAAA;;AAGhB;EACE,gBAAgB;EAChB,cAAc,EAAA","sourcesContent":["// @import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap\");\r\n// .side-class-menu {\r\n//   font-size: medium;\r\n//   font-family: \"Noto Sans KR\", \"Roboto\", sans-serif;\r\n// }\r\n@import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&family=Roboto:wght@300&display=swap\");\r\n.side-class-list {\r\n  font-family: \"Noto Sans KR\", \"Roboto\", sans-serif;\r\n  display: flex;\r\n  height: 44px;\r\n  width: 240px;\r\n  padding-left: 28px;\r\n  align-items: center;\r\n}\r\n\r\n.side-class-list:hover {\r\n  background-color: #f4f2ef;\r\n}\r\n\r\n.active {\r\n  background-color: #f4f2ef;\r\n}\r\n\r\n.img-class-icon {\r\n  width: 24px;\r\n  height: 24px;\r\n}\r\n\r\n.side-class-grade {\r\n  float: left;\r\n  margin-right: 3px;\r\n  color: #66666e;\r\n}\r\n\r\n.side-class-name {\r\n  font-size: small;\r\n  color: #9b9b9b;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side-class-list": "SidebarClassList_side-class-list__d1Npl",
	"active": "SidebarClassList_active__wP4cn",
	"img-class-icon": "SidebarClassList_img-class-icon__YqxID",
	"side-class-grade": "SidebarClassList_side-class-grade__iqvbH",
	"side-class-name": "SidebarClassList_side-class-name__xtUNM"
};
export default ___CSS_LOADER_EXPORT___;
