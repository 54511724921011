import { call, put, takeLeading } from 'redux-saga/effects';
import {
  GET_SCHOOL,
  GET_ALL_SCHOOLS,
  GET_FILTERED_SCHOOLS,
} from './actionTypes';
import {
  getSchoolSuccess,
  getSchoolFail,
  getSchoolByInviteCodeSuccess,
  getSchoolByInviteCodeFail,
  getSchoolPromise,
  getAllSchoolsSuccess,
  getAllSchoolsFail,
  getFilteredSchoolsSuccess,
  getFilteredSchoolsFail,

} from '@store/actions'
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';


function* getSchoolHandler(action) {
  try {
    const response = yield call(api.getSchool, action.schoolId);
    yield put(getSchoolSuccess(response.data));
  } catch(error) {
    yield put(getSchoolFail(error));
  }
}

function* getSchoolPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getSchool, action.payload);
      yield put(getSchoolByInviteCodeSuccess(response.data));
      return yield response.data;
    } catch(error) {
      yield put(getSchoolByInviteCodeFail(error));
    }  
  });
}

function* getAllSchoolsHandler(action) {
  try {
    const response = yield call(api.getAllSchools);
    yield put(getAllSchoolsSuccess(response.data));
  } catch(error) {
    yield put(getAllSchoolsFail(error));
  }
}

function* getFilteredSchoolsHandler(action) {
  try {
    const response = yield call(api.getFilteredSchools, action.schoolKeyword);
    yield put(getFilteredSchoolsSuccess(response.data));
  } catch(error) {
    yield put(getFilteredSchoolsFail(error));
  }
}


function* schoolSaga() {
    yield takeLeading(GET_SCHOOL, getSchoolHandler);
    yield takeLeading(GET_ALL_SCHOOLS, getAllSchoolsHandler);
    yield takeLeading(GET_FILTERED_SCHOOLS, getFilteredSchoolsHandler);
    yield takeLeading(getSchoolPromise, getSchoolPromiseHandler);
}

export default schoolSaga