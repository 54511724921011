import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import profile from '../../assets/images/quizpang/avatar.png';
import home from '../../assets/images/quizpang/home.png';

const QuizResultRanking = ({ index, data }) => {
    const user = useSelector((state) => state.user)
    const isMyself = data.clientId === user.clientId
    return (
      <OuterContainer>
        <Container style={{ backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#fff', borderWidth: isMyself && 2, borderColor: isMyself && '#0894A0', borderRadius: isMyself && 4 }}>
            <IndexBox>
                <Font>{index}</Font>
            </IndexBox>
            <NameBox>
                <img src={profile} style={{ marginRight: 8, width: 28, height: 28 }} />
                <Font>{data.studentName}</Font>
                {isMyself && <img src={home} style={{ marginLeft: 4, width: 20, height: 20 }} />}
            </NameBox>
            <CountBox>
                <CountFont>{data.correctAnswerCount}문제 정답</CountFont>
            </CountBox>
            <ScoreBox>
                <ScoreFont>{data.score}점</ScoreFont>
            </ScoreBox>
        </Container>
        {/* {isMyself && <SelectionBox />} */}
      </OuterContainer>
    )
};

const OuterContainer = styled.div`
`
const Container = styled.div`
  display: flex;  
  align-items: center;
  padding: 14px 16px;
  border-bottom-width: 1px;
  border-color: #EEEEEE;
`
const SelectionBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #0894A0;
  border-radius: 4px;
`
export const IndexBox = styled.div`
  flex-direction: column;
  width: 10%;
`
export const NameBox = styled.div`
  display: flex; 
  flex-direction: row;
  align-items: center;
  width: 40%;
`
export const CountBox = styled.div`
  flex-direction: column;
  width: 30%;
  alignItems: flex-end;
`
export const ScoreBox = styled.div`
  flex-direction: column;
  width: 20%;
  alignItems: flex-end;
`
export const Font = styled.div`
color: #000000DE;
font-size: 14px;
font-weight: 400;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
`
export const CountFont = styled.div`
color: #00000099;
font-size: 12px;
font-weight: 400;
`
export const ScoreFont = styled.div`
color: #056B75;
font-size: 12px;
font-weight: 500;
`

export default QuizResultRanking;
