import { createPromiseAction } from "@adobe/redux-saga-promise";
import {
  GET_COMMENTS_LAYER_FAIL,
  GET_COMMENTS_LAYER_SUCCESS,
  SET_COMMENTS_LAYER_ACTIVE,
  SET_COMMENTS_TEACHER,
  SET_COMMENTS_TARGET,
  SET_COMMENTS_STUDENT,
  SET_COMMENTS_SHARED_SCREEN,
  SET_MY_COMMENTS_SHARED,
  DELETE_COMMENTS_STUDENT_ALL,
} from "./actionTypes";

export const setCommentsLayerActive = (payload) => ({
  type: SET_COMMENTS_LAYER_ACTIVE,
  payload,
});
export const setCommentsTeacher = (payload) => ({
  type: SET_COMMENTS_TEACHER,
  payload,
});
export const setCommentsTarget = (payload) => ({
  type: SET_COMMENTS_TARGET,
  payload,
});
export const setCommentsStudent = (payload) => ({
  type: SET_COMMENTS_STUDENT,
  payload,
});
export const setCommentsSharedScreen = (payload) => ({
  type: SET_COMMENTS_SHARED_SCREEN,
  payload,
});
export const setMyCommentsShared = (payload) => ({
  type: SET_MY_COMMENTS_SHARED,
  payload,
})
export const deleteCommentsStudentAll = () => ({
  type: DELETE_COMMENTS_STUDENT_ALL,
});

export const getCommentsLayerPromise = createPromiseAction(
  "GET_COMMENTS_LAYER_PROMISE"
);
export const getCommentsLayerSuccess = ({ commentsLayers }) => ({
  type: GET_COMMENTS_LAYER_SUCCESS,
  payload: {
    commentsLayers,
  },
});
export const getCommentsLayerFail = (error) => ({
  type: GET_COMMENTS_LAYER_FAIL,
  payload: error,
});
