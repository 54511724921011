import React from "react";
import styled from "styled-components";

export const QuizChoice = ({
  index,
  option,
  isSelected,
  onPress,
  clicked,
  setClicked,
}) => {
  return (
    <Container>
      <button
        onClick={() => {
          onPress();
          setClicked(true);
        }}
        disabled={clicked}
        style={isSelected ? onpressChoiceContainer : choiceContainer}
      >
        <IndexBox>{String(index)}</IndexBox>
        <OptionBox style={isSelected ? onpressOptionText : optionText}>
          {option}
        </OptionBox>
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  alignitems: center;
  justify-content: center;
`;
const IndexBox = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 4px;
  alignitems: center;
  justifycontent: center;
  margin-right: 10px;

  color: #000000;
  font-size: 20px;
  font-weight: 500;
`;
const OptionBox = styled.div`
  width: 500px;
  alignitems: center;
  white-space: pre-line;
`;

const onpressChoiceContainer = {
  display: "flex",
  width: 540,
  padding: 6,
  borderRadius: 8,
  borderWidth: 2,
  marginBottom: 8,
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  backgroundColor: "#E4F6F8",
  borderColor: "#0894A0",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
};
const choiceContainer = {
  display: "flex",
  width: 540,
  padding: 6,
  borderRadius: 8,
  borderWidth: 2,
  borderColor: "#E0E0E0",
  marginBottom: 8,
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
};
const optionText = {
  fontSize: 20,
  fontWeight: "500",
  color: "#000000DE",
  width: 475,
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  textAlign: "center",
};
const onpressOptionText = {
  fontSize: 20,
  fontWeight: "500",
  color: "#056B75",
  width: 475,
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  textAlign: "center",
};
