export const PURGE_PROGRAMCONFIG = 'PURGE_PROGRAMCONFIG';

export const GET_PROGRAMCONFIG_SUCCESS = 'GET_PROGRAMCONFIG_SUCCESS';
export const GET_PROGRAMCONFIG_FAIL = 'GET_PROGRAMCONFIG_FAIL';

export const GET_GROUP_PROGRAMCONFIGS_SUCCESS =
  'GET_GROUP_PROGRAMCONFIGS_SUCCESS';
export const GET_GROUP_PROGRAMCONFIGS_FAIL = 'GET_GROUP_PROGRAMCONFIGS_FAIL';

export const CREATE_PROGRAMCONFIG_SUCCESS = 'CREATE_PROGRAMCONFIG_SUCCESS';
export const CREATE_PROGRAMCONFIG_FAIL = 'CREATE_PROGRAMCONFIG_FAIL';

export const UPDATE_PROGRAMCONFIG_SUCCESS = 'UPDATE_PROGRAMCONFIG_SUCCESS';
export const UPDATE_PROGRAMCONFIG_FAIL = 'UPDATE_PROGRAMCONFIG_FAIL';

export const DELETE_PROGRAMCONFIG_SUCCESS = 'DELETE_PROGRAMCONFIG_SUCCESS';
export const DELETE_PROGRAMCONFIG_FAIL = 'DELETE_PROGRAMCONFIG_FAIL';

export const UPDATE_EXTENSION_PROGRAMCONFIGSDATA_SUCCESS =
  'UPDATE_EXTENSION_PROGRAMCONFIGSDATA_SUCCESS';
export const UPDATE_EXTENSION_PROGRAMCONFIGSDATA_FAIL =
  'UPDATE_EXTENSION_PROGRAMCONFIGSDATA_FAIL';
