import CollapseCard from "@components/Card/CollapseCard";
import FileLink from "@components/Layout/FileLink";
import { memo } from "react";

const FileLinkList = ({ filelinks }) => {
  return (
    <CollapseCard title="받은 링크 목록" initialOpen={true}>
      {filelinks && (
        <FileLink filelinks={filelinks} />
      )}
    </CollapseCard>
  )
}

export default memo(FileLinkList, (prev, next) => {
  return JSON.stringify(prev.filelinks) === JSON.stringify(next.filelinks);
});
