export const PURGE_ANSWER = "PURGE_ANSWER";

export const GET_ANSWERS = 'GET_ANSWERS';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAIL = 'GET_ANSWERS_FAIL';

export const GET_ANSWER_CONTENT = 'GET_ANSWER_CONTENT';
export const GET_ANSWER_CONTENT_SUCCESS = 'GET_ANSWER_CONTENT_SUCCESS';
export const GET_ANSWER_CONTENT_FAIL = 'GET_ANSWER_CONTENT_FAIL';

export const CREATE_SURVEY_ANSWER = 'CREATE_SURVEY_ANSWER';
export const CREATE_SURVEY_ANSWER_SUCCESS = 'CREATE_SURVEY_ANSWER_SUCCESS';
export const CREATE_SURVEY_ANSWER_FAIL = 'CREATE_SURVEY_ANSWER_FAIL';

export const CREATE_SURVEY_ANSWERS = 'CREATE_SURVEY_ANSWERS';
export const CREATE_SURVEY_ANSWERS_SUCCESS = 'CREATE_SURVEY_ANSWERS_SUCCESS';
export const CREATE_SURVEY_ANSWERS_FAIL = 'CREATE_SURVEY_ANSWERS_FAIL';

export const CREATE_QNA_ANSWER = 'CREATE_QNA_ANSWER';
export const CREATE_QNA_ANSWER_SUCCESS = 'CREATE_QNA_ANSWER_SUCCESS';
export const CREATE_QNA_ANSWER_FAIL = 'CREATE_QNA_ANSWER_FAIL';

export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL';

export const DELETE_ANSWER = 'DELETE_ANSWER';
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS';
export const DELETE_ANSWER_FAIL = 'DELETE_ANSWER_FAIL';

export const UPLOAD_ANSWER = 'UPLOAD_ANSWER';
export const UPLOAD_ANSWER_SUCCESS = 'UPLOAD_ANSWER_SUCCESS';
export const UPLOAD_ANSWER_FAIL = 'UPLOAD_ANSWER_FAIL';