import { combineReducers } from 'redux';
import user from './user/reducer';
import schools from './school/reducer';
import accounts from './accounts/reducer';
import clients from './clients/reducer';
import groups from './groups/reducer';
import notification from './notification/reducer';
import programs from './programs/reducer';
import programconfigs from './programconfigs/reducer';
import classrooms from './classrooms/reducer';
import managing from './managing/reducer';
import control from './control/reducer';
import timetable from './timetable/reducer';
import filelinks from './filelinks/reducer';
import kakaocert from './kakaocert/reducer';
import device from './device/reducer';
import session from './session/reducer';
import outline from './outline/reducer';
import question from './question/reducer';
import subquestion from './subquestion/reducer';
import choice from './choice/reducer';
import answer from './answer/reducer';
import membership from './membership/reducer';
import participation from './participation/reducer';
import managed from './managed/reducer';
import profile from './profile/reducer';
import groupActivity from './groupActivity/reducer';
import reports from './report/reducer';
import reportEvents from './reportEvents/reducer';
import commentsLayer from './commentsLayer/reducer';
import quiz from './quiz/reducer';
import quizChoice from './quizChoice/reducer';
import quizenrollment from './quizenrollment/reducer';
import quizresponse from './quizresponse/reducer';
import quizset from './quizset/reducer';
import quizsetSession from './quizsetSession/reducer';
import dataArchive from './dataArchive/reducer';
import dataSave from './dataSave/reducer';
import calibration from './calibration/reducer';
import survey from './survey/reducer';

const rootReducer = combineReducers({
  user,
  clients,
  groups,
  notification,
  programs,
  programconfigs,
  classrooms,
  managing,
  schools,
  control,
  timetable,
  kakaocert,
  device,
  filelinks,
  accounts,
  session,
  outline,
  question,
  subquestion,
  choice,
  answer,
  membership,
  managed,
  participation,
  profile,
  groupActivity,
  reports,
  reportEvents,
  commentsLayer,
  quiz,
  quizChoice,
  quizenrollment,
  quizresponse,
  quizset,
  quizsetSession,
  dataArchive,
  dataSave,
  calibration,
  survey,
});

export default rootReducer;
