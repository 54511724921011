/**
 * 
 * @param {import('@store').State} state 
 * @returns 
 */
export const selectAllPrograms = (state) => {
  if (state.programs.allIds.length === 0) {
    return [];
  }

  return state.programs.allIds.map(
    (programId) => state.programs.byId[programId]
  );
};