import {
  getQuizset,
  getQuizsetServeys,
  postQuizResponse,
} from '@api/quizpang/quizpangApi';
import quizresponse from '@store/quizresponse/reducer';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';

export const useQuizsetSurvey = (groupId, clientId) => {
  const { data: quizsetSurvey, isLoading, isError, refetch } = useQuery({
    queryKey: ['quizsetSurvey', groupId, clientId],
    queryFn: () => getQuizsetServeys(groupId, clientId),
    enabled: !!groupId && !!clientId,
  });

  return {
    quizsetSurvey,
    isLoading,
    isError,
    refetch,
  };
};

export const useQuizset = (quizsetId) => {
  const { data: quizset, isLoading, isError, refetch } = useQuery({
    queryKey: ['quizset', quizsetId],
    queryFn: () => getQuizset(quizsetId),
    enabled: !!quizsetId,
  });

  return {
    quizset,
    isLoading,
    isError,
    refetch,
  };
};

// await postBackend(`quizresponse`, {
//   quizsetSessionId: quizsetSessionId,
//   quizId,
//   clientId: clientId,
//   responseLink: responseLink.current,
// });
