import { makeFreshAllIds } from '../helpers';
import {
    PURGE_QUIZCHOICE,
    GET_QUIZCHOICE_BY_CHOICEID_SUCCESS,
    GET_QUIZCHOICE_BY_CHOICEID_FAIL,
    GET_QUIZCHOICE_BY_QUIZID_SUCCESS,
    GET_QUIZCHOICE_BY_QUIZID_FAIL,
    CREATE_QUIZCHOICE_SUCCESS,
    CREATE_QUIZCHOICE_FAIL,
    UPDATE_QUIZCHOICE_SUCCESS,
    UPDATE_QUIZCHOICE_FAIL,
    DELETE_QUIZCHOICE_SUCCESS,
    DELETE_QUIZCHOICE_FAIL,
} from './actionTypes';

const initialState = {
  quizChoice: {},
  byId: {},
  allIds: [],
};

const quizChoice = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZCHOICE:
      return initialState;

    case GET_QUIZCHOICE_BY_CHOICEID_SUCCESS:
      return {
        ...state,
        quizChoice: action.quizChoiceData,
      };

    case GET_QUIZCHOICE_BY_QUIZID_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.byId,
        },
      };

    case CREATE_QUIZCHOICE_SUCCESS:
    case UPDATE_QUIZCHOICE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quizChoice.quizChoiceId]: action.quizChoice,
        },
        allIds: makeFreshAllIds(state.allIds, [action.quizChoice.quizChoiceId]),
      };

    case DELETE_QUIZCHOICE_SUCCESS:
      const { [action.quizChoiceId]: _, ...otherById } = state.byId;
      return {
        ...state,
        byId: otherById,
        allIds: state.allIds.filter((quizChoiceId) => quizChoiceId !== action.quizChoiceId),
      };

    case GET_QUIZCHOICE_BY_CHOICEID_FAIL:
    case GET_QUIZCHOICE_BY_QUIZID_FAIL:
    case CREATE_QUIZCHOICE_FAIL:
    case UPDATE_QUIZCHOICE_FAIL:
    case DELETE_QUIZCHOICE_FAIL:
      console.error('[quizChoice Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default quizChoice;
