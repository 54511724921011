import {
    PURGE_QUIZRESPONSE,
    GET_QUIZRESPONSE_SUCCESS,
    GET_QUIZRESPONSE_FAIL,
    CREATE_QUIZRESPONSE_SUCCESS,
    CREATE_QUIZRESPONSE_FAIL,
    DELETE_QUIZRESPONSE_SUCCESS,
    DELETE_QUIZRESPONSE_FAIL,
    UPDATE_QUIZRESPONSE_SUCCESS,
    UPDATE_QUIZRESPONSE_FAIL,
  } from './actionTypes';
  import { createPromiseAction } from '@adobe/redux-saga-promise';
  
  export const purgeQuizresponse = () => ({
    type: PURGE_QUIZRESPONSE,
  });

  // get
  export const getQuizresponsePromise = createPromiseAction('GET_QUIZRESPONSE_PROMISE');
  export const getQuizresponseSuccess = (data) => {
    return {
      type: GET_QUIZRESPONSE_SUCCESS,
      data,
    }
  };
  export const getQuizresponseFail = (error) => ({
    type: GET_QUIZRESPONSE_FAIL,
    payload: error,
  });
  
  // create
  export const createQuizresponsePromise = createPromiseAction('CREATE_QUIZRESPONSE_PROMISE');
  export const createQuizresponseSuccess = (data) => ({
    type: CREATE_QUIZRESPONSE_SUCCESS,
    quizresponse: data,
  });
  export const createQuizresponseFail = (error) => ({
    type: CREATE_QUIZRESPONSE_FAIL,
    payload: error,
  });

  //patch
  export const updateQuizresponsePromise = createPromiseAction('UPDATE_QUIZRESPONSE_PROMISE');
  export const updateQuizresponseSuccess = (data) => ({
    type: UPDATE_QUIZRESPONSE_SUCCESS,
    quizresponse: data,
  });
  export const updateQuizresponseFail = (error) => ({
    type: UPDATE_QUIZRESPONSE_FAIL,
    payload: error,
  });
  
  // delete
  export const deleteQuizresponsePromise = createPromiseAction('DELETE_QUIZRESPONSE_PROMISE');
  export const deleteQuizresponseSuccess = (data) => ({
    type: DELETE_QUIZRESPONSE_SUCCESS,
    data,
  });
  export const deleteQuizresponseFail = (error) => ({
    type: DELETE_QUIZRESPONSE_FAIL,
    payload: error,
  });
  