import { checkPermission, getUserAgent } from "@pages/QuizPang/utils";
import {
  setCameraPermission,
  setDevicemotionPermission,
  setLocationPermission,
  setMicrophonePermission,
} from "@store/actions";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

export const PermissionManager = () => {
  const dispatch = useDispatch();
  const userAgent = getUserAgent();
  const history = useHistory();
  const location = useLocation();

  const checkGrantPermission = useCallback(async () => {
    // 필요한 권한들을 정의합니다.
    const permissionsToCheck = ["camera", "geolocation", "microphone"];

    // iOS에서만 필요한 권한을 추가합니다.
    if (userAgent === "iOS") {
      permissionsToCheck.push("devicemotion");
    }

    for (const permission of permissionsToCheck) {
      if (!(await checkPermission(permission))) {
        // 권한이 허용되지 않았다면 리다이렉션을 수행합니다.
        history.push("/permission");
        return; // 더 이상의 권한 체크는 중지합니다.
      } else {
        // 권한이 허용되었다면 상태를 업데이트합니다.
        switch (permission) {
          case "camera":
            dispatch(setCameraPermission());
            break;
          case "geolocation":
            dispatch(setLocationPermission());
            break;
          case "microphone":
            dispatch(setMicrophonePermission());
            break;
          case "devicemotion":
            dispatch(setDevicemotionPermission());
            break;
          default:
            break;
        }
      }
    }
  }, [dispatch, history, userAgent]);

  useEffect(() => {
    if(/quizpang\/.*\/(ready|wait)/g.test(window.location.pathname)) {
      checkGrantPermission();
    }
  }, [location, checkGrantPermission])
  useEffect(() => {
    checkGrantPermission();
  }, [checkGrantPermission]);

  return <div />;
};
