import { selectAllClients } from '@store/selectors';

export const selectAllMemberships = (state) => {
  if (state.membership.allIds.length === 0) {
    return [];
  }
  return state.membership.allIds?.map(
    (membership) => state.membership.byId[membership]
  );
};

export const selectTeacherMemberships = (state) => {
  return selectAllMemberships(state).filter(
    (membership) => membership.role === 'TEACHER'
  );
};
export const selectGroupTeacherName = (state, groupId) => {
  const teacher = selectAllMemberships(state).filter(
    (membership) =>
      membership.role === 'TEACHER' && membership.groupId === groupId
  )?.[0];
  if (!teacher) {
    return '';
  }
  return (
    selectAllClients(state).find(
      (client) => client.clientId === teacher.clientId
    )?.userName || ''
  );
};

export const selectTeacherMembershipByGroup = (state, groupId) => {
  return selectAllMemberships(state).find(
    (membership) =>
      membership.role === 'TEACHER' && groupId === membership.groupId
  );
};

export const selectStudentsByGroup = (state, groupId) => {
  const memberships = selectAllMemberships(state).filter(
    (membership) =>
      membership.role === 'STUDENT' && membership.groupId === groupId
  );
  const clientIds = new Set(memberships.map((x) => x.clientId));
  return Object.values(state.clients.byId).filter((x) =>
    clientIds.has(x.clientId)
  );
};
