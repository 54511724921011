import { call, put, takeLeading } from "redux-saga/effects";
import {
  requestCertPromise,
  requestCertSuccess,
  requestCertFail,
  checkResponsePromise,
  checkResponseSuccess,
  checkResponseFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* requestCertPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      //console.log("REQ CERT PROMISE: ", action);
      const response = yield call(api.requestCert, action.payload);
      yield put(requestCertSuccess(response.data));
      console.log("REQ CERT PROMISE RESPONSE: ", response);
      return yield response.data;
    } catch (error) {
      console.log("REQ CERT ERR : ", error);
      yield put(requestCertFail(error));
    }
  });
}

function* checkResponsePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      console.log("CHECK RES PROMISE: ", action);
      const response = yield call(api.checkResponse, action.payload);
      console.log("CHECK RES PROMISE RESPONSE: ", response);
      yield put(checkResponseSuccess(response.data));
      return yield response.data;
    } catch (error) {
      console.log("CHECK RES ERR : ", error);
      yield put(checkResponseFail(error));
    }
  });
}

function* kakaocertSaga() {
  yield takeLeading(requestCertPromise, requestCertPromiseHandler);
  yield takeLeading(checkResponsePromise, checkResponsePromiseHandler);
}

export default kakaocertSaga;
