import { 
  selectSurveySubquestionByQuestion,
  selectQnASubquestionByQuestion,
  selectSurveyOutlinesBySesion,
 } from "../selectors";

export const selectAllQuestionsBySubject = (state, subject) => {
  const questionIds = state.question.allIds
    .filter(questionId => state.question.byId[questionId].subject === subject);
  const questionList = questionIds.map(questionId => {
    const subquestions = subject === "SURVEY" ? 
      selectSurveySubquestionByQuestion(state, questionId) :
      selectQnASubquestionByQuestion(state, questionId);
    return { questionId: questionId, subquestions: subquestions };
  });
  return questionList;
}

export const selectQnAQuestions = (state, sessionId) => {
  const questionIdListDuplicated = state.outline.allIds
    .map(outlineId => state.outline.byId[outlineId])
    .filter(outline => outline.sessionId === sessionId)
    .map(outline => outline.questionId);
  const questionIdList = [...new Set(questionIdListDuplicated)];

  if(questionIdList.length == 0) {
    return []; 
  }

  return questionIdList
    .map(questionId => state.question.byId[questionId])
    .filter(question => question?.subject === "KOREAN" || question?.subject === "MATH")
    .sort((a, b) => {
        if (a.questionId > b.questionId) {
            return 1;
        } else if (a.questionId < b.questionId) {
            return -1;
        }
    });
};

export const selectSurveyQuestions = (state, sessionId) => {
  const outlines = selectSurveyOutlinesBySesion(state, sessionId)
    .map(outline => state.question.byId[outline.questionId]);

  if (outlines.length == 0) {
    return [];
  }
    
  const surveyQuestion = [... new Set(outlines)]
    .filter(question => question.subject === "SURVEY")
    .sort((a, b) => {
        if (a.questionId > b.questionId)
            return 1;
        else if (a.questionId < b.questionId)
            return -1;
    })

  if (!surveyQuestion) {
      return []; 
  }
  
  return surveyQuestion;
};

export const selectQuestionbyId = (state, questionId) => {
  return state.question.byId[questionId];
}