import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import progressWheel from "../../assets/images/quizpang/progress-wheel.png";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const Spinner = ({
  startSecond,
  onZero,
  isSmall = false,
  saveQuizTime,
}) => {
  const [second, setSecond] = useState(startSecond || 5);

  useInterval(() => {
    if (second !== 0) {
      setSecond(second - 1);
      saveQuizTime && saveQuizTime(second - 1);
    }
  }, 1000);

  useEffect(() => {
    if (second !== 0) return;
    if (onZero) onZero();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);

  return (
    <SpinnerBox isSmall={isSmall}>
      <SpinnerImage src={progressWheel} isSmall={isSmall} />
      <SpinnerText isSmall={isSmall}>{second}</SpinnerText>
    </SpinnerBox>
  );
};

const SpinnerImage = styled.img`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  width: ${(p) => (p.isSmall ? 48 : 96)}px;
  height: ${(p) => (p.isSmall ? 48 : 96)}px;
  position: absolute;
  animation: spin 1s linear infinite;
`;

const SpinnerBox = styled.div`
  width: ${(p) => (p.isSmall ? 48 : 96)}px;
  height: ${(p) => (p.isSmall ? 48 : 96)}px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpinnerText = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: ${(p) => (p.isSmall ? 24 : 48)}px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;
