import { call, put, takeLeading, takeEvery } from 'redux-saga/effects'
import {
  GET_QUESTIONS,
  GET_QUESTION_CONTENT,
} from './actionTypes';
import {
  getQuestionsSuccess,
  getQuestionsFail,

  getQuestionContent,
  getQuestionContentSuccess,
  getQuestionContentFail,

  getSubquestionsSuccess,
  getChoicesSuccess,

  getSubquestionContent,
} from '@store/actions'
import * as api from '@api';

function* getQuestionsHandler(action) {
  try {
    const response = yield call(api.getQuestions, action.questionIds, action.subject);
    yield put(getSubquestionsSuccess(response.data.subquestions));
    if (response.data.choices) {
      yield put(getChoicesSuccess(response.data.choices));
    }
    // for (subquestion of response.data.subquestions) {
    //   if (subquestion.contentUrl) {
    //     yield put(getSubquestionContent(subquestion));
    //   }
    // }
    yield put(getQuestionsSuccess(response.data.questions));
    // for (question of response.data.questions) {
    //     if (question.contentUrl) {
    //       yield put(getQuestionContent(question));
    //     }
    // }
  } catch(error) {
    yield put(getQuestionsFail(error));
  }
}

function* getQuestionContentHandler(action) {
  try {
    const response = yield call(api.getHTMLfromAWS, action.question.contentUrl);
    if (response.status != 200) {
      throw new Error("Failed to get question content from AWS");
    }
    yield put(getQuestionContentSuccess(action.question.questionId, response.data));
  } catch(error) {
    yield put(getQuestionContentFail(error));
  }
}


function* QuestionSaga() {
  yield takeLeading(GET_QUESTIONS, getQuestionsHandler);
  yield takeEvery(GET_QUESTION_CONTENT, getQuestionContentHandler);
}

export default QuestionSaga;
