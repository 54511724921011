import styled from 'styled-components'

export const StyledCard = styled.div`
  background: #ffffff;
  color: var(--text-black-dark);
  padding: 36px 52px;
  border: 1px solid #eeeeee;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 5px;
  margin: 14px 0;
`

export const SurveyCard = styled.div`
  border-radius: 5px;
  background: #ffffff;
  color: var(--text-black-dark);
`;

export const StyledCardBlue = styled(StyledCard)`
  border-left: 9px solid var(--primary-darken-1);
  width: 100%;
`;

export const StyledCardBlueTop = styled(StyledCard)`
  border-top: 9px solid var(--primary-darken-1);
  width: 100%;
`;

export const SubmitButton = styled.button`
  background-color: var(--primary-darken-1);
  color:white;
  border-radius: 5px;
`

export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`

export const Column = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`

export const VerticalSpace8 = styled.div`
  height: 8px;
`;

export const OverflowGrid = styled.div`
  display: grid;
`
