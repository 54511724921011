import React from "react";
import styled from "styled-components";

export const QuizOX = ({
  answerBoolean,
  setAnswerBoolean,
  clicked,
  setClicked,
}) => {
  return (
    <Container>
      <button
        onClick={() => {
          setAnswerBoolean(true);
          setClicked(true);
        }}
        disabled={clicked}
        style={
          answerBoolean === true ? onpressChoiceContainer : choiceContainer
        }
      >
        <div style={{ fontSize: 48, fontWeight: "400", color: "#000000DE" }}>
          O
        </div>
      </button>
      <div style={{ width: 8 }} />
      <button
        onClick={() => {
          setAnswerBoolean(false);
          setClicked(true);
        }}
        disabled={clicked}
        style={
          answerBoolean === false ? onpressChoiceContainer : choiceContainer
        }
      >
        <div style={{ fontSize: 48, fontWeight: "400", color: "#FF5252" }}>
          X
        </div>
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const choiceContainer = {
  width: 236,
  height: 120,
  borderRadius: 8,
  borderWidth: 1,
  borderColor: "#E0E0E0",
  alignItems: "center",
  justifyContent: "center",
};
const onpressChoiceContainer = {
  width: 236,
  height: 120,
  borderRadius: 8,
  borderWidth: 2,
  borderColor: "#058692",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#E4F6F8",
};
