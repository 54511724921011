// import { REACT_APP_STATIC_ADDR } from '@env';
import AWS from 'aws-sdk';

const StaticAddrSlash = process.env.REACT_APP_STATIC_ADDR;
const StaticAddr = StaticAddrSlash.substring(0, StaticAddrSlash.length - 1);
// const KTCLOUD = process.env.REACT_APP_KTCLOUD_ADDER;
const KTCLOUD = 'https://ss1.cloud.kt.com:1000';

export const defaultIconPath = () => {
  return 'https://i.natgeofe.com/n/3861de2a-04e6-45fd-aec8-02e7809f9d4e/02-cat-training-NationalGeographic_1484324_square.jpg';
};
export const programIconPath = (programAlias) => {
  return StaticAddr + '/media/programs/' + programAlias + '.png';
};

export const getIcon = (iconPath) => {
  return StaticAddr + '/media/icons/' + iconPath + '.png';
};

export const getStickerIcon = (iconPath) => {
  return StaticAddr + '/media/stickers/' + iconPath + '.png';
};
// export const profileIconImgPath = iconName => {
//   return StaticAddr + '/media/icons-tmp/' + iconName + '.png';
// }

export const profileImgPath = (clientId) => {
  return KTCLOUD + '/focuspang-media/users/' + clientId + '.png';
};

export const uploadImage = async (file, clientId) => {
  const S3_BUCKET = 'focuspang-media';
  const REGION = 'kr-standard';
  const endpoint = new AWS.Endpoint(KTCLOUD);
  const access_key = process.env.REACT_APP_ACCESS_KEY;
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const myBucket = new AWS.S3({
    endpoint: endpoint,
    params: { Bucket: S3_BUCKET },
    region: REGION,
    credentials: {
      accessKeyId: access_key,
      secretAccessKey: secret_key,
    },
    s3ForcePathStyle: true
  });

  let typeStr = 'png';
  const params = {
    Bucket: S3_BUCKET,
    Key: 'users/' + clientId + '.' + typeStr,
    ACL: 'public-read',
    Body: file,
  };

  return await myBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {})
    .send((err) => {
      if (err) console.log(err);
    });
};

export const uploadSubmission = async (file, clientId, groupnoticeId) => {
  const { name } = file;

  const S3_BUCKET = 'focuspang-media';
  const REGION = 'kr-standard';
  const endpoint = new AWS.Endpoint(KTCLOUD);
  // const access_key = process.env.REACT_APP_ACCESS_KEY;
  // const secret_key = process.env.REACT_APP_SECRET_KEY;
  const access_key = '9cc9e84b552943bdb7a41d3d23ad2306';
  const secret_key = '573f41a0f41645d582691badbbd5ed29';
  const myBucket = new AWS.S3({
    endpoint: endpoint,
    params: { Bucket: S3_BUCKET },
    region: REGION,
    credentials: {
      accessKeyId: access_key,
      secretAccessKey: secret_key,
    },
    s3ForcePathStyle: true
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: 'submissions/' + clientId + '/' + groupnoticeId + '/' + name,
    ACL: 'public-read',
    Body: file,
  };

  await myBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {})
    .send((err, data) => {
      if (err) console.log('[media - myBucket]', err);
    });

  return (
    KTCLOUD +
    '/focuspang-media/submissions/' +
    clientId +
    '/' +
    groupnoticeId +
    '/' +
    name
  );
};

export const uploadQuizStylusWrite = async (file, quizsetId, quizId) => {
  const { name } = file;

  const S3_BUCKET = 'focuspang-media';
  const REGION = 'kr-standard';
  const endpoint = new AWS.Endpoint(KTCLOUD);
  // const access_key = process.env.REACT_APP_ACCESS_KEY;
  // const secret_key = process.env.REACT_APP_SECRET_KEY;
  const access_key = '9cc9e84b552943bdb7a41d3d23ad2306';
  const secret_key = '573f41a0f41645d582691badbbd5ed29';
  const myBucket = new AWS.S3({
    endpoint: endpoint,
    params: { Bucket: S3_BUCKET },
    region: REGION,
    credentials: {
      accessKeyId: access_key,
      secretAccessKey: secret_key,
    },
    s3ForcePathStyle: true
  });

  const key = `quiz-stylus/${quizsetId}/${quizId}/${name}`;
  const params = {
    Bucket: S3_BUCKET,
    Key: key,
    ACL: 'public-read',
    Body: file,
  };

  await myBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {})
    .send((err, data) => {
      if (err) console.log('[media - myBucket]', err);
    });

  return `${KTCLOUD}/focuspang-media/${key}`;
};

export const imageToFile = (base64Image, name) => {
const encodeImage = base64Image.split(';base64,').pop();
const binaryData = atob(encodeImage);
let uint8Array = new Uint8Array(binaryData.length);
for (var i = 0; i < binaryData.length; i++) {
  uint8Array[i] = binaryData.charCodeAt(i);
}
const mimeType = "image/png";
return new File([uint8Array], `${name}.png`, { type: mimeType });
}