import ICONS from "@images/icons/appIcons";
import imgAppIcon from '@images/icons/img_notice.png';

/**
 * 
 * @param {{program: import('@store/programs/reducer').Program}} param0 
 * @returns 
 */
const EnabledApp = ({ program }) => {
  return (
    <img
      src={ICONS[program.alias] || imgAppIcon}
      width={24}
      height={24}
    />
  );
};

export default EnabledApp;
