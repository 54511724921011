import React from 'react';
import AuthManager from '@managers/AuthManager';
import BlockingManager from '@managers/BlockingManager';
import ClassManager from '@managers/ClassManager';
import GroupManager from '@managers/GroupManager';
import ScreenManager from '@managers/ScreenManager';
import SiteManager from '@managers/SiteManager';
import ConnectionManager from '@managers/ConnectionManager';
import DevManager from './DevManager';
import ReportManager from '@managers/ReportManager';
import RefreshManager from './RefreshManager';
import { PermissionManager } from './PermissionManager';

const MasterManager = () => {
  return (
    <AuthManager>
      <ConnectionManager />
      <RefreshManager />
      <GroupManager />
      <ClassManager />
      {/* <DevManager /> */}
      {/* <ReportManager /> */}
      <PermissionManager />
      <BlockingManager />
      <ScreenManager />
      <SiteManager />
    </AuthManager>
  );
};

export default MasterManager;
