import { makeFreshAllIds } from '../helpers';
import {
  PURGE_CHOICE,

  GET_CHOCIES_SUCCESS,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const choice = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CHOICE:
      return initialState;

    case GET_CHOCIES_SUCCESS:
      return {...state,
        byId: {...state.byId, ...action.newById},
        allIds: makeFreshAllIds(state.allIds, action.newAllIds)};

    default:
      break;
  }
  return state;
};

export default choice;
