import { CREATE_SURVEY_RESPONSE, PURGE_SURVEYRESPONSE } from './actionTypes';

const initialState = {
  quizResponse: {},
};

const surveyResponse = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_SURVEYRESPONSE:
      return initialState;

    case CREATE_SURVEY_RESPONSE:
      return {
        ...state,
        quizResponse: {
          ...state.quizResponse,
          [action.payload.quizId]: action.payload,
        },
      };
    default:
      break;
  }
  return state;
};
export default surveyResponse;
