const { makeStyles } = require('@material-ui/core');
const { FormControl, RadioGroup } = require('@mui/material');


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFilledInput-root:before": {
            border: "none",
        },
    }
}))

const MdRadio = (props) => {
    const {
        width,
    } = props;

    const classes = useStyles();

    return (
        <FormControl
            variant="filled"
            sx={{ width }}
            className={classes.root}
        >
            <RadioGroup
                {...props}
            >
                {props.children}
            </RadioGroup>
        </FormControl>
    )
}

export default MdRadio;