// import timetable from '@store/timetable/reducer';

// 함수 설명
// 1. 요일을 받아서 한글로 변환한다.
export function getDayToKorean(day) {
  switch (day) {
    case 'Mon':
      return '월';
    case 'Tue':
      return '화';
    case 'Wed':
      return '수';
    case 'Thu':
      return '목';
    case 'Fri':
      return '금';
    case 'Sat':
      return '토';
    case 'Sun':
      return '일';
    default:
      return '';
  }
}

// 함수 설명
// 1. 시간표를 받아서 시간표를 요일별로 분류한다.
// 2. 분류된 시간표를 시간 순서대로 정렬한다.
// 3. 분류된 시간표를 반환한다.
export function classifyTimetable(timetables) {
  const classified = {
    Mon: [],
    Tue: [],
    Wed: [],
    Thu: [],
    Fri: [],
  };
  timetables.forEach((timetable) => {
    timetable.timetables.forEach((timetable) => {
      if (timetable === null || timetable === undefined) {
        return '';
      }
      switch (timetable.weekday) {
        case 1:
          classified.Mon.push(timetable);
          break;
        case 2:
          classified.Tue.push(timetable);
          break;
        case 3:
          classified.Wed.push(timetable);
          break;
        case 4:
          classified.Thu.push(timetable);
          break;
        case 5:
          classified.Fri.push(timetable);
          break;
        default:
          break;
      }
    });
  });

  classified.Mon.sort((a, b) => a.period - b.period);
  classified.Tue.sort((a, b) => a.period - b.period);
  classified.Wed.sort((a, b) => a.period - b.period);
  classified.Thu.sort((a, b) => a.period - b.period);
  classified.Fri.sort((a, b) => a.period - b.period);
  return classified;
}
