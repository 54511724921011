import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveClassroom } from "@app/store/selectors";
import {
  createReportEventsPromise,
  setReportEventPromise,
  setScreenData,
  setTeacherScreen,
} from "@store/actions";

const chromeExtensionId = "pmgkifpjobmkginiaoeemgeffgoohapo";

const ScreenManager = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const screenLocked = activeClassroom?.screenLocked;
  const screenShared = activeClassroom?.screenShared;
  const selectedStudent = useSelector((state) => state.control.selectedStd);
  const teacherClientId = useSelector((state) => state.control.teacherClientId);
  const { groupClientIds } = useSelector((state) => state.membership);

  const [prevScreenLocked, setPrevScreenLocked] = useState(false);

  const clients = useSelector((state) => state.clients.allIds);

  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  // // navigate to screen lock page
  useEffect(() => {
    if (user == null || user.signedIn === false) {
      return;
    }
    console.log("[ScreenManager]", "screen locked:", screenLocked);
    if (screenLocked) {
      setPrevScreenLocked(true);
      try {
        // for Chrome
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "LOCK_SCREEN",
        });
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
      }
      try {
        window.cAPI.setIsScreenLocked(true);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
      }

      return;
    } else {
      try {
        // for Chrome
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "UNLOCK_SCREEN",
        });
        // window.cAPI.setIsScreenLocked(false);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
      }
      try {
        window.cAPI.setIsScreenLocked(false);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
      }
      if (prevScreenLocked) {
        setPrevScreenLocked(false);
        console.log("[ScreenManager]", "Screen unlocked! Navigating to App");
        return;
      }
      setPrevScreenLocked(false);
      return;
    }
  }, [screenLocked]);

  useEffect(() => {
    if (user == null || user.signedIn === false) {
      return;
    }
    if (screenLocked) return;

    console.log("[ScreenManager]", "selected std id:", selectedStudent);
    console.log("[ScreenManager]", "screen shared:", screenShared);

    if (screenShared && selectedStudent !== user.clientId) {
      console.log("[ScreenManager]", "Screen shared!");
      try {
        const reportEvents = [
          {
            groupId: activeClassroom?.groupId,
            sender: user.clientId,
            receiver: teacherClientId,
            senderRole: "STUDENT",
            receiverRole: "TEACHER",
            eventType: "SCREEN_SHARE_ON",
            data: "active screen shared successfully",
            reportedAt: new Date(),
          },
        ];
        dispatch(createReportEventsPromise(reportEvents));
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "FULL_SCREEN",
        });
        window.cAPI.setIsScreenShared(true);
      } catch (error) {
        // const reportEvents = [
        //   {
        //     groupId: activeClassroom?.groupId,
        //     sender: user.clientId,
        //     receiver: user?.clientId,
        //     senderRole: 'STUDENT',
        //     receiverRole: 'TEACHER',
        //     eventType: 'ERROR_SCREEN_SHARE_ON',
        //     data: error,
        //     reportedAt: new Date(),
        //   },
        // ];
        // dispatch(createReportEventsPromise(reportEvents));
        // console.log(error);
      }
      return;
    } else if (screenShared && selectedStudent === user.clientId) {
      // 본인 화면 선택
    } else {
      dispatch(setTeacherScreen(null));
      try {
        const reportEvents = [
          {
            groupId: activeClassroom?.groupId,
            sender: user.clientId,
            receiver: teacherClientId,
            senderRole: "STUDENT",
            receiverRole: "TEACHER",
            eventType: "SCREEN_SHARE_OFF",
            data: "disabled screen shared successfully",
            reportedAt: new Date(),
          },
        ];
        dispatch(createReportEventsPromise(reportEvents));
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "NORMAL_SCREEN",
        });
        window.cAPI.setIsScreenShared(false);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
        console.log(error);
        // const reportEvents = [
        //   {
        //     groupId: activeClassroom?.groupId,
        //     sender: user.clientId,
        //     receiver: user?.clientId,
        //     senderRole: 'STUDENT',
        //     receiverRole: 'TEACHER',
        //     eventType: 'ERROR_SCREEN_SHARE_OFF',
        //     data: error,
        //     reportedAt: new Date(),
        //   },
        // ];
        // dispatch(createReportEventsPromise(reportEvents));
      }
    }
  }, [screenLocked, screenShared, selectedStudent]);

  /**
   * 화면 캡쳐
   */
  useEffect(() => {
    if (!activeClassroom?.groupId) {
      return;
    }
    const capture = (video, scaleFactor = 0.5) => {
      var w = selectedStudent === user.clientId ? 1600 : 200;
      var h = selectedStudent === user.clientId ? 900 : 110;
      var canvas = document.createElement("canvas");
      canvas.id = "canvas";
      canvas.width = w;
      canvas.height = h;
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(video, 0, 0, w, h);

      return canvas;
    };
    const interval = setInterval(async () => {
      const videoElem = document.getElementById("video");
      const canvas = capture(videoElem);
      const img = canvas
        .toDataURL("image/webp", 0.5)
        .replace("data:image/webp;base64,", "");
      dispatch(
        setScreenData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "IMAGE_STUDENT",
          clientId: user.clientId,
          groupId: activeClassroom.groupId,
          data: img,
          targetClients: groupClientIds.filter((id) => id !== user.clientId),
        })
      );
      const reportEvent = [
        {
          groupId: activeClassroom?.groupId,
          sender: user.clientId,
          receiver: teacherClientId,
          senderRole: "STUDENT",
          receiverRole: "TEACHER",
          eventType: "SHARING_SCREEN",
          data: "sharing screen to teacher",
          reportedAt: new Date(),
        },
      ];
      dispatch(setReportEventPromise(reportEvent));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [
    activeClassroom?.groupId,
    selectedStudent,
    dispatch,
    groupClientIds,
    user.clientId,
  ]);

  return (
    <div>
      <video
        autoPlay
        id='video'
        className='hidden w-2/3 mt-5 mb-10 border-2'
        // controls="false"
        style={{
          display: "none",
        }}
      />
    </div>
  );
};

export default ScreenManager;
