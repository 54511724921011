import { makeFreshAllIds } from '../helpers';
import {
  PURGE_QUESTION,

  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,

  GET_QUESTION_CONTENT_SUCCESS,
  GET_QUESTION_CONTENT_FAIL,

} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const question = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUESTION:
      return initialState;

    case GET_QUESTIONS_SUCCESS:
      return {...state,
        byId: {...state.byId, ...action.newById},
        allIds: makeFreshAllIds(state.allIds, action.newAllIds)};

    case GET_QUESTION_CONTENT_SUCCESS:
      const questionObject = state.byId[action.questionId];
      return {...state,
        byId: {
          ...state.byId,
          [action.questionId]: {...questionObject, content: action.content},
        }};

    // todo: handle errors
    case GET_QUESTIONS_FAIL:
    case GET_QUESTION_CONTENT_FAIL:
      console.error('[Question Reducer] ', action.payload);
      return state
    default:
      break;
  }
  return state;
};

export default question;
