import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveClassroom,
  selectActiveManaging,
  selectAllManaging,
  selectClassroomGroup,
  selectGroupTimetables,
} from '@store/selectors';
import {
  getActiveClassroomPromise,
  getGroupByInviteCodePromise,
  getGroupProgramconfigsPromise,
  getParticipationPromise,
  updateParticipationPromise,
  setSocketData,
  updateManagingPromise,
  getActiveManagingPromise,
  createManagingPromise,
  setSelectedClass,
  setSelectedPage,
  createParticipationPromise,
  getCommentsLayerPromise,
} from '@store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from './utils';
import moment from 'moment';

const ClassManager = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const groupTimetables = useSelector((state) =>
    selectGroupTimetables(state, activeClassroomGroup?.groupId)
  );
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const user = useSelector((state) => state.user);
  const managingSchedule = useSelector((state) => selectAllManaging(state));
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

  // 현재 진행 중인 수업 또는 수업외제어가 있는지 확인합니다.
  useEffect(() => {
    if (!user.signedIn) {
      return;
    }
    dispatch(getActiveClassroomPromise(user?.clientId));
    dispatch(getActiveManagingPromise(user?.clientId));
  }, [dispatch, user.clientId, user.signedIn]);

  useEffect(() => {
    if (!activeClassroom?.classroomId) {
      return;
    }
    dispatch(getCommentsLayerPromise(activeClassroom.classroomId));
  }, [activeClassroom?.classroomId, dispatch]);

  useEffect(() => {
    if (!user.signedIn) {
      return;
    }
    if (!activeClassroom) {
      try {
        window.cAPI.setClassOn(false);
      } catch (error) {}
      console.log('[ClassManager]', 'no active class. navigating to ClassHome');
    }
    if (activeClassroom) {
      try {
        window.cAPI.setClassOn(true);
      } catch (error) {
        // console.log(error);
      }
      dispatch(
        createParticipationPromise({
          classroomId: activeClassroom?.classroomId,
          clientId: user?.clientId,
          attendedAt: moment().toISOString(),
        })
      ).then(() => {
        dispatch(
          getParticipationPromise({
            classroomId: activeClassroom?.classroomId,
            clientId: user?.clientId,
          })
        ).then((participation) => {
          if (participation?.state === 'ABSENT') {
            console.log('[Class Manager]', '출석 필요!');
            dispatch(
              updateParticipationPromise({
                classroomId: activeClassroom?.classroomId,
                clientId: user.clientId,
                state: 'ATTEND',
                attendedAt: moment().toISOString(),
              })
            ).then(() => {
              dispatch(
                setSocketData({
                  method: 'POST',
                  uri: '/classroom/sendImage',
                  groupId: activeClassroom.groupId,
                  clientId: user.clientId,
                  type: 'JOIN_GROUP',
                  data: '',
                })
              );
              dispatch(
                setSocketData({
                  method: 'POST',
                  uri: '/classroom/sendImage',
                  groupId: activeClassroom.groupId,
                  clientId: user.clientId,
                  type: 'ATTEND_CLASS',
                  data: '',
                })
              );
            });
          }
        });
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: activeClassroom.groupId,
            clientId: user.clientId,
            type: 'ATTEND_CLASS',
            data: '',
          })
        );
      });
      dispatch(setSelectedClass(activeClassroom.groupId));
    }
    dispatch(setSelectedPage('ACTIVE_CLASSROOM'));
  }, [activeClassroom]);

  useEffect(() => {
    if (!activeClassroomGroup?.groupType) {
      return;
    }
    const group = activeClassroomGroup;
    const timetable = groupTimetables[0];
    const state = { group, timetable };

    switch (activeClassroomGroup?.groupType) {
      case 'SUBJECT':
        console.log('[ClassManager]', 'navigating to ClassInformation');
        history.push({
          pathname: `/classroom/${activeClassroomGroup.groupId}`,
          state,
        });
        break;
      case 'HR':
        console.log('[ClassManager]', 'navigating to ClassInformation');
        history.push({
          pathname: `/classroom/${activeClassroomGroup.groupId}`,
          state,
        });
        break;
      default:
        console.log(
          '[ClassManager] unknown group.groupType:',
          activeClassroomGroup.groupType
        );
        history.push({
          pathname: '/home',
          state,
        });
        break;
    }
  }, [activeClassroomGroup]);

  useEffect(() => {
    if (activeClassroomGroup) return;

    if (
      (!activeClassroom && activeManaging) ||
      (!activeClassroom && !activeManaging)
    ) {
      history.push('/home');
    }

    if (activeClassroom && activeManaging) {
      history.push(`/classroom/${activeClassroom.groupId}`);
    }

    if (activeManaging && !activeClassroom) {
      history.push(`/managing/${activeManaging.groupId}`);
    }
  }, [activeClassroom, activeManaging]);

  useEffect(() => {
    managingSchedule?.map((managing) => {
      dispatch(getGroupProgramconfigsPromise(managing.managingId));
    });
  }, [activeManaging]);

  //trigger managing Start
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === 'FINISHED')
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() > 0
      );
    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: managing.startDate,
        endDate: managing.endDate,
        state: 'IN_MANAGING',
      };
      dispatch(updateManagingPromise(updateData))
        .then((res) => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_START',
              data: managing.managingId,
            })
          );
        })
        .then(() => dispatch(getActiveManagingPromise(user?.clientId)));
    });
  }, 3000);

  //trigger managing End
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === 'IN_MANAGING')
      .filter(
        (schedule) =>
          (moment(schedule.endDate) - moment() < 0 &&
            moment(schedule.endDate) - moment() > -3000) ||
          moment(schedule.startDate) - moment() > 0
      );

    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === 'FREQ=DAILY') {
        newStartDate = moment(managing.startDate)
          .add(24, 'hours')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, 'hours')
          .toISOString();
      }
      if (managing.rRule === 'FREQ=WEEKLY') {
        newStartDate = moment(managing.startDate)
          .add(7, 'days')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, 'days')
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: 'FINISHED',
      };
      const fillData = {
        title: managing.title,
        rRule: 'rRule',
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          if (managing.rRule !== 'rRule') {
            dispatch(createManagingPromise(fillData));
          }
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_END',
              data: '',
            })
          );
        });
    });
  }, 3000);

  useInterval(() => {
    const managingForUpdateTwo = managingSchedule
      .filter((schedule) => schedule.state === 'IN_MANAGING')
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() < 0
      );

    if (!managingForUpdateTwo || managingForUpdateTwo.length === 0) {
      return;
    }
    managingForUpdateTwo.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === 'FREQ=DAILY') {
        newStartDate = moment(managing.startDate)
          .add(24, 'hours')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, 'hours')
          .toISOString();
      }
      if (managing.rRule === 'FREQ=WEEKLY') {
        newStartDate = moment(managing.startDate)
          .add(7, 'days')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, 'days')
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: 'FINISHED',
      };
      const fillData = {
        title: managing.title,
        rRule: 'rRule',
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          // dispatch(createManagingPromise(fillData));
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_UPDATE',
              data: '',
            })
          );
        });
    });
  }, 3000);

  return <div />;
};

export default ClassManager;
