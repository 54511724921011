import {
  getBackend,
  getBackendAlt,
  postBackend,
  postBackendAlt,
  patchBackend,
  patchBackendAlt,
  postBackendFormData,
  deleteBackend,
  postBackendDP,
  postErrorBackend,
} from './Backend';
import {
  niaGetBackend,
  niaPostBackend,
  niaPatchBackend,
  niaDeleteBackend,
  niaPutAWS,
  niaGetAWS,
} from './AWSBackend';

/*
 * Description:
 * postBackendAlt is related with new Django API
 * postBackend is related with new Ktor API
 */

//// Account & Client
///// User specific start
// signUp or register
// make anonymous true

// signIn for student
// make anonymous true
export const getUser = async (clientId) => {
  return await getBackend(`account?clientId=${clientId}`);
};

export const signInStudent = async (userData) => {
  return await postBackend('signIn/student', userData, true);
};

export const signInStudentAlt = async (userData) => {
  return await postBackendAlt('sign_in/student/', userData, true);
};

//Register a student
export const createStudentAdmin = async (studentAccount) => {
  return await postBackend('account?role=STUDENT', studentAccount, true);
};

///// User specific end

// update Client or Account Info
export const updateClient = async (profile) => {
  return await patchBackendAlt('client/update_client/', profile);
};

// get Client or Account Info
export const findClient = async (clientEmail) => {
  return await getBackendAlt(`client/find/?email=${clientEmail}`);
};

export const findPasswordClient = async (password) => {
  return await postBackendAlt('client/find_password/', password);
};

export const getClient = async (accountId) => {
  return await getBackendAlt(`account?accountId=${accountId}`);
};

export const getClients = async (clientIds) => {
  return await postBackendAlt('client/get/multiple/', { ids: clientIds });
};

export const updateProfileImage = async (image) => {
  return await postBackendFormData(`client/upload/`, {
    profile: image,
  });
};

//// participant
export const createParticipation = async (participationData) => {
  return await postBackend('participation', participationData);
};

export const getParticipation = async (participationData) => {
  return await getBackend(
    `participation?classroomId=${participationData.classroomId}&clientId=${participationData.clientId}`
  );
};

export const getParticipationsByClassroom = async (classroomId) => {
  return await getBackend(`participation?classroomId=${classroomId}`);
};

export const getParticipationsByClient = async (clientId) => {
  return await getBackend('participation', clientId);
};

export const updateParticipation = async (participationData) => {
  return await patchBackend('participation', participationData);
};

//// managed
export const createManaged = async (managedData) => {
  return await postBackend('Managed', managedData);
};

export const getManaged = async (managedData) => {
  return await getBackend(
    `managed?managingId=${managedData.managingId}&clientId=${managedData.clientId}`
  );
};

export const getManagedsByManaging = async (managingId) => {
  return await getBackend(`managed?managingId=${managingId}`);
};

export const getManagedsByClient = async (clientId) => {
  return await getBackend('managed', clientId);
};

export const updateManaged = async (ManagedData) => {
  return await patchBackend('managed', ManagedData);
};

export const deleteManaged = async (managingId, clientId) => {
  return await deleteBackend(`managed/${managingId}/${clientId}`);
};

///// classroom
export const getClassroom = async (classroomId) => {
  return await getBackend(`classroom?classroomId=${classroomId}`);
};

export const getActiveClassroom = async (clientId) => {
  return await getBackend(`classroom?clientId=${clientId}&active=true`);
};

export const getActiveClassroomByGroup = async (groupId) => {
  return await getBackend(`classroom?groupId=${groupId}&active=true`);
};

export const deleteClassroom = async (classroomId) => {
  return await deleteBackend(`classroom/${classroomId}`);
};

//// managing

export const getManaging = async (managingData) => {
  return await getBackend(`managing?managingId=${managingData}`);
};

export const getManagingsByGroup = async (managingData) => {
  return await getBackend(`managing?groupId=${managingData}`);
};

export const getActiveManaging = async (clientId) => {
  return await getBackend(`managing?clientId=${clientId}&active=true`);
};

export const getActiveManagingByGroup = async (groupId) => {
  return await getBackend(`managing?groupId=${groupId}&active=true`);
};

export const createManaging = async (createdManagingClassroom) => {
  return await postBackend('managing', createdManagingClassroom);
};

export const updateManaging = async (updatedManagingClassroom) => {
  return await patchBackend('managing', updatedManagingClassroom);
};

export const deleteManaging = async (managingclassroomId) => {
  return await deleteBackend(`managing/${managingclassroomId}`);
};

//// membership
export const getMembership = async (clientId) => {
  return await getBackend(`membership?clientId=${clientId}`);
};

export const getMembershipsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const createMembership = async (membership) => {
  return await postBackend('membership', membership);
};

/////// group
export const createGroup = async (group) => {
  return await postBackendAlt('group/create/', group);
};

export const deleteGroup = async (groupId) => {
  return await postBackendAlt('group/delete/', groupId);
};

export const findGroup = async (groupName) => {
  return await getBackendAlt(`group/find/?name=${groupName}`);
};

export const getGroup = async (groupId) => {
  return await getBackendAlt(`group?groupId=${groupId}`);
};

export const getGroupStudents = async (classroomId) => {
  return await getBackendAlt(`group/get/students/?id=${classroomId}`);
};

export const getAllGroup = async () => {
  return await getBackendAlt('group/get/all/');
};

export const getMyGroups = async () => {
  return await getBackendAlt('group/get/list/');
};

export const getGroups = async (groupIds) => {
  return await postBackendAlt('group/get/multiple/', { ids: groupIds });
};

export const getGroupByInviteCode = async (inviteCode) => {
  return await getBackend(`group?inviteCode=${inviteCode}`);
};

export const inviteGroupStudents = async (groupId, studentIds) => {
  return await postBackendAlt('group/invite/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const signUpStudents = async (group, students) => {
  return await postBackendAlt('group/signup/students/', {
    group: group,
    students: students,
  });
};

export const kickGroupStudent = async (groupId, studentIds) => {
  return await postBackendAlt('group/kick/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const updateGroup = async (group) => {
  return await postBackendAlt('group/update/', group);
};

export const createNotification = async (notification) => {
  return await postBackendAlt('notification/create/', notification);
};

export const findNotification = async (notificationId) => {
  return await getBackendAlt(`notification/find/?id=${notificationId}`);
};

export const getNotification = async (clientId) => {
  return await getBackend(`notification?clientId=${clientId}`);
};

export const getAllNotification = async () => {
  return await getBackendAlt('notification/get/all/');
};

export const readNotification = async (clientId, notification) => {
  return await patchBackend(`notification?clientId=${clientId}`, notification);
};

export const processNotification = async (notificationId) => {
  return await getBackendAlt(
    `notification/mark/processed/?id=${notificationId}`
  );
};

export const deleteNotification = async (notificationId) => {
  return await postBackendAlt('notification/delete/', { id: notificationId });
};

export const readAllNotification = async () => {
  return await getBackendAlt('notification/mark/read/all/');
};

///// program
export const getProgram = async (programId) => {
  return await getBackend(`program?programId=${programId}`);
};

export const getPrograms = async () => {
  return await getBackend('program/all');
};

///// programconfig
export const getProgramconfig = async (programconfigId) => {
  return await getBackend(`programconfig?programconfigId=${programconfigId}`);
};

export const getGroupProgramconfigs = async (groupId) => {
  return await getBackend(`programconfig?groupId=${groupId}`);
};

///// school
export const getSchool = async (schoolId) => {
  return await getBackend(`school?schoolId=${schoolId}`);
};

export const getAllSchools = async () => {
  return await getBackendAlt('school/get/all/');
};

export const getFilteredSchools = async (schoolKeyword) => {
  return await getBackendAlt(
    `school/get/filtered/?school_keyword=${schoolKeyword}`
  );
};

///// device
export const registerDevice = async (deviceData) => {
  return await postBackend('device/register', deviceData, true);
};

//// timetable
export const getTimetable = async (timetableId) => {
  return await getBackend(`timetable?timetableId=${timetableId}`);
};

export const getTimetablesByGroup = async (groupId) => {
  return await getBackend(`timetable?groupId=${groupId}`);
};

//// file link
export const getFilelink = async (filelinkId) => {
  return await getBackend(`filelink?filelinkId=${filelinkId}`);
};

export const getGroupFilelink = async (groupId) => {
  return await getBackend(`filelink?groupId=${groupId}`);
};

//// kakaocert
export const requestCert = async (certData) => {
  return await postBackendAlt('cert/request/', certData);
};

export const checkResponse = async (receiptId) => {
  return await postBackendAlt('cert/check/', receiptId);
};

// export const updateProfileImage = async (image) => {
//   return await postBackendFormData(`client/upload/`, { profile: image });
// };

// quiz
export const getQuizListByClient = async (clientId) => {
  return await getBackend(`quiz?clientId=${clientId}`);
};

export const getQuizListBySchool = async (schoolId) => {
  return await getBackend(`quiz?schoolId=${schoolId}`);
};

export const createQuiz = async (quizData) => {
  return await postBackend('quiz', quizData);
};

export const updateQuiz = async (quizData) => {
  return await patchBackend('quiz', quizData);
};

export const deleteQuiz = async (quizId) => {
  return await deleteBackend(`quiz/${quizId}`);
};

// quizChoice
export const getQuizChoiceByChoiceId = async (choiceId) => {
  return await getBackend(`choice?choiceId=${choiceId}`);
};

export const getQuizChoiceByQuizId = async (quizId) => {
  return await getBackend(`choice?quizId=${quizId}`);
};

export const createQuizChoice = async (quizChoiceData) => {
  return await postBackend('choice', quizChoiceData);
};

export const updateQuizChoice = async (quizChoiceData) => {
  return await patchBackend('choice', quizChoiceData);
};

export const deleteQuizChoice = async (choiceId) => {
  return await deleteBackend(`choice/${choiceId}`);
};

// Quizenrollment
export const getQuizenrollmentByQuizsetId = async (quizsetId) => {
  return await getBackend(`quizenrollment?quizsetId=${quizsetId}`);
};

export const createQuizenrollment = async (quizenrollmentData) => {
  return await postBackend('quizenrollment', quizenrollmentData);
};

export const deleteQuizenrollment = async (quizsetId, quizId) => {
  return await deleteBackend(`quizenrollment/${quizsetId}/${quizId}`);
};

// Quizresponse
export const getQuizresponseByQuizsetSessionId = async (quizsetSessionId) => {
  return await getBackend(`quizresponse?quizsetSessionId=${quizsetSessionId}`);
};

export const createQuizresponse = async (quizresponseData) => {
  return await postBackend('quizresponse', quizresponseData);
};

export const updateQuizresponse = async (quizresponseData) => {
  return await patchBackend('quizresponse', quizresponseData);
};

export const deleteQuizresponse = async (
  quizsetSessionId,
  choiceId,
  clientId
) => {
  return await deleteBackend(
    `quizresponse/${quizsetSessionId}/${choiceId}/${clientId}`
  );
};

// quizset
export const getQuizsetByQuizsetId = async (quizsetId) => {
  return await getBackend(`quizset?quizsetId=${quizsetId}`);
};

export const getQuizsetListByClient = async (clientId) => {
  return await getBackend(`quizset?clientId=${clientId}`);
};

export const getQuizsetListBySchool = async (schoolId) => {
  return await getBackend(`quizset?schoolId=${schoolId}`);
};

export const createQuizset = async (quizsetData) => {
  return await postBackend('quizset', quizsetData);
};

export const updateQuizset = async (quizsetData) => {
  return await patchBackend('quizset', quizsetData);
};

export const deleteQuizset = async (quizsetId) => {
  return await deleteBackend(`quizset/${quizsetId}`);
};

/**
 * quiz
 **/
export const getQuiz = async (quizId) => {
  return await getBackend(`quiz?quizId=${quizId}`);
};

// quizset session
export const getQuizsetSessionHistories = async ({ groupId, index }) => {
  return await getBackend(
    `quizsetSession/history?groupId=${groupId}&index=${index}`
  );
};

export const getQuizsetSession = async (quizsetSessionId) => {
  return await getBackend(
    `quizsetSession?quizsetSessionId=${quizsetSessionId}`
  );
};
export const getQuizsetSessionActive = async (groupId) => {
  return await getBackend(`quizsetSession?groupId=${groupId}&active=true`);
};
export const getQuizsetSessionActiveAll = async (clientId) => {
  return await getBackend(`quizsetSession?clientId=${clientId}&active=true`);
};

export const getQuizsetSessionTypeSurvey = async (clientId) => {
  return await getBackend(`/quizset?clientId=${clientId}&quizsetType=SURVEY`);
};

export const createQuizsetSessionTypeSurvey = async ({
  quizsetSessionId,
  choiceId,
  clientId,
}) => {
  return await postBackend(`quizresponse/?list=true`, {
    quizsetSessionId,
    choiceId,
    clientId,
  });
};

export const createQuizsetSession = async ({
  quizsetId,
  groupId,
  clientId,
  startAt,
}) => {
  return await postBackend('quizsetSession', {
    quizsetId,
    groupId,
    clientId,
    startAt,
  });
};

/**
 * Quizset Session Joint
 */
export const getQuizsetSessionJoint = async (quizsetSessionId) => {
  return await getBackend(
    `quizsetSessionQuizJoint?quizsetSessionId=${quizsetSessionId}`
  );
};

export const createQuizsetSessionByQuiz = async ({
  quizId,
  groupId,
  clientId,
  startAt,
}) => {
  return await postBackend('quizsetSession/one', {
    quizId,
    groupId,
    clientId,
    startAt,
  });
};

export const getQuizsetSessionResult = async (quizsetSessionId, quizOrder) => {
  return await getBackend(
    `quizsetSession/result?quizsetSessionId=${quizsetSessionId}&quizOrder=${quizOrder -
      1}`
  );
};

// NIA
// session
export const getSession = async ({ sessionId, studentId }) => {
  //TODO: fix this for more readability
  var paramString = '';
  if (studentId) {
    paramString += `studentId=${studentId}`;
  }
  if (sessionId) {
    if (paramString) {
      paramString += '&';
    }
    paramString += `sessionId=${sessionId}`;
  }
  return await niaGetBackend(`nia/session?${paramString}`);
};

export const createSession = async (session) => {
  return await niaPostBackend('nia/session', session);
};

export const updateSession = async (session) => {
  return await niaPatchBackend('nia/session', session);
};

export const deleteSession = async (sessionId) => {
  return await niaDeleteBackend(`nia/session/${sessionId}`);
};

export const getSessionOutline = async (sessionId) => {
  return await niaGetBackend(`nia/session/outline?sessionId=${sessionId}`);
};

//// question
export const getQuestions = async (questionIds, subject) => {
  var paramString = '';
  if (questionIds) {
    paramString += 'questionIds=' + questionIds.join(',');
  }
  if (subject) {
    paramString += '&subject=' + subject;
  }
  return await niaGetBackend(`nia/question?${paramString}`);
};

//// answer
export const getAnswers = async (
  subquestionId,
  studentId,
  requestPresignedUrl = true
) => {
  var paramString = '';
  if (subquestionId) {
    paramString += 'subquestionId=' + subquestionId;
  }
  if (studentId) {
    if (paramString != '') paramString += '&';
    paramString += 'studentId=' + studentId;
  }
  return await niaGetBackend(
    `nia/answer?${paramString}&requestPresignedUrl=${requestPresignedUrl}`
  );
};

export const createSurveyAnswer = async (answer, multiple = false) => {
  return await niaPostBackend(
    `nia/answer?subquestionType=MULTIPLE_CHOICE&multiple=${multiple}`,
    answer
  );
};

export const createQnAAnswer = async (answer) => {
  return await niaPostBackend(
    'nia/answer?subquestionType=SHORT_ANSWER',
    answer
  );
};

export const updateAnswer = async (answer) => {
  return await niaPatchBackend('nia/answer', answer);
};

export const putAnswerAWS = async (presignedUrl, content, contentType) => {
  var response = await niaPutAWS(presignedUrl, content, contentType);
  response = await response.text();
  return response;
};

export const deleteAnswer = async (answerId) => {
  return await niaDeleteBackend(`nia/answer/${answerId}`);
};

export const getHTMLfromAWS = async (AWSUrl) => {
  var response = await niaGetAWS(AWSUrl);
  var responseText = await response.text();
  return {
    status: response.status,
    data: responseText,
  };
};

//// supervisor
export const getSupervisor = async (userId) => {
  return await niaGetBackend(`nia/supervisor?userId=${userId}`);
};

// 모둠활동
export const getGroupActivity = async (groupId) => {
  return await getBackend(`subgroupActivity?groupId=${groupId}`);
};
export const getSubgroupActivity = async (subgroupActivityId) => {
  return await getBackend(`subgroup?subgroupActivityId=${subgroupActivityId}`);
};

// Report
export const createReport = async (createdReport) => {
  return await postBackend('report?list=true', createdReport);
};

//ReportEvents
export const createReportEvents = async (createdEvents) => {
  return await postBackend('log/screenShare/history', createdEvents);
};

// CommentsLayer
export const getCommentsLayer = async (classroomId) => {
  return await getBackend(`/commentsLayer?classroomId=${classroomId}`, true);
};
// 공지 과제
export const getGroupnoticeByGroupAxios = async (groupId) => {
  return await getBackend(`groupnotice?groupId=${groupId}`);
};

export const getSubmissionByClientAxios = async (clientId) => {
  return await getBackend(`submission?clientId=${clientId}`);
};

export const postSubmissionAxios = async (submission) => {
  return await postBackend(`submission`, submission);
};

export const updateSubmissionAxios = async (submission) => {
  return await patchBackend(`submission`, submission);
};

export const postDP = async (data) => {
  return await postBackendDP(`/dp/archive`, data);
};

//퀴즈 채점
export const getScoringNeededQuizResponses = async (quizsetSessionId) => {
  return await getBackend(`quizScoring?quizsetSessionId=${quizsetSessionId}`);
};

// System Notification
/**
 * @param {
 *   count: Int(optional)
 *   hour: Int(optional)
 *   state: String(optional)
 *   limit: Int
 *   offset: Int
 * }
 */
export const getSystemNotification = async (params) => {
  return await getBackend(
    `/systemNotification?${new URLSearchParams(params).toString()}`
  );
};
/**
 * @param {
 * "state": "UNREAD", // String,
 * "notificationType": "COMMAND", // String
 * "notificationKind": "CLASS_START", //String
 * "fromClientId": "XXXX-XXXX-XXXX-XXXX", // UUID
 * "toClientId": "XXXX-XXXX-XXXX-XXXX", // UUID
 * "title": "xxxx", // String(optional)
 * "body": "xxxx", // String(optional)
 * "payload": "xxxx", //String(optional)
 * }
 */
export const createSystemNotification = async (data) => {
  return await postBackend(`/systemNotification`, data);
};
/**
 * @param {
 *  "systemNotificationId": "x", // Int
 *  "state": "READ" // String
 * }
 */
export const updateSystemNotification = async (data) => {
  return await patchBackend(`/systemNotification`, data);
};
export const deleteSystemNotification = async (systemNotificationId) => {
  return await deleteBackend(`systemNotification/${systemNotificationId}`);
};

export const postSystemNotificationError = async (data) => {
  return await postBackend('/systemNotification/error', data);
};
