import { selectChoiceBySubquestion } from "../selectors";

const getSubquestionByStage = (subquestions, stage) => {
  return subquestions.find(subquestion => subquestion.stage === stage);
};

export const selectQnASubquestionByQuestion = (state, questionId) => {
  const subquestionList = state.subquestion.allIds
    .map(subquestionId => state.subquestion.byId[subquestionId])
    .filter(subquestion => subquestion.questionId == questionId);
  return [
    getSubquestionByStage(subquestionList, "BASE"),
    getSubquestionByStage(subquestionList, "COMPREHENSION"),
    getSubquestionByStage(subquestionList, "EMOTION")];
}

export const selectSurveySubquestionByQuestion = (state, questionId) => {
  const subquestionList = state.subquestion.allIds
    .map(subquestionId => state.subquestion.byId[subquestionId])
    .filter(subquestion => subquestion.questionId == questionId);

  if(!subquestionList) {
    return [];
  }
  return subquestionList.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    } else if (a.index < b.index) {
      return -1;
    }
  });
}

export const selectAllSubquestionsBySession = (state, sessionId) => {
    const subquestionList = state.outline.allIds
        .map(outlineId => state.outline.byId[outlineId])
        .filter(outline => outline.sessionId == sessionId)
        .map(outline => state.subquestion.byId[outline.subquestionId]);
    return subquestionList;
}

export const  selectSurveySubquestionsById = (state, sessionId) => {
    const subquestionList = selectAllSubquestionsBySession(state, sessionId)
        .filter(subquestion => state.question.byId[subquestion.questionId].subject === "SURVEY");
    if (subquestionList.length == 0) {
        return [];
    }

    let subquestionById = {};
    subquestionList.forEach(subquestion => {
        subquestionById[subquestion.subquestionId] = subquestion;
    });
    return subquestionById;
}