export const PURGE_SUBQUESTION = "PURGE_SUBQUESTION";

export const GET_SUBQUESTIONS_SUCCESS = 'GET_SUBQUESTIONS_SUCCESS';

export const GET_SUBQUESTION_CONTENT = 'GET_SUBQUESTION_CONTENT';
export const GET_SUBQUESTION_CONTENT_SUCCESS = 'GET_SUBQUESTION_CONTENT_SUCCESS';
export const GET_SUBQUESTION_CONTENT_FAIL = 'GET_SUBQUESTION_CONTENT_FAIL';

export const GET_SUBQUESTION_SOLUTION = 'GET_SUBQUESTION_SOLUTION';
export const GET_SUBQUESTION_SOLUTION_SUCCESS = 'GET_SUBQUESTION_SOLUTION_SUCCESS';
export const GET_SUBQUESTION_SOLUTION_FAIL = 'GET_SUBQUESTION_SOLUTION_FAIL';