import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "@components/Layout/Sidebar";
import styles from "./edit-profile.module.scss";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { signInPromise } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useInput from "../../hooks/useInput";
// import { updateUserInfoPromise } from "./../../store/user/actions";

const ErrorMessage = styled.div`
  color: red;
`;

const EditProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [gender, setGender] = useState(true);
  const [birth, setBirth] = useState("");
  const [birthToString, setBirthToString] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [email, onChangeEmail] = useInput("");
  const [phone, onChangePhone] = useInput("");
  const [password, onChangePassword] = useInput("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const birthHandler = (e) => setBirth(e.target.value);

  useEffect(() => {
    setBirthToString(
      birth.slice(0, 4) + "-" + birth.slice(4, 6) + "-" + birth.slice(6, 8)
    );
  }, [birth]);

  const onChangePasswordCheck = useCallback(
    (e) => {
      setPasswordCheck(e.target.value);
      setPasswordError(e.target.value !== password);
    },
    [password]
  );

  // useEffect(() => {
  //   if (user.state === 'NOT_SIGNED_IN') {
  //     history.replace('/auth');
  //   } else if (user.state === 'REGISTERED_FILLED') {
  //     history.replace('/class-home');
  //   }
  // }, [user.state]);

  const onSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    if (email === "" || phone === "" || email === "" || birth === "") {
      alert("생년월일, 이메일, 휴대전화, 비밀번호를 모두 채워주세요");
    } else {
      // dispatch(
      //   updateUserInfoPromise({
      //     gender: gender,
      //     birthdate: birthToString,
      //     email: email,
      //     phone: phone,
      //     password: password,
      //     // student_number: user.studentNumber,
      //     name: user.username,
      //     state: "REGISTERED_FILLED",
      //   })
      // )
      //   .then((clientData) => {
      //     if (clientData === undefined) {
      //       throw new Error("userInfo Fill Failed!");
      //     }
      //     console.log("FillInfo Signed up as: ", clientData);
      //     history.replace("/settings/");
      //   })
      //   .catch((error) => {
      //     console.error("fillinfo ERROR: ", error);
      //     alert(
      //       "로그인에 실패했어요.\n입력한 정보를 다시 확인하고 로그인해주세요"
      //     );
      //   });
    }
    setDisabled(false);
  };

  return (
    // <div className={styles['fillInfoBox']}>
    <div className="flex">
      <Sidebar />

      <div className={styles["main-container"]}>
        <form onSubmit={onSubmit} className={styles["form"]}>
          <div>
            <div className={styles["infoContainer"]}>
              <div className={styles["infoFont"]}> 생년월일</div>
              <input
                type={"text"}
                value={birth}
                onChange={birthHandler}
                className={styles["inputContainer"]}
                placeholder=" YYYYMMDD"
              />
            </div>
            <div className={styles["infoContainer"]}>
              <div className={styles["infoFont"]}>이메일</div>
              <input
                type="text"
                name="email"
                value={email}
                onChange={onChangeEmail}
                className={styles["inputContainer"]}
              />
            </div>
            <div className={styles["infoContainer"]}>
              <div className={styles["infoFont"]}>휴대전화</div>
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={onChangePhone}
                className={styles["inputContainer"]}
              />
            </div>
            <div className={styles["infoContainer"]}>
              <div className={styles["infoFont"]}>비밀번호</div>
              <input
                name="비밀번호"
                type="password"
                value={password}
                required
                onChange={onChangePassword}
                className={styles["inputContainer"]}
                // onChange={(e) => setPw(e.target.value)}
              />
            </div>
            <div className={styles["infoContainer"]}>
              <div className={styles["infoFont"]}>비밀번호 재확인</div>
              <input
                type="password"
                name="비밀번호 재확인"
                value={passwordCheck}
                onChange={onChangePasswordCheck}
                className={styles["inputContainer"]}
                required
              />
            </div>
            {passwordError && (
              <ErrorMessage>비밀번호가 일치하지 않습니다. </ErrorMessage>
            )}
          </div>
          <button
            type="submit"
            name="submit"
            className={styles["button-input"]}
            disabled={disabled}
          >
            제출하기
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
