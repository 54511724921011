import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Body2,
  Caption,
  Column,
  HorizontalSpace4,
  Row,
  VerticalSpace4,
} from "@app/pages/QuizPang/common";
import { Button, Tooltip, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
// import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment/moment";
import focuspangLogo from "@app/assets/images/focuspang.png";
import { getYoutubeVideoThumbnail } from "@app/store/helpers";

export const QuizsetSurveyCard = ({
  allData,
  data,
  actions,
  onClickBottomButton,
}) => {
  // const [firstQuizData, setFirstQuizData] = useState(false);
  // useEffect(() => {
  //   if (JSON.parse(data?.quizzes)?.length > 0) {
  //     setFirstQuizData(
  //       allData.find((quiz) => quiz?.quizId === data.quizzes[0].quizId)
  //     );
  //   }
  // }, [data, allData]);

  return (
    <Container
      onClick={() => onClickBottomButton()}
      style={{ justifyContent: "space-between" }}
    >
      <StyledRow>
        <Typography variant="body1">설문지</Typography>
        {/* <QuizsetTimeInfo data={data.quizset} />  */}
      </StyledRow>
      <VerticalSpace4 />
      <Image src={focuspangLogo} />
      {/* {firstQuizData?.link == "NotSet" || firstQuizData?.link == "" ? (
        <Image src={focuspangLogo} />
      ) : firstQuizData?.linkType === "YOUTUBE" ? (
        <Image src={getYoutubeVideoThumbnail(firstQuizData?.link)} />
      ) : (
        <Image src={firstQuizData?.link} />
      )} */}
      <VerticalSpace4 />
      <Typography variant="body2">
        {data.quizset.grade}학년 · {data?.quizset.subject} · (
        {data.quizCount}
        )개의 문제
      </Typography>
      <Tooltip title={data.quizset.title} placement="top">
        <StyledSpan style={{ paddingTop: "4px", fontSize: "16px" }}>{data.quizset.title}</StyledSpan>
      </Tooltip>
      <StyledSpan style={{ fontSize: "16px", padding: "2px 0 16px 0" }}>
        {data?.quizset.description}
      </StyledSpan>
      {data.surveyState === "NOT_DONE" ? <Status1>작성 중</Status1> : <Status2>제출 완료</Status2>}
      {/*<BottomButton
        variant='contained'
        color='secondary'
        endIcon={<ExpandMoreIcon />}
        onClick={() => onClickBottomButton()}
      ></BottomButton>*/}
    </Container>
  );
};

export const QuizsetTimeInfo = ({ data }) => {
  return (
    <Row>
      <TimerIcon>
        <TimerOutlinedIcon
          fontSize="small"
          sx={{ color: "var(--primary-darken-3)" }}
        />
      </TimerIcon>
      <TimerText>총 {data.timeDisplay} 소요</TimerText>
    </Row>
  );
};

export const QuizsetCardMyActions = ({ onEdit, onDelete }) => {
  return (
    <>
      <Button startIcon={<CreateIcon />} onClick={onEdit}>
        수정
      </Button>
      <HorizontalSpace4 />
      <Button startIcon={<DeleteIcon />} onClick={onDelete}>
        삭제
      </Button>
      {/*<HorizontalSpace4/>*/}
      {/*<Button startIcon={<PlayCircleIcon/>}>*/}
      {/*  미리보기*/}
      {/*</Button>*/}
    </>
  );
};

export const QuizsetCardOtherSchoolActions = ({ onAdd }) => {
  return (
    <>
      {/*<Button startIcon={<PlayCircleIcon/>}>*/}
      {/*  미리보기*/}
      {/*</Button>*/}
      {/*<HorizontalSpace4/>*/}
      <Button startIcon={<AddIcon />} onClick={onAdd}>
        퀴즈목록에 추가
      </Button>
    </>
  );
};

export const QuizsetCardDuringClassActions = ({ onStart }) => {
  return (
    <Button
      onClick={onStart}
      variant="contained"
      endIcon={<ChevronRightIcon />}
      style={{ marginLeft: 8 }}
    >
      퀴즈 시작
    </Button>
  );
};

const Container = styled(Column)`
  background-color: white;
  border: 1px solid var(--grey-lighten-2);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  min-width: 100%;
  cursor: pointer;

  // 마우스 오버시 컬러 변경
  &:hover {
    border: 1px solid var(--primary);
    background-color: var(--primary-light);
  }
`;

const TimerIcon = styled(Body2)`
  line-height: 20px;
  margin-right: 4px;
`;

const TimerText = styled(Body2)`
  line-height: 20px;
  color: var(--primary-darken-3);
`;

const MiddleCaption = styled(Caption)`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const BottomButton = styled(Button)`
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 1.25px;
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  color: var(--primary-darken-3);
`;

const Image = styled.img`
  min-width: 100px;
  min-height: 100px;
  /*max-width: 100px;
  max-height: 100px;*/
  width: 100%;
  justify-content: center;
  height: 160px;
  margin: 10px 0;
  border-radius: 4px;
  object-fit: scale-down;
  border-width: 1px;
`;

const StyledSpan = styled.span`
  letter-spacing: 0.025em;
  color: var(--font-color-80);
  font-size: 24px;
  font-weight: bold;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Status1 = styled.div`
padding: 12px;
border-radius: 8px;
border: 1px solid var(--primaryblue-300, #5F87C1);
background: var(--white, #FFF);
color: var(--primaryblue-300, #5F87C1);

/* KOR/subtitle-2 */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
letter-spacing: 0.1px;
`
const Status2 = styled.div`
padding: 12px;
gap: 10px;
border-radius: 8px;
background: var(--primaryblue-400, #3964A4);
color: var(--white, #FFF);

/* KOR/subtitle-2 */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
letter-spacing: 0.1px;
`