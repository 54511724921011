import { PURGE_PROFILE, SET_PROFILE_IMAGE_URL } from './actionTypes';

/**
 * @typedef {Object} ProfileAction
 * @property {PURGE_PROFILE|SET_PROFILE_IMAGE_URL} type
 * @property {string?} profileImageUrl
 */

/**
 * @returns {ProfileAction}
 */
export const purgeProfile = () => setProfileImageURL(null);

/**
 * @param {string?} profileImageUrl
 * @returns {ProfileAction}
 */
export const setProfileImageURL = (profileImageUrl) => ({
  type: SET_PROFILE_IMAGE_URL,
  profileImageUrl,
});