import React, { useEffect } from "react";
import MdInput from "@components/Input/MdInput";
import BaseModal from "./BaseModal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import MdSelect from '@components/Input/MdSelect';
import MdRadio from '@components/Radio/MdRadio';
import { FormControlLabel, Radio } from '@mui/material';

const Wrapper = styled.div`
margin-bottom: 12px;
`;

const Description = styled.div`
font-weight: 500;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.15px;
margin-bottom: 32px;
`;

const StudentInfo = styled.div`
font-weight: 400;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.15px;
margin-bottom: 16px;
`;

const InputWrapper = styled.div`
display: flex;
align-items: center;
gap: 16px;
`;

const InviteRestInfoModal = (props) => {
  const {
    visible,
    title = '초대 코드 로그인',
    values,
    handleChange = () => {},
    prevStep = () => {},
    onHide = () => {},
    onCancel = () => {},
    onComplete = () => {},
  } = props;

  const decipherGroup = useSelector((state) => state.invite.decipherGroup);
  const decipherSchool = useSelector((state) => state.invite.decipherSchool);

  useEffect(() => {
    if (
      Object.keys(decipherGroup).length === 0 ||
      Object.keys(decipherSchool).length === 0
    ) {
      alert("올바르지 않은 초대 코드예요. 다시 확인해주세요");
      prevStep();
      return;
    }
  }, []);

  return (
    <BaseModal
      title={title}
      completeText="완료"
      visible={visible}
      onHide={onHide}
      onCancel={onCancel}
      onComplete={onComplete}
    >
      <Wrapper>
        <Description>나머지 정보를 입력해주세요.</Description>
        <StudentInfo>
          {`${decipherSchool.name} ${decipherGroup.grade}학년 ${decipherGroup.class_name}반`}
        </StudentInfo>
        <InputWrapper>
          <MdInput
            type="text"
            name="studentNumber"
            label="학번"
            value={values.studentNumber}
            onChange={handleChange}
            style={{ width: 128 }}

          />
          <MdInput
            type="text"
            name="name"
            label="이름"
            value={values.name}
            onChange={handleChange}
            style={{ width: 162 }}
          />
        </InputWrapper>
      </Wrapper>
    </BaseModal>
  );
};

export default InviteRestInfoModal;