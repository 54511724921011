import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import {
  createReportEventsPromise,
  createReportEventsFail,
  postReportEventsPromise,
} from '@store/actions';
import { purgeReportEvents } from './actions';

function* createReportEventsHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createReportEvents, action.payload);
      return yield response.data;
    } catch (error) {
      yield put(createReportEventsFail);
    }
  });
}

function* postReportEventsHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createReportEvents, action.payload);
      yield put(purgeReportEvents());
      return yield response.data;
    } catch (error) {
      yield put(createReportEventsFail);
    }
  });
}

function* reportEventsSaga() {
  yield takeLeading(postReportEventsPromise, postReportEventsHandler);
  yield takeEvery(createReportEventsPromise, createReportEventsHandler);
}

export default reportEventsSaga;
