export const PURGE_REPORT_EVENTS = 'PURGE_REPORT_EVENTS';

export const SET_REPORT_EVENT_PROMISE = 'SET_REPORT_EVENT_PROMISE';
export const SET_REPORT_EVENT_SUCCESS = 'SET_REPORT_EVENT_SUCCESS';
export const SET_REPORT_EVENT_FAIL = 'SET_REPORT_EVENT_FAIL';

export const CREATE_REPORT_EVENTS_SUCCESS = 'CREATE_REPORT_EVENTS_SUCCESS';
export const CREATE_REPORT_EVENTS_FAIL = 'CREATE_REPORT_EVENTS_FAIL';

export const POST_REPORT_EVENTS_SUCCESS = 'POST_REPORT_EVENTS_SUCCESS';
export const POST_REPORT_EVENTS_FAIL = 'POST_REPORT_EVENTS_FAIL';
