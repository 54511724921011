import { makeFreshAllIds } from "../helpers";
import {
  PURGE_FILELINK,
  GET_GROUP_FILELINK_SUCCESS,
  GET_GROUP_FILELINK_FAIL,
  CREATE_FILELINK_SUCCESS,
  CREATE_FILELINK_FAIL,
  DELETE_FILELINK_SUCCESS,
  DELETE_FILELINK_FAIL,
} from "./actionTypes";


/**
 * @typedef {Object} FilelinksState
 * @property {{[key: string]: focuspang.Filelink}} byId
 * @property {string[]} allIds
 */
const initialState = {
  byId: {},
  allIds: [],
};

const filelinks = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_FILELINK:
      return initialState;

    case GET_GROUP_FILELINK_SUCCESS:
      return {
        ...state,
        byId: action.byId,
        allIds: action.allIds,
        //byId: { ...state.byId, ...action.byId },
        //allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_FILELINK_SUCCESS:
      //return {
      //  ...state,
      //  byId: { ...state.byId, [action.filelink.filelinkId]: action.filelink },
      //  allIds: makeFreshAllIds(state.allIds, [action.filelink.filelinkId]),
      //};
      break;
    case DELETE_FILELINK_SUCCESS:
      //delete state.byId[action.filelink];
      //return {
      //  ...state,
      //  byId: { ...state.byId, },
      //  allIds: state.allIds.filter(
      //    (filelinkId) => filelinkId !== action.filelink
      //  )
      //};
      break;

    case GET_GROUP_FILELINK_FAIL:
      return initialState;
    case CREATE_FILELINK_FAIL:
    case GET_GROUP_FILELINK_FAIL:
    case DELETE_FILELINK_FAIL:
      console.error("[Filelink Reducer] ", action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default filelinks;
