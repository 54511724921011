import { Stack, Typography, Divider } from "@mui/material";
import { selectGroupTeacherName } from "@store/membership/selector";
import { selectGroupById } from "@store/selectors";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const NoticeItem = ({ groupnotice }) => {
  const { grade, groupName, subjectName } = useSelector((state) =>
    selectGroupById(state, groupnotice.groupId)
  );
  const teacherName = useSelector((state) =>
    selectGroupTeacherName(state, groupnotice.groupId)
  );
  const { body = "", title = "", releaseAt = "" } = groupnotice;

  return (
    <Stack border='1px solid #E0E0E0' borderRadius='4px' p={3} spacing={3}>
      <Typography>{title}</Typography>
      <Stack direction='row' spacing={3}>
        <Typography color='#00000099' variant='body2'>
          {format(new Date(releaseAt), "yyyy-MM-dd HH:mm")}
        </Typography>
        <Typography color='#00000099' variant='body2'>
          {`${grade}학년 ${groupName}반 ${subjectName}`}
        </Typography>
        <Typography color='#00000099' variant='body2'>
          · {teacherName} 선생님 공지
        </Typography>
      </Stack>
      <Divider />
      <Typography variant='body2'>
        <StyledDiv dangerouslySetInnerHTML={{ __html: body }} />
      </Typography>
    </Stack>
  );
};

const StyledDiv = styled.div`
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1.2;
  }
  h1 {
    font-size: 39px;
  }
  h2 {
    font-size: 31px;
  }
  h3 {
    font-size: 27px;
  }
  b,
  strong {
    font-weight: bold;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  ol,
  ul {
    margin-top: 0;
    padding-left: 32px;
    margin-bottom: 16px;
  }
  p {
    margin-top: 0;
    margin-bottom: 16px;
  }
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
`;
