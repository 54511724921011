import React, { useEffect } from "react";
import { SentryRoute } from '@app/infra/sentry';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedGroup } from "@store/actions";

const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const { groupId } = useParams();

  // useEffect(() => {
  //   if(groupId) {
  //     dispatch(setSelectedGroup(groupId));
  //   }
  // }, [dispatch, location, groupId]);
  
  return (
    <div>
      <SentryRoute {...rest} render={(props) => <Component {...props} />} />
    </div>
  );
};

export default AppRoute;
