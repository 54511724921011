import { makeFreshAllIds } from '../helpers';
import {
  PURGE_OUTLINE,

  GET_OUTLINE_SUCCESS,
  GET_OUTLINE_FAIL,

  UPDATE_OUTLINE_SOLVED,
  UPDATE_OUTLINES_SOLVED,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const outline = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_OUTLINE:
      return initialState;

    case GET_OUTLINE_SUCCESS:
      return {...state,
        byId: {...state.byId, ...action.newById},
        allIds: makeFreshAllIds(state.allIds, action.newAllIds)};

    case UPDATE_OUTLINE_SOLVED:
      const outlineId = state.allIds.find(it => 
        state.byId[it].subquestionId === action.subquestionId
      );
      return {...state,
        byId: {...state.byId, 
          [outlineId]: {...state.byId[outlineId], state: 'SOLVED'}}
      };
    case UPDATE_OUTLINES_SOLVED:
      let newById = {};
      action.subquestionIds.forEach(subquestionId => {
        const outlineId = state.allIds.find(it => 
          state.byId[it].subquestionId === subquestionId);
        newById[outlineId] = {...state.byId[outlineId], state: 'SOLVED'};
      });
      return {...state, 
        byId: {...state.byId, ...newById }};

    // todo: handle errors
    case GET_OUTLINE_FAIL:
      console.error('[Outline Reducer] ', action.payload);
      return state
    default:
      break;
  }
  return state;
};

export default outline;
