import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EraserIcon from '@icons/material/EraserIcon';
import {
  Replay,
  Redo,
  Undo,
  Create,
  FormatColorFill,
} from '@mui/icons-material';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { GithubPicker } from 'react-color';
import { imageToFile, uploadQuizStylusWrite } from '@api';
import { useSelector } from 'react-redux';
import QuizStylusLogger from '../quiz-stylus-logger';
import '@tldraw/tldraw/tldraw.css';
import { Tldraw } from '@tldraw/tldraw';
import { getSvgAsImage } from '@components/CanvasDrawer/utils/utils';

const QuizStylusWriteTldrawerComponent = ({
  quizId,
  quizsetSessionId,
  quizTimelimit,
  stylusImage,
  setStylusImage,
  containerRef,
  stylusData,
  setStylusData,
  deviceType,
  quizEnded,
}) => {
  const [editor, setEditor] = useState();
  // const [stylusData, setStylusData] = useState([]);
  const [strokeWidth, setStrokeWidth] = useState(4);
  const [strokeColor, setStrokeColor] = useState('#000');
  const [isVisibleColor, setIsVisibleColor] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);

  const onChangeEraserMode = useCallback(
    (isEraser) => {
      editor.setCurrentTool(isEraser ? 'eraser' : 'draw');
      setIsEraserMode(isEraser);
    },
    [editor]
  );
  const onClear = useCallback(() => {
    editor.selectAll();
    editor.deleteShapes(editor.selectedShapeIds);
  }, [editor]);

  const onPathChange = useCallback(async () => {
    if (!editor) {
      return;
    }

    const svg = await editor.getSvg([...editor.currentPageShapeIds], {
      scale: 1,
      background: false,
    });
    if (!svg) return;
    const base64 = await getSvgAsImage(svg, {
      type: 'png',
      quality: 1,
      scale: 1,
    });
    setStylusImage(base64);
  }, [editor, setStylusImage]);

  const onMount = (editor) => {
    editor.setCurrentTool('draw');
    editor.selectAll();
    editor.resetZoom();
    editor.deleteShapes(editor.selectedShapeIds);
    setEditor(editor);
  };

  useEffect(() => {
    if (!editor) return;

    const interval = setInterval(() => {
      onPathChange();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [editor, onPathChange]);

  return (
    <StyledContainer>
      <StyledToolkitWrap>
        <StyledModeButton onClick={() => editor?.undo()}>
          <Undo />
        </StyledModeButton>
        <StyledModeButton onClick={() => editor?.redo()}>
          <Redo />
        </StyledModeButton>
        <StyledModeButton
          $isActive={!isEraserMode}
          onClick={() => onChangeEraserMode(false)}
        >
          <Create />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isEraserMode}
          onClick={() => onChangeEraserMode(true)}
        >
          <EraserIcon />
        </StyledModeButton>
        <StyledModeButton onClick={onClear}>
          <Replay />
        </StyledModeButton>
        {/* <StyledModeButton
          $backgroundColor={strokeColor}
          onClick={() => setIsVisibleColor(!isVisibleColor)}
        >
          <FormatColorFill />
        </StyledModeButton> */}
        {isVisibleColor && (
          <StyledGithubPicker
            triangle="top-right"
            color={strokeColor}
            onChangeComplete={(color) => {
              setStrokeColor(color.hex);
              setIsVisibleColor(false);
            }}
          />
        )}
      </StyledToolkitWrap>
      <QuizStylusLogger
        name="SketchCanvas"
        stylusData={stylusData}
        setStylusData={setStylusData}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
        strokeColor={strokeColor}
        containerRef={containerRef}
        isEraser={isEraserMode}
        deviceType={deviceType}
      >
        <StyledTldrawWrap>
          <Tldraw
            hideUi
            isLocked
            persistenceKey="tldraw"
            onMount={onMount}
            autoFocus
          />
        </StyledTldrawWrap>
      </QuizStylusLogger>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  border: 1px solid #676767;
  border-radius: 8px;
  max-width: 960px;
  margin: 20px auto 0;
`;

const StyledToolkitWrap = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 4px;
  color: white;
  z-index: 205;
  border-radius: 8px;
`;
const StyledModeButton = styled.button`
  padding: 8px;
  font-size: 12px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || '#969696'};
  border-radius: 50%;
  margin: 0 4px;
  border: 1px solid #969696;

  ${({ $isActive }) =>
    $isActive &&
    `
      background-color: white;
      color: black;
  `}
`;
const StyledGithubPicker = styled(GithubPicker)`
  position: absolute !important;
  right: 10px;
  bottom: -90px;
  width: 188px !important;
`;
const StyledTldrawWrap = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  max-width: 960px;
  overflow: hidden;

  .tl-canvas {
    background-color: transparent;
  }
  .tl-background {
    background-color: transparent;
  }
`;
export const QuizStylusWriteTldrawer = memo(QuizStylusWriteTldrawerComponent);
