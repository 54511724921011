import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import { selectGroupFilelinks } from "@store/selectors";
import styles from "./off-class.module.scss";
import FileLink from "@components/Layout/FileLink";
// import ImgOffClass from "@images/home/img-offclass.svg";
import {
  getActiveClassroomByGroupPromise,
  getActiveClassroomPromise,
  getGroupFilelinkPromise,
  getMembershipPromise,
} from "@store/actions";
import { selectGroup } from "@store/selectors";
import {
  setSelectedPage,
  setSelectedClass,
  getGroupFileLink,
} from "@store/actions";
import { selectMyGroupsAsStudent } from "@store/groups/selector";
import {
  selectActiveGroup,
  selectActiveClassroom,
  selectGroupFilelinks,
} from "@store/selectors";
import ImgOffClass from "@images/classroom/playful cat-pana.png";
import { setToken } from "@api";
import PageWrapper from "@components/Layout/PageWrapper";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import CardFileLink from "@components/Card/CardFileLink";

const SECONDS = 1000;
const MINUTES = 60;

const OffClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  /**
   * @type {{groupId: string | undefined}}
   */
  const params = useParams();
  // const groupId = history.location.search.slice(1);
  // const filelinks = useSelector((state) =>
  //   selectGroupFilelinks(state, groupId)
  // );
  // const selectedClassId = useSelector((state) => state.control.selectedClassId);
  // const selectedGroup = useSelector((state) =>
  //   selectGroup(state, selectedClassId)
  // );
  const user = useSelector((state) => state.user);
  const [groups, setGroups] = useState(
    /** @type {focuspang.Group[]?} */ (null)
  );
  const [currentGroup, setCurrentGroup] = useState(
    /** @type {focuspang.Group?} */ (null)
  );
  const [filelinks, setFilelinks] = useState(
    /** @type {focuspang.Filelink[]?} */ (null)
  );

  useEffect(() => {
    const eventEmitterHandler = async () => {
      const groups = (await dispatch(getMembershipPromise(user.clientId))).map(
        (membership) => membership.group
      );
      console.log("[OffClass] groups", groups);
      setGroups(groups);

      const activeClassroom = await dispatch(
        getActiveClassroomByGroupPromise(params.groupId)
      );

      if ((params.groupId ?? null) === null) {
        history.replace(`/home`);
      } else {
        global.eventEmitter.on("filelinkchanged", async () => {
          const filelinks = await dispatch(
            getGroupFilelinkPromise(params.groupId)
          );
          console.log("[OffClass] filelinks", filelinks);
          setFilelinks(filelinks);
        });
        global.eventEmitter.emit("filelinkchanged");
      }
    };
    eventEmitterHandler();
  }, []);

  useEffect(() => {
    setCurrentGroup(
      groups?.find((group) => group.groupId === params.groupId) ?? null
    );
  }, [groups]);

  // useEffect(() => {
  //   dispatch(getGroupFilelinkPromise(groupId));
  // }, [groupId]);

  return (
    <>
      <SidebarClassRoom
        groupId={params.groupId}
        subject={currentGroup?.subjectName}
      />
      <PageWrapper>
        <div className={styles["wrapper"]}>
          <div className={styles["main-container"]}>
            <img
              className={styles["img-off-class"]}
              src={ImgOffClass}
              alt='활성화된 수업 없음'
            />
            <span className={styles["span-bold-notice"]}>
              아직 수업 시간이 아니에요!
            </span>
            <span className={styles["span-light-notice"]}>
              좋은 휴식시간을 보내는 것도 정말 중요하답니다.
            </span>
            <span className={styles["span-light-notice"]}>
              수업이 시작한 후에 다시 만나요!
            </span>
          </div>
          <div className={styles["file-link"]}>
            <div className={styles["link-title"]}>받은 링크 목록</div>
            {filelinks && <FileLink filelinks={filelinks} />}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default OffClass;

// {/* <>
//   <SidebarClassRoom
//     subject={classInfo?.name}
//   />
//   <PageWrapper>
//     <div className={styles["wrapper"]}>
//       <div className={styles['main-container']}>
//         <img
//           className={styles['img-off-class']}
//           src={ImgOffClass}
//           alt="활성화된 수업 없음"
//         />
//         <span className={styles['span-bold-notice']}>
//           아직 수업 시간이 아니에요!
//         </span>
//         <span className={styles['span-light-notice']}>
//           좋은 휴식시간을 보내는 것도 정말 중요하답니다.
//         </span>
//         <span className={styles['span-light-notice']}>
//           수업이 시작한 후에 다시 만나요!
//         </span>
//       </div>
//       {classInfo && (
//         <div className={styles['file-link']}>
//           <div className={styles["link-title"]}>받은 링크 목록</div>
//           {(groupFileLinks ?? []).reverse().map((fileLink, i) => (
//             <div
//               key={i}
//               style={{ marginBottom: 16 }}
//             >
//               <CardFileLink fileLink={fileLink} />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   </PageWrapper>
// </> */}
