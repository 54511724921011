import { getTimetableByClientId } from '@api/timetable/timetableApi';
import { useQuery } from '@tanstack/react-query';

export const useTimetableByClientId = (clientId) => {
  const { data: timetable, isFetching, isLoading, isError, refetch } = useQuery(
    {
      queryKey: ['timetable', clientId],
      queryFn: () => getTimetableByClientId(clientId),
      enabled: !!clientId,
    }
  );

  return {
    timetable,
    isFetching,
    isLoading,
    isError,
    refetch,
  };
};
