import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import SidebarClassList from '@components/Layout/SidebarClassList';

import { selectAllClients, selectManagingGroup } from '@store/selectors';

import InviteCodeJoinUp from '@components/Modal/InviteCodeJoinUp';
import ProfileImage from '@components/ProfileImage';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { ReactComponent as PsychologySvg } from '@images/icons/psychology.svg';
import { profileImgPath } from '@api';
import SidebarTab from '@components/SidebarTab/SidebarTab';
import SettingsIcon from '@mui/icons-material/Settings';
import { selectTeacherMemberships } from '@store/membership/selector';
import BookSvg from '@images/icons/book.svg';
import PlusIcon from '@images/icons/mdi_plus.png';
import { Tooltip } from '@mui/material';
import { setSelectedClass } from '@store/actions';

/**
 * @typedef {Object} SidebarProps
 * @property {focuspang.Group} [afterSchoolGroup]
 */

/**
 *
 * @param {SidebarProps} props
 * @returns
 */
function Sidebar({ afterSchoolGroup }) {
  const dispatch = useDispatch();
  let ClassListEnalble = false;
  const history = useHistory();
  const { groupId } = useParams();
  const [showInvite, setShowInvite] = useState(false);
  const user = useSelector((state) => state.user);
  const clients = useSelector((state) => selectAllClients(state));
  const managingGroup = useSelector((state) => selectManagingGroup(state));

  const teacherMemberships = useSelector((state) =>
    selectTeacherMemberships(state)
  );

  const findClientName = (groupId) => {
    if (!groupId) return null;

    const foundMembership = teacherMemberships?.find(
      (membership) => membership.groupId === groupId
    );
    if (!foundMembership) return null;

    const foundClient = clients?.find(
      (client) => foundMembership?.clientId === client.clientId
  );
    if (foundClient !== null) return foundClient?.userName;

    return null;
  };
 
  useEffect(() => {
    groupId && dispatch(setSelectedClass(groupId));
  }, [dispatch, groupId]);


  // const group = useSelector(
  //   (/** @type {import('@store').State} */ state) => selectHRGroup(state)
  // );
  // const afterSchoolGroup = useSelector(
  //   (/** @type {import('@store').State} */ state) => selectAfterSchoolGroup(state)
  // );
  // const sub_group = useSelector((state) => selectSubjectGroups(state));

  const profileImageUrl = useSelector((
    /** @type {import('@store').State} */ state
  ) => {
    return state.profile.profileImageUrl;
  });

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }

    return profileImgPath(user.clientId);
  }, [user]);

  useEffect(() => {
    if (ClassListEnalble) return <SidebarClassList />;
  }, [ClassListEnalble]);

  const onPressSettings = () => {
    if (user.signedIn) {
      history.replace('/settings');
    }
    return;
  };

  const onPressDevMode = () => {
    if (user.signedIn) {
      history.replace('/dev');
    }
    return;
  };

  const onPressHelp = () => {
    const helpUrl = 'https://focuspang.com/about.html';
    try {
      window.cAPI.openUrl(helpUrl);
    } catch (error) {
      window.open(helpUrl);
    }
  };

  return (
    <div className={styles['wrapper']}>
      <div className={styles['inner-side']}>
        <div className={styles['student-info-container']}>
          {user.signedIn && (
            <>
              <ProfileImage size={40} url={profileImageUrl} />
              <div className={styles['user-name-container']}>
                <Tooltip title={user?.userName} arrow>
                 <div className={styles['p-user-name']}>{user?.userName}</div>
                </Tooltip>
                <div className={styles['p-student-number']}>
                  학번 : {user?.studentNumber}
                </div>
                {/* {user.signedIn &&
                  ((group?.grade && group?.class_name) ||
                    (sub_group[0]?.grade && sub_group[0]?.class_name)) && (
                    <div className={styles['p-student-number']}>
                      {group !== undefined
                        ? `${group?.grade}학년 ${group?.class_name}반 ${user.studentNumber}번`
                        : `${sub_group[0]?.grade}학년 ${sub_group[0]?.class_name}반 ${user.studentNumber}번`}
                    </div>
                  )} */}
              </div>
            </>
          )}
        </div>

        {/* // {!user.signedIn && (
        //   <div className={`${styles.parent} ${styles.active}`}>
        //     <img src={imgStartIcon} alt='home' />
        //     <div className={styles["span-sidebar"]}>시작하기</div>
        //   </div>
        // )}
        // <div
        //   className={
        //     user.signedIn ? styles["parent"] : styles["parent-disabled"]
        //   }
        // >
        //   <img src={imgClassListIcon} alt='home' />
        //   <div
        //     className={styles["span-sidebar"]}
        //     onClick={() => {
        //       history.replace("/class-home");
        //     }}
        //   >
        //     수업 리스트
        //   </div>
        //   {user.signedIn && (
        //     <img
        //       className={styles["img-set-add"]}
        //       src={imgAddIcon}
        //       alt='addclass'
        //       onClick={() => inviteCode()} */}

        <div className={styles['horizontal-line']} />

        <div onClick={() => history.replace('/home')}>
          <SidebarTab
            icon={<HomeRoundedIcon style={{ marginRight: 24 }} />}
            text="처음으로"
            active={history.location.pathname === '/home'}
          />
        </div>

        <div className={styles['horizontal-line']} />

        <div
          onClick={() => {
            history.replace(
              `/managing${
                (managingGroup ?? null) === null
                  ? ''
                  : `/${managingGroup[0]?.groupId}`
              }`
            );
          }}
        >
          <SidebarTab
            icon={<PsychologySvg style={{ marginRight: 24 }} />}
            text="자기 관리 연습"
            active={history.location.pathname.startsWith('/managing')}
          />
        </div>

        {history.location.pathname.startsWith('/managing') &&
          managingGroup.length !== 0 && (
            <>
              <div className={styles['managing-sidebar']}>
                <span className={styles['managing-title']}>
                  초대된 관리 그룹
                </span>

                <button
                  className={styles['managing-add-box']}
                  onClick={() => setShowInvite(true)}
                >
                  <div className={styles['managing-add']}>그룹 초대 받기</div>
                  <img className={styles['managing-add-icon']} src={PlusIcon} />
                </button>
              </div>

              <div className={styles['managing-group-container']}>
                {managingGroup.map((group) => {
                  return (
                    <div
                      className={styles['managing-group-box']}
                      key={group.groupId}
                      icon="book-open-variant"
                      text={group.subjectName}
                      onClick={() => {
                        history.replace(`/managing/${group.groupId}`);
                      }}
                    >
                      <img
                        className={styles['managing-group-icon']}
                        src={BookSvg}
                        alt="BookSvg"
                      />
                      <Tooltip title={group.subjectName} arrow>
                        <div className={styles['managing-group-subject']}>
                          {group.subjectName}
                        </div>
                      </Tooltip>
                      <Tooltip title={findClientName(group.groupId)} arrow>
                        <div className={styles['managing-group-teacher']}>
                          {findClientName(group.groupId)}선생님
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </>
          )}

        {/* <div onClick={() => history.replace('/classroom/manage/')}>
          <SidebarTab
            icon={
              <ManageSettingSvg fill='#ffffff' style={{ marginRight: 24 }} />
            }
            text='수업 시간 외 앱/웹 사용 관리'
            active={false}
          />
        </div> */}

        <div />
        {/* <div className={styles["tab"]}>
          <div className={styles["tab-text"]}>
        <Link to='/class-home'>
          <SidebarTab
            icon={(
              <HomeRoundedIcon
                style={{
                  width: 24,
                  height: 24,
                  color: location.pathname === '/class-home' ? '#35B5C2' : '#ffffff',
                  marginRight: 24,
                }}
              />
            )}
            text='수업 시간표'
            active={location.pathname === '/class-home'}
          />
        </Link>
        <SidebarTab
          icon={(
            <FountainPenSvg
              fill='#ffffff'
              style={{ marginRight: 24 }}
            />
          )}
          text='문제 풀기'
          active={false}
        />
        <SidebarTab
          icon={(
            <ManageSettingSvg
              fill='#ffffff'
              style={{ marginRight: 24 }}
            />
          )}
          text='수업 시간 외 앱/웹 사용 제한'
          active={false}
        />
        </div> */}
        {/* <div className={styles["bottom-container"]}>
          <div
            className={styles["setting-block"]}
            onClick={onPressHelp}
          >
            <img className={styles["img-set"]} src={imgHelpIcon} alt="home" />
            <div className={styles["span-sidebar"]}>사용법 보러 가기</div>
          </div>
        </div>
        <div className={styles['horizontal-line']} />
        <div className={styles['classList-wrapper']}> */}
        {/* <div className={styles['span-classList']}>수업 리스트</div>
        <div className={styles['add-class']} onClick={() => inviteCode()}>
          <div>수업 추가</div>
          <img
            className={styles['img-set-add']}
            src={imgAddIcon}
            alt='addclass'
          />
        </div> */}
      </div>

      {/* {user.signedIn && (
          <div className={styles["class-list"]}>
            <SidebarClassList />
          </div>
        )} */}

      <div className={styles['setting-parent']}>
        <div onClick={onPressDevMode}>devMode</div>

        <div className={styles['setting-box']} onClick={onPressSettings}>
          <SettingsIcon
            style={{
              color: '#424242',
              width: 20,
              height: 20,
            }}
          />
        </div>
      </div>

      {/* <div
          className={
            user.signedIn ? styles["parent"] : styles["parent-disabled"]
          }
          onClick={onPressSettings}
        >
          <img src={imgSettingIcon} alt="home" />
          <div className={styles["span-sidebar"]}>수업 자료 관리</div>
        </div> */}

      {/* <InviteCodeModal
          visible={showInvite}
          code={code}
          onChangeCode={e => setCode(e.target.value)}
          onHide={() => {
            setCode(undefined);
            setShowInvite(false);
          }}
          onCancel={() => {
            setShowInvite(false);
            setCode(undefined);
          }}
          onComplete={joinToGroup}
          completeText='완료'
        /> */}
      <InviteCodeJoinUp showInvite={showInvite} setShowInvite={setShowInvite} />
    </div>
  );
}

export default Sidebar;
