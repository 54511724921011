import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { QuizPermission } from ".";
import QuizReadyCalib from "./quiz-ready-calib";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationKind,
  useSystemNotification,
} from "hooks/useSystemNotification";
import { setSocketData } from "@store/actions";
import { useParams } from "react-router-dom";

export const QuizBefore = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { groupId } = useParams();
  const { onSendMessage } = useSystemNotification();
  const [isGranted, setIsGranted] = useState(false);
  const [isCalibration, setIsCalibration] = useState(false);

  useEffect(() => {
    onSendMessage({
      notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
      payload: "READY_INPROGRESS",
    });
  }, [onSendMessage]);
  useEffect(() => {
    if (isCalibration) {
      onSendMessage({
        notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
        payload: "READY_SUCCESS",
      });
    }
  }, [
    dispatch,
    groupId,
    isCalibration,
    isGranted,
    onSendMessage,
    user?.clientId,
  ]);

  // if (!isGranted) {
  //   return (
  //     <StyledContainer>
  //       <QuizPermission onIsGranted={setIsGranted} />
  //     </StyledContainer>
  //   );
  // }
  // if (!isCalibration) {
  //   return (
  //     <StyledContainer>
  //       <QuizReadyCalib onSuccess={setIsCalibration} />
  //     </StyledContainer>
  //   );
  // }
  return (
    <StyledContainer>
      <StyledReady>
        <div>퀴즈를 곧 시작합니다.</div>
        <div>잠시만 기다려주세요.</div>
      </StyledReady>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const StyledReady = styled.div`
  line-height: 1.8;
  font-size: 15px;
`;
