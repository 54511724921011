import {
  PURGE_PARTICIPATION,
  GET_PARTICIPATION_SUCCESS,
  GET_PARTICIPATION_FAIL,
  GET_PARTICIPATIONS_BY_CLIENT_SUCCESS,
  GET_PARTICIPATIONS_BY_CLIENT_FAIL,
  GET_PARTICIPATIONS_BY_CLASSROOM_SUCCESS,
  GET_PARTICIPATIONS_BY_CLASSROOM_FAIL,
  CREATE_PARTICIPATION_SUCCESS,
  CREATE_PARTICIPATION_FAIL,
  UPDATE_PARTICIPATION_SUCCESS,
  UPDATE_PARTICIPATION_FAIL,
  DELETE_PARTICIPATION_SUCCESS,
  DELETE_PARTICIPATION_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeParticipation = () => ({
  type: PURGE_PARTICIPATION,
});

///// GET_PARTICIPATION

export const getParticipationPromise = createPromiseAction(
  'GET_PARTICIPATION_PROMISE'
);

export const getParticipationSuccess = (participationData) => ({
  type: GET_PARTICIPATION_SUCCESS,
  participation: participationData,
});

export const getParticipationFail = (error) => ({
  type: GET_PARTICIPATION_FAIL,
  payload: error,
});

export const getParticipationsByClientPromise = createPromiseAction(
  'GET_PARTICIPATIONS_BY_CLIENT_PROMISE'
);

export const getParticipationsByClientSuccess = (participationsData) => {
  let byId = {};
  participationsData.forEach((participationData) => {
    byId[participationData.classroomId] = participationData;
  });

  const allIds = participationsData.map(
    (participationsData) => participationsData.classroomId
  );

  return {
    type: GET_PARTICIPATIONS_BY_CLIENT_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getParticipationsByClientFail = (error) => ({
  type: GET_PARTICIPATIONS_BY_CLIENT_FAIL,
  payload: error,
});
export const getParticipationsByClassroomPromise = createPromiseAction(
  'GET_PARTICIPATIONS_BY_CLASSROOM_PROMISE'
);

export const getParticipationsByClassroomSuccess = (participationsData) => {
  let byId = {};
  participationsData.forEach((participationData) => {
    byId[participationData.clientId] = participationData;
  });

  const allIds = participationsData.map(
    (participationsData) => participationsData.clientId
  );

  return {
    type: GET_PARTICIPATIONS_BY_CLASSROOM_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getParticipationsByClassroomFail = (error) => ({
  type: GET_PARTICIPATIONS_BY_CLASSROOM_FAIL,
  payload: error,
});

export const createParticipationPromise = createPromiseAction(
  'CREATE_PARTICIPATION_PROMISE'
);

export const createParticipationSuccess = (participationData) => ({
  type: CREATE_PARTICIPATION_SUCCESS,
  participation: participationData,
});

export const createParticipationFail = (error) => ({
  type: CREATE_PARTICIPATION_FAIL,
  payload: error,
});

export const updateParticipationPromise = createPromiseAction(
  'UPDATE_PARTICIPATION_PROMISE'
);

export const updateParticipationSuccess = (participationData) => ({
  type: UPDATE_PARTICIPATION_SUCCESS,
  participation: participationData,
});

export const updateParticipationFail = (error) => ({
  type: UPDATE_PARTICIPATION_FAIL,
  payload: error,
});

export const deleteParticipationPromise = createPromiseAction(
  'DELETE_PARTICIPATION_PROMISE'
);

export const deleteParticipationSuccess = (participationData) => ({
  type: DELETE_PARTICIPATION_SUCCESS,
  participation: participationData,
});

export const deleteParticipationFail = (error) => ({
  type: DELETE_PARTICIPATION_FAIL,
  payload: error,
});
