
/**
 * @typedef {Object} ProfileState
 * @property {string?} profileImageUrl
 */

/** @type {ProfileState} */
const initialState = {
  profileImageUrl: null
};

/**
 * @param {ProfileState} state
 * @param {import('@store/profile/actions').ProfileAction} action
 */
const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'PURGE_PROFILE':
      return initialState;
    case 'SET_PROFILE_IMAGE_URL':
      return {
        ...state,
        profileImageUrl: action.profileImageUrl
      };
    default:
      break;
  }
  return state;
};

export default profile;
