export const PURGE_QUIZSET = 'PURGE_QUIZSET';
export const PURGE_OTHER_SCHOOL_QUIZSET = 'PURGE_OTHER_SCHOOL_QUIZSET';
export const GET_QUIZSET_BY_QUIZSETID_SUCCESS = 'GET_QUIZSET_BY_QUIZSETID_SUCCESS';
export const GET_QUIZSET_BY_QUIZSETID_FAIL = 'GET_QUIZSET_BY_QUIZSETID_FAIL';
export const GET_QUIZSET_LIST_BY_CLIENT_SUCCESS = 'GET_QUIZSET_LIST_BY_CLIENT_SUCCESS';
export const GET_QUIZSET_LIST_BY_CLIENT_FAIL = 'GET_QUIZSET_LIST_BY_CLIENT_FAIL';
export const GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS = 'GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS';
export const GET_QUIZSET_LIST_BY_SCHOOL_FAIL = 'GET_QUIZSET_LIST_BY_SCHOOL_FAIL';
export const CREATE_QUIZSET_SUCCESS = 'CREATE_QUIZSET_SUCCESS';
export const CREATE_QUIZSET_FAIL = 'CREATE_QUIZSET_FAIL';
export const UPDATE_QUIZSET_SUCCESS = 'UPDATE_QUIZSET_SUCCESS';
export const UPDATE_QUIZSET_FAIL = 'UPDATE_QUIZSET_FAIL';
export const DELETE_QUIZSET_SUCCESS = 'DELETE_QUIZSET_SUCCESS';
export const DELETE_QUIZSET_FAIL = 'DELETE_QUIZSET_FAIL';
