import { call, put, takeLeading, takeEvery } from "redux-saga/effects";
import {
  getFilelinkPromise,
  getFilelinkSuccess,
  getFilelinkFail,
  getGroupFilelinkPromise,
  getGroupFilelinkSuccess,
  getGroupFilelinkFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* getFilelinkPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getFilelink, action.payload);
      yield put(getFilelinkSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getFilelinkFail(error));
    }
  });
}

function* getGroupFilelinkPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroupFilelink, action.payload);
      yield put(getGroupFilelinkSuccess(response.data.filelinks));
      return yield response.data.filelinks;
    } catch (error) {
      yield put(getGroupFilelinkFail(error));
    }
  });
}

function* filelinkSaga() {
  yield takeLeading(getFilelinkPromise, getFilelinkPromiseHandler);
  yield takeEvery(getGroupFilelinkPromise, getGroupFilelinkPromiseHandler);
}

export default filelinkSaga;
