import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_SUBQUESTION_CONTENT,
  GET_SUBQUESTION_SOLUTION,
} from './actionTypes';
import {
  getSubquestionContentSuccess,
  getSubquestionContentFail,
} from '@store/actions'
import * as api from '@api';

function* getSubquestionContentHandler(action) {
  try {
    const response = yield call(api.getHTMLfromAWS, action.subquestion.contentUrl);
    if (response.status !== 200) {
      throw new Error("Failed to get subquestion content from AWS");
    }
    yield put(getSubquestionContentSuccess(action.subquestion.subquestionId, response.data));
  } catch (error) {
    yield put(getSubquestionContentFail(error));
  }
}

function* getSubquestionSolutionHandler(action) {
  try {
    const response = yield call(api.getHTMLfromAWS, action.subquestion.solutionUrl);
    if (response.status !== 200) {
      throw new Error("Failed to get subquestion content from AWS");
    }
    yield put(getSubquestionContentSuccess(action.subquestion.subquestionId, response.data));
  } catch (error) {
    yield put(getSubquestionContentFail(error));
  }
}


function* SubquestionSaga() {
  yield takeEvery(GET_SUBQUESTION_CONTENT, getSubquestionContentHandler);
  yield takeEvery(GET_SUBQUESTION_SOLUTION, getSubquestionSolutionHandler);
}

export default SubquestionSaga;
