import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CardLectureInfo_Empty.module.scss';
import BaseCard from './baseCard';

const CardLectureInfo = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const history = useHistory();
  const editing = false;

  const navigateToClassRoom = () => {
    if (group.groupType === 'AFTER') {
      history.push({
        pathname: `/after-school`,
        state: { group, timetable },
      });
    } else {
      history.push({
        pathname: `/classroom`,
        state: { group, timetable },
      });
    }
  };

  return (
    <>
      <BaseCard
        className={styles['container']}
        style={{ backgroundColor: '#F5F5F5' }}
      >
        <div
          style={{
            // cursor: 'pointer',
            backgroundColor: '#F5F5F5',
            textAlign: 'center',
          }}
          className={styles['empty_text']}
          // onClick={() =>
          //   modal_func(prop_tables, prop_weekday, timetable.period)
          // }
        >
          {timetable.period}
        </div>
      </BaseCard>
    </>
  );
};

export default CardLectureInfo;
