import {
  selectAllMyGroupsCachever,
  selectManagingGroupCacheVer,
} from '@app/store/selectors';
import { postReportEventsPromise } from '@store/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getActiveManagingByGroupPromise,
  getManagingsByGroupPromise,
} from '@app/store/actions';
import { useInterval } from './utils';
import { createReportPromise } from '@store/actions';

const ReportManager = () => {
  //chromeExtensionId
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  const user = useSelector((state) => state.user);
  const reports = useSelector((state) => state.reportEvents.reports);
  const dispatch = useDispatch();

  // main logic: timer task
  useInterval(() => {
    let reportData = 'todo: update this value';
    let uploadReportData = {
      clientId: user.clientId,
      osType: 'WINDOWS',
      reports: [],
    };
    // console.log(getOS());
    try {
      chrome.runtime.sendMessage(
        chromeExtensionId,
        { type: 'UPDATE_REPORT', data: reportData },
        function(response) {
          let keys = Object.keys(response);
          keys.map((tabId) => {
            // console.log('[ReportManager]: ', response[tabId]);
            uploadReportData.reports.push({
              clientId: user.clientId,
              osType: 'Windows',
              content: response[tabId],
              status: 'ETC',
              reportedAt: new Date().toISOString(),
            });
          });

          dispatch(createReportPromise(uploadReportData));
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, 5000);

  useInterval(() => {
    if (reports.length > 0) {
      dispatch(postReportEventsPromise(reports));
    }
  }, 30000);

  // getOS
  const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };
  return <div />;
};

export default ReportManager;
