import { createSelector } from 'reselect';

export const selectManaging = (state) => {
  return state.managing[state.managing.length - 1];
};

export const selectActiveManaging = createSelector(
  (state) => state.managing?.managingSession,
  (managingSession) => {
    return managingSession;
  }
);

export const selectIsManagementSession = (state) => {
  return state.managing.isManagementSession;
};

// export const selectManagingGroupId = createSelector(
//   (state) => state.managing.groupId,
//   (groupId) => {
//     return groupId;
//   }
// );

export const selectAllManaging = (state) => {
  if (state.managing?.allIds.length === 0) {
    return [];
  }
  return state.managing?.allIds
    .filter((id) => id !== null)
    ?.filter(
      (managingId) => state.managing?.byId[managingId].state !== 'DELETED'
    )
    .map((groupId) => state.managing.byId[groupId]);
};

export const selectManagingByGroupId = (state, groupId) => {
  if (state.managing?.allIds.length === 0) return null;

  return selectAllManaging(state).filter((group) => group?.groupId === groupId);
};
