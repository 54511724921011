import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root:before": {
      border: "none",
    },
  }
}));

const MdSelect = (props) => {
  const {
    width,
  } = props;

  const classes = useStyles();

  return (
    <FormControl
      variant="filled"
      sx={{ width }}
      className={classes.root}
    >
      <InputLabel
        id={props.labelId}
      >{props.label}</InputLabel>
      <Select
        {...props}
      >
        {props.children}
      </Select>
    </FormControl>
  )
};

export default MdSelect;