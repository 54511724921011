import { getQuizsetByQuizsetId } from '@api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useQuizset = (quizsetId) => {
  const { data: quizset } = useQuery({
    queryKey: ['quizsetQuizzes', quizsetId],
    queryFn: () => getQuizsetByQuizsetId(quizsetId),
    enabled: !!quizsetId,
    select: ({ data }) => ({
      ...data,
      quizzes: JSON.parse(data.quizzes),
    }),
  });

  const quizTotalTime = useMemo(
    () => quizset?.quizzes?.reduce((prev, curr) => prev + Number(curr.timelimit), 0),
    [quizset?.quizzes]
  );
  return {
    quizset,
    quizTotalTime
  };
};
