import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import QuizEyetrackerInit from "./quiz-eyetracker-init";

const QuizReadyCalib = ({ onSuccess }) => {
  const { groupId } = useParams();
  const [isCalibration, doCalibration] = useState(false);

  return (
    <Container>
      {!isCalibration ? (
        <Button onClick={() => doCalibration(true)}>눈운동 시작하기</Button>
      ) : (
        <QuizEyetrackerInit groupId={groupId} onSuccess={onSuccess} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default QuizReadyCalib;
