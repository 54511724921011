import {
  PURGE_QUESTION,

  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,

  GET_QUESTION_CONTENT,
  GET_QUESTION_CONTENT_SUCCESS,
  GET_QUESTION_CONTENT_FAIL,

} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeQuestion = () => ({
  type: PURGE_QUESTION,
})

///// GET_QUESTIONS
export const getQuestions = ({questionIds=null, subject=null}) => ({
  type: GET_QUESTIONS,
  questionIds: questionIds,
  subject: subject,
});

export const getQuestionsSuccess = (questions) => {
  let newQuestionById = {};
  questions.forEach(question => {
    newQuestionById[question.questionId] = question; 
  });
  const newQuestionAllIds = questions.map(question => question.questionId);
  return {
    type: GET_QUESTIONS_SUCCESS,
    newById: newQuestionById,
    newAllIds: newQuestionAllIds,
  };
}

export const getQuestionsFail = (error) => ({
  type: GET_QUESTIONS_FAIL,
  payload: error,
});


///// GET_QUESTION_CONTENT
export const getQuestionContent = (question) => ({
  type: GET_QUESTION_CONTENT,
  question: question,
});

export const getQuestionContentSuccess = (questionId, content) => ({
  type: GET_QUESTION_CONTENT_SUCCESS,
  questionId: questionId,
  content: content
})

export const getQuestionContentFail = (error) => ({
  type: GET_QUESTION_CONTENT_FAIL,
  payload: error,
});