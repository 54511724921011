export const PURGE_SCHOOL = 'PURGE_SCHOOL';

export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_FAIL = 'GET_SCHOOL_FAIL';

export const GET_SCHOOL_BY_INVITE_CODE_SUCCESS = 'GET_SCHOOL_BY_INVITE_CODE_SUCCESS';
export const GET_SCHOOL_BY_INVITE_CODE_FAIL = 'GET_SCHOOL_BY_INVITE_CODE_FAIL';

export const GET_FILTERED_SCHOOLS = 'GET_FILTERED_SCHOOLS';
export const GET_FILTERED_SCHOOLS_SUCCESS = 'GET_FILTERED_SCHOOLS_SUCCESS';
export const GET_FILTERED_SCHOOLS_FAIL = 'GET_FILTERED_SCHOOLS_FAIL';

export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS';
export const GET_ALL_SCHOOLS_SUCCESS = 'GET_ALL_SCHOOLS_SUCCESS';
export const GET_ALL_SCHOOLS_FAIL = 'GET_ALL_SCHOOLS_FAIL';
