export const PURGE_CLIENT = 'PURGE_CLIENT';
export const PURGE_CLIENT_TEMP = 'PURGE_CLIENT_TEMP';

export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';

export const GET_CLIENT_TEMP = 'GET_CLIENT_TEMP';
export const GET_CLIENT_TEMP_SUCCESS = 'GET_CLIENT_TEMP_SUCCESS';
export const GET_CLIENT_TEMP_FAIL = 'GET_CLIENT_TEMP_FAIL';

export const FIND_CLIENT = 'FIND_CLIENT';
export const FIND_CLIENT_SUCCESS = 'FIND_CLIENT_SUCCESS';
export const FIND_CLIENT_FAIL = 'FIND_CLIENT_FAIL';

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';

export const SET_PRIMARY_GROUP = 'SET_PRIMARY_GROUP';
// export const SET_PRIMARY_GROUP_SUCCESS = 'SET_PRIMARY_GROUP_SUCCESS';
export const SET_PRIMARY_GROUP_FAIL = 'SET_PRIMARY_GROUP_FAIL';