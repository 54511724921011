import { makeFreshAllIds } from '../helpers';
import {
  PURGE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_REPORT:
      return initialState;

    // CREATE REPORT
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };
      
    case CREATE_REPORT_FAIL:
      console.error('[Report reducer]', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default reports;