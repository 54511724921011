// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnabledApps_container__EqVSm {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  width: calc(min(520px, 100%));\n  margin-top: 7px;\n  margin-bottom: -13px; }\n", "",{"version":3,"sources":["webpack://./src/components/ClassRoom/EnabledApps/EnabledApps.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,6BAA6B;EAE7B,eAAe;EACf,oBAAoB,EAAA","sourcesContent":[".container{\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-wrap: wrap;\r\n  width: calc(min(520px, 100%));\r\n\r\n  margin-top: 7px;\r\n  margin-bottom: -13px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EnabledApps_container__EqVSm"
};
export default ___CSS_LOADER_EXPORT___;
