import React, { memo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveClassroom } from '@store/selectors';
// import { deleteFileLink } from "@store/actions";
import { selectGroupFileLinks } from '@store/selectors';
import styles from './CardFileLink.module.scss';
import imgNotiIcon from '@images/home/img_notice.png';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import BaseCard from './baseCard';

const getFaviconImageUrl = (link) => {
  return `https://www.google.com/s2/favicons?sz=64&domain_url=${link}`;
};

const CardFileLink = ({ filelink }) => {
  const onClick = () => {
    try {
      window.cAPI.openUrl(filelink.link);
    } catch (error) {
      window.open(filelink.link);
    }
  };

  // console.log('filelink', filelink);

  return (
    <>
      <div
        name="CardFileLink"
        className={styles['link-list']}
        onClick={onClick}
      >
        <div className={styles['date']}>
          {moment(filelink.createdAt).format('YYYY.MM.DD')}
        </div>
        <BaseCard
          radius={4}
          className={styles['card-wrapper']}
          shadow="0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
        >
          <div className={styles['icon-wrap']}>
            <img
              className={styles['icon']}
              src={getFaviconImageUrl(filelink.link)}
              alt="icon"
            />
            <div>
              <div className={styles['title']}>
                {filelink.title ?? '제목없음'}
              </div>
              <div className={styles['link']}>{filelink.link}</div>
            </div>
          </div>
        </BaseCard>
      </div>
    </>
  );
};

export default memo(CardFileLink);
