import { call, put, takeLeading } from 'redux-saga/effects';
import {
    getQuizresponsePromise,
    getQuizresponseSuccess,
    getQuizresponseFail,
    createQuizresponsePromise,
    createQuizresponseSuccess,
    createQuizresponseFail,
    deleteQuizresponsePromise,
    deleteQuizresponseSuccess,
    deleteQuizresponseFail,
    updateQuizresponsePromise,
    updateQuizresponseSuccess,
    updateQuizresponseFail
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getQuizresponsePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizresponseByQuizsetSessionId, action.payload);
      yield put(getQuizresponseSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getQuizresponseFail(error));
    }
  })
};

function* createQuizresponsePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizresponse, action.payload);
      yield put(createQuizresponseSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizresponseFail(error));
    }
  });
}

function* updateQuizresponsePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateQuizresponse, action.payload);
      yield put(updateQuizresponseSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateQuizresponseFail(error));
    }
  });
}


function* deleteQuizresponsePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteQuizresponse, action.payload);
      yield put(deleteQuizresponseSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteQuizresponseFail(error));
    }
  });
}

function* quizresponseSaga() {
  yield takeLeading(getQuizresponsePromise, getQuizresponsePromiseHandler);
  yield takeLeading(createQuizresponsePromise, createQuizresponsePromiseHandler);
  yield takeLeading(updateQuizresponsePromise, updateQuizresponsePromiseHandler)
  yield takeLeading(deleteQuizresponsePromise, deleteQuizresponsePromiseHandler);
}

export default quizresponseSaga;
