import { memo } from "react";
// 안써도 자동으로 한국 시간을 불러온다. 명확하게 하기 위해 import
import "moment/locale/ko";
import styles from "./ClassInfo.module.scss";

const ClassInfo = ({ groupInfo, classTime }) => {
  const weekData = classTime?.weekday;
  // console.log('[ClassInfo]', weekData);
  const weekDay = (weekData) => {
    switch (weekData) {
      case 1:
        return '월';
      case 2:
        return '화';
      case 3:
        return '수';
      case 4:
        return '목';
      case 5:
        return '금';
      default:
        return '';
    }
  }

  return (
    <div
      className={styles["header"]}
      style={{ marginBottom: 16 }}
    >
      <div className={styles["header-left"]}>
        <div className={styles["header-subject"]}>{groupInfo?.subjectName}</div>
        <div className={styles["timeline-wrapper"]}>
          <span>{weekDay(weekData)}요일</span>
          <span>{classTime?.period}교시</span>
          <span 
            // className={styles["timeline-time"]}
          >{classTime?.startTime + " ~ " + classTime?.endTime}</span>
        </div>
        {/* <div className={styles["header-class-students"]}>
          <PeopleAltIcon
            style={{
              width: 20,
              height: 20,
              color: '#424242',
              marginRight: 5,
            }}
          />
          총원 {groupInfo?.students?.length ?? '0'}명
        </div> */}
        {/* NEWTODO: 월요일~~ 카드 생성 */}
        {/* { timeTable &&
          <div className={styles["header-class-info"]}>
            {weekDayToKorean(timeTable.weekDay)} {timeTable.period}교시
            <span className={styles["header-class-info-time"]}>
              {`${timeTable.startTime?.slice(0, timeTable.startTime.length - 3)} ~ ${timeTable.endTime.slice(0, timeTable.endTime.length - 3)}`}
            </span>
          </div>
        } */}
      </div>
    </div>
  );
};

export default memo(ClassInfo);

