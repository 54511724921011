import React from 'react';
import styles from './index.module.scss';

const ScreenLock = () => {
    return (
        <div className={styles['container']}>
            선생님이 화면을 잠갔습니다
        </div>
    );
};

export default ScreenLock;
