import { getQuizChoiceByQuizId } from '@api';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Column } from '@pages/Survey/common';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

/**다지선다 */
export const MultipleChoices = ({
  title,
  quizId,
  quizSubjectm,
  submit,
  handleSubmit,
  link,
}) => {
  const [value, setValue] = useState();

  const { data } = useQuery({
    queryKey: ['surveyChoice', quizId],
    queryFn: () => getQuizChoiceByQuizId(quizId),
  });

  useEffect(() => {
    if (submit) {
      handleSubmit(value, quizId);
    }
  }, [submit, handleSubmit, value, quizId]);

  if (!data) return <div>로딩중...</div>;

  const choice = data?.data.choices;
  return (
    <Column>
      <ReactQuill
        value={title}
        readOnly
        theme="bubble"
        style={{
          width: '100%',
        }}
      />
      {link && link !== 'NotSet' ? (
        <img
          crossOrigin="anonymous"
          src={link}
          alt="quiz-image"
          style={{
            width: '50%',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        />
      ) : null}

      <RadioGroup
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          marginLeft: '80px',
        }}
        value={value}
      >
        {choice?.map((data, index) => (
          <FormControlLabel
            key={index}
            value={data.choiceId}
            control={<Radio />}
            label={data.description}
            labelPlacement="end"
            onChange={(e) => setValue(data.choiceId)}
            sx={{ marginTop: '30px', fontSize: '16px' }}
          />
        ))}
      </RadioGroup>
    </Column>
  );
};
