import React, { useEffect, useMemo, useRef, useState } from 'react';
// import { SpinnerCountdown } from '@app/pages/QuizPang/spinner-countdown'
import styled from 'styled-components';
// import events from '@app/events'
import { useDispatch, useSelector } from 'react-redux';
import { createQuizresponsePromise } from '@app/store/quizresponse/actions';
import {
  QuizInput,
  QuizChoice,
  QuizOX,
  QuizLongInput,
  QuizStylusWrite,
} from '.';
import { Spinner } from '../spinner-countdown';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import getYoutubeId from 'get-youtube-id';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
//import QuizAccelGyroLogger from './quiz-accelgyro-logger.js';
import QuizAccelGyroLogger from '../quiz-accel-gyro-logger-buffer.js';
//import QuizGPSLogger from './quiz-gps-logger.js';
import QuizGPSLogger from '../quiz-gps-logger-buffer.js';
//import QuizTouchLogger from './quiz-touch-logger-container'
import ArchiveModule from '../data-archive.js';
import QuizTouchLoggerContainer from '../quiz-touch-logger-container';
import QuizAudioLogger from '../quiz-audio-logger';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import QuizEyetrackingLogger from '../quiz-eyetracking-logger';
import { useHistory } from 'react-router-dom';
import {
  notificationKind,
  useSystemNotification,
} from 'hooks/useSystemNotification';
import { getUserAgent } from '../utils';
import { useQuiz, useQuizset, useQuizsetSession } from '../hooks';
import Loading from '@components/Loading/Loading';
import { imageToFile, uploadQuizStylusWrite } from '@api';
import { QuizStylusWriteTldrawer } from './quiz-stylus-write-tldrawer';

const sessionDeviceType = getUserAgent();

export const QuizSession = () => {
  const dispatch = useDispatch();
  const { groupId, quizsetSessionId } = useParams();
  const user = useSelector((state) => state.user);

  const {
    quizsetSession,
    quizOrder,
    isLoading,
    refetch,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  } = useQuizsetSession(groupId);

  const { quizset } = useQuizset(quizsetSession?.quizsetId);
  const { quiz } = useQuiz(quizset?.quizzes?.[quizOrder].quizId);

  const [isSpinner, setIsSpinner] = useState(false);
  const [textAnswer, setTextAnswer] = useState(null);
  const [choiceId, setChoiceId] = useState(null);
  const [stylusImage, setStylusImage] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [gpsData, setGpsData] = useState(null);
  const [accelgyroData, setAceelgyroData] = useState(null);
  const [sessionTouchData, setSessionTouchData] = useState([]);
  const [sessionKeyboardData, setSessionKeyboardData] = useState([]);
  const [sessionStylusData, setSessionStylusData] = useState([]);
  const [isStartRecording, setIsStartRecording] = useState(true);
  const [voiceData, setVoiceData] = useState(null);
  const [eyetrackingData, setEyeTrackingData] = useState(null);
  const [sessionDeviceInfo, setSessionDeviceInfo] = useState('');
  const [quizEnded, setQuizEnded] = useState(false);
  const [input, setInput] = useState('');
  const history = useHistory();
  const containerRef = useRef(null);
  const { onSendMessage } = useSystemNotification();
  const quizSessionType = 'QUIZ';

  // WS_QUIZ_SUMMARY event handler
  const eventHandler = useRef(() => {});

  const quizEndStylusData = () => {
    if (sessionTouchData.length > 0) {
      let mb = JSON.stringify(sessionTouchData).length / 1024 / 1024;
      mb = Math.round(mb * 10000) / 10000;
      let kb = JSON.stringify(sessionTouchData).length / 1024;
      kb = Math.round(kb * 10000) / 10000;
      //console.log(`${sessionTouchData.length} rows, ${kb}KB, ${mb}MB`)
      // Activate this to send touch data
      // data.touchData = sessionTouchData;
    }
    if (sessionStylusData.length > 0) {
      let mb = JSON.stringify(sessionStylusData).length / 1024 / 1024;
      mb = Math.round(mb * 10000) / 10000;
      let kb = JSON.stringify(sessionStylusData).length / 1024;
      kb = Math.round(kb * 10000) / 10000;
      //console.log(`${sessionStylusData.length} rows, ${kb}KB, ${mb}MB`)
      // Activate this to send stylus data
      // data.stylusData = sessionStylusData;
    }
    if (sessionDeviceType !== '') {
      // data.deviceType = sessionDeviceType;
    }
    if (sessionDeviceInfo !== '') {
      // data.deviceInfo = sessionDeviceInfo;
    }
  };

  const onTimeEnd = async () => {
    setQuizEnded(true);

    const data = {};
    if (choiceId !== null) data.choiceId = choiceId;
    if (stylusImage !== null) {
      const file = imageToFile(stylusImage, user.clientId);
      const res = await uploadQuizStylusWrite(
        file,
        quizsetSessionId,
        quiz.quizId
      );
      console.log('🚀 ~ file: quiz-session.js:117 ~ onTimeEnd ~ res:', res);
      data.responseLink = res;
    }
    if (input !== null) data.responseText = input;

    if (input !== null || choiceId !== null || stylusImage !== null) {
      await dispatch(
        createQuizresponsePromise({
          clientId: user.clientId,
          quizsetSessionId,
          quizId: quiz.quizId,
          ...data,
        })
      );
    }
    onSendMessage({
      notificationKind: notificationKind.QUIZ_STUDENT_COMPLETED,
    });

    setTimeout(() => {
      clearQuizTime();
      setQuizEnded(false);
      history.replace(`/quizpang/${groupId}/result/${quizsetSessionId}`);
    }, 3000);
  };

  useEffect(() => {
    setIsSpinner(!isLoading && quizOrder === 0 && !storageQuizTime);
  }, [isLoading, quizOrder, storageQuizTime]);

  useEffect(() => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const deviceInfo = `${screenWidth}x${screenHeight}`;
    setSessionDeviceInfo(deviceInfo);
  }, [refetch]);

  useEffect(() => {
    if (!isLoading && !quizsetSession) {
      history.replace(`/quizpang/${groupId}`);
    }
  }, [groupId, history, isLoading, quizsetSession]);

  useEffect(() => {
    const handler = (params) => eventHandler.current(params);
    global.eventEmitter.once('WS_QUIZ_SUMMARY', handler);
    return () => {
      global.eventEmitter.off('WS_QUIZ_SUMMARY', handler);
    };
  }, []);

  if (isSpinner) {
    return (
      <SpinnerContainer>
        <Spinner startSecond={5} onZero={() => setIsSpinner(false)} />
      </SpinnerContainer>
    );
  }
  if (!quizsetSession || !quizset || !quiz || isLoading) {
    return <SpinnerContainer />;
  }

  return (
    <QuizEyetrackingLogger
      name="QuizSessionLogger"
      quizType={quiz.quizType}
      quizIndex={quizOrder + 1}
      onEyeTrackingData={setEyeTrackingData}
      quizSessionType={quizSessionType}
    >
      <QuizGPSLogger
        name="QuizSessionLogger"
        quizType={quiz.quizType}
        onGpsData={setGpsData}
        quizSessionType={quizSessionType}
      >
        <QuizAccelGyroLogger
          name="QuizSessionLogger"
          quizType={quiz.quizType}
          onAccelgyroData={setAceelgyroData}
          quizSessionType={quizSessionType}
        >
          <QuizTouchLoggerContainer
            name="QuizSessionLogger"
            myRef={containerRef}
            touchData={sessionTouchData}
            setTouchData={setSessionTouchData}
            quizType={quiz.quizType}
            deviceType={sessionDeviceType}
            quizSessionType={quizSessionType}
          >
            <QuizAudioLogger
              name="QuizSessionLogger"
              quizType={quiz.quizType}
              isStartRecording={isStartRecording}
              onVoiceData={setVoiceData}
              clientId={user.clientId}
              quizId={quiz.quizId}
              quizSessionType={quizSessionType}
            >
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  padding: '20px',
                }}
              >
                <Column
                  style={{
                    width: 128,
                    color: '##000000C4',
                    fontSize: 24,
                    fontWeight: 500,
                    alignItems: 'center',
                  }}
                >
                  {quizOrder + 1}/{quizset.quizzes.length}
                  {[1, 2, 3].includes(quiz.quizLevel) && (
                    <div style={{ fontSize: 14, color: '#757575' }}>
                      난이도: {['쉬움', '보통', '어려움'][quiz.quizLevel - 1]}
                    </div>
                  )}
                </Column>
                <Column
                  dangerouslySetInnerHTML={{ __html: quiz.title }}
                  style={{
                    color: '##000000C4',
                    fontSize: 24,
                    fontWeight: 500,
                    width: 700,
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                  }}
                />
                <Column
                  style={{
                    width: 128,
                    alignItems: 'flex-end',
                    color: '##000000C4',
                    fontSize: 24,
                    fontWeight: 500,
                  }}
                >
                  <Spinner
                    isSmall
                    saveQuizTime={saveQuizTime}
                    startSecond={storageQuizTime?.second ?? quiz.timelimit}
                    onZero={onTimeEnd}
                  />
                </Column>
              </Row>
              <Column>
                {!['NotSet', ''].includes(quiz.link) && (
                  <>
                    <ImageContainer>
                      {quiz.linkType === 'YOUTUBE' ? (
                        <LiteYouTubeEmbed
                          id={getYoutubeId(quiz.link)}
                          title="video"
                        />
                      ) : (
                        <img
                          src={quiz.link}
                          style={{
                            width: 480,
                            height: 'auto',
                            objectFit: 'contain',
                          }}
                          alt="quizImage"
                          lazy
                        />
                      )}
                    </ImageContainer>
                  </>
                )}
                {quiz.quizType === 'OX' && quiz.choices && (
                  <QuizOX
                    answerBoolean={
                      choiceId === null
                        ? null
                        : choiceId === quiz.choices[0].choiceId
                    }
                    setAnswerBoolean={(v) => {
                      setChoiceId(quiz.choices[v ? 0 : 1].choiceId);
                    }}
                    setClicked={setClicked}
                    clicked={clicked}
                  />
                )}
                {quiz.quizType === 'STYLUS' && (
                  // <QuizStylusWriteTldrawer
                  //   quizsetSessionId={quizsetSessionId}
                  //   quizId={quiz.quizId}
                  //   quizTimelimit={quiz.timelimit}
                  //   stylusImage={stylusImage}
                  //   setStylusImage={setStylusImage}
                  //   containerRef={containerRef}
                  //   stylusData={sessionStylusData}
                  //   setStylusData={setSessionStylusData}
                  //   deviceType={sessionDeviceType}
                  //   quizEnded={quizEnded}
                  // />
                  <QuizStylusWrite
                    quizsetSessionId={quizsetSessionId}
                    quizId={quiz.quizId}
                    quizTimelimit={quiz.timelimit}
                    stylusImage={stylusImage}
                    setStylusImage={setStylusImage}
                    containerRef={containerRef}
                    stylusData={sessionStylusData}
                    setStylusData={setSessionStylusData}
                    deviceType={sessionDeviceType}
                    quizEnded={quizEnded}
                  />
                )}
                {quiz.quizType === 'MULTIPLE' &&
                  quiz.choices?.map((c, index) => {
                    return (
                      <QuizChoice
                        key={index}
                        index={index + 1}
                        option={c.description}
                        isSelected={choiceId === c.choiceId}
                        onPress={() => setChoiceId(c.choiceId)}
                        setClicked={setClicked}
                        clicked={clicked}
                      />
                    );
                  })}
                {quiz.quizType === 'SHORT' && (
                  <QuizInput
                    setShortResult={textAnswer}
                    quizTimelimit={quiz.timelimit}
                    quizId={quiz.quizId}
                    keyboardData={sessionKeyboardData}
                    setKeyboardData={setSessionKeyboardData}
                    deviceType={sessionDeviceType}
                    input={input}
                    setInput={setInput}
                  />
                )}
                {quiz.quizType === 'LONG' && (
                  <QuizLongInput
                    setLongResult={textAnswer}
                    quizTimelimit={quiz.timelimit}
                    quizId={quiz.quizId}
                    keyboardData={sessionKeyboardData}
                    setKeyboardData={setSessionKeyboardData}
                    deviceType={sessionDeviceType}
                    input={input}
                    setInput={setInput}
                  />
                )}
                <div style={{ height: 20 }} />
              </Column>
              <ArchiveModule
                clientId={user.clientId}
                quizId={quiz.quizId}
                quizEnded={quizEnded}
                quizTimelimit={quiz.timelimit}
                gpsData={gpsData}
                accelgyroData={accelgyroData}
                touchData={sessionTouchData}
                keyboardData={sessionKeyboardData}
                stylusData={sessionStylusData}
                voiceData={voiceData}
                eyetrackingData={eyetrackingData}
              />
              {quizEnded && <Loading />}
            </QuizAudioLogger>
          </QuizTouchLoggerContainer>
        </QuizAccelGyroLogger>
      </QuizGPSLogger>
    </QuizEyetrackingLogger>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  border-radius: 8px;
  width: 480px;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
  align-self: center;

  position: relative;
  overflow: hidden;
`;
