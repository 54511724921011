import { makeFreshAllIds } from "../helpers";

import {
  PURGE_ACCOUNT,

  REGISTER_TEACHER_SUCCESS,
  REGISTER_TEACHER_FAIL,

} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_ACCOUNT:
      return initialState;
    
    case REGISTER_TEACHER_SUCCESS:
        return {
          ...state,
          byId: { ...state.byId, [action.accountData.account.accountId]: action.accountData },
          allIds: makeFreshAllIds(state.allIds, [action.accountData.account.accountId]),
        };
    case REGISTER_TEACHER_FAIL:
        console.error('[Account Reducer] ', action.error);
        return state;

    default:
      break;
  }
  return state;
}

export default accounts;