import {
    PURGE_QUIZSET,
    PURGE_OTHER_SCHOOL_QUIZSET,
    GET_QUIZSET_BY_QUIZSETID_SUCCESS,
    GET_QUIZSET_BY_QUIZSETID_FAIL,
    GET_QUIZSET_LIST_BY_CLIENT_SUCCESS,
    GET_QUIZSET_LIST_BY_CLIENT_FAIL,
    GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS,
    GET_QUIZSET_LIST_BY_SCHOOL_FAIL,
    CREATE_QUIZSET_SUCCESS,
    CREATE_QUIZSET_FAIL,
    UPDATE_QUIZSET_SUCCESS,
    UPDATE_QUIZSET_FAIL,
    DELETE_QUIZSET_SUCCESS,
    DELETE_QUIZSET_FAIL,
  } from './actionTypes';
  import { createPromiseAction } from '@adobe/redux-saga-promise';
  
  export const purgeQuizset = () => ({
    type: PURGE_QUIZSET,
  });
  export const purgeOtherSchoolQuizset = () => ({
    type: PURGE_OTHER_SCHOOL_QUIZSET,
  });

  //quizset by quizsetId
  export const getQuizsetByQuizsetIdPromise = createPromiseAction('GET_QUIZSET_BY_QUIZSETID_PROMISE');
  export const getQuizsetByQuizsetIdSuccess = (quizsetData) => {
    return {
      type: GET_QUIZSET_BY_QUIZSETID_SUCCESS,
      quizsetData,
    }
  };
  export const getQuizsetByQuizsetIdFail = (error) => ({
    type: GET_QUIZSET_BY_QUIZSETID_FAIL,
    payload: error,
  });

  // quizset list by client
  export const getQuizsetListByClientPromise = createPromiseAction('GET_QUIZSET_LIST_BY_CLIENT_PROMISE');
  export const getQuizsetListByClientSuccess = (quizsetListData) => {
    let byId = {};
    quizsetListData.forEach((quizsetData) => {
      byId[quizsetData.quizsetId] = quizsetData;
    });
    const allIds = quizsetListData.map((quizsetData) => quizsetData.quizsetId);
    return {
      type: GET_QUIZSET_LIST_BY_CLIENT_SUCCESS,
      byId: byId,
      allIds: allIds,
    };
  };
  export const getQuizsetListByClientFail = (error) => ({
    type: GET_QUIZSET_LIST_BY_CLIENT_FAIL,
    payload: error,
  });
  
  // quizset list by school
  export const getQuizsetListBySchoolPromise = createPromiseAction('GET_QUIZSET_LIST_BY_SCHOOL_PROMISE');
  export const getQuizsetListBySchoolSuccess = (quizsetListData) => {
    let byId = {};
    quizsetListData.forEach((quizsetData) => {
      byId[quizsetData.quizsetId] = quizsetData;
    });
    const allIds = quizsetListData.map((quizsetData) => quizsetData.quizsetId);
    return {
      type: GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS,
      otherSchoolById: byId,
      otherSchoolAllIds: allIds,
    };
  };
  export const getQuizsetListBySchoolFail = (error) => ({
    type: GET_QUIZSET_LIST_BY_SCHOOL_FAIL,
    payload: error,
  });
  
  // create quizset
  export const createQuizsetPromise = createPromiseAction('CREATE_QUIZSET_PROMISE');
  export const createQuizsetSuccess = (quizsetData) => ({
    type: CREATE_QUIZSET_SUCCESS,
    quizset: quizsetData,
  });
  export const createQuizsetFail = (error) => ({
    type: CREATE_QUIZSET_FAIL,
    payload: error,
  });
  
  // update quizset
  export const updateQuizsetPromise = createPromiseAction('UPDATE_QUIZSET_PROMISE');
  export const updateQuizsetSuccess = (quizsetData) => ({
    type: UPDATE_QUIZSET_SUCCESS,
    quizset: quizsetData,
  });
  export const updateQuizsetFail = (error) => ({
    type: UPDATE_QUIZSET_FAIL,
    payload: error,
  });
  
  // delete quizset
  export const deleteQuizsetPromise = createPromiseAction('DELETE_QUIZSET_PROMISE');
  export const deleteQuizsetSuccess = (quizsetId) => ({
    type: DELETE_QUIZSET_SUCCESS,
    quizsetId,
  });
  export const deleteQuizsetFail = (error) => ({
    type: DELETE_QUIZSET_FAIL,
    payload: error,
  });
  