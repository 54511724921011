import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './band-assignment.module.scss';
import PageWrapper from '@components/Layout/PageWrapper';
import SidebarClassRoom from '@components/Layout/SidebarClassRoom';
import BaseCard from '@components/Card/baseCard';
import Select from 'react-select';
import { setSocketData, getGroupFilelinkPromise } from '@app/store/actions';
import { profileImgPath } from '@api';
import imgHomeIcon from '@images/home/ic-home-24.svg';
import ProfileImage from '@components/ProfileImage';
import { useParams } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  selectGroup,
  selectActiveClassroom,
  selectAllClients,
  selectGroupActivity,
  selectSubroupActivity,
} from '@store/selectors';
import SharedScreenFull from '@components/Modal/SharedScreenFull';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {
  deleteClassroomPromise,
  getActiveClassroomPromise,
  getGroupActivityPromise,
  getSubgroupActivityPromise,
} from '@store/actions';
import { ButtonBase } from '@mui/material';
import styled from 'styled-components';

const SECONDS = 1000;
const MINUTES = 60;

const BandAssignment = () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const {
    selectedStd,
    selectedClassId,
    teacherScreen,
    studentImage,
  } = useSelector((state) => state.control);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const clients = useSelector(selectAllClients);
  const selectedGroup = useSelector((state) =>
    selectGroup(state, selectedClassId)
  );
  const user = useSelector((state) => state.user);
  const activityList = useSelector(selectGroupActivity);
  const subgroup = useSelector(selectSubroupActivity);

  const [seconds, setSeconds] = useState(0);
  const [selectedActivity, setSelectedAcitivity] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [currentStudent, setCurrentStudent] = useState();
  const [gridSize, setGridSize] = useState(3);

  const findClientName = useCallback((clientId) => {
    if (!clientId) return '';

    const foundClient = clients.find((client) => client.clientId === clientId);
    if (foundClient !== null) return foundClient?.userName;

    return '';
  }, []);

  const convertTimerToString = useCallback((time) => {
    const minute = Math.floor(time / MINUTES);
    const second = Math.floor(time) - minute * MINUTES;
    return `${minute}:${second.toString().padStart(2, '0')}`;
  }, []);

  useEffect(() => {
    dispatch(getGroupFilelinkPromise(groupId));
  }, [dispatch, groupId]);

  useEffect(() => {
    if (activityList.length && !selectedActivity) {
      setSelectedAcitivity(activityList[0]);
    }
  }, [activityList, selectedActivity]);

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupActivityPromise(groupId));
    }
  }, [groupId, dispatch]);

  useEffect(() => {
    if (selectedActivity) {
      dispatch(getSubgroupActivityPromise(selectedActivity.subgroupActivityId));
    }
  }, [dispatch, selectedActivity]);

  useEffect(() => {
    if (!activeClassroom) {
      return;
    }

    const timer = setInterval(() => {
      const diff = (new Date(activeClassroom.finishAt) - new Date()) / SECONDS;
      const diffSecond = parseInt(diff);
      if (diffSecond <= 0) {
        dispatch(deleteClassroomPromise(activeClassroom?.classroomId)).then(
          () => {
            dispatch(
              setSocketData({
                method: 'POST',
                uri: '/classroom/sendImage',
                groupId: activeClassroom?.groupId,
                clientId: user.clientId,
                type: 'CLASS_END',
                data: '',
              })
            );
            dispatch(getActiveClassroomPromise(user.clientId));
          }
        );
      }
      setSeconds(parseInt(Math.abs(diff)));
    }, 1000);
    return () => clearInterval(timer);
  }, [activeClassroom, dispatch, user.clientId]);

  return (
    <>
      <PageWrapper>
        <SidebarClassRoom
          groupId={groupId}
          subject={selectedGroup?.subjectName}
          active={'band'}
          inClass
        />
        <div className={styles['wrapper']}>
          <BaseCard
            radius={8}
            style={{ marginBottom: 8, padding: 16, width: '100%' }}
          >
            <div className={styles['header']} style={{ marginBottom: 0 }}>
              <div className={styles['header-left']}>
                <div className={styles['header-page-name']}>모둠 활동</div>
                {!!activityList.length && (
                  <Select
                    value={{
                      label: selectedActivity?.name,
                      value: selectedActivity?.subgroupActivityId,
                    }}
                    isSearchable={false}
                    options={activityList.map((item) => ({
                      label: item.name,
                      value: item.subgroupActivityId,
                    }))}
                    onChange={(e) =>
                      setSelectedAcitivity(
                        activityList.find(
                          (item) => item.subgroupActivityId === e.value
                        )
                      )
                    }
                    isClearable={false}
                  />
                )}
              </div>
            </div>

            <div className={styles['body-container']}>
              <div className={styles['teacher-screen-wrap']}>
                {teacherScreen ? (
                  <img
                    src={`data:image/webp;base64,${teacherScreen}`}
                    alt="screenshot"
                    className={styles['teacher-screen']}
                  />
                ) : (
                  <div>선생님이 공유해 주시는 화면이 보이는 곳입니다</div>
                )}
                <div className={styles['show-fullscreen-btn']}>
                  <div className={styles['profile-wrapper']}>
                    <ProfileImage size={28} url={profileImgPath(selectedStd)} />
                    <div className={styles['name']}>
                      {findClientName(selectedStd)}
                    </div>
                    <TimerOutlinedIcon className={styles['timer-icon']} />
                    <div>{convertTimerToString(seconds)}</div>
                  </div>
                  <button
                    onClick={() => {
                      try {
                        window.cAPI.setTopMost(activeClassroom);
                      } catch (error) {
                        // this is not error calling browser
                      }
                      setModalShow(true);
                    }}
                  >
                    <FullscreenIcon className={styles['fullscreen-icon']} />
                    <div className={styles['fullscreen-btn-text']}>
                      전체 화면 보기
                    </div>
                  </button>
                </div>

                <SharedScreenFull
                  show={modalShow}
                  setShow={setModalShow}
                  screenImg={teacherScreen}
                  name={findClientName(selectedStd)}
                  timeString={convertTimerToString(seconds)}
                />
              </div>
            </div>

            {subgroup ? (
              <div className={styles['group-members']}>
                <div className={styles['group-name']}>
                  {subgroup?.name}
                  <StyledButton
                    onClick={() =>
                      setGridSize((prev) => (prev <= 1 ? 3 : prev - 1))
                    }
                  >
                    사이즈 변경
                  </StyledButton>
                </div>
                <StyledHorizontal $girdSize={gridSize}>
                  {subgroup?.students.map((s, idx) => {
                    if (s.clientId === user.clientId) {
                      return null;
                    }
                    const imageKey = studentImage[s.clientId];
                    const isOnline =
                      imageKey !== undefined && imageKey !== false;
                    return (
                      <button
                        className={styles['student-screen']}
                        type="button"
                        style={{
                          border: `solid 1px ${
                            s.clientId === currentStudent
                              ? '#40C3D1'
                              : 'transparent'
                          }`,
                        }}
                        onClick={() => {
                          setCurrentStudent(s.clientId);
                        }}
                      >
                        <div className={styles['student-screen-box']}>
                          {activeClassroom && isOnline ? (
                            <ButtonBase onClick={() => {}}>
                              <img
                                src={`data:image/webp;base64,${imageKey}`}
                                alt={s.clientId}
                                // onClick={() => setBigURI(images[imageKey])}
                              />
                            </ButtonBase>
                          ) : (
                            <div>
                              <div className={styles['screenEmptyBox']} />
                            </div>
                          )}
                        </div>
                        {/* {activeClassroom && isOnline && (
                          <div className={styles["online-mark"]}>
                            화면 공유 중
                          </div>
                        )} */}
                        <div
                          className={styles['student-statusbar']}
                          style={
                            isOnline
                              ? { background: '#056B75', opacity: 0.8 }
                              : {}
                          }
                        >
                          {/* <div className={styles[isOnline ? "status-circle" : "status-circle-gray"]} /> */}
                          <div className={styles['student-statusbar-left']}>
                            <ProfileImage
                              size={28}
                              url={profileImgPath(s.clientId)}
                            />
                            <span className={styles['text']}>{s.userName}</span>
                            <img src={imgHomeIcon} alt="home" width={18} />
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </StyledHorizontal>
              </div>
            ) : !!activityList.length ? (
              <div className={styles['group-members']}>
                <div className={styles['group-name']}>
                  해당 모둠에 속해있지 않습니다.
                </div>
              </div>
            ) : (
              <div className={styles['group-members']}>
                <div className={styles['group-name']}>
                  모둠이 존재하지 않습니다.
                </div>
              </div>
            )}
          </BaseCard>
        </div>
      </PageWrapper>
    </>
  );
};

const StyledHorizontal = styled.div`
  width: 100%;
  display: grid;
  padding: 2px;
  grid-template-columns: repeat(${({ $girdSize }) => $girdSize}, 1fr);
  gap: 16px;
  overflow-x: scroll;
`;
const StyledButton = styled.button`
  margin-left: 10px;
  font-size: 11px;
  padding: 0px 20px;
  background-color: rgb(5, 134, 146);
  color: white;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
`;

export default BandAssignment;
