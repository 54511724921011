import { call, put, takeLeading } from 'redux-saga/effects'
import {
  getProgramPromise,
  getProgramSuccess,
  getProgramFail,

  getProgramsPromise,
  getProgramsSuccess,
  getProgramsFail,
} from '@store/actions'
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getProgramPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getProgram, action.payload.programId);
      yield put(getProgramSuccess(response.data));
      return yield response.data;
    } catch(error) {
      yield put(getProgramFail(error));
    }
  });
  
}

function* getProgramsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getPrograms)
      yield put(getProgramsSuccess(response.data.programs));
    } catch(error) {
      yield put(getProgramsFail(error));
    }
  });
}

function* programSaga() {
  yield takeLeading(getProgramPromise, getProgramPromiseHandler);
  yield takeLeading(getProgramsPromise, getProgramsPromiseHandler);
}

export default programSaga;
