import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  getClassroomPromise,
  getClassroomSuccess,
  getClassroomFail,
  
  getActiveClassroomPromise,
  getActiveClassroomSuccess,
  getActiveClassroomFail,
  
  getActiveClassroomByGroupPromise,
  getActiveClassroomByGroupSuccess,
  getActiveClassroomByGroupFail,

  setActiveClassroomExist,
  setActiveClassroomNotExist,

  deleteClassroomPromise,
  deleteClassroomSuccess,
  deleteClassroomFail,

} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';


function* getClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getClassroom, action.payload);
      yield put(getClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getClassroomFail(error));
    }
  });
}

function* getActiveClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getActiveClassroom, action.payload);
      if (response.data.classroom.state === 'IN_CLASS') {
        yield put(getActiveClassroomSuccess(response.data));
      }
      yield put(setActiveClassroomExist(response.data));
      return yield response.data.classroom;
    } catch (error) {
      yield put(getActiveClassroomFail(error));
      yield put(setActiveClassroomNotExist());
      return null;
    }
  });
}

function* getActiveClassroomByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const response = yield call(api.getActiveClassroomByGroup, action.payload);
      yield put(getActiveClassroomByGroupSuccess(response.data));
      yield put(setActiveClassroomExist(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getActiveClassroomByGroupFail(error));
      yield put(setActiveClassroomNotExist());
      return null;
    }
  });
}

function* deleteClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteClassroom, action.payload);
      yield put(deleteClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteClassroomFail(error));
    }
  });
}



function* classroomSaga() {
  yield takeLeading(getClassroomPromise, getClassroomPromiseHandler);
  yield takeLeading(
    getActiveClassroomPromise,
    getActiveClassroomPromiseHandler
  );
  yield takeEvery(getActiveClassroomByGroupPromise, getActiveClassroomByGroupPromiseHandler);
  yield takeLeading(deleteClassroomPromise, deleteClassroomPromiseHandler);
}

export default classroomSaga;
