import BaseCard from "@components/Card/baseCard";
import React from "react";
import styled from "styled-components";

const Layout = styled.div`
margin-left: 280px;
width: 100%;
background: #f9f9f9;
min-height: 100vh;
box-sizing: border-box;
`;

const Title = styled.div`
font-weight: 500;
font-size: 20px;
line-height: 32px;
margin-bottom: 18px;
`;

const Divider = styled.hr`
background-color: #d0cccc;
margin-bottom: 24px;
`;

const PageWrapper = (props) => {
  const {
    title,
  } = props;

  return (
    <div className="flex w-100vw h-100vh">
      <Layout>
        { title
          ? (
            <div style={{ padding: 14, height: '100%' }}>
              <BaseCard
                style={{ minHeight: "100%", padding: "20px 40px" }}
              >
                <Title>{title}</Title>
                <Divider />
                {props.children}
              </BaseCard>
            </div>
          )
          : (
            <div>{props.children}</div>
          )
        }
      </Layout>
    </div>
  );
};

export default PageWrapper;