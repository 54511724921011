import { CREATE_SURVEY_RESPONSE, PURGE_SURVEYRESPONSE } from './actionTypes';

export const purgeSurveyresponse = () => ({
  type: PURGE_SURVEYRESPONSE,
});

export const createSurveyresponse = (
  quizId,
  choiceId,
  quizType,
  responseText,
  responseLink
) => {
  return {
    type: CREATE_SURVEY_RESPONSE,
    payload: {
      quizId: quizId,
      choiceId: choiceId,
      quizType: quizType,
      responseText: responseText,
      responseLink: responseLink,
    },
  };
};
