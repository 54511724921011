import {
  PURGE_CLASSROOM,

  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAIL,

  GET_ACTIVE_CLASSROOM_SUCCESS,
  GET_ACTIVE_CLASSROOM_FAIL,

  GET_ACTIVE_CLASSROOM_BY_GROUP_SUCCESS,
  GET_ACTIVE_CLASSROOM_BY_GROUP_FAIL,

  SET_ACTIVE_CLASSROOM_EXIST,
  SET_ACTIVE_CLASSROOM_NOT_EXIST,

  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeClassroom = () => ({
  type: PURGE_CLASSROOM,
});

///// GET_CLASSROOM
export const getClassroomPromise = createPromiseAction('GET_CLASSROOM_PROMISE');

export const getClassroomSuccess = (classroomData) => ({
  type: GET_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const getClassroomFail = (error) => ({
  type: GET_CLASSROOM_FAIL,
  payload: error,
});

// GET_ACTIVE_CLASSROOM
export const getActiveClassroomPromise = createPromiseAction('GET_ACTIVE_CLASSROOM_PROMISE');

export const getActiveClassroomSuccess = (classroomSessionData) => {
  console.log('classsroomSessionData', classroomSessionData)
  const classroom = classroomSessionData.classroom;
  const participation = classroomSessionData.participation;

  return {
    type: GET_ACTIVE_CLASSROOM_SUCCESS,
    classroom: classroom,
    participation: participation,
  };
};
export const getActiveClassroomFail = (error) => {
  return {
    type: GET_ACTIVE_CLASSROOM_FAIL,
    payload: error,
  };
};

// GET_ACTIVE_CLASSROOM_BY GROUP
export const getActiveClassroomByGroupPromise = createPromiseAction(
  'GET_ACTIVE_CLASSROOM_BY_GROUP_PROMISE'
);

export const getActiveClassroomByGroupSuccess = (classroomData) => {
  return {
    type: GET_ACTIVE_CLASSROOM_BY_GROUP_SUCCESS,
    classroom: classroomData,
  };
};
export const getActiveClassroomByGroupFail = (error) => {
  return {
    type: GET_ACTIVE_CLASSROOM_BY_GROUP_FAIL,
    payload: error,
  };
};

export const setActiveClassroomExist = (classroomData) => ({
  type: SET_ACTIVE_CLASSROOM_EXIST,
  classroom: classroomData,
});

export const setActiveClassroomNotExist = () => ({
  type: SET_ACTIVE_CLASSROOM_NOT_EXIST,
});

//// DELETE_CLASSROOM
export const deleteClassroomPromise = createPromiseAction('DELETE_CLASSROOM_PROMISE');

export const deleteClassroomSuccess = (classroomData) => ({
  type: DELETE_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const deleteClassroomFail = (error) => ({
  type: DELETE_CLASSROOM_FAIL,
  payload: error,
});