import {
  PURGE_KAKAOCERT,
  REQUEST_CERT,
  REQUEST_CERT_SUCCESS,
  REQUEST_CERT_FAIL,
  CHECK_RESPONSE,
  CHECK_RESPONSE_SUCCESS,
  CHECK_RESPONSE_FAIL,
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeKakaocert = () => ({
  type: PURGE_KAKAOCERT,
});

export const requestCertPromise = createPromiseAction("REQUEST_CERT_PROMISE");

export const requestCert = (certData) => ({
  type: REQUEST_CERT,
  certData: {
    birthdate: certData.birthdate,
    phone: certData.phone,
    name: certData.name,
  },
});

export const requestCertSuccess = (certData) => ({
  type: REQUEST_CERT_SUCCESS,
  payload: certData,
});

export const requestCertFail = (error) => ({
  type: REQUEST_CERT_FAIL,
  payload: error,
});

export const checkResponsePromise = createPromiseAction(
  "CHECK_RESPONSE_PROMISE"
);

export const checkResponse = (checkVal) => ({
  type: CHECK_RESPONSE,
  checkVal: checkVal,
});

export const checkResponseSuccess = (checkVal) => ({
  type: CHECK_RESPONSE_SUCCESS,
  payload: checkVal,
});

export const checkResponseFail = (error) => ({
  type: CHECK_RESPONSE_FAIL,
  payload: error,
});
