import { call, put, takeLeading } from 'redux-saga/effects';
import {
    getQuizenrollmentPromise,
    getQuizenrollmentSuccess,
    getQuizenrollmentFail,
    createQuizenrollmentPromise,
    createQuizenrollmentSuccess,
    createQuizenrollmentFail,
    deleteQuizenrollmentPromise,
    deleteQuizenrollmentSuccess,
    deleteQuizenrollmentFail
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getQuizenrollmentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizenrollmentByQuizsetId, action.payload);
      yield put(getQuizenrollmentSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getQuizenrollmentFail(error));
    }
  })
};

function* createQuizenrollmentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizenrollment, action.payload);
      yield put(createQuizenrollmentSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizenrollmentFail(error));
    }
  });
}

function* deleteQuizenrollmentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteQuizenrollment, action.payload);
      yield put(deleteQuizenrollmentSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteQuizenrollmentFail(error));
    }
  });
}

function* quizenrollmentSaga() {
  yield takeLeading(getQuizenrollmentPromise, getQuizenrollmentPromiseHandler);
  yield takeLeading(createQuizenrollmentPromise, createQuizenrollmentPromiseHandler);
  yield takeLeading(deleteQuizenrollmentPromise, deleteQuizenrollmentPromiseHandler);
}

export default quizenrollmentSaga;
