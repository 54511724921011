import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import {
  GET_SESSION,
  CREATE_SESSION,
  UPDATE_SESSION,
  DELETE_SESSION,
} from "./actionTypes";
import {
  getSession,
  getSessionSuccess,
  getSessionFail,
  createSessionPromise,
  createSessionSuccess,
  createSessionFail,
  createSessionWarn,
  updateSessionSuccess,
  updateSessionFail,
  deleteSessionSuccess,
  deleteSessionFail,
  getSupervisorPromise,
  getSupervisorSuccess,
  getSupervisorFail,
  getOutline,
  getOutlineSuccess,
  getQuestionsSuccess,
  getSubquestionsSuccess,
  getChoicesSuccess,
  getSubquestionContent,
  getQuestionContent,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* getSessionHandler(action) {
  try {
    const response = yield call(
      api.getSession,
      action.sessionId,
      action.userId
    );
    yield put(getSessionSuccess(response.data.sessions));
    for (let session of response.data.sessions) {
      if (session.state == "STARTED") {
        yield put(getOutline(session.sessionId));
      }
    }
  } catch (error) {
    yield put(getSessionFail(error));
  }
}

function* createSessionHandler(action) {
  try {
    const response = yield call(api.createSession, action.session);
    yield put(getQuestionsSuccess(response.data.questions));
    // for (question of response.data.questions) {
    //     if (question.contentUrl) {
    //         yield put(getQuestionContent(question));
    //     }
    // }
    yield put(getSubquestionsSuccess(response.data.subquestions));
    // for (subquestion of response.data.subquestions) {
    //     if (subquestion.contentUrl) {
    //         yield put(getSubquestionContent(subquestion));
    //     }
    // }
    yield put(getChoicesSuccess(response.data.choices));
    yield put(getOutlineSuccess(response.data.outlines));
    yield put(createSessionSuccess(response.data));
  } catch (error) {
    if (error.response?.status === 400) {
      yield put(getSession(error.response.data));
      yield put(createSessionWarn(error));
    } else {
      yield put(createSessionFail(error));
    }
  }
}

function* createSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createSession, action.payload);
      yield put(getQuestionsSuccess(response.data.questions));
      // for (question of response.data.questions) {
      //     if (question.contentUrl) {
      //         yield put(getQuestionContent(question));
      //     }
      // }
      yield put(getSubquestionsSuccess(response.data.subquestions));
      // for (subquestion of response.data.subquestions) {
      //     if (subquestion.contentUrl) {
      //         yield put(getSubquestionContent(subquestion));
      //     }
      // }
      yield put(getChoicesSuccess(response.data.choices));
      yield put(getOutlineSuccess(response.data.outlines));
      yield put(createSessionSuccess(response.data));
      return yield response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        yield put(getSession({ sessionId: error.response.data }));
        yield put(createSessionWarn(error));
      } else {
        yield put(createSessionFail(error));
      }
    }
  });
}

function* updateSessionHandler(action) {
  try {
    const response = yield call(api.updateSession, action.session);
    yield put(updateSessionSuccess(response.data));
  } catch (error) {
    yield put(updateSessionFail(error));
  }
}

function* deleteSessionHandler(action) {
  try {
    const response = yield call(api.deleteSession, action.sessionId);
    yield put(deleteSessionSuccess(action.sessionId));
  } catch (error) {
    yield put(deleteSessionFail(error));
  }
}

function* getSupervisorPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getSupervisor, action.payload.userId);
      yield put(getSupervisorSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getSupervisorFail(error));
    }
  });
}

function* sessionSaga() {
  yield takeLeading(GET_SESSION, getSessionHandler);
  yield takeLeading(CREATE_SESSION, createSessionHandler);
  yield takeLeading(UPDATE_SESSION, updateSessionHandler);
  yield takeEvery(DELETE_SESSION, deleteSessionHandler);
  yield takeLeading(getSupervisorPromise, getSupervisorPromiseHandler);
  yield takeLeading(createSessionPromise, createSessionPromiseHandler);
}

export default sessionSaga;
