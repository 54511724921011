import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectGroup } from '@store/selectors';
import { v4 as uuidv4 } from 'uuid';
import io from 'socket.io-client';

const COLLECTION_NAME = 'Classroom';

const { useState, useEffect } = React;
const ScreenShareDev = (props) => {
  const user = useSelector((state) => state.user);

  const socket = io('https://node.focuspang.com');
  const videoElem = document.getElementById('video');

  const stopCapture = (videoElem) => {
    try {
      if (videoElem == null) return;

      let tracks = videoElem.srcObject.getTracks();

      tracks?.forEach((track) => track.stop());
      videoElem.srcObject = null;
    } catch (err) {
      console.error('Error when stop capturing screen : ', err);
    }
  };

  const capture = (video, scaleFactor = 0.5) => {
    // var h = video.videoHeight * scaleFactor;
    // TODO: re-scale based on UI
    var w = 744;
    var h = 420;
    // var w = video.videoWidth * scaleFactor;
    var canvas = document.createElement('canvas');
    canvas.id = 'canvas';
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);

    return canvas;
  };

  // const [screenCaptureIntervalId, setScreenCaptureIntervalId] = useState(0);
  // const stopCaptureInterval = () => {
  //   clearInterval(screenCaptureIntervalId);
  //   setScreenCaptureIntervalId(0);
  // };

  const startCapture = async () => {
    const displayMediaOptions = {
      video: {
        cursor: 'always',
      },
      audio: false,
    };

    const display = await navigator.mediaDevices.getDisplayMedia(
      displayMediaOptions
    );
    const video = document.getElementById('video');
    video.srcObject = display;

    videoElem.srcObject.getVideoTracks()[0].addEventListener('ended', () => {
      var timer = null;
      stopCapture(videoElem);
      clearInterval(timer);
    });
    setInterval(async () => {
      const track = display.getVideoTracks()[0];
      // console.log(track);
      // const imageCapture = new ImageCapture(track);
      // // console.log("imageCapture => ", imageCapture);
      // const imageBitmap = await imageCapture.grabFrame();
      // console.log("imageBitmap => ", imageBitmap);
      // const canvas = document.getElementById("canvas");
      // const ctx = canvas.getContext("2d");
      // ctx.drawImage(imageBitmap, 0, 0);
      const canvas = capture(video);
      const img = canvas
        .toDataURL('image/jpeg')
        .replace('data:image/jpeg;base64,', '');

      socket.emit('image', {
        img: img,
        id: socket.id,
        // TODO: change below to classroom Id
        roomId: 644,
      });
    }, 1000);
  };

  return (
    <div>
      <button onClick={startCapture}>start capture(test)</button>
      <br />
      <br />
      <button onClick={stopCapture}>stop capture(test)</button>
      <canvas id="canvas" className="hidden " />
      {/* <button onClick={startCapture}>start capture</button> */}
      <video
        autoPlay
        id="video"
        className="hidden w-2/3 mt-5 mb-10 border-2"
        controls="false"
        style={{
          display: 'none',
        }}
      />
    </div>
  );
};

export default ScreenShareDev;
