import { getBackend } from '@api/Backend';

export const getTimetableByClientId = async (clientId) => {
  return await getBackend(`timetable?clientId=${clientId}`);
};

export const getTimetablesByGroupId = async (groupId) => {
  return await getBackend(`timetable?groupId=${groupId}`);
};

export const getTimetableByTimetableId = async (timetableId) => {
  return await getBackend(`timetable?timetableId=${timetableId}`);
};
