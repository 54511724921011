import { call, put, takeLatest } from "redux-saga/effects";
import {
  getGroupActivityPromise,
  getGroupActivitySuccess,
  getGroupActivityFail,
  getSubgroupActivityPromise,
  getSubgroupActivitySuccess,
  getSubgroupActivityFail,
  updateGroupActivityLoading,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

/**
 * 모둠활동 리스트
 * @param { groupId } action
 */
function* getGroupActivityPromiseHandler(action) {
  yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroupActivity, action.payload);
      yield put(getGroupActivitySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getGroupActivityFail(error));
    } finally {
      yield put(updateGroupActivityLoading(false));
    }
  });
}

/**
 * 모둠 리스트
 * @param { subgroupActivityId } action
 */
function* getSubgroupActivityPromiseHandler(action) {
  yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getSubgroupActivity, action.payload);
      yield put(getSubgroupActivitySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getSubgroupActivityFail(error));
    } finally {
      yield put(updateGroupActivityLoading(false));
    }
  });
}

function* groupActivitySaga() {
  yield takeLatest(getGroupActivityPromise, getGroupActivityPromiseHandler);
  yield takeLatest(
    getSubgroupActivityPromise,
    getSubgroupActivityPromiseHandler
  );
}

export default groupActivitySaga;
