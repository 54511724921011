import { makeFreshAllIds } from '../helpers';
import {
  PURGE_SUBQUESTION,

  GET_SUBQUESTIONS_SUCCESS,

  GET_SUBQUESTION_CONTENT_SUCCESS,
  GET_SUBQUESTION_CONTENT_FAIL,

  GET_SUBQUESTION_SOLUTION_SUCCESS,
  GET_SUBQUESTION_SOLUTION_FAIL,

} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const subquestion = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_SUBQUESTION:
      return initialState;

    case GET_SUBQUESTIONS_SUCCESS:
      return {...state,
        byId: {...state.byId, ...action.newById},
        allIds: makeFreshAllIds(state.allIds, action.newAllIds)};

    case GET_SUBQUESTION_CONTENT_SUCCESS:
      return {...state,
        byId: {
          ...state.byId,
          [action.subquestionId]: {
            ...state.byId[action.subquestionId], content: action.content},
        }};
    
    case GET_SUBQUESTION_SOLUTION_SUCCESS:
        return {...state,
            byId: {
              ...state.byId,
              [action.subquestionId]: {
                ...state.byId[action.subquestionId], solution: action.solution},
            }};

    // todo: handle errors
    case GET_SUBQUESTION_CONTENT_FAIL:
    case GET_SUBQUESTION_SOLUTION_FAIL:
      console.error("subquestion reducer failed: ", action.payload);
      return state
    default:
      break;
  }
  return state;
};

export default subquestion;
