import {
  PURGE_ANSWER,

  GET_ANSWERS,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,

  GET_ANSWER_CONTENT,
  GET_ANSWER_CONTENT_SUCCESS,
  GET_ANSWER_CONTENT_FAIL,

  CREATE_SURVEY_ANSWER,
  CREATE_SURVEY_ANSWER_SUCCESS,
  CREATE_SURVEY_ANSWER_FAIL,

  CREATE_SURVEY_ANSWERS,
  CREATE_SURVEY_ANSWERS_SUCCESS,
  CREATE_SURVEY_ANSWERS_FAIL,

  CREATE_QNA_ANSWER,
  CREATE_QNA_ANSWER_SUCCESS,
  CREATE_QNA_ANSWER_FAIL,

  UPDATE_ANSWER,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAIL,

  DELETE_ANSWER,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,

  UPLOAD_ANSWER,
  UPLOAD_ANSWER_SUCCESS,
  UPLOAD_ANSWER_FAIL,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeAnswer = () => ({
  type: PURGE_ANSWER,
})

///// GET_ANSWER
export const getAnswers = answerData => ({
  type: GET_ANSWERS,
  subquestionId: answerData.subquestionId,
  studentId: answerData.studentId,
  requestPresignedUrl: answerData.requestPresignedUrl,
})

export const getAnswersSuccess = answers => {
  let byId = {};
  answers.forEach(answer => {
    byId[answer.answerId] = answer;
  })
  return {
    type: GET_ANSWERS_SUCCESS,
    byId: byId,
    allIds: answers.map(answer => answer.answerId),
  }
};

export const getAnswersFail = error => ({
  type: GET_ANSWERS_FAIL,
  payload: error,
})

///// GET_ANSWER_CONTENT
export const getAnswerContent = answer => ({
  type: GET_ANSWER_CONTENT,
  answer: answer,
})

export const getAnswerContentSuccess = (answerId, content) => ({
  type: GET_ANSWER_CONTENT_SUCCESS,
  answerId: answerId,
  content: content,
})

export const getAnswerContentFail = error => ({
  type: GET_ANSWER_CONTENT_FAIL,
  payload: error,
})

///// CREATE_SURVEY_ANSWER
export const createSurveyAnswer = answer => {
  return {
    type: CREATE_SURVEY_ANSWER,
    answer: answer,
  };
}

export const createSurveyAnswerSuccess = answer => ({
  type: CREATE_SURVEY_ANSWER_SUCCESS,
  answer: answer,
})

export const createSurveyAnswerFail = error => ({
  type: CREATE_SURVEY_ANSWER_FAIL,
  payload: error,
})

///// CREATE_SURVEY_ANSWERS
export const createSurveyAnswers = answers => {
    return {
      type: CREATE_SURVEY_ANSWERS,
      answers: { answers: answers },
    };
  }
  
export const createSurveyAnswersSuccess = answers => {
  let byId = {};
  answers.forEach(answer => {
    byId[answer.answerId] = answer;
  })
  return {
    type: CREATE_SURVEY_ANSWERS_SUCCESS,
    byId: byId,
    allIds: answers.map(answer => answer.answerId),
  }
}

export const createSurveyAnswersFail = error => ({
  type: CREATE_SURVEY_ANSWERS_FAIL,
  payload: error,
})

///// CREATE_QNA_ANSWER
export const createQnAAnswer = answerData => {
  let {content, ...answer} = answerData;
  return {
    type: CREATE_QNA_ANSWER,
    answer: answer,
    content: content,
  };
}

export const createQnAAnswerSuccess = answer => ({
  type: CREATE_QNA_ANSWER_SUCCESS,
  answer: answer,
})

export const createQnAAnswerFail = error => ({
  type: CREATE_QNA_ANSWER_FAIL,
  payload: error,
})

///// UPDATE_ANSWER
export const updateAnswer = answerData => ({
  type: UPDATE_ANSWER,
  answerData: answerData,
})

export const updateAnswerSuccess = answer => ({
  type: UPDATE_ANSWER_SUCCESS,
  answer: answer,
})

export const updateAnswerFail = error => ({
  type: UPDATE_ANSWER_FAIL,
  payload: error,
})

///// DELETE_ANSWER
export const deleteAnswer = answerId => ({
  type: DELETE_ANSWER,
  answerId: answerId,
})
  
export const deleteAnswerSuccess = answerId => ({
  type: DELETE_ANSWER_SUCCESS,
  answerId: answerId,
})
  
export const deleteAnswerFail = error => ({
  type: DELETE_ANSWER_FAIL,
  payload: error,
})

///// UPLOAD_ANSWER
export const uploadAnswer = answerData => ({
  type: UPLOAD_ANSWER,
  answer: answerData.answer,
  content: answerData.content,
})

export const uploadAnswerSuccess = answer => ({
  type: UPLOAD_ANSWER_SUCCESS,
  answer: answer,
})

export const uploadAnswerFail = error => ({
  type: UPLOAD_ANSWER_FAIL,
  payload: error,
})