import { selectQnAOutlinesBySession } from "../selectors";

export const selectAllSessions = (state) => {
  const sessions = state.session.allIds.map(sessionId => state.session.byId[sessionId]);
  if (sessions.length == 0) {
    return [];
  }
  return sessions;
}

export const selectSessionbyState = (state, sessionState) => {
  const sessions = selectAllSessions(state);
  return sessions.find(session => session.state == sessionState);
}

export const selectSessionSubject = (state, sessionId) => {
  const outlines = selectQnAOutlinesBySession(state, sessionId);
  if (outlines.length == 0) {
    return null;
  }
  return state.question.byId[outlines[0].questionId].subject;
}