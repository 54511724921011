import { purgeAll, setRefreshstate } from '@app/store/actions';
import {
  clearNetworkState,
  setNetworkState,
  setNewtorkState,
} from '@store/actions';
import React, { useEffect } from 'react';
import { useInterval } from 'react-hookedup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RefreshManager = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const reduxRefreshDate = useSelector((state) => state.control.refreshDate);
  const networkCounter = useSelector((state) => state.control.networkState);
  const today = new Date();
  const dispatch = useDispatch();

  const checkNetwork = () => {
    if (!navigator.onLine && user.signedIn) {
      dispatch(setNetworkState(networkCounter + 1));
      console.log('networkCounter: ', networkCounter);
    }
    if (navigator.onLine && networkCounter > 0 && user.signedIn) {
      dispatch(clearNetworkState());
    }
  };

  useInterval(() => {
    checkNetwork();
  }, 5000);

  useEffect(() => {
    if (!user.signedIn) {
      return;
    }
    if (!localStorage.getItem('token')) {
      dispatch(purgeAll());
      history.replace('/auth');
    }
  }, [dispatch, user.clientId, reduxRefreshDate]);

  useEffect(() => {
    if (networkCounter > 3) {
      alert('인터넷 연결이 불안정합니다! 인터넷 연결을 확인해주세요.');
      // dispatch(purgeAll());
      // localStorage.clear();
      // history.replace("/auth");
    }
  }, [networkCounter]);

  useEffect(() => {
    if (history.location.pathname.includes('survey')) {
      alert('설문이 종료되었습니다.');
    }
  }, [history.location.pathname]);

  return <div />;
};

export default RefreshManager;
