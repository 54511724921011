import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Caption,
  CaptionMedium,
  Column,
  HorizontalSpace4,
  Row,
  Subtitle2,
  VerticalSpace4,
  VerticalSpace8,
} from "@app/pages/QuizPang/common";
import { Button, Checkbox, Radio } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { getBackend } from "@api";

export const SurveyCard = ({ actions, data, isActive = false, onClick, setAnswers }) => {
  const [choice, setChoice] = useState([]);
  const [selected, setSelected] = useState();
  const getChoice = async () => {
    try {
      const res = await getBackend(`choice?quizId=${data.quizId}`);

      setChoice(res.data.choices);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getChoice();
    
  }, [data]);
  
  const onRadioClick = (el)=>{
    setSelected(el.index);
  };

  return (
    <Container
      isActive={isActive}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {actions?.props?.onChoose && actions}
      {actions?.props?.onSelect && actions}
      <Column>
        <TopRow onClick={onClick}>
          <Subtitle2
            dangerouslySetInnerHTML={{ __html: data.title }}
            style={{
              whiteSpace: "normal",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "40vw",
            }}
          />
        </TopRow>
        <AnswersWrapper>
          {choice.map((el) => (
            <RadioColumn>
              <RadioText>{el.index}</RadioText>
              {selected === el.index ?
              <div><RadioButtonChecked /></div> :
                <div onClick={()=>{onRadioClick(el);}}>
                  <RadioButtonUnchecked/>
                </div>}
              <RadioText>{el.description}</RadioText>
            </RadioColumn>
          ))}
        </AnswersWrapper>
        {actions?.props?.onDelete && actions}
      </Column>
      {!actions?.props?.onDelete && !actions?.props?.onSelect && actions}
    </Container>
  );
};

export const QuizCardMyActions = ({ onEdit, onDelete }) => {
  return (
    <ActionRow style={{ alignSelf: "flex-start" }}>
      <Button
        style={{ width: 70, fontSize: 12 }}
        startIcon={<CreateIcon />}
        onClick={onEdit}
      >
        수정
      </Button>
      <HorizontalSpace4 />
      <Button
        style={{ width: 70, fontSize: 12 }}
        startIcon={<DeleteIcon />}
        onClick={onDelete}
      >
        삭제
      </Button>
    </ActionRow>
  );
};

export const QuizCardSelectActions = ({ onSelect, index }) => {
  const isSelected = index >= 0;
  return (
    <ActionRow>
      <Checkbox onClick={onSelect} checked={isSelected} />
    </ActionRow>
  );
};

export const QuizCardChooseActions = ({ onChoose, isChosen }) => {
  return (
    <ActionRow>
      <Radio onClick={onChoose} checked={isChosen} />
    </ActionRow>
  );
};

export const QuizCardOtherSchoolActions = ({ onPreview, onAdd }) => {
  return (
    <ActionRow>
      <Button
        style={{ width: 100 }}
        startIcon={<PlayCircleIcon />}
        onClick={onPreview}
      >
        미리보기
      </Button>
      <HorizontalSpace4 />
      <Button style={{ width: 160 }} startIcon={<AddIcon />} onClick={onAdd}>
        퀴즈용 문제에 추가
      </Button>
      <HorizontalSpace4 />
    </ActionRow>
  );
};

const Container = styled(Row)`
  flex: 1;
  border: 1px solid ${(p) => (p.isActive ? "#0894A0" : "#e0e0e0")};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${(p) => (p.isActive ? "#E4F6F8" : "")};
  transition: all 0.1s ease-out;
`;

const TopRow = styled(Row)`
  margin-bottom: 12px;
`;

const ActionRow = styled(Row)`
  align-self: center;
  justify-content: flex-end;
`;
const AnswersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
`;
const RadioColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RadioText = styled.p`
  font-size: 13px;
  font-weight: 400;
`;
