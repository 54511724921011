export const selectAllNotifications = state => {
  if(state.notification.allIds.length === 0) {
    return [];
  }

  return state.notification.allIds
    .map(notificationId => state.notification.byId[notificationId])
    .sort(function (a, b) {
      if (a.timestamp < b.timestamp) {
        return 1;
      } else if (a.timestamp > b.timestamp) {
        return -1;
      }
      return 0;
    });
}

export const selectAllUnreadNotifications = state => {
  if(!state.notification.notifications?.length) {
    return [];
  }
  return state.notification.notifications
    .filter(notification => notification.state !== 'READ');
}

export const selectAllNotificationByKind = (state, kind) => {
  if(state.notification.allIds.length === 0) {
    return [];
  }

  return state.notification.allIds
    .filter(notificationId => state.notification.byId[notificationId].kind === kind)
    .map(notificationId => state.notification.byId[notificationId]);
}

export const selectReadNotificationByKind = (state, kind) => {
  if(state.notification.allIds.length === 0) {
    return [];
  }

  return state.notification.allIds
    .filter(notificationId => state.notification.byId[notificationId].kind === kind)
    .filter(notificationId => state.notification.byId[notificationId].read)
    .map(notificationId => state.notification.byId[notificationId]);
}

export const selectUnreadNotificationByKind = (state, kind) => {
  if(state.notification.allIds.length === 0) {
    return [];
  }

  return state.notification.allIds
    .filter(notificationId => state.notification.byId[notificationId].kind === kind)
    .filter(notificationId => !state.notification.byId[notificationId].read)
    .map(notificationId => state.notification.byId[notificationId]);
}

export const selectAllNotificationDev = (state) => {
  return selectAllNotifications(state).filter(notification => 
    notification.kind === 'CLASSROOM_START' ||
    notification.kind === 'CLASSROOM_END' || 
    notification.kind === 'GROUP_INVITE' ||
    notification.kind === 'GROUP_INVITE_PLEASE' ||
    notification.kind === 'GROUP_INVITE_ACCEPT' ||
    notification.kind === 'GROUP_INVITE_DECLINE' ||
    notification.kind === 'GROUP_REQUEST' ||
    notification.kind === 'GROUP_REQUEST_PLEASE' ||
    notification.kind === 'GROUP_REQUEST_ACCEPT' ||
    notification.kind === 'GROUP_REQUEST_DECLINE' ||
    notification.kind === 'GROUP_ACTIVATE' ||
    notification.kind === 'GROUP_DEACTIVATE'
  );
}