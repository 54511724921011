import getYoutubeId from "get-youtube-id";
export const makeFreshAllIds = (oldAllIds, newIds) => {
  if (newIds == null) {
    return [];
  }
  const freshAllIds = newIds.filter((newId) => !oldAllIds.includes(newId));
  return [...oldAllIds, ...freshAllIds];
};

export const formatDateMMDD = (day = new Date()) => {
  var date = day.getDate();
  date = date >= 10 ? date : "0" + date;
  var month = day.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;

  return `${month}/${date}`; //format: MM/DD;
};

export const formatDateYYYYMMDD = (day = new Date()) => {
  var date = day.getDate();
  date = date >= 10 ? date : "0" + date;
  var month = day.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  var year = day.getFullYear();

  return year + "-" + month + "-" + date; //format: YYYY-MM-DD;
};

export const formatDateYYYYMMDDHHMMSS = (day = new Date()) => {
  var date = day.getDate();
  date = date >= 10 ? date : "0" + date;
  var month = day.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  var year = day.getFullYear();

  var hour = day.getHours();
  hour = hour >= 10 ? hour : "0" + hour;
  var minute = day.getMinutes();
  minute = minute >= 10 ? minute : "0" + minute;
  var second = day.getSeconds();
  second = second >= 10 ? second : "0" + second;

  return `${year}-${month}-${date} ${hour}:${minute}:${second}`; //format: YYYY-MM-DD HH:MM:SS;
};

export const formatMinuteHHMM = (totMinute) => {
  return `${("00" + String(Math.floor(totMinute / 60))).slice(-2)}:${(
    "00" + String(totMinute % 60)
  ).slice(-2)}`;
};

export const formatSecondHHMM = (totSecond) => {
  const totMinute = Math.floor(totSecond / 60);
  return `${("00" + String(Math.floor(totMinute / 60))).slice(-2)}:${(
    "00" + String(totMinute % 60)
  ).slice(-2)}`;
};

export const weekdayToKorean = (weekday) => {
  switch (weekday) {
    case 'monday':
      return '월요일';
    case 'tuesday':
      return '화요일';
    case 'wednesday':
      return '수요일';
    case 'thursday':
      return '목요일';
    case 'friday':
      return '금요일';
    default:
      return '';
  }
};
export const getYoutubeVideoThumbnail = url => {
  return `https://i.ytimg.com/vi/${getYoutubeId(url)}/maxresdefault.jpg`;
};
