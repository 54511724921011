import styles from "./Sticker.module.scss";
import {getStickerIcon} from "api";

const Sticker = ({ sticker, stickerPresets }) => {
  return (
    <div className={styles["sticker-container"]}>
      {/*<div className={styles["img-sticker"]} />*/}
      <img className={styles["img-sticker"]} alt={"스티커 사진"} src={getStickerIcon(sticker.name)}/>
      <div className={styles["count-container"]}>
        <span className={styles["span-count"]}>{sticker.count}</span>
      </div>
      {sticker.name.split(" ").map((word) => (
        <span className={styles["span-name"]}>{word}</span>
      ))}
    </div>
  );
};

export default Sticker;
