import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import {
  GET_ANSWERS,
  GET_ANSWER_CONTENT,
  CREATE_SURVEY_ANSWER,
  CREATE_SURVEY_ANSWERS,
  CREATE_QNA_ANSWER,
  UPDATE_ANSWER,
  UPLOAD_ANSWER,
  DELETE_ANSWER,
} from './actionTypes';
import {
  getAnswers,
  getAnswersSuccess,
  getAnswersFail,

  getAnswerContent,
  getAnswerContentSuccess,
  getAnswerContentFail,
  
  createSurveyAnswerSuccess,
  createSurveyAnswerFail,

  createSurveyAnswersSuccess,
  createSurveyAnswersFail,

  createQnAAnswerSuccess,
  createQnAAnswerFail,

  updateAnswerSuccess,
  updateAnswerFail,

  uploadAnswerSuccess,
  uploadAnswerFail,

  deleteAnswerSuccess,
  deleteAnswerFail,

  uploadAnswer,
  updateAnswer,

  updateOutlineSolved,
  updateOutlinesSolved,
} from '@store/actions'
import * as api from '@api';

function* getAnswersHandler(action) {
  try {
    const response = yield call(api.getAnswers, action.subquestionId, action.studentId, action.requestPresignedUrl);
    yield put(getAnswersSuccess(response.data.answers));
  } catch(error) {
    yield put(getAnswersFail(error));
  }
}

function* getAnswerContentHandler(action) {
  try {
    const response = yield call(api.getHTMLfromAWS, action.answer.presignedUrl);
    if (response.status != 200) {
      throw new Error("Failed to get answer content from AWS");
    }
    yield put(getAnswerContentSuccess(action.answer.answerId, response.data));
  } catch(error) {
    yield put(getAnswerContentFail(error));
  }
}


function* createSurveyAnswerHandler(action) {
  try {
    const response = yield call(api.createSurveyAnswer, action.answer, false);
    yield put(updateOutlineSolved(response.data.subquestionId));
    yield put(createSurveyAnswerSuccess(response.data));
  } catch(error) {
    yield put(createSurveyAnswerFail(error));
  }
}

function* createSurveyAnswersHandler(action) {
    try {
      const response = yield call(api.createSurveyAnswer, action.answers, true);
      yield put(updateOutlinesSolved(response.data.answers.map(answer => answer.subquestionId)));
      yield put(createSurveyAnswersSuccess(response.data.answers));
    } catch(error) {
      yield put(createSurveyAnswersFail(error));
    }
  }

function* createQnAAnswerHandler(action) {
  try {
    const response = yield call(api.createQnAAnswer, action.answer);
    const answerData = {answer: response.data, content:action.content};
    yield put(uploadAnswer(answerData));
    yield put(updateOutlineSolved(response.data.subquestionId));
    // yield put(getAnswers({subquestionId: action.answer.subquestionId, studentId: action.answer.studentId}));
    yield put(createQnAAnswerSuccess(response.data));
  } catch(error) {
    yield put(createQnAAnswerFail(error));
  }
}

function* updateAnswerHandler(action) {
  try {
    const response = yield call(api.updateAnswer, action.answerData);
    yield put(updateAnswerSuccess(response.data));
  } catch(error) {
    yield put(updateAnswerFail(error));
  }
}

function* uploadAnswerHandler(action) {
  try {
    const response = yield call(api.putAnswerAWS, action.answer.presignedUrl, action.content, action.answer.contentType);
    yield put(updateAnswer({answerId: action.answer.answerId, uploadState: "COMPLETE"}));
    yield put(uploadAnswerSuccess(response));
  } catch(error) {
    yield put(updateAnswer({answerId: action.answerId, uploadState: "FAILED"}));
    yield put(uploadAnswerFail(error));
  }
}

function* deleteAnswerHandler(action) {
    try {
      const response = yield call(api.deleteAnswer, action.answerId);
      yield put(deleteAnswerSuccess(action.answerId));
    } catch(error) {
      yield put(deleteAnswerFail(error));
    }
  }

function* answerSaga() {
  yield takeEvery(GET_ANSWERS, getAnswersHandler);
  yield takeEvery(GET_ANSWER_CONTENT, getAnswerContentHandler);
  yield takeEvery(CREATE_SURVEY_ANSWER, createSurveyAnswerHandler);
  yield takeLeading(CREATE_SURVEY_ANSWERS, createSurveyAnswersHandler);
  yield takeEvery(CREATE_QNA_ANSWER, createQnAAnswerHandler);
  yield takeEvery(UPDATE_ANSWER, updateAnswerHandler);
  yield takeEvery(UPLOAD_ANSWER, uploadAnswerHandler);
  yield takeEvery(DELETE_ANSWER, deleteAnswerHandler);
}

export default answerSaga;
