import React from 'react';
import MdInput from '@components/Input/MdInput';
import BaseModal from './BaseModal';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 12px;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
`;

const InviteCodeModal = (props) => {
  const {
    visible,
    title = '초대 코드 로그인',
    code,
    onChangeCode = () => {},
    onHide = () => {},
    onCancel = () => {},
    onComplete = () => {},
    cancelText = '취소',
    completeText = '다음',
  } = props;

  return (
    <BaseModal
      title={title}
      visible={visible}
      onHide={onHide}
      onCancel={onCancel}
      onComplete={onComplete}
      cancelText={cancelText}
      completeText={completeText}
    >
      <Wrapper>
        <Description>선생님께 전달 받은 초대코드를 입력해주세요.</Description>
        <MdInput
          type="text"
          name="code"
          label="초대 코드"
          value={code}
          onChange={onChangeCode}
        />
      </Wrapper>
    </BaseModal>
  );
};

export default InviteCodeModal;
