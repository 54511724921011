export const processSurveyResponse = (
  surveyResponse,
  quizsetSessionId,
  user
) => {
  // 1. surveyResponse를 Object.keys를 통해 key값을 배열로 만든다.
  // 2. 배열을 forEach를 통해 surveyResponse의 quizType이 'MULTIPLE' || 'OX' || 'RADIO'인 경우에는
  //    quizsetSessionId, choiceId, clientId만 남기고 나머지는 삭제한다.
  // 3. 배열을 forEach를 통해 surveyResponse의 quizType이 'SHORT' || 'LONG' || 'STYLUS' || 'TIME'인 경우에는
  //    quizsetSessionId, quizId, clientId, responseText, responseLink만 남기고 나머지는 삭제한다.
  // 4. Object.values를 통해 surveyResponse를 배열로 만든다.
  // 5. map을 통해 quizsetSessionId, clientId를 user의 quizsetSessionId, clientId로 바꾼다.
  // 6. 최종적으로 surveyResponseArray를 반환한다.
  Object.keys(surveyResponse).forEach((key) => {
    if (
      surveyResponse[key].quizType === 'MULTIPLE' ||
      surveyResponse[key].quizType === 'OX' ||
      surveyResponse[key].quizType === 'RADIO'
    ) {
      surveyResponse[key] = {
        quizsetSessionId: surveyResponse[key].quizsetSessionId,
        choiceId: surveyResponse[key].choiceId,
        clientId: surveyResponse[key].clientId,
      };
    } else if (
      surveyResponse[key].quizType === 'SHORT' ||
      surveyResponse[key].quizType === 'LONG' ||
      surveyResponse[key].quizType === 'STYLUS' ||
      surveyResponse[key].quizType === 'TIME'
    ) {
      surveyResponse[key] = {
        quizsetSessionId: surveyResponse[key].quizsetSessionId,
        quizId: surveyResponse[key].quizId,
        clientId: surveyResponse[key].clientId,
        responseText: surveyResponse[key].responseText,
        responseLink: surveyResponse[key].responseLink,
      };
    }
  });

  const surveyResponseArray = Object.values(surveyResponse);

  return surveyResponseArray.map((data) => ({
    ...data,
    quizsetSessionId: quizsetSessionId,
    clientId: user.clientId,
  }));
};
