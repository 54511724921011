import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import {
  Prompt,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useQuizset } from '@pages/QuizPang/hooks/useSurvey';
import { SurveySession } from './survey-session';

// 추후 스크롤용으로 사용할 컴포넌트
import { SurveySessionScroll } from './survey-session-scroll';
import { useDispatch } from 'react-redux';
import { purgeSurveyresponse } from '@store/actions';

const Survey = (props) => {
  const { groupId, quizsetId, quizsetSessionId } = useParams();
  const [isBlocking, setIsBlocking] = useState(true);

  const getQuizset = useQuizset(quizsetId);

  if (getQuizset.isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  if (getQuizset.isError)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-2xl text-gray-900">
          설문을 불러오는 도중 문제가 발생하였습니다. 새로고침을 해주세요.
        </div>
      </div>
    );

  const quizsetData = getQuizset.quizset.data;

  return (
    // <Container maxWidth="lg">
    <div>
      <Prompt when={isBlocking} message="설문을 종료하시겠습니까?" />
      <SurveySession
        groupId={groupId}
        quizsetId={quizsetId}
        quizsetSessionId={quizsetSessionId}
        quizsetData={quizsetData}
      />
      {/* <SurveySessionScroll
        groupId={groupId}
        quizsetId={quizsetId}
        quizsetSessionId={quizsetSessionId}
        quizsetData={quizsetData}
      /> */}
      {/* </Container> */}
    </div>
  );
};

export default Survey;

const StyledBox = styled.div`
  position: relative;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 20px;
`;

const SlideButton = styled.button`
  content: '';
  display: flex;
  z-index: 1;
  border-width: 0 2px 2px 0;
  margin-left: -2px;
  color: var(--primary-darken-1);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  svg {
    width: 100px;
    height: 100px;
  }
`;
