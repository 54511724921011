import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAllClients, selectActiveClassroom } from '@store/selectors';
import SidebarTab from '@components/SidebarTab/SidebarTab';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import styled from 'styled-components';

const TeacherNameWrapper = styled.div`
  padding: 2px 8px;
  background: ${(props) => (props.isHR ? '#FFF9C4' : '#F5F5F5')};
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${(props) => (props.isHR ? '#F57F17' : 'rgba(0, 0, 0, 0.6)')};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CardSidebarLectureInfo = ({ classInfo }) => {
  const clients = useSelector((state) => selectAllClients(state));
  const getTeacherName = (ownerId) => {
    return clients.find((client) => client.id === ownerId)?.username;
  };

  // const setIcon = (lectureName) => {
  //   if (lectureName.includes("HR")) {
  //     return imgHRIcon;
  //   } else {
  //     return imgSubjectIcon;
  //   }
  // };

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeGroup =
    activeClassroom !== null ? activeClassroom.group === classInfo.id : false;
  const pathName = activeGroup ? '/classroom/notice' : '/classroom/notice/';

  // const getTeacherName = (ownerId) => {
  //   return clients.find((client) => client.id === ownerId)?.username;
  // };

  const isHR = classInfo?.name === 'HR';

  const RightComponent = () => {
    const iconStyle = {
      width: 20,
      height: 20,
      color: '#F57F17',
    };

    return (
      <TeacherNameWrapper isHR={isHR}>
        {isHR && <StarRoundedIcon style={iconStyle} />}
        {getTeacherName(classInfo.owner)}
      </TeacherNameWrapper>
    );
  };

  return (
    <Link
      to={{
        pathname: pathName,
        state: {
          classInfo: classInfo,
        },
      }}
    >
      <SidebarTab
        icon={
          isHR ? (
            <BookRoundedIcon fill="#ffffff" style={{ marginRight: 24 }} />
          ) : (
            <MenuBookRoundedIcon fill="#ffffff" style={{ marginRight: 24 }} />
          )
        }
        text={
          isHR
            ? `${classInfo.grade}학년 ${classInfo.class_name}반`
            : classInfo.name
        }
        active={false}
        rightComponent={<RightComponent />}
      />
    </Link>
  );
};

export default CardSidebarLectureInfo;
