import {
    PURGE_QUIZCHOICE,
    GET_QUIZCHOICE_BY_CHOICEID_SUCCESS,
    GET_QUIZCHOICE_BY_CHOICEID_FAIL,
    GET_QUIZCHOICE_BY_QUIZID_SUCCESS,
    GET_QUIZCHOICE_BY_QUIZID_FAIL,
    CREATE_QUIZCHOICE_SUCCESS,
    CREATE_QUIZCHOICE_FAIL,
    UPDATE_QUIZCHOICE_SUCCESS,
    UPDATE_QUIZCHOICE_FAIL,
    DELETE_QUIZCHOICE_SUCCESS,
    DELETE_QUIZCHOICE_FAIL,
  } from './actionTypes';
  import { createPromiseAction } from '@adobe/redux-saga-promise';

  export const purgeQuizChoice = () => ({
    type: PURGE_QUIZCHOICE,
  });

  // get
  export const getQuizChoiceByChoiceIdPromise = createPromiseAction('GET_QUIZCHOICE_BY_CHOICEID_PROMISE');
  export const getQuizChoiceByChoiceIdSuccess = (data) => {
    return {
      type: GET_QUIZCHOICE_BY_CHOICEID_SUCCESS,
      data,
    }
  };
  export const getQuizChoiceByChoiceIdFail = (error) => ({
    type: GET_QUIZCHOICE_BY_CHOICEID_FAIL,
    payload: error,
  });

  export const getQuizChoiceByQuizIdPromise = createPromiseAction('GET_QUIZCHOICE_BY_QUIZID_PROMISE');
  export const getQuizChoiceByQuizIdSuccess = (choices) => {
    const byId = {}
    if (choices.length > 0) {
      byId[choices[0].quizId] = choices
    }
    return {
      type: GET_QUIZCHOICE_BY_QUIZID_SUCCESS,
      byId,
    };
  };
  export const getQuizChoiceByQuizIdFail = (error) => ({
    type: GET_QUIZCHOICE_BY_QUIZID_FAIL,
    payload: error,
  });

  // create
  export const createQuizChoicePromise = createPromiseAction('CREATE_QUIZCHOICE_PROMISE');
  export const createQuizChoiceSuccess = (quizChoiceData) => ({
    type: CREATE_QUIZCHOICE_SUCCESS,
    quizChoice: quizChoiceData,
  });
  export const createQuizChoiceFail = (error) => ({
    type: CREATE_QUIZCHOICE_FAIL,
    payload: error,
  });

  // update
  export const updateQuizChoicePromise = createPromiseAction('UPDATE_QUIZCHOICE_PROMISE');
  export const updateQuizChoiceSuccess = (quizChoiceData) => ({
    type: UPDATE_QUIZCHOICE_SUCCESS,
    quizChoice: quizChoiceData,
  });
  export const updateQuizChoiceFail = (error) => ({
    type: UPDATE_QUIZCHOICE_FAIL,
    payload: error,
  });

  // delete
  export const deleteQuizChoicePromise = createPromiseAction('DELETE_QUIZCHOICE_PROMISE');
  export const deleteQuizChoiceSuccess = (quizChoiceId) => ({
    type: DELETE_QUIZCHOICE_SUCCESS,
    quizChoiceId,
  });
  export const deleteQuizChoiceFail = (error) => ({
    type: DELETE_QUIZCHOICE_FAIL,
    payload: error,
  });
