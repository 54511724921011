export const selectOutlinesByQuestion = (state, questionId) => {
  const outlines = state.outline.allIds
    .map(outlineId => state.outline.byId[outlineId])
    .filter(outline => outline.questionId === questionId);
  if (outlines.length === 0) {
    return [];
  }
  return outlines;
}

export const selectQnAOutlinesBySession = (state, sessionId) => {
  const outlines = selectAllOutlinesBySession(state, sessionId);

  if (outlines.length==0) {
    return [];
  }
  
  return outlines.filter(outline => {
    const question = state.question.byId[outline.questionId];
    return question.subject !== "SURVEY";
  });
}

export const selectSurveyOutlinesBySesion = (state, sessionId) => {
  const outlines = selectAllOutlinesBySession(state, sessionId);

  if (outlines.length==0) {
    return [];
  }
  
  return outlines.filter(outline => {
    const question = state.question.byId[outline.questionId];
    return question.subject === "SURVEY";
  });
}

export const selectAllOutlinesBySession = (state, sessionId) => {
  const outlines = state.outline.allIds
    .map(outlineId => state.outline.byId[outlineId])
    .filter(outline => outline.sessionId === sessionId);
  if (outlines.length === 0) {
    return [];
  }
  return outlines;
}

export const selectSolvedQnAOutlinesBySession = (state, sessionId) => {
  return selectQnAOutlinesBySession(state, sessionId).filter(outline => outline.state === "SOLVED");
}

export const selectSolvedSurveyOutlinesBySession = (state, sessionId) => {
  return selectSurveyOutlinesBySesion(state, sessionId).filter(outline => outline.state === "SOLVED");
}

