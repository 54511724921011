import { call, put, takeLeading } from 'redux-saga/effects';
import {
  getQuizsetByQuizsetIdPromise,
  getQuizsetByQuizsetIdSuccess,
  getQuizsetByQuizsetIdFail,
  getQuizsetListByClientPromise,
  getQuizsetListByClientSuccess,
  getQuizsetListByClientFail,
  getQuizsetListBySchoolPromise,
  getQuizsetListBySchoolSuccess,
  getQuizsetListBySchoolFail,
  createQuizsetPromise,
  createQuizsetSuccess,
  createQuizsetFail,
  updateQuizsetPromise,
  updateQuizsetSuccess,
  updateQuizsetFail,
  deleteQuizsetPromise,
  deleteQuizsetSuccess,
  deleteQuizsetFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getQuizsetByQuizsetIdPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizsetByQuizsetId, action.payload);
      // console.log('[getQuizsetByQuizsetIdPromiseHandler]', response.data)
      yield put(getQuizsetByQuizsetIdSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetByQuizsetIdFail(error));
    }
  })
}

function* getQuizsetListByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizsetListByClient, action.payload);
      yield put(getQuizsetListByClientSuccess(response.data.quizsets));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetListByClientFail(error));
    }
  });
}

function* getQuizsetListBySchoolPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizsetListBySchool, action.payload);
      yield put(getQuizsetListBySchoolSuccess(response.data.quizsets));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetListBySchoolFail(error));
    }
  });
}

function* createQuizsetPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizset, action.payload);
      yield put(createQuizsetSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizsetFail(error));
    }
  });
}

function* updateQuizsetPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateQuizset, action.payload);
      yield put(updateQuizsetSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateQuizsetFail(error));
      throw error;
    }
  });
}

function* deleteQuizsetPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteQuizset, action.payload);
      yield put(deleteQuizsetSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteQuizsetFail(error));
    }
  });
}

function* quizsetSaga() {
  yield takeLeading(getQuizsetByQuizsetIdPromise, getQuizsetByQuizsetIdPromiseHandler);
  yield takeLeading(getQuizsetListByClientPromise, getQuizsetListByClientPromiseHandler);
  yield takeLeading(getQuizsetListBySchoolPromise, getQuizsetListBySchoolPromiseHandler);
  yield takeLeading(createQuizsetPromise, createQuizsetPromiseHandler);
  yield takeLeading(updateQuizsetPromise, updateQuizsetPromiseHandler);
  yield takeLeading(deleteQuizsetPromise, deleteQuizsetPromiseHandler);
}

export default quizsetSaga;
