import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getQuizsetSessionResultPromise } from "@app/store/quizsetSession/actions";
import rightIcon from "../../assets/images/quizpang/right.png";
import wrongIcon from "../../assets/images/quizpang/wrong.png";
import QuizResultRanking from "./quiz-result-ranking";
import { Spinner } from "./spinner-countdown";
import { useParams, useHistory } from "react-router-dom";
import { useQuizset, useQuizsetSession } from "./hooks";
import ReactQuill from "react-quill";

const QuizResult = () => {
  const history = useHistory();
  const { groupId, quizsetSessionId } = useParams();
  const {
    quizsetSession,
    isLoading: quizsetLoading,
    quizOrder,
  } = useQuizsetSession(groupId);
  const { quizset } = useQuizset(quizsetSession?.quizsetId);

  const result = useSelector(
    (state) => state.quizsetSession.resultsById[quizsetSessionId]
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // delay 1s for server to process students result
    if (typeof quizOrder === "number") {
      setTimeout(() => {
        dispatch(
          getQuizsetSessionResultPromise({
            quizsetSessionId,
            quizIndex: quizOrder + 1,
          })
        ).then(() => {
          setIsLoading(false);
        });
      }, 1000);
    }
  }, [dispatch, quizOrder, quizsetSessionId]);

  useEffect(() => {
    if (!quizsetLoading && !quizsetSession) {
      history.replace(`/quizpang/${groupId}`);
    }
  }, [groupId, history, quizsetLoading, quizsetSession]);

  if (!quizsetSession || !quizset) {
    return null;
  }
  const isAutoPassed = quizset.isAutoPassed;
  const isShowRank = quizset.isShowRank;
  const isLast = quizset.quizzes.length - 1 === quizOrder;

  return (
    <Container>
      {isLoading ? (
        <div style={{ flex: 1, justifyContent: "center" }}>
          {/* <ActivityIndicator size={56} color={colors.grey.base} /> */}
          <Caption style={{ marginTop: 24, textAlign: "center" }}>
            결과 로딩 중입니다...
          </Caption>
        </div>
      ) : (
        <BetweenRow style={{ flex: 1 }}>
          <Column style={{ width: isShowRank ? "60%" : "100%" }}>
            <BetweenRow style={{ alignItems: "center", marginBottom: 24 }}>
              <Title>퀴즈 결과</Title>
              {!isLast && isAutoPassed && (
                <Row>
                  <Title style={{ marginRight: 16 }}>다음 퀴즈까지</Title>
                  <Spinner isSmall startSecond={10} />
                </Row>
              )}
            </BetweenRow>
            <Column style={{ flex: 1 }}>
              {result &&
                result.quizresults.map((x) => {
                  return <Quiz key={x.quizId} data={x} />;
                })}
            </Column>
          </Column>
          {isShowRank && (
            <Column style={{ width: "40%" }}>
              {isAutoPassed && <div style={{ height: 16 }} />}
              <Title style={{ paddingBottom: 8, marginBottom: 8 }}>
                현재 순위
              </Title>
              {isAutoPassed && <div style={{ height: 8 }} />}
              <Column style={{ flex: 1 }}>
                {result &&
                  result.leaderboards.map((x, i) => {
                    return (
                      <QuizResultRanking
                        key={`${i}_${x.clientId}`}
                        index={i + 1}
                        data={x}
                      />
                    );
                  })}
              </Column>
            </Column>
          )}
        </BetweenRow>
      )}
    </Container>
  );
};

const Quiz = ({ data }) => {
  const totalParticipants = data.choices.reduce(
    (sum, choice) => sum + choice.chooserCount,
    0
  );
  return (
    <Column style={{ marginBottom: 40 }}>
      {/* <QuizTitle style={{width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>{data.title}</QuizTitle> */}
      <QuizTitle
        dangerouslySetInnerHTML={{ __html: data.title }}
        style={{ width: 700, wordWrap: "break-word", whiteSpace: "pre-wrap" }}
      />
      {!["STYLUS", "LONG"].includes(data.quizType) ? (
        data.choices.map((x, i) => {
          return (
            <QuizChoice
              key={i}
              data={x}
              totalParticipants={totalParticipants}
            />
          );
        })
      ) : (
        <Description>채점 예정입니다.</Description>
      )}
      {!!data.description && (
        <>
          <Title>해설</Title>
          <Description
            style={{
              width: 700,
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            <ReactQuill
              value={data.description}
              readOnly
              theme='bubble'
              style={{
                width: "100%",
                minHeight: "200px",
              }}
            />
            {/* {data.description} */}
          </Description>
        </>
      )}
    </Column>
  );
};

const QuizChoice = ({ data, totalParticipants }) => {
  const [width, setWidth] = useState(100);
  const isRight = data.point > 0;
  const percentage = data.chooserCount / totalParticipants || 0;
  const innerWidth = Math.min(width * percentage, width);

  return (
    <Column style={{ marginBottom: 16, width: 700 }}>
      <QuizOptionTitle
        style={{ width: 700, wordWrap: "break-word", whiteSpace: "pre-wrap" }}
      >
        {data.index}.{" "}
        {data.description === "Wrong Answer" ? "오답" : data.description} (
        {data.point}점)
      </QuizOptionTitle>
      <ChoiceBarOuter
      // onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
      >
        <ChoiceBarInner
          style={{
            backgroundColor: isRight ? "#388E3C" : "#fff",
            width: innerWidth > 0 ? `${innerWidth}%` : `${innerWidth + 20}%`,
          }}
        >
          <img
            src={isRight ? rightIcon : wrongIcon}
            style={{ marginRight: 8, width: 20, height: 20 }}
          />
          <QuizOption style={{ color: isRight ? "#FAFAFA" : "#000000DE" }}>
            {data.chooserCount}명 ({Math.round(percentage * 100)}%)
          </QuizOption>
        </ChoiceBarInner>
      </ChoiceBarOuter>
    </Column>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 32px;
`;

const BetweenRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Column = styled.div`
  flex-direction: column;
`;
const Caption = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #00000099;
`;
const Title = styled.div`
  color: #000000de;
  font-size: 20px;
  font-weight: 500;
`;
const QuizFinish = styled.div`
  color: #0894a0;
  font-size: 14px;
  font-weight: 500;
`;
const QuizTitle = styled.div`
  color: #000000de;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;
const Description = styled.div`
  color: #000000de;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;
const QuizOptionTitle = styled.div`
  color: #00000099;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;
const ChoiceBarOuter = styled.div`
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 4px;
`;
const ChoiceBarInner = styled.div`
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 6px 12px;
`;
const QuizOption = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export default QuizResult;
